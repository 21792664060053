import React, { useState } from "react";
import CiroDropdown from "../../shared/CiroDropdown";
import { IFilteredContact } from "./FinalizePushToCrm";
import { gql, useLazyQuery } from "@apollo/client";
import {
  FinalizePushToCrmAccountDropdown_CrmAccountTextSearchQuery,
  FinalizePushToCrmAccountDropdown_CrmAccountTextSearchQueryVariables,
} from "../../../__generated__/graphql";

const FinalizePushToCrmAccountDropdown_CrmAccountTextSearch = gql`
  query FinalizePushToCrmAccountDropdown_CrmAccountTextSearch($query: String!) {
    crmAccountTextSearch(query: $query) {
      records {
        id
        name
      }
    }
  }
`;

const CREATE_NEW_ACCOUNT_OPTION = {
  label: "+ Create new account",
  value: "NEW_ACCOUNT",
};

const FinalizePushToCrmAccountDropdown = ({
  setFilteredContacts,
  contactList,
  contactId,
  contactAccountId,
  options,
}: {
  setFilteredContacts: (filteredContacts: IFilteredContact[]) => void;
  contactList: IFilteredContact[];
  contactId: number;
  contactAccountId: string;
  options: {
    label: string;
    value: string;
  }[];
}) => {
  const [currentOptions, setCurrentOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([...options, CREATE_NEW_ACCOUNT_OPTION]);

  const fetchCrmAccountTextSearch = (inputValue: string) => {
    return findCrmAccountTextSearch({
      variables: {
        query: inputValue,
      },
    })
      .then((res) => {
        return [
          CREATE_NEW_ACCOUNT_OPTION,
          ...(res.data?.crmAccountTextSearch.records || []).map((record) => {
            return {
              label: record.name,
              value: record.id,
            };
          }),
        ];
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  const [findCrmAccountTextSearch] = useLazyQuery<
    FinalizePushToCrmAccountDropdown_CrmAccountTextSearchQuery,
    FinalizePushToCrmAccountDropdown_CrmAccountTextSearchQueryVariables
  >(FinalizePushToCrmAccountDropdown_CrmAccountTextSearch);
  return (
    <CiroDropdown
      async={true}
      isMulti={false}
      defaultOptions
      isClearable={false}
      options={currentOptions}
      loadOptions={fetchCrmAccountTextSearch}
      onChangeFullValues={(newValue) => {
        setFilteredContacts(
          contactList.map((filteredContact) => {
            if (contactId === filteredContact.id) {
              return {
                ...filteredContact,
                org_contact: {
                  ...filteredContact.org_contact,
                  accountId: newValue.value,
                },
              };
            }
            return filteredContact;
          }),
        );
        setCurrentOptions([newValue]);
      }}
      value={contactAccountId}
      menuPortalTarget={document.body}
    />
  );
};

export default FinalizePushToCrmAccountDropdown;
