import classNames from "classnames";
import { gql, useLazyQuery } from "@apollo/client";
import ContactListContactTable, {
  ContactListContactTable_ContactList,
} from "./ContactListContactTable";
import {
  AutomationListFindEmailsButton_ContactListFragmentDoc,
  AutomationListFindNumbersButton_ContactListFragmentDoc,
  AutomationListHome_ContactListFragment,
  AutomationListHome_PushToCrmTransactionQuery,
  AutomationListHome_PushToCrmTransactionQueryVariables,
  AutomationListHomeDownloadButton_ContactListFragmentDoc,
  ContactListContactTable_ContactListFragmentDoc,
  CrmObjectType,
  InitiatePushToCrmButton_PushToCrmTransactionFragmentDoc,
  PushToCrmTransactionDetailsModal_PushToCrmTransactionFragmentDoc,
} from "../../../__generated__/graphql";
import { useFragment } from "../../../__generated__";
import AutomationListHomeDownloadButton, {
  AutomationListHomeDownloadButton_ContactList,
} from "./AutomationListHomeDownloadButton";
import AutomationListFindNumbersButton, {
  AutomationListFindNumbersButton_ContactList,
} from "./AutomationListFindNumbersButton";
import { AutomationListStateEnum } from "../../../routes/automationList/AutomationList";
import InitiatePushToCrmButton, {
  InitiatePushToCrmButton_PushToCrmTransaction,
} from "./InitiatePushToCrmButton";
import { useContext, useEffect, useState } from "react";
import PushToCrmTransactionDetailsModal, {
  PushToCrmTransactionDetailsModal_PushToCrmTransaction,
} from "./PushToCrmTransactionDetailsModal";
import AutomationListHomeFilterButton from "./AutomationListHomeFilterButton";
import { IContactListFilters } from "../../../reactHooks/filters/contactList/useContactListFiltersAndSort";
import ListContext from "../../../contexts/ListContext";
import AutomationListFindEmailsButton, {
  AutomationListFindEmailsButton_ContactList,
} from "./AutomationListFindEmailsButton";
import AutomationListHideDisqualifiedSwitch from "./AutomationListHideDisqualifiedSwitch";

export const AutomationListHome_ContactList = gql`
  fragment AutomationListHome_ContactList on ContactList {
    id
    name
    mostRecentPushToCrmTransaction {
      id
      finished_at
      error
      ...InitiatePushToCrmButton_PushToCrmTransaction
      ...PushToCrmTransactionDetailsModal_PushToCrmTransaction
    }
    contacts {
      id
      org_contact {
        orgEmails {
          email
        }
      }
    }
    ...ContactListContactTable_ContactList
    ...AutomationListHomeDownloadButton_ContactList
    ...AutomationListFindNumbersButton_ContactList
    ...AutomationListFindEmailsButton_ContactList
  }
  ${ContactListContactTable_ContactList}
  ${AutomationListHomeDownloadButton_ContactList}
  ${AutomationListFindNumbersButton_ContactList}
  ${AutomationListFindEmailsButton_ContactList}
  ${InitiatePushToCrmButton_PushToCrmTransaction}
  ${PushToCrmTransactionDetailsModal_PushToCrmTransaction}
`;

const AutomationListHome_PushToCrmTransaction = gql`
  query AutomationListHome_PushToCrmTransaction($id: Int!) {
    pushToCrmTransaction(id: $id) {
      id
      ...InitiatePushToCrmButton_PushToCrmTransaction
      ...PushToCrmTransactionDetailsModal_PushToCrmTransaction
      created_at
      finished_at
      error
    }
  }

  ${InitiatePushToCrmButton_PushToCrmTransaction}
  ${PushToCrmTransactionDetailsModal_PushToCrmTransaction}
`;

const AutomationListHome = ({
  contactList,
  setViewState,
  setSelectedCrmObject,
  filters,
  filteredContactIdsToOrgContactIds,
  missingPersonaCount,
}: {
  contactList: AutomationListHome_ContactListFragment | null;
  setViewState: (viewState: AutomationListStateEnum) => void;
  setSelectedCrmObject: (crmObject: CrmObjectType) => void;
  filters: IContactListFilters;
  filteredContactIdsToOrgContactIds: Map<number, number>;
  missingPersonaCount: number;
}) => {
  const {
    numFiltersApplied,
    resetAll,
    hideDisqualifiedPersonas,
    setHideDisqualifiedPersonas,
  } = filters;

  const tableContacts = useFragment(
    ContactListContactTable_ContactListFragmentDoc,
    contactList,
  );
  const findNumbersButtonContactList = useFragment(
    AutomationListFindNumbersButton_ContactListFragmentDoc,
    contactList,
  );
  const findEmailsButtonContactList = useFragment(
    AutomationListFindEmailsButton_ContactListFragmentDoc,
    contactList,
  );
  const downloadButtonContactList = useFragment(
    AutomationListHomeDownloadButton_ContactListFragmentDoc,
    contactList,
  );

  const [getPushToCrmTransaction] = useLazyQuery<
    AutomationListHome_PushToCrmTransactionQuery,
    AutomationListHome_PushToCrmTransactionQueryVariables
  >(AutomationListHome_PushToCrmTransaction, {
    fetchPolicy: "network-only",
  });

  const [pushToCrmTransaction, setPushToCrmTransaction] = useState<
    | AutomationListHome_PushToCrmTransactionQuery["pushToCrmTransaction"]
    | AutomationListHome_ContactListFragment["mostRecentPushToCrmTransaction"]
    | null
  >(contactList?.mostRecentPushToCrmTransaction ?? null);

  const {
    selectedContactIdsToOrgContactIds,
    setSelectedContactIdsToOrgContactIds,
  } = useContext(ListContext);

  useEffect(() => {
    setPushToCrmTransaction(
      contactList?.mostRecentPushToCrmTransaction ?? null,
    );
  }, [contactList?.mostRecentPushToCrmTransaction]);

  useEffect(() => {
    const pushToCrmTransactionId =
      contactList?.mostRecentPushToCrmTransaction?.id;
    if (pushToCrmTransactionId) {
      let timeoutId: NodeJS.Timeout;

      const pollTransaction = async () => {
        const result = await getPushToCrmTransaction({
          variables: { id: pushToCrmTransactionId },
          onError: (error) => {
            console.error("Error polling push to CRM transaction:", error);
          },
        });

        setPushToCrmTransaction(result.data?.pushToCrmTransaction ?? null);

        if (
          result.data?.pushToCrmTransaction &&
          !result.data.pushToCrmTransaction.finished_at
        ) {
          timeoutId = setTimeout(pollTransaction, 2000);
        }
      };

      pollTransaction();

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [
    contactList?.mostRecentPushToCrmTransaction?.id,
    getPushToCrmTransaction,
  ]);

  const initiatePushToCrmButtonPushToCrmTransaction = useFragment(
    InitiatePushToCrmButton_PushToCrmTransactionFragmentDoc,
    pushToCrmTransaction,
  );
  const pushToCrmTransactionDetailsModalPushToCrmTransaction = useFragment(
    PushToCrmTransactionDetailsModal_PushToCrmTransactionFragmentDoc,
    pushToCrmTransaction,
  );

  const getSelectedOrFilteredIds = (useValues: boolean = false) => {
    const map =
      selectedContactIdsToOrgContactIds.size > 0
        ? selectedContactIdsToOrgContactIds
        : filteredContactIdsToOrgContactIds;

    return Array.from(useValues ? map.values() : map.keys());
  };

  const allSelectedContactsHaveEmails = contactList?.contacts
    ?.filter((contact) => getSelectedOrFilteredIds(false).includes(contact.id))
    ?.every((contact) => contact.org_contact.orgEmails.length > 0);

  if (!contactList) {
    return null;
  }

  const listName = contactList.name;

  return (
    <>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "pb-6",
        )}
      >
        <div>
          <h1 className={classNames("text-2xl")}>Manage {listName}</h1>
          <div className={classNames("pt-1")}>
            <PushToCrmTransactionDetailsModal
              pushToCrmTransaction={
                pushToCrmTransactionDetailsModalPushToCrmTransaction ?? null
              }
            />
          </div>
        </div>
        <div className={classNames("flex", "gap-2")}>
          <AutomationListHomeFilterButton
            resetAll={resetAll}
            numFiltersApplied={numFiltersApplied}
            filters={filters}
          />
          <AutomationListHideDisqualifiedSwitch
            hideDisqualifiedPersonas={hideDisqualifiedPersonas}
            setHideDisqualifiedPersonas={setHideDisqualifiedPersonas}
          />
          <AutomationListHomeDownloadButton
            list={downloadButtonContactList ?? null}
            contactIds={getSelectedOrFilteredIds(false)}
            showNumberSelected={selectedContactIdsToOrgContactIds.size > 0}
          />
          <AutomationListFindNumbersButton
            contactIds={getSelectedOrFilteredIds(true)}
            contactList={findNumbersButtonContactList ?? null}
          />
          <AutomationListFindEmailsButton
            contactIds={getSelectedOrFilteredIds(true)}
            contactList={findEmailsButtonContactList ?? null}
          />
          <InitiatePushToCrmButton
            allSelectedContactsHaveEmails={allSelectedContactsHaveEmails}
            contactIds={getSelectedOrFilteredIds(true)}
            pushToCrmTransaction={
              initiatePushToCrmButtonPushToCrmTransaction ?? null
            }
            setSelectedCrmObject={setSelectedCrmObject}
            setViewState={setViewState}
          />
        </div>
      </div>
      <div
        className={classNames(
          "w-full",
          "overflow-y-auto",
          "overflow-x-auto",
        )}
      >
        <ContactListContactTable
          contactsList={tableContacts}
          filters={filters}
          filteredContactIds={Array.from(
            filteredContactIdsToOrgContactIds.keys(),
          )}
          selectedContactIdsToOrgContactIds={selectedContactIdsToOrgContactIds}
          setSelectedContactIdsToOrgContactIds={
            setSelectedContactIdsToOrgContactIds
          }
          missingPersonaCount={missingPersonaCount}
        />
      </div>
    </>
  );
};

export default AutomationListHome;
