import { InternalRefetchQueriesInclude, gql } from "@apollo/client";
import {
  AccountsUpdateCollection_PermissionsFragment,
  CompaniesFromFiltersInput,
  Maybe,
} from "../../__generated__/graphql";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../shared/CiroDropdownButton";
import AccountsAddToCollectionModal from "./AccountsAddToCollectionModal";
import classNames from "classnames";
import { useState } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import AccountsRemoveFromCollectionModal from "./AccountsRemoveFromCollectionModal";

export const AccountsUpdateCollection_Permissions = gql`
  fragment AccountsUpdateCollection_Permissions on Permissions {
    canEditCollectionsByPk
    canEditCollectionsByQuery
  }
`;

interface IAccountsUpdateCollectionProps {
  filteredCompanyQueryVariables?: CompaniesFromFiltersInput;
  numberChecked: number;
  numFiltersApplied?: number;
  permissions?: Maybe<AccountsUpdateCollection_PermissionsFragment>;
  refetchQueries?: InternalRefetchQueriesInclude;
  selectedCompanyPks: Set<string>;
  setCheckedCompanies: (v: Set<string>) => void;
}

const AccountsUpdateCollection = ({
  filteredCompanyQueryVariables,
  numberChecked,
  numFiltersApplied,
  permissions,
  refetchQueries = [],
  selectedCompanyPks,
  setCheckedCompanies
}: IAccountsUpdateCollectionProps) => {
  const [addAccountsModalOpen, setAddAccountsModalOpen] = useState(false);
  const [removeAccountsModalOpen, setRemoveAccountsModalOpen] = useState(false);

  if (!permissions) {
    return null;
  }

  const { canEditCollectionsByPk, canEditCollectionsByQuery } = permissions;
  if (
    !Boolean(canEditCollectionsByPk && numberChecked) &&
    !Boolean(canEditCollectionsByQuery && numFiltersApplied)
  ) {
    return null;
  }

  return (
    <div>
      <CiroDropdownButton
        label={<span className="flex">Update collection</span>}
      >
        <CiroDropdownButtonItem
          onClick={() => {
            setAddAccountsModalOpen(true);
          }}
          analyticsField="Open add collections to account modal"
        >
          <div className={classNames(["flex"])}>
            <PlusCircleIcon
              className={classNames(["mr-2", "h-5 w-5"])}
              aria-hidden="true"
            />
            <div>Add</div>
          </div>
        </CiroDropdownButtonItem>
        <CiroDropdownButtonItem
          onClick={() => {
            setRemoveAccountsModalOpen(true);
          }}
          analyticsField="Open remove collections from account modal"
        >
          <div className={classNames(["flex"])}>
            <MinusCircleIcon
              className={classNames(["mr-2", "h-5 w-5"])}
              aria-hidden="true"
            />
            <div>Remove</div>
          </div>
        </CiroDropdownButtonItem>
      </CiroDropdownButton>
      <AccountsAddToCollectionModal
        filteredCompanyQueryVariables={filteredCompanyQueryVariables}
        isOpen={addAccountsModalOpen}
        numberChecked={numberChecked}
        selectedCompanyPks={[...selectedCompanyPks]}
        refetchQueries={refetchQueries}
        setIsOpen={setAddAccountsModalOpen}
        setCheckedCompanies={setCheckedCompanies}
      />
      <AccountsRemoveFromCollectionModal
        filteredCompanyQueryVariables={filteredCompanyQueryVariables}
        isOpen={removeAccountsModalOpen}
        numberChecked={numberChecked}
        selectedCompanyPks={[...selectedCompanyPks]}
        setIsOpen={setRemoveAccountsModalOpen}
        setCheckedCompanies={setCheckedCompanies}
      />
    </div>
  );
};

export default AccountsUpdateCollection;
