import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import { gql, useQuery } from "@apollo/client";
import ApolloIntegrationUpdateForm, {
  ApolloIntegrationUpdateForm_ApolloIntegration,
} from "../../components/mobileNumbers/mobileNumbersIntegrationModal/apollo/ApolloIntegrationUpdateForm";
import { useEffect, useState } from "react";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import { useFragment } from "../../__generated__";
import {
  ApolloIntegrationUpdateForm_ApolloIntegrationFragment,
  ApolloMobileConnection_ApolloIntegrationQuery,
  ApolloMobileConnection_ApolloIntegrationQueryVariables,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";

export const ApolloMobileConnection_ApolloIntegration = gql`
  query ApolloMobileConnection_ApolloIntegration {
    userAccount {
      org {
        apolloIntegration {
          api_key
          bad_number_dispositions
          ...ApolloIntegrationUpdateForm_ApolloIntegration
        }
      }
    }
  }
  ${ApolloIntegrationUpdateForm_ApolloIntegration}
`;

const ApolloMobileConnection = () => {
  const [isInitialSetup, setIsInitialSetup] = useState(true);
  const { data, loading } = useQuery<
    ApolloMobileConnection_ApolloIntegrationQuery,
    ApolloMobileConnection_ApolloIntegrationQueryVariables
  >(ApolloMobileConnection_ApolloIntegration);

  const apolloIntegrationUpdateFormFragment = useFragment(
    ApolloIntegrationUpdateForm_ApolloIntegration,
    data?.userAccount?.org?.apolloIntegration,
  ) as ApolloIntegrationUpdateForm_ApolloIntegrationFragment;

  useEffect(() => {
    if (data?.userAccount?.org?.apolloIntegration?.bad_number_dispositions) {
      setIsInitialSetup(false);
    }
  }, [data, isInitialSetup]);

  if (loading)
    return (
      <div
        className={classNames(
          "w-full",
          "px-4",
          "pt-16",
        )}
      >
        <div
          className={classNames(
            "flex",
            "flex-row",
            "justify-between",
            "gap-4",
            "w-full",
          )}
        >
          <div className={classNames("w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20rem"} />
          </div>
        </div>
      </div>
    );

  return (
    <CiroContainer
      className={classNames("bg-zinc-100", "w-full")}
    >
      <CiroBreadCrumbs
        values={["Enrich CRM", "Apollo connection"]}
        href="/enrich-crm"
      />
      <div
        className={classNames(
          "flex",
          "justify-between",
          "w-full",
        )}
      >
        <h1
          className={classNames(
            "text-neutral-900",
            "text-xl",
            "font-semibold",
          )}
        >
          {isInitialSetup ? "Connect with Apollo" : "Manage Apollo connection"}
        </h1>
      </div>

      <div className={classNames("flex", "gap-6")}>
        <div>
          <h1
            className={classNames(
              "text-neutral-900",
              "text-base",
              "font-bold",
              "mb-2",
            )}
          >
            Call metrics
          </h1>
          <div
            className={classNames(
              "text-neutral-500",
              "text-sm",
              "font-normal",
            )}
          >
            Track the success of calls made to numbers enriched by Ciro.
          </div>
        </div>
        <div
          className={classNames(
            "bg-white",
            "p-4",
            "rounded-lg",
            "border",
            "border-neutral-200",
            "w-full",
          )}
        >
          <ApolloIntegrationUpdateForm
            apolloIntegration={apolloIntegrationUpdateFormFragment}
            initialSetup={isInitialSetup}
          />
        </div>
      </div>
    </CiroContainer>
  );
};

export default ApolloMobileConnection;
