import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  IFilterEnrichmentStep,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import {
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { useFragment } from "../../../../__generated__";
import { useEffect, useMemo } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import { IEnrichmentStepTechniqueCardProps } from ".";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

const CRM_ACCOUNT_KEY = "crmAccount";

const EnrichmentStepTechniqueFindCiroCompanyCardSchema = yup
  .object({
    enrichment_technique: yup
      .string()
      .required("Enrichment Technique is required"),
    parentEnrichmentStepInput: yup
      .object({
        key: yup.string().required("Key is required"),
        input: yup.string().nullable(),
        required: yup.boolean().required("Required is required"),
        sourceEnrichmentStepId: yup.number().nullable(),
      })
      .required("Input is required"),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required();

interface FindCiroCompanyCardFormData {
  enrichment_technique: EnrichmentTechniqueEnum;
  parentEnrichmentStepInput: {
    key: string;
    input: string | null;
    required: boolean;
    sourceEnrichmentStepId: number | null;
  };
  filterEnrichmentSteps: IFilterEnrichmentStep[];
}

const EnrichmentStepTechniqueFindCiroCompanyCard = ({
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const defaultValues = useMemo(() => {
    const findCompanyInput = enrichmentStep?.parentEnrichmentStepInputs.find(
      (stepInput) => {
        return stepInput.key === CRM_ACCOUNT_KEY;
      },
    );

    const filterEnrichmentSteps = parseEnrichmentStepsForList(
      enrichmentStepWithFilters,
    );

    return (
      {
        enrichment_technique: EnrichmentTechniqueEnum.FindCiroCompany,
        parentEnrichmentStepInput: {
          key: findCompanyInput?.key || CRM_ACCOUNT_KEY,
          input: findCompanyInput?.input || "null",
          required: true,
          sourceEnrichmentStepId:
            findCompanyInput?.sourceEnrichmentStep?.id || null,
        },
        filterEnrichmentSteps: filterEnrichmentSteps,
      } || []
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const methods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueFindCiroCompanyCardSchema),
    defaultValues,
  });
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    return () => {
      reset();
    };
  }, [defaultValues, reset]);

  const [parentEnrichmentStepInput, filterEnrichmentSteps] = useWatch({
    control,
    name: ["parentEnrichmentStepInput", "filterEnrichmentSteps"],
  });

  const formatAndSubmitRequest = (data: FindCiroCompanyCardFormData) => {
    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: data.enrichment_technique,
          selected_input: null,
          parentEnrichmentStepInputs: [data.parentEnrichmentStepInput],
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div className={classNames("pt-4")}>
        CRM Account ID:
        <div>
          <EnrichmentStepSelector
            inputError={errors.parentEnrichmentStepInput?.input?.message}
            stepVariable={{
              stepId: parentEnrichmentStepInput.sourceEnrichmentStepId || null,
              stepInput: JSON.parse(parentEnrichmentStepInput.input),
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "parentEnrichmentStepInput.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue(
                "parentEnrichmentStepInput.input",
                JSON.stringify(newVariable?.stepInput),
              );
            }}
          />
        </div>
      </div>
      <div className={classNames("mt-12")}>
        <FormProvider {...methods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueFindCiroCompanyCard;
