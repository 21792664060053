import classNames from "classnames";
import { useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import XIcon from "../../assets/img/icons/XIcon";
import React from "react";

interface ICiroAlertProps {
  message: string;
  level?: "error" | "info";
}

const CiroAlert = ({ message, level = "error" }: ICiroAlertProps) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return null;

  return (
    <div
      className={classNames(
        "border",
        "mb-8",
        "p-4",
        "rounded-xl",
        "text-sm",
        "flex",
        "items-start",
        "justify-between",
        {
          "bg-red-100": level === "error",
          "border-red-400": level === "error",
          "text-red-700": level === "error",
          "bg-gray-100": level === "info",
          "border-gray-400": level === "info",
        },
      )}
    >
      {message}
      <CiroButton
        style={CiroButtonStyleEnum.UNSTYLED}
        analyticsField="Close alert for modal"
        onClick={() => setIsOpen(false)}
      >
        <XIcon />
      </CiroButton>
    </div>
  );
};

export default CiroAlert;
