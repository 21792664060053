import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroButton, { CiroButtonStyleEnum } from "../../components/shared/CiroButton";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import CiroIcon from "../../assets/img/icons/CiroIcon";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const AutomationSplashPage = ({ onCreateNewList }: { onCreateNewList: () => void }) => {
  return (
    <CiroContainer
      className={classNames(
        "bg-neutral-100",
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        "min-h-[80vh]",
        "w-full",
        "xl:px-80",
        "sm:px-20",
      )}
    >
      <div
        className={classNames([
          "flex",
          "flex-col",
          "items-center",
          "justify-center",
          "min-h-[80vh]",
          "w-full",
          "px-4",
        ])}
      >
        <div
          className={classNames([
            "flex",
            "items-center",
            "gap-4",
            "mb-8",
          ])}
        >
          <div
            className={classNames(
              "bg-white",
              "h-[80px]",
              "w-[80px]",
              "flex",
              "items-center",
              "justify-center",
              "rounded-lg",
              "shadow-sm",
            )}
          >
            <CiroIcon width="32" height="32" />
          </div>

          <div
            className={classNames(
              "bg-neutral-100",
              "rounded-full",
              "mx-[-30px]",
              "z-10",
              "p-2"
            )}
          >
            <ArrowPathIcon className="w-6 h-6 text-orange-600" />
          </div>

          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "h-[80px]",
              "w-[80px]",
              "flex",
              "items-center",
              "justify-center",
              "shadow-sm",
            )}
          >
            <LinkedInIcon />
          </div>
        </div>

        <h1
          className={classNames([
            "text-2xl",
            "font-semibold",
            "mb-4",
            "text-center",
          ])}
        >
          Start automating your workflow now
        </h1>

        <p
          className={classNames([
            "text-center",
            "text-gray-600",
            "max-w-lg",
            "mb-8",
          ])}
        >
          Ciro helps you spend less time doing manual work by automating
          research on LinkedIn. Create a list, define some personas you'd like to add, and we'll do the heavy lifting to
          find high-fit contacts on LinkedIn.
        </p>

        <CiroButton
          onClick={onCreateNewList}
          analyticsField="Create new contact list"
          style={CiroButtonStyleEnum.LOUD}
        >
          Create new list
        </CiroButton>
      </div>
    </CiroContainer>
  );
};

export default AutomationSplashPage;
