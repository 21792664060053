import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  SpecialtiesFilter_SpecialtiesQuery,
  SpecialtiesFilter_SpecialtiesQueryVariables,
} from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface ISpecialtiesFilterProps {
  allSpecialtiesFilter: string[];
  blurred?: boolean;
  defaultOpen?: boolean;
  industryFilter: string[];
  notSpecialtiesFilter: string[];
  resetOffset?: () => void;
  setAllSpecialtiesFilter: (v: string[]) => void;
  setNotSpecialtiesFilter: (v: string[]) => void;
  setSpecialtiesFilter: (v: string[]) => void;
  specialtiesFilter: string[];
}

export const SpecialtiesFilter_specialties = gql`
  query SpecialtiesFilter_specialties(
    $industries: [String]
    $specialty_name: String
  ) {
    specialties(industries: $industries, specialty_name: $specialty_name)
  }
`;

function SpecialtiesFilter({
  allSpecialtiesFilter,
  blurred,
  defaultOpen = false,
  industryFilter,
  notSpecialtiesFilter,
  resetOffset,
  setAllSpecialtiesFilter,
  setNotSpecialtiesFilter,
  setSpecialtiesFilter,
  specialtiesFilter,
}: ISpecialtiesFilterProps) {
  const [selectedSpecialtiesAny, setSelectedSpecialtiesAny] = useState(
    [] as { value: string; label: string }[],
  );
  const [selectedSpeciatiesAll, setSelectedSpecialtiesAll] = useState(
    [] as { value: string; label: string }[],
  );
  const [selectedSpecialtiesExclude, setSelectedSpecialtiesExclude] = useState(
    [] as { value: string; label: string }[],
  );

  const [fetchSpecialties] = useLazyQuery<
    SpecialtiesFilter_SpecialtiesQuery,
    SpecialtiesFilter_SpecialtiesQueryVariables
  >(SpecialtiesFilter_specialties);

  useEffect(() => {
    setSelectedSpecialtiesAny(
      specialtiesFilter.map((specialty) => {
        return {
          value: specialty,
          label: specialty,
        };
      }),
    );
    setSelectedSpecialtiesAll(
      allSpecialtiesFilter.map((specialty) => {
        return {
          value: specialty,
          label: specialty,
        };
      }),
    );
    setSelectedSpecialtiesExclude(
      notSpecialtiesFilter.map((specialty) => {
        return {
          value: specialty,
          label: specialty,
        };
      }),
    );
  }, [specialtiesFilter, allSpecialtiesFilter, notSpecialtiesFilter]);

  const fetchSpecialtiesOptions = (inputValue: string) => {
    return fetchSpecialties({
      variables: {
        industries: industryFilter,
        specialty_name: inputValue,
      },
    }).then((res) => {
      return (res.data?.specialties || []).map((specialty) => {
        return {
          value: specialty,
          label: specialty,
        };
      });
    });
  };

  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={
        (allSpecialtiesFilter.length ? 1 : 0) +
        (specialtiesFilter.length ? 1 : 0) +
        (notSpecialtiesFilter.length ? 1 : 0)
      }
      sectionTitle="Categories & Services"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              async={true}
              isMulti
              blurred={blurred}
              defaultOptions
              loadOptions={fetchSpecialtiesOptions}
              options={selectedSpecialtiesAny}
              value={specialtiesFilter}
              onChangeFullValues={(newValue) => {
                setSelectedSpecialtiesAny(newValue);
                const newValues = newValue.map((item) => item?.value) as any;
                setSpecialtiesFilter(newValues);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
        {
          label: "Include all of:",
          filter: (
            <CiroDropDown
              async={true}
              isMulti
              blurred={blurred}
              defaultOptions
              loadOptions={fetchSpecialtiesOptions}
              options={selectedSpeciatiesAll}
              value={allSpecialtiesFilter}
              onChangeFullValues={(newValue) => {
                setSelectedSpecialtiesAll(newValue);
                const newValues = newValue.map((item) => item?.value) as any;
                setAllSpecialtiesFilter(newValues);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              async={true}
              isMulti={true}
              blurred={blurred}
              defaultOptions
              loadOptions={fetchSpecialtiesOptions}
              options={selectedSpecialtiesExclude}
              value={notSpecialtiesFilter}
              onChangeFullValues={(newValue) => {
                setSelectedSpecialtiesExclude(newValue);
                const newValues = newValue.map((item) => item?.value) as any;
                setNotSpecialtiesFilter(newValues);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default SpecialtiesFilter;
