import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { PhoneIcon } from "@heroicons/react/24/outline";
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  AutomationListFindNumbersButton_ContactListFragment,
  AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransactionMutation,
  AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransactionMutationVariables,
  AutomationListFindNumbersButton_CurrentBalanceQuery,
  AutomationListFindNumbersButton_CurrentBalanceQueryVariables,
  AutomationListFindNumbersButton_PhoneNumberRequestTransactionQuery,
  AutomationListFindNumbersButton_PhoneNumberRequestTransactionQueryVariables,
} from "../../../__generated__/graphql";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import pluralize from "pluralize";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroModal from "../../shared/CiroModal";
import NumberFormat from "react-number-format";
import CiroSpinner from "../../shared/CiroSpinner";
export const CREDITS_PER_PHONE_NUMBER = 10;

export const AutomationListFindNumbersButton_ContactList = gql`
  fragment AutomationListFindNumbersButton_ContactList on ContactList {
    id
    name
    mostRecentPhoneNumberRequestTransaction {
      id
      finished_at
      error
    }
  }
`;

const AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransaction = gql`
  mutation AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransaction(
    $input: CreateContactListPhoneNumberRequestsInput!
  ) {
    createContactListPhoneNumberRequests(input: $input) {
      message
      success
      transaction {
        id
      }
    }
  }
`;

const AutomationListFindNumbersButton_PhoneNumberRequestTransaction = gql`
  query AutomationListFindNumbersButton_PhoneNumberRequestTransaction(
    $id: Int!
  ) {
    phoneNumberRequestTransaction(id: $id) {
      id
      finished_at
      error
      orgContacts {
        id
        orgPhoneNumbers {
          phone_number
        }
      }
    }
  }
`;

const AutomationListFindNumbersButton_CurrentBalance = gql`
  query AutomationListFindNumbersButton_CurrentBalance {
    userAccount {
      org {
        credit_plan {
          balance
        }
      }
    }
  }
`;

const AutomationListFindNumbersButton = ({
  contactList,
  contactIds,
}: {
  contactList: AutomationListFindNumbersButton_ContactListFragment | null;
  contactIds: number[];
}) => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    currentPhoneNumberRequestTransactionId,
    setCurrentPhoneNumberRequestTransactionId,
  ] = useState<number | null>(null);

  const [createContactPhoneNumberRequests] = useMutation<
    AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransactionMutation,
    AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransactionMutationVariables
  >(AutomationListFindNumbersButton_CreatePhoneNumbersRequestTransaction);

  const [getPhoneNumberRequestTransaction] = useLazyQuery<
    AutomationListFindNumbersButton_PhoneNumberRequestTransactionQuery,
    AutomationListFindNumbersButton_PhoneNumberRequestTransactionQueryVariables
  >(AutomationListFindNumbersButton_PhoneNumberRequestTransaction);

  const [getBalance, { data: balanceData, loading: balanceLoading }] =
    useLazyQuery<
      AutomationListFindNumbersButton_CurrentBalanceQuery,
      AutomationListFindNumbersButton_CurrentBalanceQueryVariables
    >(AutomationListFindNumbersButton_CurrentBalance, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (
      contactList?.mostRecentPhoneNumberRequestTransaction &&
      !contactList.mostRecentPhoneNumberRequestTransaction.finished_at &&
      !contactList.mostRecentPhoneNumberRequestTransaction.error
    ) {
      setIsLoading(true);
      setCurrentPhoneNumberRequestTransactionId(
        contactList.mostRecentPhoneNumberRequestTransaction.id,
      );
    }
  }, [contactList?.mostRecentPhoneNumberRequestTransaction]);

  const currentBalance =
    balanceData?.userAccount?.org?.credit_plan?.balance || 0;

  useEffect(() => {
    if (currentPhoneNumberRequestTransactionId) {
      const pollInterval = 3000;

      const pollTransaction = async () => {
        const { data } = await getPhoneNumberRequestTransaction({
          variables: { id: currentPhoneNumberRequestTransactionId },
          fetchPolicy: "network-only",
        });

        if (data?.phoneNumberRequestTransaction) {
          const transaction = data.phoneNumberRequestTransaction;

          transaction.orgContacts.forEach((orgContact) => {
            client.cache.modify({
              id: client.cache.identify({
                __typename: "OrgContact",
                id: orgContact.id,
              }),
              fields: {
                orgPhoneNumbers() {
                  return orgContact.orgPhoneNumbers;
                },
              },
            });
          });

          // Keep polling if transaction isn't finished
          if (!transaction.finished_at && !transaction.error) {
            setTimeout(pollTransaction, pollInterval);
          } else {
            setIsLoading(false);
          }
        }
      };

      pollTransaction();
    }
  }, [
    currentPhoneNumberRequestTransactionId,
    getPhoneNumberRequestTransaction,
    client.cache,
  ]);

  const handleFindNumbers = useCallback(
    async (ids: number[]) => {
      setIsLoading(true);
      if (!contactList) {
        return;
      }

      const { data } = await createContactPhoneNumberRequests({
        variables: {
          input: {
            orgContactIds: ids,
            contactListId: contactList.id,
          },
        },
      });

      if (data?.createContactListPhoneNumberRequests?.success) {
        setCurrentPhoneNumberRequestTransactionId(
          data.createContactListPhoneNumberRequests.transaction?.id ?? null,
        );
      }
    },
    [createContactPhoneNumberRequests, contactList],
  );

  const totalCreditsNeeded = contactIds.length * CREDITS_PER_PHONE_NUMBER;

  let tooltipMessage = "Find phone numbers";
  if (isLoading) {
    tooltipMessage = "Running find phone numbers. This may take a few minutes";
  } else if (contactIds.length === 0) {
    tooltipMessage = "No contacts selected";
  } else if (contactList?.mostRecentPhoneNumberRequestTransaction) {
    const lastUpdateDate = new Date(
      contactList.mostRecentPhoneNumberRequestTransaction.finished_at,
    );
    tooltipMessage = `Last updated: ${formatDistanceToNow(lastUpdateDate)} ago`;
  }

  return (
    <>
      <CiroTooltipContainer
        tooltip={tooltipMessage}
        disabled={
          !isLoading && !contactList?.mostRecentPhoneNumberRequestTransaction
        }
      >
        <CiroButton
          onClick={() => {
            getBalance();
            setIsModalOpen(true);
          }}
          disabled={isLoading || contactIds.length === 0}
          analyticsField={"Start find numbers for contact list"}
          analyticsProps={{
            contactListId: contactList?.id,
          }}
        >
          <div className={classNames("flex", "items-center")}>
            {isLoading ? (
              <CiroSpinner loading={true} />
            ) : (
              <PhoneIcon className={classNames("w-5", "h-5")} />
            )}
          </div>
        </CiroButton>
      </CiroTooltipContainer>
      <CiroModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {balanceLoading ? (
          <div
            className={classNames(
              "flex",
              "justify-center",
              "py-10",
            )}
          >
            <CiroSpinner loading={true} />
          </div>
        ) : (
          <div>
            <div
              className={classNames(
                "text-lg",
                "font-medium",
                "pb-4",
              )}
            >
              Find numbers for {contactIds.length}{" "}
              {pluralize("contact", contactIds.length)}
            </div>
            <div className={classNames("pb-4")}>
              <div className={classNames("font-medium")}>
                Total credits needed:{" "}
                <NumberFormat
                  value={totalCreditsNeeded}
                  thousandSeparator=","
                  displayType="text"
                />
              </div>
              {totalCreditsNeeded > currentBalance && (
                <div
                  className={classNames(
                    "text-xs",
                    "text-red-500",
                    "mt-2",
                  )}
                >
                  Not enough credits to find phone numbers
                </div>
              )}
            </div>
            <div
              className={classNames(
                "flex",
                "justify-end",
                "gap-2",
              )}
            >
              <CiroButton
                analyticsField={"Cancel find numbers"}
                onClick={() => setIsModalOpen(false)}
                style={CiroButtonStyleEnum.INVERTED_LOUD}
              >
                Cancel
              </CiroButton>
              <CiroButton
                analyticsField={"Run find numbers for contact list"}
                disabled={totalCreditsNeeded > currentBalance}
                onClick={() => {
                  setIsModalOpen(false);
                  handleFindNumbers([...contactIds]);
                }}
                style={CiroButtonStyleEnum.LOUD}
              >
                Find numbers
              </CiroButton>
            </div>
          </div>
        )}
      </CiroModal>
    </>
  );
};

export default AutomationListFindNumbersButton;
