import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroAlert from "../shared/CiroAlert";
import ClockIcon from "../../assets/img/icons/ClockIcon";

const AccountsQueryError = ({ errorMessage }: { errorMessage: string }) => {
  if (!errorMessage.includes("Received status code 503")) {
    return (
      <div className={classNames("p-4")}>
        <CiroAlert message={`Company query error: ${errorMessage}`} />
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "w-full",
        "flex",
        "items-center",
        "justify-center",
        "content-between",
        "h-screen",
        "bg-gray-50"
      )}
    >
      <div className={classNames("w-1/2")}>
        <CiroCard size="small">
          <div>
            <div
              className={classNames(
                "flex",
                "font-medium",
                "items-center",
                "mb-6",
                "gap-2",
              )}
            >
              <ClockIcon />
              <span>Your query timed out</span>
            </div>
            <div
              className={classNames(
                "flex",
                "flex-col",
                "md:flex-row",
                "md:items-center",
                "md:gap-6",
              )}
            >
              <div
                className={classNames(
                  "text-neutral-500",
                  "ml-2",
                  "mt-4",
                  "md:mt-0",
                )}
              ></div>
            </div>
            <div
              className={classNames(
                "flex",
                "flex-row",
                "justify-between",
                "mt-4",
                "text-neutral-600"
              )}
            >
              <div>Click reload to try again</div>
              <CiroButton
                analyticsField={"refetchCompaniesQuery"}
                onClick={() => {
                  window.location.reload();
                }}
                style={CiroButtonStyleEnum.PRIMARY}
              >
                Reload
              </CiroButton>
            </div>
          </div>
        </CiroCard>
      </div>
    </div>
  );
};

export default AccountsQueryError;
