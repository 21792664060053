import { useCallback, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import AccountsTable, {
  AccountsTable_CompaniesFromFiltersResponse,
  AccountsTable_Permissions,
  AccountsTable_UserAccount,
} from "../../components/accounts/AccountsTable";
import useAccountFilters from "../../reactHooks/filters/accounts/useAccountFilters";
import {
  AccountsTable_CompaniesFromFiltersResponseFragmentDoc,
  Accounts_CompaniesFromFiltersQuery,
  Accounts_CompaniesFromFiltersQueryVariables,
  Accounts_CompanyCountFromFiltersQuery,
  Accounts_CompanyCountFromFiltersQueryVariables,
} from "../../__generated__/graphql";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import approx from "approximate-number";
import { DownloadLink } from "../../components/shared/DownloadLink/DownloadLink";
import pluralize from "pluralize";
import CiroCTACard from "../../components/shared/CiroCTACard";
import AccountsUpdateCollection, {
  AccountsUpdateCollection_Permissions,
} from "../../components/accounts/AccountsUpdateCollection";
import getStoredChecked from "../../utils/getStoreChecked";
import { useFragment } from "../../__generated__";
import CiroFilterAccountsWrapper from "../../components/shared/CiroFilter/CiroFilterAccountsWrapper";
import { useSearchParams } from "react-router-dom";
import createAccountFiltersInput from "../../services/filters/createAccountFiltersInput";
import CiroUnlockFromFiltersCard from "../../components/shared/CiroUnlockFromFiltersCard";
import FilterContext from "../../contexts/FilterContext";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import AccountsEnrichButton, {
  AccountsEnrichButton_Permissions,
} from "../../components/accounts/AccountsEnrichButton";
import { EnrichmentFlowFromCollectionModal_CollectionsFilter } from "../../components/enrichments/EnrichmentFlowFromCollectionModal";
import AccountsIntroModal from "../../components/accounts/AccountsIntroModal";
import ShowExplainerDropdown from "../../components/shared/CiroExplainerDropdown";
import AccountsQueryError from "../../components/accounts/AccountsQueryError";

export const ACCOUNTS_MAX_NUM_ROWS = 25;
export const BULK_SELECTION_LIMIT = 250;
const ACCOUNTS_STORED_CHECKED = "selectedAccounts";

export const Accounts_companyCountFromFilters = gql`
  query Accounts_companyCountFromFilters($filters: CompaniesFromFiltersInput) {
    companyCountFromFilters(filters: $filters)
  }
`;

export const Accounts_companiesFromFilters = gql`
  query Accounts_companiesFromFilters(
    $filters: CompaniesFromFiltersInput
    $limit: Int
    $offset: Int
  ) {
    permissions {
      canEditCollectionsByPk
      canExportAccountsData
      accountPaginationLimit

      ...AccountsTable_Permissions
      ...AccountsEnrichButton_Permissions
      ...AccountsUpdateCollection_Permissions
    }
    companiesFromFilters(filters: $filters, limit: $limit, offset: $offset) {
      companies {
        company {
          pk
        }
      }
      lockedCount
      ...AccountsTable_CompaniesFromFiltersResponse
    }
    userAccount {
      id
      ...AccountsTable_UserAccount
    }
  }
  ${AccountsTable_CompaniesFromFiltersResponse}
  ${AccountsTable_Permissions}
  ${AccountsEnrichButton_Permissions}
  ${AccountsTable_UserAccount}
  ${AccountsUpdateCollection_Permissions}
`;

function Accounts() {
  const [checked, setCheckedState] = useState<Set<string>>(
    getStoredChecked(ACCOUNTS_STORED_CHECKED),
  );

  const setChecked = useCallback((newChecked: Set<string>) => {
    setCheckedState(newChecked);
    sessionStorage.setItem(
      ACCOUNTS_STORED_CHECKED,
      JSON.stringify([...newChecked]),
    );
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useAccountFilters({
    searchParams,
    setSearchParams,
  });

  const { numFiltersApplied, offset, setOffset, textFilter, textTypeFilter } =
    filters;

  const filteredCompanyQueryVariables = createAccountFiltersInput(filters);

  let start = offset + 1;

  const companiesQueryResult = useQuery<
    Accounts_CompaniesFromFiltersQuery,
    Accounts_CompaniesFromFiltersQueryVariables
  >(Accounts_companiesFromFilters, {
    variables: {
      filters: filteredCompanyQueryVariables,
      limit: ACCOUNTS_MAX_NUM_ROWS,
      offset: offset,
    },
  });
  const { data: companyCountResultsData, loading: companyCountResultsLoading } =
    useQuery<
      Accounts_CompanyCountFromFiltersQuery,
      Accounts_CompanyCountFromFiltersQueryVariables
    >(Accounts_companyCountFromFilters, {
      variables: {
        filters: filteredCompanyQueryVariables,
      },
    });
  const companiesCount = companyCountResultsData?.companyCountFromFilters || 0;
  const [showSmbFilters, setShowSmbFilters] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [semanticSearchQuery, setSemanticSearchQuery] = useState<string | null>(
    null,
  );

  const {
    data: filteredCompaniesData,
    loading: filteredCompaniesLoading,
    error: filteredCompaniesError,
  } = companiesQueryResult;
  const permissions = filteredCompaniesData?.permissions || {};
  const { canExportAccountsData, canEditCollectionsByPk } = permissions;
  const filteredCompanies =
    filteredCompaniesData?.companiesFromFilters?.companies;
  const lockedCount =
    filteredCompaniesData?.companiesFromFilters?.lockedCount || 0;

  const accountsTableCompanies = useFragment(
    AccountsTable_CompaniesFromFiltersResponseFragmentDoc,
    filteredCompaniesData?.companiesFromFilters,
  );

  if (filteredCompaniesError) {
    return (
      <AccountsQueryError errorMessage={filteredCompaniesError?.message} />
    );
  }

  const end =
    Math.min(offset + ACCOUNTS_MAX_NUM_ROWS, companiesCount) - lockedCount;

  const numberVisibleChecked = (filteredCompanies || []).filter((key) =>
    checked.has(key.company.pk),
  ).length;

  const numberChecked = checked.size;

  return (
    <FilterContext.Provider
      value={{
        page: "ACCOUNTS",
        stringifiedParams: filters.stringifiedParams,
        companiesCount,
        semanticSearchQuery,
        setSemanticSearchQuery,
        showSmbFilters,
        setShowSmbFilters,
      }}
    >
      <CiroContainer className={classNames("bg-zinc-100")}>
        <AccountsIntroModal
          showModal={showIntroModal}
          setShowModal={setShowIntroModal}
        />
        <div
          className={classNames(
            "flex",
            "justify-between",
            "mb-3",
            "sm:mb-6",
            "text-left",
            "w-full",
            "flex-col",
            "sm:flex-row",
          )}
        >
          <CiroTitle
            title={
              <div
                className={classNames(
                  "flex",
                  "flex-row",
                  "gap-2",
                  "items-center",
                )}
              >
                <span>Accounts</span>
                <ShowExplainerDropdown setShowHint={setShowIntroModal} />
              </div>
            }
            subtitleClassName="test-display-company-count"
            subtitle={
              Boolean(companiesCount) ? (
                <>
                  Displaying{" "}
                  <NumberFormat
                    value={start}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                  -
                  <NumberFormat
                    value={end}
                    thousandSeparator={true}
                    displayType={"text"}
                  />{" "}
                  of{" "}
                  {`${approx(companiesCount)} ${pluralize("account", companiesCount)}`}
                  {Boolean(lockedCount) &&
                    ` (${lockedCount} hidden on current page)`}
                </>
              ) : (
                <div>
                  {companyCountResultsLoading && (
                    <SkeletonLoading
                      ignoreDefaultPadding
                      numSkeletons={1}
                      skeletonHeight={"1rem"}
                    />
                  )}
                </div>
              ) // Maintains correct spacing on loading
            }
          />

          <div
            className={classNames(
              "flex",
              "text-neutral-600",
              "gap-1",
            )}
          >
            <AccountsEnrichButton
              numberChecked={numberChecked}
              selectedCompanyPks={Array.from(checked)}
              semanticSearchQuery={
                textTypeFilter === "semantic" ? textFilter : null
              }
              setCheckedCompanies={setChecked}
              permissions={permissions}
            />
            <div>
              <AccountsUpdateCollection
                filteredCompanyQueryVariables={filteredCompanyQueryVariables}
                numberChecked={numberChecked}
                numFiltersApplied={numFiltersApplied}
                permissions={permissions}
                selectedCompanyPks={checked}
                setCheckedCompanies={setChecked}
                refetchQueries={[
                  {
                    query: Accounts_companiesFromFilters,
                    variables: {
                      filters: filteredCompanyQueryVariables,
                      limit: ACCOUNTS_MAX_NUM_ROWS,
                      offset,
                    },
                  },
                  {
                    query: EnrichmentFlowFromCollectionModal_CollectionsFilter,
                  },
                ]}
              />
            </div>
            {canExportAccountsData &&
              Boolean(numberChecked || numFiltersApplied) && (
                <DownloadLink
                  selectedCompanyPks={[...checked]}
                  filteredCompanyQueryVariables={filteredCompanyQueryVariables}
                  companiesCount={companiesCount}
                />
              )}
          </div>
        </div>

        {!filteredCompaniesLoading &&
          (permissions.accountPaginationLimit || 0) <=
            Math.ceil(offset / ACCOUNTS_MAX_NUM_ROWS) + 1 && (
            <div className={classNames("py-5")}>
              <CiroCTACard
                title="Upgrade to view more companies"
                subtitle="Access additional features such as CSV downloads, CRM integrations and additional filters"
              />
            </div>
          )}
        {Boolean(lockedCount) && (
          <CiroUnlockFromFiltersCard
            filteredCompanyQueryVariables={filteredCompanyQueryVariables}
            offset={offset}
            refetchQueries={[
              {
                query: Accounts_companiesFromFilters,
                variables: {
                  filters: filteredCompanyQueryVariables,
                  limit: ACCOUNTS_MAX_NUM_ROWS,
                  offset,
                },
              },
            ]}
            totalNumberRecords={companiesCount}
          />
        )}
        <div
          className={classNames(
            "overflow-auto",
            "w-full",
            "my-4",
          )}
        >
          <CiroFilterAccountsWrapper
            accountFilters={filters}
            resetOffset={() => setOffset(0)}
          />
          <AccountsTable
            canSelectRecords={Boolean(
              canExportAccountsData || canEditCollectionsByPk,
            )}
            checked={checked}
            filteredCompanyQueryVariables={filteredCompanyQueryVariables}
            companiesFromFilter={accountsTableCompanies}
            isPageLoading={
              filteredCompaniesLoading || companyCountResultsLoading
            }
            numberVisibleChecked={numberVisibleChecked}
            setChecked={setChecked}
            totalNumberChecked={numberChecked}
            permissions={permissions}
            setOffset={setOffset}
            offset={offset}
            userAccount={filteredCompaniesData?.userAccount}
          />
        </div>
      </CiroContainer>
    </FilterContext.Provider>
  );
}

export default withAuthenticationRequired(Accounts, {
  onRedirecting: () => <Loading />,
});
