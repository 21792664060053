import classNames from "classnames";
import { ReactNode } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroTitle from "../shared/CiroTitle";

interface IAutomationHomeTableSection {
  buttonHandler: () => void;
  objectType: "list" | "persona";
  table?: ReactNode;
}

const AutomationHomeTableSection = ({
  buttonHandler,
  objectType,
  table,
}: IAutomationHomeTableSection) => {
  return (
    <div className={classNames("mb-4")}>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "items-start",
          "pb-4",
        )}
      >
        <CiroTitle
          title={objectType === "list" ? "My contact lists" : "My personas"}
          size="small"
        />
        <CiroButton
          analyticsField={`Create new ${objectType}`}
          style={CiroButtonStyleEnum.LOUD}
          onClick={buttonHandler}
        >
          + New {objectType}
        </CiroButton>
      </div>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "flex-col",
          "w-full",
          "mb-4",
        )}
      >
        {table || (
          <div
            className={classNames(
              "text-start",
              "text-gray-500",
              "text-sm",
              "w-full",
            )}
          >
            {objectType === "list"
              ? "No lists found. Lists are collections of profiles you scrape from LinkedIn."
              : "No personas found. Personas are archetypes you categorize your leads into, e.g., CEOs, CTOs, VPs of Finance, etc."}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationHomeTableSection;
