import classNames from "classnames";
import { IEnrichmentExecutionCellProps } from ".";

const ExecutionCell_FooterCount = ({
  value,
}: IEnrichmentExecutionCellProps) => {
  const displayedValue = value == null ? "" : `${value.output} rows enriched`;

  return (
    <div
      className={classNames(
        "flex",
        "justify-end",
        "items-center",
        "text-xs",
        "text-neutral-400",
        "w-full",
        "h-full",
        "h-full",
      )}
    >
      {displayedValue}
    </div>
  );
};

export default ExecutionCell_FooterCount;
