import { useRef } from "react";
import { useQuery, gql } from "@apollo/client";
import classNames from "classnames";

import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroAlert from "../../components/shared/CiroAlert";

import {
  CrmMobileConnection_UserAccountQuery,
  CrmMobileConnection_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import DispositionSettingsForm from "../../components/mobileNumbers/CrmConnectionSettings/DispositionSettingsForm";

const CrmMobileConnection_UserAccount = gql`
  query CrmMobileConnection_UserAccount {
    userAccount {
      org {
        id
        organizationMergeIntegration {
          id
          integration
          invalid_number_statuses
          valid_numbers_statuses
        }
      }
    }
  }
`;

const CrmMobileConnection = () => {

  const { data, error, refetch } = useQuery<
    CrmMobileConnection_UserAccountQuery,
    CrmMobileConnection_UserAccountQueryVariables
  >(CrmMobileConnection_UserAccount, {
    fetchPolicy: "network-only",
  });

  const dispositionSettingsRef = useRef<{
    onSubmit: () => void;
    checkValidity: () => Promise<boolean>;
  } | null>(null);

  if (error) return <CiroAlert message={error.message} />;

  const integrationName =
    data?.userAccount?.org?.organizationMergeIntegration?.integration || "";

  return (
    <CiroContainer className={classNames("bg-zinc-100")}>
      <div className={classNames("w-full")}>
        <CiroBreadCrumbs
          values={["Enrich CRM", "CRM connection"]}
          href="/enrich-crm"
        />
        <div
          className={classNames(
            "flex",
            "justify-between",
            "items-center",
          )}
        >
          <h1 className={classNames("text-2xl", "pb-6")}>
            Manage {integrationName} settings
          </h1>
        </div>
        <DispositionSettingsForm
          initialData={data?.userAccount}
          refetch={refetch}
          ref={dispositionSettingsRef}
        />
      </div>
    </CiroContainer>
  );
};

export default CrmMobileConnection;
