import classNames from "classnames";
import CiroModal from "../CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PRICING_PAGE_LINK } from "../../../utils/consts";
import CiroLink from "../CiroLink";
import LinkArrowIcon from "../../../assets/img/icons/LinkArrowIcon";

interface ICiroFilterModalContainerProps {
  displayCTA: boolean;
  filtersSection: JSX.Element[];
  isLoading: boolean;
  isOpen: boolean;
  numFiltersApplied: number;
  onClose: () => void;
  resetAll: () => void;
  subtitle: string;
}

const CiroFilterModalContainer = ({
  displayCTA,
  filtersSection,
  isLoading,
  isOpen,
  numFiltersApplied,
  onClose,
  resetAll,
  subtitle,
}: ICiroFilterModalContainerProps) => {
  return (
    <CiroModal
      isOpen={isOpen}
      onClose={onClose}
      size="FIXED_RIGHT"
      hideScrollbar
    >
      <div className={classNames("border-b-1", "p-4")}>
        <div className={classNames("flex", "justify-between")}>
          <div className={classNames("font-medium", "text-lg")}>
            Filters
          </div>
          {numFiltersApplied > 0 && (
            <CiroButton
              analyticsField="Remove Filters"
              style={CiroButtonStyleEnum.UNSTYLED}
              onClick={resetAll}
            >
              <div
                className={classNames(
                  "border-1",
                  "py-1",
                  "px-2",
                  "border-gray-300",
                  "rounded-lg",
                  "text-slate-700",
                  "text-xs",
                  "flex",
                  "items-center",
                )}
              >
                <span className={classNames("pr-1")}>
                  Remove Filters
                </span>
                <XMarkIcon
                  className={classNames("h-4", "text-gray-500")}
                />
              </div>
            </CiroButton>
          )}
        </div>
        {!isLoading && !displayCTA && (
          <div
            className={classNames("text-slate-600", "text-sm")}
          >
            {subtitle}
          </div>
        )}
        {!isLoading && displayCTA && (
          <CiroLink href={PRICING_PAGE_LINK}>
            <div
              className={classNames(
                "flex",
                "items-center",
                "text-sm",
              )}
            >
              <span>Upgrade to view all filters</span>
              <span className="pl-2">
                <LinkArrowIcon />
              </span>
            </div>
          </CiroLink>
        )}
      </div>
      <div
        className={classNames(
          "overflow-y-auto",
          "h-screen",
          "pb-40",
          "test-filter-modal-container-filter-section",
        )}
      >
        {filtersSection}
        <div
          className={classNames(
            "bg-white",
            "border-t-1",
            "bottom-0",
            "fixed",
            "px-5",
            "py-6",
            "right-0",
            "w-3/4",
            "lg:w-96",
          )}
        >
          <div
            className={classNames("flex", "justify-between")}
          >
            <CiroButton
              style={CiroButtonStyleEnum.LOUD}
              analyticsField="Apply filters"
              customClassName="test-filter-close-btn"
              onClick={onClose}
            >
              Apply
            </CiroButton>
          </div>
        </div>
      </div>
    </CiroModal>
  );
};

export default CiroFilterModalContainer;
