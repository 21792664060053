import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import { ManageIntegrationsButton } from "../manageIntegration/manageIntegrationButton/ManageIntegrationsButton";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { CHROME_EXTENSION_URL } from "./ChromeExtensionCard";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";
import SalesforceIcon from "../../assets/img/icons/SalesforceIcon";
import HubspotIcon from "../../assets/img/icons/HubspotIcon";
import { CreateIntegrationButton_UserAccountFragment } from "../../__generated__/graphql";
import ChromeOneColorIcon from "../../assets/img/icons/ChromeOneColorIcon";

const HomeGetStarted = ({
  userAccountData,
  hasAutopilotSessions,
  hasRecoveredPhoneNumbers,
}: {
  userAccountData:
    | CreateIntegrationButton_UserAccountFragment
    | null
    | undefined;
  hasAutopilotSessions: boolean;
  hasRecoveredPhoneNumbers: boolean;
}) => {
  return (
    <div className={classNames("p-5", "w-full")}>
      <CiroTitle title="Get started" size="small" />
      <div
        className={classNames(
          "flex",
          "flex-col",
          "gap-4",
          "mt-5",
        )}
      >
        {!hasAutopilotSessions && (
          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "p-4",
              "flex",
              "items-center",
              "justify-between",
              "shadow-sm",
            )}
          >
            <div>
              <div
                className={classNames("font-medium", "text-sm")}
              >
                Install Chrome extension
              </div>
              <div
                className={classNames(
                  "text-gray-600",
                  "text-xs",
                )}
              >
                10x your LinkedIn prospecting with an AI Autopilot
              </div>
            </div>
            <CiroButton
              analyticsField="Install Chrome Extension"
              href={CHROME_EXTENSION_URL}
              style={CiroButtonStyleEnum.LOUD}
            >
              <div
                className={classNames(
                  "flex",
                  "items-center",
                  "gap-2",
                  "h-[20px]",
                )}
              >
                <ChromeOneColorIcon color="white" transform={1.25} />
                <span>Install Chrome extension</span>
              </div>
            </CiroButton>
          </div>
        )}
        {!hasRecoveredPhoneNumbers && (
          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "p-4",
              "flex",
              "items-center",
              "justify-between",
              "shadow-sm",
            )}
          >
            <div>
              <div
                className={classNames("font-medium", "text-sm")}
              >
                Connect your CRM
              </div>
              <div
                className={classNames(
                  "text-gray-600",
                  "text-xs",
                )}
              >
                Connect your CRM to keep your contact data fresh & up-to-date
              </div>
            </div>
            <div
              className={classNames(
                "flex",
                "items-center",
                "gap-4",
              )}
            >
              <div className={classNames("flex", "gap-2")}>
                <div
                  className={classNames(
                    "flex",
                    "p-1",
                    "relative",
                    "w-full",
                  )}
                >
                  <div
                    className={classNames(
                      "border-2",
                      "p-2",
                      "rounded-full",
                      "bg-white",
                    )}
                  >
                    <HubspotIcon width={20} height={20} />
                  </div>
                  <div
                    className={classNames(
                      "border-2",
                      "p-2",
                      "rounded-full",
                      "bg-white",
                      "z-10",
                      "-ml-3",
                    )}
                  >
                    <ApolloIcon />
                  </div>
                  <div
                    className={classNames(
                      "border-2",
                      "p-2",
                      "rounded-full",
                      "bg-white",
                      "z-20",
                      "-ml-3",
                    )}
                  >
                    <SalesforceIcon width={20} height={20} />
                  </div>
                </div>
              </div>
              <ManageIntegrationsButton userAccount={userAccountData} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeGetStarted;
