import classNames from "classnames";
import CiroSwitch from "../../shared/CiroSwitch";
import {
  CiroButtonStyleEnum,
  getCiroButtonClasses,
} from "../../shared/CiroButton";

const AutomationListHideDisqualifiedSwitch = ({
  hideDisqualifiedPersonas,
  setHideDisqualifiedPersonas,
}: {
  hideDisqualifiedPersonas: boolean;
  setHideDisqualifiedPersonas: (v: boolean) => void;
}) => {
  return (
    <div
      className={classNames(
        getCiroButtonClasses({
          style: CiroButtonStyleEnum.PRIMARY,
        }),
      )}
    >
      <div
        className={classNames(
          "flex",
          "items-center",
          "gap-2",
        )}
      >
        <div
          className={classNames(
            "font-medium",
            "text-sm",
            "text-gray-700",
          )}
        >
          Hide disqualified
        </div>
        <CiroSwitch
          aria-label="Toggle visibility of disqualified personas"
          checked={hideDisqualifiedPersonas}
          onChange={() =>
            setHideDisqualifiedPersonas(!hideDisqualifiedPersonas)
          }
        />
      </div>
    </div>
  );
};

export default AutomationListHideDisqualifiedSwitch;
