import { useMemo } from "react";
import { TextTypeFilterEnum } from "../../../../reactHooks/filters/accounts/useAccountFilters";
import { SparklesIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import KeywordSearchIcon from "../../../../assets/img/icons/KeywordSearchIcon";
import ContentSearchIcon from "../../../../assets/img/icons/ContentSearchIcon";

interface ICiroSearchBarLabelOption {
  textTypeFilter: TextTypeFilterEnum | null;
}

const getIcon = (textTypeFilter: string | null) => {
  switch (textTypeFilter) {
    case TextTypeFilterEnum.KEYWORD:
      return <KeywordSearchIcon />;
    case TextTypeFilterEnum.SEMANTIC:
      return <SparklesIcon />;
    case TextTypeFilterEnum.WEB:
      return <ContentSearchIcon />;
    default:
      return <SparklesIcon />;
  }
};

const getText = (textTypeFilter: string | null) => {
  switch (textTypeFilter) {
    case TextTypeFilterEnum.KEYWORD:
      return "Keyword search";
    case TextTypeFilterEnum.SEMANTIC:
      return "AI search";
    case TextTypeFilterEnum.WEB:
      return "Web page search";
    default:
      return "AI search";
  }
};

const CiroSearchBarLabel = ({ textTypeFilter }: ICiroSearchBarLabelOption) => {
  const icon = useMemo(() => getIcon(textTypeFilter), [textTypeFilter]);
  const text = useMemo(() => getText(textTypeFilter), [textTypeFilter]);
  return (
    <div
      className={classNames(
        "flex",
        "flex-row",
        "text-base",
        "items-center",
      )}
    >
      <div
        className={classNames(
          {
            "text-orange-600":
              textTypeFilter === TextTypeFilterEnum.SEMANTIC,
          },
          "w-5",
          "mx-2",
        )}
      >
        {icon}
      </div>
      <div
        className={classNames(
          "w-max",
          "text-neutral-500",
          // Only show label on larger screens
          "hidden",
          "sm:inline",
        )}
      >
        {text}
      </div>
    </div>
  );
};

export default CiroSearchBarLabel;
