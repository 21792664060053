import classNames from "classnames";
import CiroCard from "../shared/CiroCard";

const MobileNumbersOverviewCard = ({
  amount,
  description,
}: {
  amount: number | null;
  description: string;
}) => {
  return (
    <CiroCard size="small" customClassName={classNames("w-full")}>
      <div
        className={classNames(
          "text-2xl",
          "font-bold",
          "pb-4",
        )}
      >
        {amount || 0}
      </div>
      <div className={classNames("text-sm", "text-zinc-500")}>
        {description}
      </div>
    </CiroCard>
  );
};

export default MobileNumbersOverviewCard;
