import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { IntegrationEnum } from "../../__generated__/graphql";
import NavigationContext from "../../contexts/NavigationContext";
import { useContext } from "react";
import { IntegrationModalPhases } from "./manageIntegrationButton/ManageIntegrationsButton";

interface IIntegrationSyncStatus {
  integrations: IntegrationEnum[];
}

export function IntegrationSyncStatus({
  integrations,
}: IIntegrationSyncStatus) {

  const { setIntegrationModalPhase } = useContext(NavigationContext);

  return (
    <div
      className={classNames(
        "text-sm",
        "text-neutral-600",
        "flex",
        "items-center",
        "justify-between",
      )}
    >
      <div className={classNames("flex", "items-center")}>
        <ArrowPathIcon
          height={20}
          className={classNames("pr-2", "text-blue-500")}
        />
        <span>{integrations.join(", ")} synced</span>
      </div>
      <Menu
        as="div"
        className={classNames(
          "relative",
          "inline-block",
          "text-left",
          "mx-2",
        )}
      >
        <Menu.Button
          className={classNames(
            "p-2",
            "rounded-full",
            "hover:bg-gray-100",
          )}
        >
          <EllipsisHorizontalIcon
            className={classNames("h-5", "w-5")}
          />
        </Menu.Button>
        <Menu.Items
          className={classNames(
            "absolute",
            "right-0",
            "mt-2",
            "w-56",
            "origin-top-right",
            "rounded-md",
            "bg-white",
            "shadow-lg",
            "ring-1",
            "ring-black",
            "ring-opacity-5",
            "focus:outline-none",
          )}
        >
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  active
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700",
                  "block",
                  "w-full",
                  "text-left",
                  "px-4",
                  "py-2",
                  "text-sm",
                )}
                onClick={() => {
                  setIntegrationModalPhase(IntegrationModalPhases.CHOOSE_INTEGRATION);
                }}
              >
                Manage
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default IntegrationSyncStatus;
