import classNames from "classnames";
import {
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";

interface IManageExistingIntegrationOption {
  icon: JSX.Element;
  integrationName: string;
  isSynced?: boolean;
  onRemove?: () => void;
}

const ManageExistingIntegrationOption = ({
  icon,
  integrationName,
  onRemove,
}: IManageExistingIntegrationOption) => {
  return  (

    <div
      className={classNames(
          "rounded-md",
          "border-1",
          "border-neutral-300",
          "px-4",
          "py-1.5",
          "text-sm",
          "text-neutral-600",
          "bg-neutral-100",
          "mt-4",
          "flex",
          "justify-between",
          "items-center",
          "w-full",
      )}
    >
      <div className="flex items-center">
        <span className="mr-2">{icon}</span>
        <span>Manage {integrationName} integration</span>
      </div>
      <div className={classNames("text-neutral-600")}>
        <Menu as="div" className="relative">
          <Menu.Button
            className={classNames(
              "p-1",
              "rounded-full",
              "hover:bg-gray-100",
            )}
          >
            <EllipsisHorizontalIcon className="h-5 w-5" />
          </Menu.Button>
          <Menu.Items
            className={classNames(
              "absolute",
              "right-0",
              "mt-2",
              "w-56",
              "origin-top-right",
              "rounded-md",
              "bg-white",
              "shadow-lg",
              "ring-1",
              "ring-black",
              "ring-opacity-5",
              "focus:outline-none",
            )}
          >
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-700",
                    "block",
                    "w-full",
                    "text-left",
                    "px-4",
                    "py-2",
                    "text-sm",
                  )}
                  onClick={onRemove}
                >
                  Remove integration
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  )
};

export default ManageExistingIntegrationOption;
