import {
  EllipsisVerticalIcon,
  StopIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useRef } from "react";

interface IEnrichmentFlowOverflowHeaderProps {
  showFilter: (source: HTMLElement) => void;
  menuIcon: string;
}

const EnrichmentFlowOverflowHeader = ({
  showFilter,
  menuIcon,
}: IEnrichmentFlowOverflowHeaderProps) => {
  const refButton = useRef<null | HTMLDivElement>(null);

  return (
    <div>
      <div
        ref={refButton}
        className={classNames("cursor-pointer")}
        onClick={() => {
          showFilter(refButton.current!);
        }}
      >
        {menuIcon === "checkbox" ? (
          <div className={classNames("flex", "items-center")}>
            <StopIcon
              className={classNames("h-5", "text-neutral-500")}
            />
            <ChevronDownIcon
              className={classNames("h-3", "text-neutral-500")}
            />
          </div>
        ) : (
          <EllipsisVerticalIcon
            className={classNames("h-5", "text-neutral-500")}
          />
        )}
      </div>
    </div>
  );
};

export default EnrichmentFlowOverflowHeader;
