import { gql, useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import CiroModal from "../CiroModal";
import {
  CompaniesFromFiltersInput,
  DownloadLinkModal_CreditsToSpendQuery,
  DownloadLinkModal_CreditsToSpendQueryVariables,
  PremiumDataFieldEnum,
} from "../../../__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";
import CiroTooltipContainer from "../CiroTooltipContainer";
import CiroUnlockPremiumDataContent, {
  CiroCreditUseCaseEnum,
  CiroUnlockPremiumDataContent_CreditsToSpendResponse,
} from "../CiroUnlockPremiumData/CiroUnlockPremiumDataContent";
import CiroCheckbox from "../CiroCheckbox";
import DownloadLinkLoadingBar from "./DownloadLinkLoadingBar";
import CiroByQueryLimitSelectionModal, {
  MAX_DOWNLOAD_LIMIT,
} from "../CiroByQueryLimitSelectionModal";

const DownloadLinkModal_CreditsToSpend = gql`
  query DownloadLinkModal_CreditsToSpend(
    $collectionId: Int
    $companyPks: [ID!]
    $companyQuery: CompaniesFromFiltersAndCountInput
  ) {
    creditsToSpend(
      collectionId: $collectionId
      companyPks: $companyPks
      companyQuery: $companyQuery
    ) {
      exports {
        creditsToSpend
        accountsCount
      }
      totalCompanyCount
      currentBalance
      totalParentCount
      unlockPms {
        creditsToSpend
        accountsCount
      }
      ...CiroUnlockPremiumDataContent_CreditsToSpendResponse
    }
  }
  ${CiroUnlockPremiumDataContent_CreditsToSpendResponse}
`;

interface IDownloadLinkModalProps {
  closeModal: () => void;
  collectionId?: number;
  companyPks?: string[];
  companiesCount?: number;
  filteredCompanyQueryVariables?: CompaniesFromFiltersInput;
  handleDownload: (premiumFields: PremiumDataFieldEnum[]) => void;
  isExporting: boolean;
  isOpen: boolean;
  setQueryLimit: (v: number) => void;
  queryLimit: number;
  scheduleToken?: string | null;
}

const DownloadLinkModal = ({
  closeModal,
  collectionId,
  companyPks,
  companiesCount,
  filteredCompanyQueryVariables,
  handleDownload,
  isExporting,
  isOpen,
  queryLimit,
  setQueryLimit,
  scheduleToken,
}: IDownloadLinkModalProps) => {
  const [
    fetchCreditsToSpend,
    { data: fetchedCreditData, loading: fetchCreditsLoading },
  ] = useLazyQuery<
    DownloadLinkModal_CreditsToSpendQuery,
    DownloadLinkModal_CreditsToSpendQueryVariables
  >(DownloadLinkModal_CreditsToSpend);

  const usingQueryFilters =
    filteredCompanyQueryVariables && !companyPks?.length;

  useEffect(() => {
    if (isOpen && (!usingQueryFilters || queryLimit)) {
      fetchCreditsToSpend({
        variables: {
          companyPks: usingQueryFilters ? undefined : companyPks,
          collectionId,
          companyQuery: usingQueryFilters
            ? {
                filters: filteredCompanyQueryVariables,
                limit: queryLimit,
                offset: 0,
              }
            : undefined,
        },
        fetchPolicy: "network-only",
      });
    }

    if (!isOpen) {
      setQueryLimit(0);
    }
  }, [
    collectionId,
    companyPks,
    queryLimit,
    fetchCreditsToSpend,
    isOpen,
    usingQueryFilters,
    filteredCompanyQueryVariables,
    setQueryLimit,
  ]);

  const forcedIncludePms = Boolean(
    filteredCompanyQueryVariables?.pmsProviderFilter?.length,
  );

  const [includePms, setIncludePms] = useState(forcedIncludePms);

  const creditActions = useMemo(() => {
    const actions = [CiroCreditUseCaseEnum.EXPORTS];
    if (includePms) {
      actions.push(CiroCreditUseCaseEnum.UNLOCK_PMS);
    }
    return actions;
  }, [includePms]);

  const exportCredits =
    fetchedCreditData?.creditsToSpend?.exports.creditsToSpend || 0;
  const currentBalance = fetchedCreditData?.creditsToSpend?.currentBalance || 0;
  const unlockPmsCredits =
    fetchedCreditData?.creditsToSpend?.unlockPms.creditsToSpend || 0;
  let remainingBalance = currentBalance - exportCredits;
  if (includePms) {
    remainingBalance -= unlockPmsCredits;
  }

  if (usingQueryFilters && !queryLimit) {
    const totalAccounts = Math.min(
      MAX_DOWNLOAD_LIMIT,
      companiesCount || MAX_DOWNLOAD_LIMIT,
    );
    const limitType =
      totalAccounts === MAX_DOWNLOAD_LIMIT ? "maximum" : "total";
    return (
      <CiroByQueryLimitSelectionModal
        closeModal={closeModal}
        isOpen={isOpen}
        setLimit={setQueryLimit}
        textLabel={`How many records do you want to download? (${totalAccounts} ${limitType})`}
        title={"Select number of accounts to export"}
        maxAccounts={totalAccounts}
      />
    );
  }

  return (
    <CiroModal size="LARGE" onClose={closeModal} isOpen={isOpen}>
      <div className={classNames("text-black")}>
        <div className={classNames("font-medium", "text-lg")}>
          Download Accounts
        </div>
        <div className={classNames("mt-2")}>
          <CiroCheckbox
            checked={includePms}
            label={"Include EHR / Practice Management Systems"}
            onClick={() => !forcedIncludePms && setIncludePms(!includePms)}
          />
          {forcedIncludePms && (
            <div
              className={classNames("text-sm", "text-gray-500")}
            >
              You must include manually verified technologies for this export
              based on your filters.
            </div>
          )}
        </div>
        <CiroUnlockPremiumDataContent
          action={"Export"}
          creditActions={creditActions}
          creditsToSpend={fetchedCreditData?.creditsToSpend}
          isLoading={fetchCreditsLoading}
        />
        {isExporting && (
          <div className={classNames("italic", "pb-4")}>
            Note: Exporting a large number of records (5k+) may take a few
            minutes.
          </div>
        )}
        {scheduleToken && (
          <DownloadLinkLoadingBar scheduleToken={scheduleToken} />
        )}
        {fetchedCreditData && (
          <div>
            <div className={classNames("flex", "justify-end")}>
              <CiroTooltipContainer
                tooltip="Not enough credits to complete your export"
                disabled={remainingBalance >= 0 || isExporting}
              >
                <CiroButton
                  customClassName="mr-2"
                  style={CiroButtonStyleEnum.LOUD}
                  analyticsField="Cancelled download from modal"
                  disabled={remainingBalance < 0 || isExporting}
                  onClick={() => {
                    const premiumFields = [] as PremiumDataFieldEnum[];
                    if (includePms) {
                      premiumFields.push(
                        PremiumDataFieldEnum.PracticeManagementSystem,
                      );
                    }
                    handleDownload(premiumFields);
                  }}
                >
                  Confirm
                </CiroButton>
              </CiroTooltipContainer>
              <CiroButton
                analyticsField="Confirmed download link from modal"
                disabled={isExporting}
                onClick={closeModal}
              >
                Cancel
              </CiroButton>
            </div>
          </div>
        )}
      </div>
    </CiroModal>
  );
};

export default DownloadLinkModal;
