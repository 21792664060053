import { useImperativeHandle, forwardRef, useMemo, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gql, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import {
  DispositionSettingsForm_GetCrmDispositionsQuery,
  DispositionSettingsForm_GetCrmDispositionsQueryVariables,
  CrmMobileConnection_UserAccountQuery,
  UpdateOrganizationMergeIntegrationInput,
} from "../../../__generated__/graphql";
import CiroDropDown from "../../shared/CiroDropdown";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";
import CiroNumbersFormSection from "../../shared/CiroNumbersFormSection";

interface DispositionSettingsFormHandle {
  onSubmit: () => void;
}

const DispositionSettingsForm_GetCrmDispositions = gql`
  query DispositionSettingsForm_GetCrmDispositions($field: String) {
    organizationMergeIntegrationCrmDispositions(field: $field) {
      id
      label
    }
  }
`;

const DispositionSettingsForm_UpdateOrganizationMergeIntegration = gql`
  mutation DispositionSettingsForm_UpdateOrganizationMergeIntegration(
    $input: UpdateOrganizationMergeIntegrationInput!
  ) {
    updateOrganizationMergeIntegration(input: $input) {
      id
    }
  }
`;

interface DispositionSettingsFormProps {
  initialData: CrmMobileConnection_UserAccountQuery["userAccount"] | null;
  refetch: () => void;
}

const DispositionSettingsForm = forwardRef<
  DispositionSettingsFormHandle,
  DispositionSettingsFormProps
>(
  (
    { initialData, refetch }: DispositionSettingsFormProps,
    ref: React.ForwardedRef<DispositionSettingsFormHandle>,
  ) => {
    const DispositionSettingsUpdateFormSchema = yup.object({
      bad_number_dispositions: yup
        .array()
        .of(yup.string())
        .min(1, "At least one option is required"),
      good_number_dispositions: yup
        .array()
        .of(yup.string())
        .min(1, "At least one option is required"),
    });

    const {
      control,
      formState: { errors },
      setValue,
      handleSubmit,
      trigger,
    } = useForm({
      resolver: yupResolver(DispositionSettingsUpdateFormSchema),
      defaultValues: {
        bad_number_dispositions:
          initialData?.org?.organizationMergeIntegration
            ?.invalid_number_statuses || [],
        good_number_dispositions:
          initialData?.org?.organizationMergeIntegration
            ?.valid_numbers_statuses || [],
      },
      context: {
        integrationName:
          initialData?.org?.organizationMergeIntegration?.integration || "",
      },
    });

    useEffect(() => {
      if (initialData) {
        setValue(
          "bad_number_dispositions",
          initialData.org?.organizationMergeIntegration
            ?.invalid_number_statuses || [],
        );
        setValue(
          "good_number_dispositions",
          initialData.org?.organizationMergeIntegration
            ?.valid_numbers_statuses || [],
        );
      }
    }, [initialData, setValue]);

    const [badNumberDispositions, goodNumberDispositions] = useWatch({
      control,
      name: ["bad_number_dispositions", "good_number_dispositions"],
    });

    const { data: dispositionsData, loading: dispositionsLoading } = useQuery<
      DispositionSettingsForm_GetCrmDispositionsQuery,
      DispositionSettingsForm_GetCrmDispositionsQueryVariables
    >(DispositionSettingsForm_GetCrmDispositions);

    const dispositionOptions = useMemo(() => {
      return (
        dispositionsData?.organizationMergeIntegrationCrmDispositions?.map(
          (disposition) => ({
            value: disposition.id,
            label: disposition.label,
          }),
        ) || []
      );
    }, [dispositionsData]);

    const checkValidity = async () => {
      const valid = await trigger();
      return valid;
    };

    const onSubmit = handleSubmit(async (data) => {
      try {
        const input: UpdateOrganizationMergeIntegrationInput = {
          invalid_number_statuses: data.bad_number_dispositions,
          valid_numbers_statuses: data.good_number_dispositions,
        };

        const result = await updateOrganizationMergeIntegration({
          variables: { input },
        });

        if (result.data) {
          refetch();
          toast.success("Settings saved successfully");
        }
      } catch (error) {
        console.error("Error updating CRM settings:", error);
        toast.error(
          "An error occurred while saving settings. Please try again.",
        );
      }
    });

    useImperativeHandle(ref, () => ({
      onSubmit,
      checkValidity,
    }));

    const [updateOrganizationMergeIntegration, { loading: updateLoading }] =
      useMutation(DispositionSettingsForm_UpdateOrganizationMergeIntegration);

    return (
      <CiroNumbersFormSection
        title="Call metrics"
        description="Track the success of calls made to numbers enriched by Ciro."
        isLoading={dispositionsLoading || !initialData}
      >
        <div className={classNames("pt-4")}>
          <CiroDropDown
            isDisabled={dispositionOptions.length === 0}
            isMulti={true}
            label="Which call outcomes indicate whether the call went to an invalid number, e.g. “Wrong number” or “Out of service”"
            options={dispositionOptions}
            value={badNumberDispositions}
            onChange={(v) => {
              setValue("bad_number_dispositions", v as string[]);
            }}
            error={errors.bad_number_dispositions?.message as string}
          />
        </div>
        <div className={classNames("pt-4")}>
          <CiroDropDown
            isDisabled={dispositionOptions.length === 0}
            isMulti={true}
            label="Which call outcomes indicate whether a number was successfully reached, e.g. “Answered”"
            options={dispositionOptions}
            value={goodNumberDispositions}
            onChange={(v) => {
              setValue("good_number_dispositions", v as string[]);
            }}
            error={errors.good_number_dispositions?.message as string}
          />
        </div>
        {(
          <div
            className={classNames(
              "pt-6",
              "flex",
              "justify-end",
            )}
          >
            <CiroButton
              analyticsField="save_crm_integration_changes"
              disabled={updateLoading}
              style={CiroButtonStyleEnum.LOUD}
              onClick={onSubmit}
            >
              Save changes
            </CiroButton>
          </div>
        )}
      </CiroNumbersFormSection>
    );
  },
);

export default DispositionSettingsForm;
