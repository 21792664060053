import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import CheckIcon from "../../assets/img/icons/CheckIcon";
import IndeterminateCheckIcon from "../../assets/img/icons/IndeterminateCheckIcon";

interface ICiroCheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  label?: any;
  labelClassName?: string;
}

const CiroCheckbox = ({
  checked,
  indeterminate,
  className = "",
  onClick,
  label,
  labelClassName,
  disabled = false,
}: ICiroCheckboxProps) => {
  const inputRef =
    useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(indeterminate);
    }
  });

  return (
    <label
      className={classNames(
        "cursor-pointer",
        "flex",
        "items-start",
      )}
    >
      <input
        ref={inputRef}
        checked={checked}
        readOnly
        className={classNames(
          "absolute",
          "w-0",
          "h-0",
          "opacity-0",
        )}
        disabled={disabled}
        onClick={onClick}
        type="checkbox"
      />
      <span
        className={classNames(
          [
            "border-1",
            "checkmark",
            "cursor-pointer",
            "flex",
            "h-5",
            "items-center",
            "justify-center",
            "p-0.5",
            "rounded",
            "w-5",
            "test-checkbox",
            className,
            "flex-shrink-0",
            "mt-0.5",
          ],
          {
            "bg-amber-500/30": checked,
            "text-orange-400": checked,
            "border-orange-400": checked,

            "bg-white": !checked,
            "border-gray-300": !checked,
            "text-white": !checked,
          },
        )}
      >
        {checked && indeterminate && <IndeterminateCheckIcon />}
        {checked && !indeterminate && <CheckIcon />}
      </span>
      {label && (
        <span
          className={classNames(
            "pl-2",
            "flex-grow",
            labelClassName,
          )}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default CiroCheckbox;
