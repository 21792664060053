import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";

export const DataProviderConnectionCard_Organization = gql`
  fragment DataProviderConnectionCard_Organization on Organization {
    id
    organizationDataSources {
      id
      data_source
    }
  }
`;

const DataProviderConnectionCard = ({
  organization,
}: {
  organization: any;
}) => {
  const navigate = useNavigate();
  const hasDataSource = organization?.organizationDataSources?.length > 0;

  return (
    <CiroCard size="small">
      <h2 className={classNames("font-semibold", "mb-4")}>
        {hasDataSource ? "Settings" : "Data provider connection"}
      </h2>
      <div
        className={classNames(
          "border-t-1",
          "bg-neutral-50",
          "rounded-b-xl",
          "-mx-4",
          "-mb-12",
          "p-4",
        )}
      >
        <p className={classNames("text-gray-500", "mb-4")}>
          {hasDataSource
            ? "Manage your existing data provider connection:"
            : "Add an existing phone data provider to check first before Ciro:"}
        </p>
        <CiroButton
          analyticsField="data_provider_connection_card_connect_with_apollo"
          style={CiroButtonStyleEnum.PRIMARY}
          onClick={() => navigate("/profile/data-provider-connection")}
          customClassName={classNames(
            "w-full",
            "mt-2",
            "flex",
            "flex-row",
            "items-center",
            "py-3",
            "justify-between",
          )}
        >
          <span>
            {hasDataSource ? (
              <div
                className={classNames("flex", "items-center")}
              >
                <div className={classNames("mr-2")}>
                  <ApolloIcon />
                </div>
                Manage your Apollo connection
              </div>
            ) : (
              "Connect with Apollo"
            )}
          </span>
          <ChevronRightIcon
            className={classNames(
              "text-orange-500",
              "h-5",
              "w-5",
            )}
          />
        </CiroButton>
      </div>
    </CiroCard>
  );
};

export default DataProviderConnectionCard;
