import classNames from "classnames";
import MobileNumbersOverviewCard from "./MobileNumbersOverviewCard";
import { gql, useQuery } from "@apollo/client";
import {
  MobileNumbersOverview_PhoneNumbersOverviewQuery,
  MobileNumbersOverview_PhoneNumbersOverviewQueryVariables,
} from "../../__generated__/graphql";
import SkeletonLoading from "../shared/SkeletonLoading";

export const MobileNumbersOverview_PhoneNumbersOverview = gql`
  query MobileNumbersOverview_PhoneNumbersOverview {
    phoneNumbersRequestTransactionsCount
    phoneNumbersRecoveredCount(requestType: batch)
    conversationsCreatedCount
  }
`;

const MobileNumbersOverviewLoading = () => {
  return (
    <div className={classNames("w-full")}>
      <div
        className={classNames(
          "flex",
          "flex-row",
          "justify-between",
          "gap-4",
          "mt-4",
          "w-full",
        )}
      >
        <div className={classNames("w-full")}>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
        </div>
        <div className={classNames("w-full")}>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
        </div>
        <div className={classNames("w-full")}>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
        </div>
      </div>
    </div>
  );
};

const MobileNumbersOverview = () => {
  const { data: overviewData, loading: overviewLoading } = useQuery<
    MobileNumbersOverview_PhoneNumbersOverviewQuery,
    MobileNumbersOverview_PhoneNumbersOverviewQueryVariables
  >(MobileNumbersOverview_PhoneNumbersOverview);

  if (overviewLoading) {
    return <MobileNumbersOverviewLoading />;
  }

  if (!overviewData) {
    return null;
  }
  return (
    <div
      className={classNames(
        "flex",
        "flex-row",
        "justify-between",
        "gap-4",
        "mt-4",
        "w-full",
      )}
    >
      <MobileNumbersOverviewCard
        amount={overviewData?.phoneNumbersRequestTransactionsCount}
        description="Pipeline runs"
      />
      <MobileNumbersOverviewCard
        amount={overviewData?.phoneNumbersRecoveredCount}
        description="Numbers written to CRM"
      />
      <MobileNumbersOverviewCard
        amount={overviewData?.conversationsCreatedCount}
        description="Conversations created"
      />
    </div>
  );
};

export default MobileNumbersOverview;
