import React, { useEffect, useState } from "react";
import CiroTextInput from "../../shared/CiroTextInput";
import CiroButton from "../../shared/CiroButton";
import classNames from "classnames";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import CiroModal from "../../shared/CiroModal";

const AdminAddApolloKey_AddApolloIntegration = gql`
  mutation AddApolloIntegration($input: AddApolloIntegrationInput!) {
    addApolloIntegration(input: $input) {
      disposition_map
    }
  }
`;

const AdminAddApolloKey_UpdateApolloIntegration = gql`
  mutation UpdateApolloIntegration($input: UpdateApolloIntegrationInput!) {
    updateApolloIntegration(input: $input) {
      org_id
      bad_number_dispositions
      good_number_dispositions
      overwrite
    }
  }
`;

const AdminAddApolloKey_DeleteApolloIntegration = gql`
  mutation deleteApolloIntegration($input: Int!) {
    deleteApolloIntegration(org_id: $input)
  }
`;

const AdminAddApolloKey_Organization = gql`
  query organization($id: Int!) {
    organization(id: $id) {
      apolloIntegration {
        id
        org_id
        api_key
        bad_number_dispositions
        good_number_dispositions
        overwrite
        disposition_map
      }
    }
  }
`;

const AdminAddApolloKey = ({ orgId }: { orgId: number }) => {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBadFacets, setSelectedBadFacets] = useState<any[]>([]);
  const [selectedGoodFacets, setSelectedGoodFacets] = useState<any[]>([]);
  const [overwrite, setOverwrite] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dispositionMap, setDispositionMap] = useState<any>(null);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [existingIntegration, setExistingIntegration] = useState<any>(null);
  const [addApolloIntegration] = useMutation(
    AdminAddApolloKey_AddApolloIntegration,
  );
  const [updateApolloIntegration] = useMutation(
    AdminAddApolloKey_UpdateApolloIntegration,
  );
  const [deleteApolloIntegration] = useMutation(
    AdminAddApolloKey_DeleteApolloIntegration,
  );

  const { data: apolloIntegrationData, loading: apolloIntegrationLoading } =
    useQuery(AdminAddApolloKey_Organization, {
      variables: { id: orgId },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (!apolloIntegrationLoading && apolloIntegrationData) {
      setExistingIntegration(
        apolloIntegrationData?.organization?.apolloIntegration,
      );
      setDispositionMap(
        JSON.parse(
          apolloIntegrationData?.organization?.apolloIntegration
            ?.disposition_map || "null",
        ),
      );
      setSelectedBadFacets(
        apolloIntegrationData?.organization?.apolloIntegration
          ?.bad_number_dispositions || [],
      );
      setSelectedGoodFacets(
        apolloIntegrationData?.organization?.apolloIntegration
          ?.good_number_dispositions || [],
      );
      setOverwrite(
        apolloIntegrationData?.organization?.apolloIntegration?.overwrite ||
          false,
      );
    }
  }, [apolloIntegrationLoading, apolloIntegrationData]);

  const handleOverwriteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverwrite(e.target.checked);
  };

  const handleFacetSelection = (facet: any, type: "bad" | "good") => {
    const setFacets =
      type === "bad" ? setSelectedBadFacets : setSelectedGoodFacets;

    setFacets((prevSelectedFacets) => {
      const isAlreadySelected = prevSelectedFacets.includes(facet.value);
      return isAlreadySelected
        ? prevSelectedFacets.filter(
            (selectedFacet) => selectedFacet !== facet.value,
          )
        : [...prevSelectedFacets, facet.value];
    });
  };

  const handleDeleteIntegration = async () => {
    try {
      const result = await deleteApolloIntegration({
        variables: {
          input: orgId,
        },
      });
      if (result.data.deleteApolloIntegration) {
        toast.success("Apollo integration deleted successfully");
        setExistingIntegration(null);
        setDispositionMap(null);
        setSelectedBadFacets([]);
        setSelectedGoodFacets([]);
        setOverwrite(false);
      } else {
        toast.error("Error deleting Apollo integration");
      }
    } catch (err) {
      console.error("Error deleting Apollo integration:", err);
      toast.error("Error deleting Apollo integration");
    }
  };

  const handleSubmitApi = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await addApolloIntegration({
        variables: {
          input: {
            org_id: orgId,
            api_key: apiKey,
          },
        },
      });
      if (result.data.addApolloIntegration) {
        setExistingIntegration(result.data.addApolloIntegration);
        setDispositionMap(
          JSON.parse(result.data.addApolloIntegration.disposition_map),
        );
        toast.success("Api key added successfully");
      } else {
        toast.error("Error adding Apollo integration");
      }
    } catch (err) {
      console.error("Error adding Apollo key:", err);
      toast.error("Error adding Apollo integration");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitIntegrationOptions = async () => {
    try {
      setOptionsLoading(true);
      const result = await updateApolloIntegration({
        variables: {
          input: {
            org_id: orgId,
            overwrite,
            bad_number_dispositions: selectedBadFacets,
            good_number_dispositions: selectedGoodFacets,
          },
        },
      });
      if (result.data.updateApolloIntegration) {
        toast.success("Apollo integration updated successfully");
        setExistingIntegration(result.data.updateApolloIntegration);
      } else {
        toast.error("Error updating Apollo integration");
      }
      setOptionsLoading(false);
    } catch (error) {
      toast.error("Error updating Apollo integration");
      setOptionsLoading(false);
    }
  };

  const hasIntegrationChanged = () => {
    if (!existingIntegration) return false;

    const existingBadFacets = existingIntegration.bad_number_dispositions || [];
    const existingGoodFacets =
      existingIntegration.good_number_dispositions || [];

    if (
      selectedBadFacets.length !== existingBadFacets.length ||
      selectedGoodFacets.length !== existingGoodFacets.length ||
      overwrite !== existingIntegration.overwrite
    ) {
      return true;
    }

    const badFacetsDiff = selectedBadFacets.some(
      (facet) => !existingBadFacets.includes(facet),
    );
    const goodFacetsDiff = selectedGoodFacets.some(
      (facet) => !existingGoodFacets.includes(facet),
    );

    return (
      badFacetsDiff ||
      goodFacetsDiff ||
      overwrite !== existingIntegration.overwrite
    );
  };

  return (
    <div className={classNames("mt-4")}>
      <>
        {existingIntegration ? (
          <>
            <div
              className={classNames(
                "mt-4",
                "mb-4",
                "flex",
                "items-center",
                "justify-between",
              )}
            >
              <span>Apollo API Key Entered</span>
              <CiroButton
                onClick={() => setShowDeleteModal(true)}
                analyticsField="delete_apollo_integration"
              >
                Delete Apollo Integration
              </CiroButton>
            </div>
            <CiroModal
              isOpen={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
            >
              <p>Are you sure you want to delete this Apollo integration?</p>
              <div
                className={classNames(
                  "mt-4",
                  "flex",
                  "justify-end",
                )}
              >
                <CiroButton
                  onClick={() => setShowDeleteModal(false)}
                  analyticsField="cancel_delete_apollo_integration"
                >
                  Cancel
                </CiroButton>
                <CiroButton
                  onClick={() => {
                    handleDeleteIntegration();
                    setShowDeleteModal(false);
                  }}
                  analyticsField="delete_apollo_integration"
                >
                  Delete
                </CiroButton>
              </div>
            </CiroModal>
          </>
        ) : (
          <form onSubmit={handleSubmitApi}>
            <CiroTextInput
              label="Apollo API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Paste your Apollo API key here"
            />
            <CiroButton disabled={!apiKey} analyticsField="add_apollo_key">
              {loading ? "Loading..." : "Fetch call dispositions"}
            </CiroButton>
          </form>
        )}
        {dispositionMap && (
          <>
            <div className={classNames("mt-4", "mb-4")}>
              <label
                className={classNames("flex", "items-center")}
              >
                <input
                  type="checkbox"
                  checked={overwrite}
                  onChange={handleOverwriteChange}
                  className={classNames("mr-2")}
                />
                <span
                  className={classNames(
                    "text-sm",
                    "text-gray-700",
                  )}
                >
                  Overwrite data in organization
                </span>
              </label>
            </div>
            <div>
              <div>Select dispositions that would require a new number</div>
              {dispositionMap.map((facet: any) => (
                <div
                  key={`bad-${facet.value}`}
                  className={classNames(
                    "inline-block",
                    "w-1/2",
                    "p-2",
                  )}
                >
                  <input
                    type="checkbox"
                    checked={selectedBadFacets.some(
                      (selectedFacet) => selectedFacet === facet.value,
                    )}
                    onChange={() => handleFacetSelection(facet, "bad")}
                  />
                  <label className={classNames("ml-2")}>
                    {facet.display_name}
                  </label>
                </div>
              ))}
            </div>
            <div className={classNames("mt-4")}>
              <div>Select dispositions that indicate a good number</div>
              {dispositionMap.map((facet: any) => (
                <div
                  className={classNames(
                    "inline-block",
                    "w-1/2",
                    "p-2",
                  )}
                >
                  <input
                    type="checkbox"
                    id={`good-${facet.value}`}
                    checked={selectedGoodFacets.some(
                      (selectedFacet) => selectedFacet === facet.value,
                    )}
                    onChange={() => handleFacetSelection(facet, "good")}
                  />
                  <label className={classNames("ml-2")}>
                    {facet.display_name}
                  </label>
                </div>
              ))}
            </div>
          </>
        )}
        {dispositionMap && (
          <CiroButton
            onClick={handleSubmitIntegrationOptions}
            disabled={!hasIntegrationChanged() || optionsLoading}
            analyticsField="submit_apollo_integration"
          >
            {optionsLoading ? "Saving..." : "Save Integration Options"}
          </CiroButton>
        )}
      </>
    </div>
  );
};

export default AdminAddApolloKey;
