import { gql, useMutation } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import {
  IntegrationEnum,
  RemoveIntegrationModal_RemoveIntegrationMutationVariables,
  RemoveIntegrationModal_RemoveIntegrationMutation,
} from "../../__generated__/graphql";
import { useState } from "react";
import classNames from "classnames";
import toast from "react-hot-toast";
import Loading from "../shared/Loading";

const RemoveIntegrationModal_RemoveIntegration = gql`
  mutation RemoveIntegrationModal_RemoveIntegration($integration: IntegrationEnum!) {
    removeIntegration(integration: $integration) {
      success
      message
    }
  }
`;

interface IRemoveIntegrationModalProps {
  integration?: IntegrationEnum;
  onClose: () => void;
  refetchUserAccount: () => void;
}

const RemoveIntegrationModal = ({
  integration,
  onClose,
  refetchUserAccount
}: IRemoveIntegrationModalProps) => {
  const [loading, setLoading] = useState(false);

  const [removeIntegration] = useMutation<
    RemoveIntegrationModal_RemoveIntegrationMutation,
    RemoveIntegrationModal_RemoveIntegrationMutationVariables
    >(RemoveIntegrationModal_RemoveIntegration);

  if (!integration) {
    return null;
  }

  const handleSubmit = () => {
    setLoading(true);
    removeIntegration({
      variables: {
        integration: integration,
      },
      onCompleted: async (data) => {  
        await refetchUserAccount();
        setLoading(false);
        onClose();
        if (data?.removeIntegration?.success) {
          toast.success("Integration removed");
        } else {
          toast.error(data?.removeIntegration?.message || "Integration couldn't be removed");
        }
      },
      onError: async () => {
        await refetchUserAccount();
        setLoading(false);
        onClose();
        toast.error("Integration couldn't be removed");
      }
    });
  };

  return (
    <CiroModal isOpen={true} onClose={onClose}>
      {loading && <Loading size="SMALL"/>}
      {!loading && <div className={classNames("text-gray-700")}>
        <div>
          Are you sure you want to remove the{" "}
          <span className={"font-semibold"}>{integration}</span>
          {" "}integration?
        </div>
        <div className={classNames("text-sm", "pt-4")}>
          This action cannot be undone.
        </div>
        <div
          className={classNames(
            "flex",
            "justify-end",
            "pt-4",
          )}
        >
          <span
            className={classNames({
              "pr-2": true,
            })}
          >
            <CiroButton
              analyticsField="Cancel remove integration"
              onClick={onClose}
            >
              Cancel
            </CiroButton>
          </span>
          <CiroButton
            analyticsField="Remove integration"
            onClick={handleSubmit}
            style={CiroButtonStyleEnum.LOUD}
          >
            Remove
          </CiroButton>
        </div>
      </div>}
    </CiroModal>
  );
};

export default RemoveIntegrationModal;

