import CiroNumbersFormSection from "../shared/CiroNumbersFormSection";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import CiroDropDown from "../shared/CiroDropdown";
import { useMemo } from "react";
import {
  OrgContactObjectType,
  PhoneNumberRequestSegmentTypeEnum,
} from "../../__generated__/graphql";
import CallDispositionWorkflowFormSection from "./CallDispositionWorkflowFormSection";
import CrmRecordValuesWorkflowFormSection from "./CrmRecordValuesWorkflowFormSection";
import classNames from "classnames";

const FilterOptionsWorkflowFormSection = ({
  sharedWorkflowFormProperties,
  isLoadingWorkflow,
}: {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
  isLoadingWorkflow: boolean;
}) => {
  const { setValue, trigger, errors, isHubspot, isSalesforce, watch } =
    sharedWorkflowFormProperties;

  const segmentationType = watch("segmentation.segment_type");
  const orgContactObjectType = watch("org_contact_object_type");

  const segmentationTypeOptions = useMemo(() => {
    const options = [
      {
        label: "Call",
        value: PhoneNumberRequestSegmentTypeEnum.CallDisposition,
      },
    ];

    if (isHubspot || isSalesforce) {
      options.push({
        label:
          orgContactObjectType === OrgContactObjectType.Lead
            ? "Lead"
            : "Contact",
        value: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      });
    }

    return options;
  }, [isHubspot, isSalesforce, orgContactObjectType]);

  return (
    <CiroNumbersFormSection
      title="Filter options"
      description="Choose how to identify contacts needing new numbers."
      isLoading={isLoadingWorkflow}
    >
      <div className={classNames("mb-4")}>
        <CiroDropDown
          isMulti={false}
          label="Object to filter on"
          options={segmentationTypeOptions}
          onChange={(value) => {
            setValue("segmentation.segment_type", value);
            trigger("segmentation.segment_type");
            if (value === PhoneNumberRequestSegmentTypeEnum.CrmRecordValues) {
              setValue(
                "segmentation.crm_record_type",
                OrgContactObjectType.Contact as string,
              );
              trigger("segmentation.crm_record_type");
            } else {
              setValue("segmentation.crm_record_type", null);
              trigger("segmentation.crm_record_type");
            }
          }}
          value={segmentationType}
          error={(errors.segmentation?.segment_type?.message as string) || ""}
          infoTooltip={
            "Choose whether to enrich based on call data or by choosing a segment of contacts or leads."
          }
        />
      </div>
      {segmentationType ===
        PhoneNumberRequestSegmentTypeEnum.CallDisposition && (
        <CallDispositionWorkflowFormSection
          sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        />
      )}
      {segmentationType ===
        PhoneNumberRequestSegmentTypeEnum.CrmRecordValues && (
        <CrmRecordValuesWorkflowFormSection
          sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        />
      )}
    </CiroNumbersFormSection>
  );
};

export default FilterOptionsWorkflowFormSection;
