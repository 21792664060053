import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  AutomationListFindEmailsButton_ContactListFragment,
  AutomationListFindEmailsButton_CreateEmailRequestsTransactionMutation,
  AutomationListFindEmailsButton_CreateEmailRequestsTransactionMutationVariables,
  AutomationListFindEmailsButton_CurrentBalanceQuery,
  AutomationListFindEmailsButton_CurrentBalanceQueryVariables,
  AutomationListFindEmailsButton_EmailRequestTransactionQuery,
  AutomationListFindEmailsButton_EmailRequestTransactionQueryVariables,
} from "../../../__generated__/graphql";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import pluralize from "pluralize";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroModal from "../../shared/CiroModal";
import NumberFormat from "react-number-format";
import CiroSpinner from "../../shared/CiroSpinner";
import toast from "react-hot-toast";

export const CREDITS_PER_EMAIL = 3;

export const AutomationListFindEmailsButton_ContactList = gql`
  fragment AutomationListFindEmailsButton_ContactList on ContactList {
    id
    name
    mostRecentEmailRequestTransaction {
      id
      finished_at
      error
    }
  }
`;

const AutomationListFindEmailsButton_CreateEmailRequestsTransaction = gql`
  mutation AutomationListFindEmailsButton_CreateEmailRequestsTransaction(
    $input: CreateContactListEmailRequestsInput!
  ) {
    createContactListEmailRequests(input: $input) {
      message
      success
      transaction {
        id
      }
    }
  }
`;

const AutomationListFindEmailsButton_EmailRequestTransaction = gql`
  query AutomationListFindEmailsButton_EmailRequestTransaction($id: Int!) {
    emailRequestTransaction(id: $id) {
      id
      finished_at
      error
      orgContacts {
        id
        orgEmails {
          email
        }
      }
    }
  }
`;

const AutomationListFindEmailsButton_CurrentBalance = gql`
  query AutomationListFindEmailsButton_CurrentBalance {
    userAccount {
      org {
        credit_plan {
          balance
        }
      }
    }
  }
`;

const AutomationListFindEmailsButton = ({
  contactList,
  contactIds,
}: {
  contactList: AutomationListFindEmailsButton_ContactListFragment | null;
  contactIds: number[];
}) => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    currentEmailRequestTransactionId,
    setCurrentEmailRequestTransactionId,
  ] = useState<number | null>(null);

  const [createContactEmailRequests] = useMutation<
    AutomationListFindEmailsButton_CreateEmailRequestsTransactionMutation,
    AutomationListFindEmailsButton_CreateEmailRequestsTransactionMutationVariables
  >(AutomationListFindEmailsButton_CreateEmailRequestsTransaction);

  const [getEmailRequestTransaction] = useLazyQuery<
    AutomationListFindEmailsButton_EmailRequestTransactionQuery,
    AutomationListFindEmailsButton_EmailRequestTransactionQueryVariables
  >(AutomationListFindEmailsButton_EmailRequestTransaction);

  const { data: balanceData, loading: balanceLoading } = useQuery<
    AutomationListFindEmailsButton_CurrentBalanceQuery,
    AutomationListFindEmailsButton_CurrentBalanceQueryVariables
  >(AutomationListFindEmailsButton_CurrentBalance, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      contactList?.mostRecentEmailRequestTransaction &&
      !contactList.mostRecentEmailRequestTransaction.finished_at &&
      !contactList.mostRecentEmailRequestTransaction.error
    ) {
      setIsLoading(true);
      setCurrentEmailRequestTransactionId(
        contactList.mostRecentEmailRequestTransaction.id,
      );
    }
  }, [contactList?.mostRecentEmailRequestTransaction]);

  const currentBalance =
    balanceData?.userAccount?.org?.credit_plan?.balance || 0;

  useEffect(() => {
    if (currentEmailRequestTransactionId) {
      const pollInterval = 3000;

      const pollTransaction = async () => {
        const { data } = await getEmailRequestTransaction({
          variables: { id: currentEmailRequestTransactionId },
          fetchPolicy: "network-only",
        });

        if (data?.emailRequestTransaction) {
          const transaction = data.emailRequestTransaction;

          transaction.orgContacts.forEach((orgContact) => {
            client.cache.modify({
              id: client.cache.identify({
                __typename: "OrgContact",
                id: orgContact.id,
              }),
              fields: {
                orgEmails() {
                  return orgContact.orgEmails;
                },
              },
            });
          });

          if (!transaction.finished_at && !transaction.error) {
            setTimeout(pollTransaction, pollInterval);
          } else {
            setIsLoading(false);
          }
        }
      };

      pollTransaction();
    }
  }, [
    currentEmailRequestTransactionId,
    getEmailRequestTransaction,
    client.cache,
  ]);

  const handleFindEmails = useCallback(
    async (ids: number[]) => {
      if (!contactList) {
        return;
      }

      setIsLoading(true);

      const { data } = await createContactEmailRequests({
        variables: {
          input: {
            orgContactIds: ids,
            contactListId: contactList.id,
          },
        },
        onError: (error) => {
          setIsLoading(false);
          toast.error("Error creating email enrichment");
        },
      });

      if (data?.createContactListEmailRequests?.success) {
        setCurrentEmailRequestTransactionId(
          data.createContactListEmailRequests.transaction?.id ?? null,
        );
      }
    },
    [createContactEmailRequests, contactList],
  );

  const totalCreditsNeeded = contactIds.length * CREDITS_PER_EMAIL;

  let tooltipMessage = "Find email addresses";
  if (isLoading) {
    tooltipMessage = "Running find emails. This may take a few minutes";
  } else if (contactIds.length === 0) {
    tooltipMessage = "No contacts selected";
  } else if (contactList?.mostRecentEmailRequestTransaction) {
    const lastUpdateDate = new Date(
      contactList.mostRecentEmailRequestTransaction.finished_at,
    );
    tooltipMessage = `Last updated: ${formatDistanceToNow(lastUpdateDate)} ago`;
  }

  return (
    <>
      <CiroTooltipContainer
        tooltip={tooltipMessage}
        disabled={!isLoading && !contactList?.mostRecentEmailRequestTransaction}
      >
        <CiroButton
          onClick={() => {
            setIsModalOpen(true);
          }}
          disabled={isLoading || contactIds.length === 0}
          analyticsField={"Start find emails for contact list"}
          analyticsProps={{
            contactListId: contactList?.id,
          }}
        >
          <div className={classNames("flex", "items-center")}>
            {isLoading ? (
              <CiroSpinner loading={true} />
            ) : (
              <EnvelopeIcon
                className={classNames("w-5", "h-5")}
              />
            )}
          </div>
        </CiroButton>
      </CiroTooltipContainer>
      <CiroModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {balanceLoading ? (
          <div
            className={classNames(
              "flex",
              "justify-center",
              "py-10",
            )}
          >
            <CiroSpinner loading={true} />
          </div>
        ) : (
          <div>
            <div
              className={classNames(
                "text-lg",
                "font-medium",
                "pb-4",
              )}
            >
              Find emails for {contactIds.length}{" "}
              {pluralize("contact", contactIds.length)}
            </div>
            <div className={classNames("pb-4")}>
              <div className={classNames("font-medium")}>
                Maximum number of credits needed:{" "}
                <NumberFormat
                  value={totalCreditsNeeded}
                  thousandSeparator=","
                  displayType="text"
                />
              </div>
              <div
                className={classNames(
                  "text-xs",
                  "text-gray-500",
                )}
              >
                Credits will only be deducted for found emails.
              </div>
              {totalCreditsNeeded > currentBalance && (
                <div
                  className={classNames(
                    "text-xs",
                    "text-red-500",
                    "mt-2",
                  )}
                >
                  Not enough credits to find emails
                </div>
              )}
            </div>
            <div
              className={classNames(
                "flex",
                "justify-end",
                "gap-2",
              )}
            >
              <CiroButton
                analyticsField={"Cancel find emails"}
                onClick={() => setIsModalOpen(false)}
                style={CiroButtonStyleEnum.INVERTED_LOUD}
              >
                Cancel
              </CiroButton>
              <CiroButton
                analyticsField={"Run find emails for contact list"}
                disabled={totalCreditsNeeded > currentBalance}
                onClick={() => {
                  setIsModalOpen(false);
                  handleFindEmails([...contactIds]);
                }}
                style={CiroButtonStyleEnum.LOUD}
              >
                Find emails
              </CiroButton>
            </div>
          </div>
        )}
      </CiroModal>
    </>
  );
};

export default AutomationListFindEmailsButton;
