import EnrichmentFlowTableDropdownOption from "./EnrichmentFlowTableDropdownOption";
import NodeBranchIcon from "../../../../assets/img/icons/NodeBranchIcon";
import classNames from "classnames";
import EnrichmentFlowTableRangeDropdown from "./EnrichmentFlowTableRangeDropdown";

interface IEnrichmentFlowTableIndexDropdownProps {
  filtersShown: boolean;
  onClick: () => void;
}

const EnrichmentFlowTableIndexDropdown = ({
  filtersShown,
  onClick,
}: IEnrichmentFlowTableIndexDropdownProps) => {
  return (
    <div className={classNames("overflow-hidden")}>
      <EnrichmentFlowTableDropdownOption
        option={filtersShown ? "Hide filters" : "Show filters"}
        onClick={onClick}
        Icon={NodeBranchIcon}
      />
      <EnrichmentFlowTableRangeDropdown/>
    </div>
  );
};

export default EnrichmentFlowTableIndexDropdown;
