import { useContext } from "react";
import { gql } from "@apollo/client";
import { CreateIntegrationButton_UserAccountFragment } from "../../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";
import NavigationContext from "../../../contexts/NavigationContext";

export const CreateIntegrationButton_UserAccount = gql`
  fragment CreateIntegrationButton_UserAccount on UserAccount {
    org {
      organizationMergeIntegration {
        integration
      }
      apolloIntegration {
        id
      }
    }
  }
`;

interface IManageIntegrationsButton {
  userAccount: CreateIntegrationButton_UserAccountFragment | null | undefined;
  homeButton?: boolean;
}

export const enum IntegrationModalPhases {
  GET_STARTED = "GET_STARTED",
  CHOOSE_INTEGRATION = "CHOOSE_INTEGRATION",
  MERGE_MODAL = "MERGE_MODAL",
  APOLLO_API_KEY = "APOLLO_API_KEY",
}

export function ManageIntegrationsButton({
  userAccount,
  homeButton,
}: IManageIntegrationsButton) {
  const { integrationModalPhase, setIntegrationModalPhase } =
    useContext(NavigationContext);
  const hasIntegrations = Boolean(
    userAccount?.org?.organizationMergeIntegration?.integration ||
      userAccount?.org?.apolloIntegration?.id,
  );

  return (
    <div className={classNames({ "w-full": homeButton })}>
      {integrationModalPhase === IntegrationModalPhases.GET_STARTED && (
        <CiroButton
          style={CiroButtonStyleEnum.LOUD}
          onClick={() =>
            setIntegrationModalPhase(IntegrationModalPhases.CHOOSE_INTEGRATION)
          }
          analyticsField="Connect integration"
        >
          {hasIntegrations ? "Manage integrations" : "Connect CRM"}
        </CiroButton>
      )}
    </div>
  );
}
