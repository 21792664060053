import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import CiroLink from "../shared/CiroLink";

const MobileNumbersGetStartedContainer = () => {
  return (
    <div
      className={classNames(
        "flex",
        "justify-center",
        "items-center",
        "w-full",
        "pt-32",
      )}
    >
      <div
        className={classNames(
          "max-w-md",
          "flex-col",
          "justify-center",
          "items-center",
        )}
      >
        <div
          className={classNames(
            "flex",
            "justify-center",
            "mb-8",
          )}
        >
          <div
            className={classNames(
              "bg-white",
              "rounded-full",
              "text-orange-500",
              "w-fit",
              "p-2",
            )}
          >
            <ArchiveBoxIcon
              className={classNames("w-6", "h-6")}
            />
          </div>
        </div>
        <div
          className={classNames(
            "text-center",
            "text-xl",
            "font-bold",
            "pb-2",
          )}
        >
          Enrich phone data via waterfall
        </div>
        <div
          className={classNames(
            "text-center",
            "text-sm",
            "text-zinc-500",
          )}
        >
          Ciro pulls from the world's best mobile phone data providers. Use our{" "}
          <CiroLink
            href="https://chromewebstore.google.com/detail/ciro-data-assistant/poiofceiibpjdmhihnpaembimmnioifk?hl=en"
            shouldOpenInNewTab
          >
            Chrome extension
          </CiroLink>{" "}
          to find the right numbers for your contacts, or connect your CRM to
          automatically replace wrong numbers.
        </div>
      </div>
    </div>
  );
};

export default MobileNumbersGetStartedContainer;
