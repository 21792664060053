import React from "react";
import classNames from "classnames";

interface ICiroPopoutDropdownProps {
  children: any;
  setIsOpen: (v: boolean) => void;
  isOpen: boolean;
  parent: any;
  customWidth?: string;
  isGray?: boolean;
}

const CiroPopoutDropdown = ({
  children,
  setIsOpen,
  isOpen,
  parent,
  customWidth,
  isGray = false,
}: ICiroPopoutDropdownProps) => {
  return (
    <div>
      <div
        className={classNames("relative", {
          "z-20": isOpen,
        })}
      >
        {parent}
      </div>
      <div
        className={classNames([
          "flex-col",
          "flex",
          "items-end",
        ])}
      >
        {isOpen && (
          <>
            <div
              className={classNames(
                {
                  "bg-gray-100": isGray,
                  "bg-white": !isGray,
                },
                "fixed",
                "overflow-scroll",
                "shadow-lg",
                "z-20",
                "lg:absolute",
                "lg:inset-auto",
                "sm:ml-32",
                customWidth,
                {
                  "inset-0": !customWidth,
                  "p-6": customWidth !== "w-full",
                  "p-4": customWidth === "w-full",
                  "lg:mt-4": customWidth !== "w-full",
                  "rounded-md": customWidth !== "w-full",
                  "lg:w-3/5": !customWidth,
                },
              )}
            >
              <div>{children}</div>
            </div>
            <div
              className={classNames([
                "bg-gray-500",
                "fixed",
                "inset-0",
                "opacity-50",
                "z-10",
              ])}
              onClick={() => setIsOpen(!isOpen)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CiroPopoutDropdown;
