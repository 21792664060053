const BookmarkSearch = ({
  width = "24",
  height = "24",
  stroke = "currentColor",
}: {
  width?: string;
  height?: string;
  stroke?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9996 17.531L18.1836 14.715"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0002 7.061C17.4682 7.061 19.4692 9.062 19.4692 11.53C19.4692 13.998 17.4682 16 15.0002 16C12.5322 16 10.5312 13.999 10.5312 11.531C10.5312 9.063 12.5322 7.061 15.0002 7.061"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16V21L9.008 18L3 21V5C3 3.895 3.895 3 5 3H13C14.105 3 15 3.895 15 5V7.061"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookmarkSearch;
