import { useContext } from "react";
import EnrichmentFlowContext from "../../../../contexts/EnrichmentFlowContext";
import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroCreateableTextInput from "../../../shared/CiroCreateableTextInput";
import { EnrichmentFlowFilterInputTypeEnum } from "../../../../__generated__/graphql";
import {
  EnrichmentFilterTechnique,
  enrichmentFilters,
} from "../../EnrichmentStepCard/EnrichmentStepCardFilters/EnrichmentStepFilterDropdown";

const EnrichmentFlowTableFilterOptions = () => {
  const {
    enrichmentFlowWithSteps,
    enrichmentFlowFilters,
    updateEnrichmentFilter,
  } = useContext(EnrichmentFlowContext);
  return (
    <div>
      {enrichmentFlowWithSteps?.enrichmentSteps.map((step) => {
        if (
          enrichmentFilters.includes(
            step.enrichment_technique as EnrichmentFilterTechnique,
          )
        ) {
          return null;
        }
        const enrichmentStepFilters = enrichmentFlowFilters[step.id];
        return (
          <div key={step.id}>
            <CiroFilterModalDropdownSection
              numFiltersApplied={
                Object.keys(enrichmentStepFilters || {}).length
              }
              sectionTitle={step.name}
              filterOptions={[
                {
                  label: "Include any of",
                  filter: (
                    <CiroCreateableTextInput
                      containerWidthClass="w-full"
                      value={
                        enrichmentStepFilters?.[
                          EnrichmentFlowFilterInputTypeEnum.Any
                        ] || []
                      }
                      setValue={(v) => {
                        updateEnrichmentFilter({
                          stepId: step.id,
                          method: EnrichmentFlowFilterInputTypeEnum.Any,
                          values: v,
                        });
                      }}
                    />
                  ),
                },
                {
                  label: "Include all of",
                  filter: (
                    <CiroCreateableTextInput
                      containerWidthClass="w-full"
                      value={
                        enrichmentStepFilters?.[
                          EnrichmentFlowFilterInputTypeEnum.All
                        ] || []
                      }
                      setValue={(v) => {
                        updateEnrichmentFilter({
                          stepId: step.id,
                          method: EnrichmentFlowFilterInputTypeEnum.All,
                          values: v,
                        });
                      }}
                    />
                  ),
                },
                {
                  label: "Exclude",
                  filter: (
                    <CiroCreateableTextInput
                      containerWidthClass="w-full"
                      value={
                        enrichmentStepFilters?.[
                          EnrichmentFlowFilterInputTypeEnum.Exclude
                        ] || []
                      }
                      setValue={(v) => {
                        updateEnrichmentFilter({
                          stepId: step.id,
                          method: EnrichmentFlowFilterInputTypeEnum.Exclude,
                          values: v,
                        });
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EnrichmentFlowTableFilterOptions;
