import classNames from "classnames";
import { FormProvider, useWatch } from "react-hook-form";
import {
  EnrichmentStep,
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { IEnrichmentTemplateVariable } from "../EnrichmentStepRichTextEditor/EnrichmentStepRichTextContainer";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import CiroDropDown from "../../../shared/CiroDropdown";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { useFragment } from "../../../../__generated__";
import CiroTextArea from "../../../shared/CiroTextArea";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import { IEnrichmentStepTechniqueCardProps } from ".";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

export const PICKLIST_INPUT_KEY = "picklist";
export const WEBSITE_INPUT_KEY = "businessWebsite";
export const QUESTION_INPUT_KEY = "question";

export const EnrichmentStepTechniqueLlmWebEnrichmentCardSchema = yup
  .object({
    businessWebsite: yup
      .object({
        key: yup.string(),
        input: yup
          .string()
          // Nullable when sourceEnrichmentStep is just a primitive type
          .nullable(),
        sourceEnrichmentStepId: yup.number().nullable(),
      })
      .required("Business website is required"),
    question: yup.string().required("Question is required"),
    usePicklist: yup.boolean().nullable(),
    picklist: yup.array().of(yup.string()),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required();

export const getDefaultLlmWebEnrichmentValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );

  const getInputOrNull = (key: string) => {
    const filteredInputArray =
      enrichmentStep?.parentEnrichmentStepInputs?.filter(
        (input) => input.key === key,
      );
    if (filteredInputArray?.length) {
      const { key, input, sourceEnrichmentStep } = filteredInputArray[0];
      return {
        key,
        input: input ? JSON.parse(input) : ("" as string),
        sourceEnrichmentStep: sourceEnrichmentStep,
      };
    } else {
      return null;
    }
  };

  const defaultValueWithoutPicklist = {
    businessWebsite: getInputOrNull(WEBSITE_INPUT_KEY),
    question: getInputOrNull(QUESTION_INPUT_KEY)?.input || "",
    usePicklist: enrichmentStep?.parentEnrichmentStepInputs?.filter(
      (input) => input.key === PICKLIST_INPUT_KEY,
    )?.length
      ? true
      : false,
    filterEnrichmentSteps: filterEnrichmentSteps,
  };

  const picklist = enrichmentStep?.parentEnrichmentStepInputs
    ?.filter((input) => input.key === PICKLIST_INPUT_KEY && input.input)
    .map((input) => input.input)[0];
  const jsonPicklist = picklist ? (JSON.parse(picklist) as string[]) : [];
  const defaultValue = {
    ...defaultValueWithoutPicklist,
    picklist: jsonPicklist,
  };
  return defaultValue;
};

const EnrichmentStepTechniqueLlmWebEnrichmentCard = ({
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const getDefaultValues = useCallback(() => {
    return getDefaultLlmWebEnrichmentValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formMethods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueLlmWebEnrichmentCardSchema),
    defaultValues: getDefaultValues(),
  });

  const {
    control,
    formState: { errors, isDirty },
    register,
    setValue,
    handleSubmit,
    reset,
  } = formMethods;

  useEffect(() => {
    reset(getDefaultValues());
    return () => {
      reset();
    };
  }, [getDefaultValues, reset]);

  const [
    usePicklist,
    picklist,
    businessWebsite,
    question,
    filterEnrichmentSteps,
  ] = useWatch({
    control,
    name: [
      "usePicklist",
      PICKLIST_INPUT_KEY,
      WEBSITE_INPUT_KEY,
      QUESTION_INPUT_KEY,
      "filterEnrichmentSteps",
    ],
  });

  const formatAndSubmitRequest = (data: any) => {
    const parentEnrichmentStepInputs = [];

    if (usePicklist && picklist?.length) {
      parentEnrichmentStepInputs.push({
        key: PICKLIST_INPUT_KEY,
        required: true,
        input: JSON.stringify(data.picklist),
        sourceEnrichmentStepId: null,
      });
    }

    if (businessWebsite?.sourceEnrichmentStep?.id || businessWebsite?.input) {
      parentEnrichmentStepInputs.push({
        key: WEBSITE_INPUT_KEY,
        required: true,
        input: JSON.stringify(businessWebsite.input),
        sourceEnrichmentStepId: businessWebsite?.sourceEnrichmentStep?.id,
      });
    }

    if (question) {
      parentEnrichmentStepInputs.push({
        key: QUESTION_INPUT_KEY,
        required: false,
        input: JSON.stringify(question),
        sourceEnrichmentStepId: null,
      });
    }

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: EnrichmentTechniqueEnum.LlmWebResearch,

          // selected_input refers to the display value (which JSON key is displayed in the column)
          selected_input: data.selected_input || null,
          parentEnrichmentStepInputs,
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div>
        <div className={classNames("mb-5", "mt-2")}>
          <EnrichmentStepSelector
            label={"Business Website*"}
            inputError={errors.businessWebsite?.message}
            stepVariable={{
              stepId: businessWebsite?.sourceEnrichmentStep?.id || null,
              stepInput: businessWebsite?.input || null,
            }}
            setStepVariable={(stepVariable: IEnrichmentTemplateVariable) => {
              if (stepVariable.stepId) {
                setValue(
                  `businessWebsite.sourceEnrichmentStep.id`,
                  stepVariable?.stepId,
                );
              } else {
                setValue(`businessWebsite.sourceEnrichmentStep`, null);
              }
              setValue(`businessWebsite.input`, stepVariable?.stepInput);
            }}
          />
        </div>
        <div className={classNames("my-6")}>
          <CiroTextArea
            label="What do you want to know?"
            register={register(`question`)}
            error={errors.question?.message as string}
          />
        </div>
        <div
          className={classNames(
            "mt-6",
            { "mb-8": !usePicklist },
            "flex",
            "flex-row",
            "items-center",
          )}
        >
          <CiroCheckbox
            checked={usePicklist}
            label={"Limit answers to picklist"}
            labelClassName={classNames("text-gray-500")}
            onClick={() => {
              if (!usePicklist) {
                setValue("usePicklist", true);
              } else {
                setValue("usePicklist", false);
              }
            }}
          />
          <div className={classNames("ml-1", "text-gray-400")}>
            <CiroTooltipContainer
              tooltip={
                <div>
                  Provide a list of categories or industries you'd like Ciro's
                  research team to choose from
                </div>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </CiroTooltipContainer>
          </div>
        </div>
        {usePicklist && (
          <div className={classNames("mt-4", "mb-8")}>
            <CiroDropDown
              formatCreateLabel={(inputValue) =>
                `Create "${inputValue}" option`
              }
              label="Predefined answers"
              error={errors?.picklist?.message as string}
              labelClassName={classNames("text-gray-500")}
              placeholder="Start typing to create a picklist option..."
              creatable
              isMulti
              options={picklist.map((option) => {
                return { label: option, value: option };
              })}
              value={picklist as any[]}
              isClearable
              onChange={(v) => {
                setValue("picklist", v as any);
              }}
            />
          </div>
        )}
      </div>
      <div className={classNames("mt-12")}>
        <FormProvider {...formMethods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueLlmWebEnrichmentCard;
