import classNames from "classnames";
import {
  EnrichmentTechniqueEnum,
  NewStepDropdownOption_EnrichmentTechniqueOptionsQuery,
  NewStepDropdownOption_EnrichmentTechniqueOptionsQueryVariables,
} from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import StarsIcon from "../../assets/img/icons/StarsIcon";
import enrichmentTechniqueEnumToIcon from "./enrichmentTechniqueEnumToIcon";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";
import { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { UPGRADE_PLAN_FOR_FEATURE } from "../../utils/consts";
import CiroCoinPill from "../shared/CiroCoinPill";
import CiroSpinner from "../shared/CiroSpinner";

interface IEnrichmentFlowNewStepDropdownProps {
  setSelectedEnrichmentStepId: (v: number | null) => void;
  setSelectedEnrichmentTechnique: (v: EnrichmentTechniqueEnum | null) => void;
  closeExecutionCellData: () => void;
}

const NewStepDropdownOption_EnrichmentTechniqueOptions = gql`
  query NewStepDropdownOption_EnrichmentTechniqueOptions {
    enrichmentTechniqueOptions {
      label
      options {
        description
        name
        isPremium
        costPerExecution
      }
    }
    permissions {
      canUsePremiumEnrichments
    }
  }
`;

const NewStepDropdownOption = ({
  name,
  label,
  isPremium,
  canUsePremiumEnrichments,
  costPerExecution,
}: {
  costPerExecution: number;
  name: string;
  label: string;
  isPremium: boolean;
  canUsePremiumEnrichments: boolean;
}) => {
  const Icon = enrichmentTechniqueEnumToIcon[name];

  return (
    <CiroTooltipContainer
      tooltip={UPGRADE_PLAN_FOR_FEATURE}
      disabled={canUsePremiumEnrichments || !isPremium}
    >
      <span
        className={classNames(
          "flex",
          "items-center",
          "justify-between",
        )}
      >
        <span
          className={classNames(
            "flex",
            "items-center",
            "text-xs",
          )}
        >
          <Icon className={classNames("w-4", "h-4")} />
          <span className={classNames("pl-2")}>{label}</span>
        </span>
        {(costPerExecution || 0) > 0 && (
          <CiroCoinPill amount={costPerExecution} />
        )}
      </span>
    </CiroTooltipContainer>
  );
};

const EnrichmentFlowNewStepDropdown = ({
  setSelectedEnrichmentStepId,
  setSelectedEnrichmentTechnique,
  closeExecutionCellData,
}: IEnrichmentFlowNewStepDropdownProps) => {
  const { setNewEnrichmentTechniqueDetails, setNewEnrichmentTitle } =
    useContext(EnrichmentFlowContext);

  const {
    data: enrichmentTechniquesData,
    loading: enrchmentTechniquesLoading,
  } = useQuery<
    NewStepDropdownOption_EnrichmentTechniqueOptionsQuery,
    NewStepDropdownOption_EnrichmentTechniqueOptionsQueryVariables
  >(NewStepDropdownOption_EnrichmentTechniqueOptions);

  const optionGroups = (enrichmentTechniquesData?.enrichmentTechniqueOptions ||
    []) as any;

  const canUsePremiumEnrichments = Boolean(
    enrichmentTechniquesData?.permissions?.canUsePremiumEnrichments,
  );

  return (
    <CiroDropDown
      isDisabled={enrchmentTechniquesLoading}
      className="w-72"
      isMulti={false}
      options={optionGroups}
      getOptionLabel={({
        costPerExecution,
        name,
        isPremium,
        description,
      }: {
        name: string;
        description: string;
        isPremium: boolean;
        costPerExecution: number;
      }) => (
        <NewStepDropdownOption
          costPerExecution={costPerExecution}
          name={name}
          label={description}
          isPremium={isPremium}
          canUsePremiumEnrichments={canUsePremiumEnrichments}
        />
      )}
      placeholder={
        <div className={classNames("flex", "items-center")}>
          {!enrchmentTechniquesLoading && <StarsIcon />}
          {enrchmentTechniquesLoading && <CiroSpinner loading={true} />}
          <span className={classNames("pl-2")}>Create new column</span>
        </div>
      }
      value={null}
      onChangeFullValues={(v: any) => {
        setSelectedEnrichmentTechnique(v.name);
        setNewEnrichmentTechniqueDetails(v);
        setNewEnrichmentTitle(v.label);
        setSelectedEnrichmentStepId(null);
        closeExecutionCellData();
      }}
    />
  );
};

export default EnrichmentFlowNewStepDropdown;
