import { TrashIcon } from "@heroicons/react/24/outline";
import CiroButton from "../../shared/CiroButton";
import classNames from "classnames";
import { useContext, useState } from "react";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";
import { gql, useMutation } from "@apollo/client";
import CiroConfirmationModal from "../../shared/CiroConfirmationModal";
import {
  EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRowsMutation,
  EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRowsMutationVariables,
} from "../../../__generated__/graphql";
import toast from "react-hot-toast";

const EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRows = gql`
  mutation EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRows(
    $flowId: Int!
    $flowRowIds: [Int!]!
  ) {
    deleteEnrichmentFlowRows(flowId: $flowId, flowRowIds: $flowRowIds) {
      success
    }
  }
`;

const EnrichmentFlowDeleteSelectedRowsButton = () => {
  const {
    selectedRows,
    setSelectedRows,
    enrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    EnrichmentFlow_refetchEnrichmentFlow,
    setEnrichmentFlowLoading,
    enrichmentFlowId,
    enrichmentFlowRowIds,
  } = useContext(EnrichmentFlowContext);
  const [isConfirming, setIsConfirming] = useState(false);
  const [deleteEnrichmentFlowRows] = useMutation<
    EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRowsMutation,
    EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRowsMutationVariables
  >(EnrichmentFlowClearSelectionButton_DeleteEnrichmentFlowRows);

  return (
    <>
      <CiroButton
        analyticsField="Delete selected rows in enrichments table"
        onClick={() => {
          setIsConfirming(true);
        }}
      >
        <TrashIcon className={classNames("h-5", "mr-2")} />
        <div>{`Delete ${selectedRows.tableIndexStart! + 1}-${selectedRows.tableIndexEnd! + 1}`}</div>
      </CiroButton>
      {isConfirming && (
        <CiroConfirmationModal
          isOpen={isConfirming}
          text={`You are about to delete rows ${selectedRows.tableIndexStart! + 1}-${selectedRows.tableIndexEnd! + 1}. Are you sure?`}
          onClose={() => setIsConfirming(false)}
          onConfirm={async () => {
            const flowRowIds = [] as number[];
            enrichmentFlowRowIds.forEach((flowRowId, tableIndex) => {
              if (
                tableIndex >= selectedRows.tableIndexStart! &&
                tableIndex <= selectedRows.tableIndexEnd!
              ) {
                flowRowIds.push(flowRowId);
              }
            });
            setIsConfirming(false);
            setEnrichmentFlowLoading(true);
            deleteEnrichmentFlowRows({
              variables: {
                flowId: enrichmentFlowId,
                flowRowIds,
              },
              onCompleted: async (data) => {
                setSelectedRows({
                  tableIndexStart: null,
                  tableIndexEnd: null,
                });
                await Promise.all([
                  EnrichmentFlow_refetchEnrichmentFlow(),
                  enrichmentFlowTableContainer_refetchEnrichmentFlowRows(),
                ]);
                setEnrichmentFlowLoading(false);
                if (data.deleteEnrichmentFlowRows?.success) {
                  toast.success("Rows deleted");
                } else {
                  toast.error("Error deleting rows");
                }
              },
              onError: async (data) => {
                setEnrichmentFlowLoading(false);
                await Promise.all([
                  EnrichmentFlow_refetchEnrichmentFlow(),
                  enrichmentFlowTableContainer_refetchEnrichmentFlowRows(),
                ]);
                toast.error(`Error deleting rows: ${data?.message}`);
              },
            });
          }}
          analyticsField={"Confirmed deletion of rows in enrichments table"}
        />
      )}
    </>
  );
};

export default EnrichmentFlowDeleteSelectedRowsButton;
