import classNames from "classnames";
import CiroModal from "../../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import XIcon from "../../../assets/img/icons/XIcon";
import CiroUnlockPremiumDataContent, {
  CiroCreditUseCaseEnum,
  CiroUnlockPremiumDataContent_CreditsToSpendResponse,
} from "../../shared/CiroUnlockPremiumData/CiroUnlockPremiumDataContent";
import CiroUnlockPremiumDataSubmit from "../../shared/CiroUnlockPremiumData/CiroUnlockPremiumDataSubmit";
import { BusinessDetails_Company } from "../../../routes/businessDetails/BusinessDetails";
import { gql, useLazyQuery } from "@apollo/client";
import {
  BusinessDetailsKeyDetailsUnlockModal_CreditsToSpendQuery,
  BusinessDetailsKeyDetailsUnlockModal_CreditsToSpendQueryVariables,
  CiroUnlockPremiumDataContent_CreditsToSpendResponseFragmentDoc,
} from "../../../__generated__/graphql";
import { useEffect } from "react";
import { useFragment } from "../../../__generated__";

interface ICiroUnlockPremiumDataSubmitProps {
  companyPk: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const BusinessDetailsKeyDetailsUnlockModal_CreditsToSpend = gql`
  query BusinessDetailsKeyDetailsUnlockModal_CreditsToSpend(
    $companyPks: [ID!]
  ) {
    creditsToSpend(companyPks: $companyPks) {
      unlockPms {
        creditsToSpend
        accountsCount
      }
      currentBalance
      ...CiroUnlockPremiumDataContent_CreditsToSpendResponse
    }
  }
  ${CiroUnlockPremiumDataContent_CreditsToSpendResponse}
`;

const BusinessDetailsKeyDetailsUnlockModal = ({
  companyPk,
  isOpen,
  setIsOpen,
}: ICiroUnlockPremiumDataSubmitProps) => {
  const [
    fetchCreditsToSpend,
    { data: fetchedCreditData, loading: fetchCreditsLoading },
  ] = useLazyQuery<
    BusinessDetailsKeyDetailsUnlockModal_CreditsToSpendQuery,
    BusinessDetailsKeyDetailsUnlockModal_CreditsToSpendQueryVariables
  >(BusinessDetailsKeyDetailsUnlockModal_CreditsToSpend);

  useEffect(() => {
    if (isOpen) {
      fetchCreditsToSpend({
        variables: {
          companyPks: [companyPk],
        },
        fetchPolicy: "network-only",
      });
    }
  }, [companyPk, fetchCreditsToSpend, isOpen]);

  const currentBalance = fetchedCreditData?.creditsToSpend.currentBalance || 0;
  const unlockPmsCredits =
    fetchedCreditData?.creditsToSpend.unlockPms.creditsToSpend || 0;

  const creditsToSpendContent = useFragment(
    CiroUnlockPremiumDataContent_CreditsToSpendResponseFragmentDoc,
    fetchedCreditData?.creditsToSpend,
  );

  return (
    <CiroModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div
        className={classNames(
          "flex",
          "font-medium",
          "justify-between",
          "pb-4",
          "text-lg",
        )}
      >
        <span>Unlock Phone Verified Practice Management System</span>
        <span>
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Close N selected modal"
            onClick={() => setIsOpen(false)}
          >
            <XIcon />
          </CiroButton>
        </span>
      </div>
      <CiroUnlockPremiumDataContent
        creditActions={[CiroCreditUseCaseEnum.UNLOCK_PMS]}
        creditsToSpend={creditsToSpendContent}
        isLoading={fetchCreditsLoading}
      />
      <div
        className={classNames(
          "flex",
          "justify-end",
          "mt-4",
        )}
      >
        <CiroUnlockPremiumDataSubmit
          disabled={fetchCreditsLoading || currentBalance < unlockPmsCredits}
          companyPks={[companyPk]}
          onCompleted={() => setIsOpen(false)}
          refetchQueries={[
            {
              query: BusinessDetails_Company,
              variables: {
                companyPk: companyPk,
              },
            },
          ]}
        />
      </div>
    </CiroModal>
  );
};

export default BusinessDetailsKeyDetailsUnlockModal;
