import { createContext } from "react";
import { IntegrationModalPhases } from "../components/manageIntegration/manageIntegrationButton/ManageIntegrationsButton";

const NavigationContext = createContext({
  refreshNavData: async () => {
    return;
  },
  integrationModalPhase: IntegrationModalPhases.GET_STARTED,
  setIntegrationModalPhase: (phase: IntegrationModalPhases) => {
    return;
  },
} as {
  refreshNavData: () => Promise<any>;
  integrationModalPhase: IntegrationModalPhases;
  setIntegrationModalPhase: (phase: IntegrationModalPhases) => void;
});

export default NavigationContext;
