import CiroTextInput from "../shared/CiroTextInput";
import classNames from "classnames";
import CiroErrorMsg from "../shared/forms/CiroErrorMsg";
import { RangeFilterInput } from "../../__generated__/graphql";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { DEFAULT_FILTER_TOOLTIP_TEXT } from "../../utils/consts";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface IRangeFilter {
  blurred?: boolean;
  defaultOpen?: boolean;
  max?: number;
  min: number;
  rangeFilter: RangeFilterInput | null;
  resetOffset?: () => void;
  setRangeFilter: (val: RangeFilterInput) => void;
  step: number;
  title?: string;
  smbFilter?: boolean;
}

function RangeFilter({
  blurred = false,
  defaultOpen = false,
  max,
  min,
  rangeFilter,
  resetOffset,
  setRangeFilter,
  step,
  title,
  smbFilter = false,
}: IRangeFilter) {
  const getErrorMessage = (val: number | null) => {
    if (val) {
      if (max) {
        if (val > max || val < min) {
          return `Enter a number between ${min} and ${max}`;
        }
      } else {
        if (val < min) {
          return `Enter a number greater than ${min}`;
        }
      }
    }
    return "";
  };

  const lowerBoundFilter = rangeFilter?.lowerBound;
  const upperBoundFilter = rangeFilter?.upperBound;
  const setLowerBoundFilter = (newVal: number | null) => {
    setRangeFilter({
      lowerBound: newVal,
      upperBound: upperBoundFilter,
    });
  };
  const setUpperBoundFilter = (newVal: number | null) => {
    setRangeFilter({
      lowerBound: lowerBoundFilter,
      upperBound: newVal,
    });
  };

  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={lowerBoundFilter || upperBoundFilter ? 1 : 0}
      sectionTitle={title}
      filterOptions={[
        {
          filter: (
            <div>
              <CiroTooltipContainer
                className={classNames("w-full")}
                tooltip={DEFAULT_FILTER_TOOLTIP_TEXT}
                disabled={!blurred}
              >
                <div
                  className={classNames([
                    "2xl:flex",
                    "2xl:items-center",
                  ])}
                >
                  <div
                    className={classNames([
                      "pr-2",
                      "text-sm",
                      "slate-500",
                      { "blur-sm": blurred },
                    ])}
                  >
                    At least
                  </div>
                  <CiroTextInput
                    className={classNames([
                      "w-12",
                      "focus:outline-none",
                    ])}
                    value={lowerBoundFilter ? lowerBoundFilter.toString() : ""}
                    showErrorAsBorder={true}
                    error={
                      lowerBoundFilter ? getErrorMessage(lowerBoundFilter) : ""
                    }
                    type="number"
                    step={step}
                    min={min}
                    max={max}
                    onChange={(v) => {
                      const newVal = v.target.value;
                      setLowerBoundFilter(newVal ? Number(newVal) : null);
                      if (resetOffset) resetOffset();
                    }}
                    disabled={blurred}
                  />
                  <div
                    className={classNames([
                      "px-2",
                      "text-sm",
                      "slate-500",
                      { "blur-sm": blurred },
                    ])}
                  >
                    At most
                  </div>
                  <CiroTextInput
                    className={classNames([
                      "w-12",
                      "focus:outline-none",
                    ])}
                    showErrorAsBorder={true}
                    error={
                      upperBoundFilter
                        ? getErrorMessage(upperBoundFilter)
                        : null
                    }
                    value={upperBoundFilter ? upperBoundFilter.toString() : ""}
                    type="number"
                    step={step}
                    min={min}
                    max={max}
                    onChange={(v) => {
                      const newVal = v.target.value;
                      setUpperBoundFilter(newVal ? Number(newVal) : null);
                      if (resetOffset) resetOffset();
                    }}
                    disabled={blurred}
                  />
                </div>
                <CiroErrorMsg
                  error={
                    getErrorMessage(lowerBoundFilter as number) ||
                    getErrorMessage(upperBoundFilter as number)
                  }
                ></CiroErrorMsg>
              </CiroTooltipContainer>
            </div>
          ),
        },
      ]}
      smbFilter={smbFilter}
    />
  );
}

export default RangeFilter;
