import { gql, useMutation } from "@apollo/client";
import CiroTableCell from "../../shared/CiroTable/CiroTableCell";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import classNames from "classnames";
import { ContactListContactRow_ContactFragment } from "../../../__generated__/graphql";
import ContactListConfidenceLevelDropdown from "./ContactListConfidenceLevelDropdown";
import ContactListPersonaDropdown from "./ContactListPersonaDropdown";
import { addLinkedinIdToUrl } from "../../../utils/linkedinUtils";
import { NavLink } from "react-router-dom";
import ContactListRationaleTextBox from "./ContactListRationaleTextBox";
import LinkedInSquareIcon from "../../../assets/img/icons/LinkedinSquareIcon";
import CiroCheckbox from "../../shared/CiroCheckbox";

export const ContactListContactRow_Contact = gql`
  fragment ContactListContactRow_Contact on ContactListSavedContact {
    id
    org_contact {
      id
      first_name
      last_name
      title
      linkedin_id
      company_name
      orgPhoneNumbers {
        phone_number
      }
      orgEmails {
        email
      }
    }
    assignedPersona {
      id
      name
    }
    persona_fit
    rationale
  }
`;

const ContactListContactRow_UpdateContactListSavedContact = gql`
  mutation ContactListContactRow_UpdateContactListSavedContact(
    $input: UpdateContactListSavedContactInput!
  ) {
    updateContactListSavedContact(input: $input) {
      success
      message
      contactListSavedContact {
        id
      }
    }
  }
`;

interface IContactListContactRowProps {
  contact: ContactListContactRow_ContactFragment;
  isSelected: boolean;
  onSelect: () => void;
}

const ContactListContactRow = ({
  contact,
  isSelected,
  onSelect,
}: IContactListContactRowProps) => {
  const [updateContactListSavedContact] = useMutation(
    ContactListContactRow_UpdateContactListSavedContact,
  );

  return (
    <CiroTableRow clickable={false}>
      <CiroTableCell padding={2} minWidth="50px">
        <div className={classNames("pl-2")}>
          <CiroCheckbox checked={isSelected} onClick={onSelect} />
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", "whitespace-normal")}
        minWidth="200px"
      >
        <div>
          <div className={classNames("flex", "items-center")}>
            <div
              className={classNames(
                "font-semibold",
                "text-gray-900",
                "break-words",
              )}
            >
              {contact.org_contact?.first_name} {contact.org_contact?.last_name}
            </div>
            {contact.org_contact?.linkedin_id && (
              <NavLink
                to={addLinkedinIdToUrl(contact.org_contact.linkedin_id)}
                target="_blank"
              >
                <LinkedInSquareIcon customScale={0.5} />
              </NavLink>
            )}
          </div>
          <div className={classNames("break-words")}>
            {contact.org_contact?.title}
          </div>
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top")}
        minWidth="150px"
      >
        <div>{contact.org_contact?.company_name}</div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top")}
        minWidth="150px"
      >
        <ContactListPersonaDropdown
          personaId={contact.assignedPersona?.id ?? null}
          onChange={(personaId) => {
            updateContactListSavedContact({
              variables: {
                input: { id: contact.id, assigned_persona_id: personaId },
              },
            });
          }}
        />
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top")}
        minWidth="150px"
      >
        <ContactListConfidenceLevelDropdown
          personaFit={contact.persona_fit ?? null}
          onChange={(personaFit) => {
            updateContactListSavedContact({
              variables: {
                input: { id: contact.id, persona_fit: personaFit },
              },
            });
          }}
        />
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top")}
        colSpan={2}
        minWidth="200px"
      >
        <ContactListRationaleTextBox
          rationale={contact.rationale ?? ""}
          contactId={contact.id}
          onSave={(rationale) => {
            updateContactListSavedContact({
              variables: { input: { id: contact.id, rationale } },
            });
          }}
        />
      </CiroTableCell>
      <CiroTableCell minWidth="150px">
        <span
          title={contact.org_contact?.orgPhoneNumbers[0]?.phone_number ?? "-"}
          className={classNames("min-w-32")}
        >
          {contact.org_contact?.orgPhoneNumbers[0]?.phone_number ?? "-"}
        </span>
      </CiroTableCell>
      <CiroTableCell minWidth="150px">
        <span title={contact.org_contact?.orgEmails[0]?.email ?? "-"}>
          {(contact.org_contact?.orgEmails[0]?.email ?? "-").length > 30
            ? `${contact.org_contact?.orgEmails[0]?.email.slice(0, 30)}...`
            : contact.org_contact?.orgEmails[0]?.email ?? "-"}
        </span>
      </CiroTableCell>
    </CiroTableRow>
  );
};

export default ContactListContactRow;
