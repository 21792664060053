import { useContext, useState } from "react";
import AppContext from "../../contexts/AppContext";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";

const useHandleDownloadContactList = () => {
  const { accessToken } = useContext(AppContext);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadContactList = async ({
    listId,
    listName,
    customContactIds,
  }: {
    listId: number;
    listName: string;
    customContactIds?: number[];
  }) => {
    try {
      setIsDownloading(true);
      const exportResponse = await fetch(
        `${window.location.origin}/export-contact-list-saved-contacts`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            contactListId: listId,
            customContactIds,
          }),
        },
      );
      const blob = await exportResponse.blob();
      fileDownload(blob, `Contact List - ${listName} - Contacts.csv`);
      toast.success("CSV downloaded successfully");
    } catch (error) {
      toast.error("Error during CSV download");
      console.error("Error during CSV download:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    handleDownloadContactList,
    isDownloading,
  };
};

export default useHandleDownloadContactList;
