export const getLinkedinIdFromUrl = (url: string | null | undefined) => {
  // Remove query params and then get everything after /in/
  return url?.split("?")[0]?.match(/\/in\/([^/]+)\/?$/)?.[1];
};

export const addLinkedinIdToUrl = (linkedinId: string) => {
  return `https://www.linkedin.com/in/${linkedinId}`;
};

export default getLinkedinIdFromUrl;
