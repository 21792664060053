import { TrashIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../../../../shared/CiroButton";
import { IEnrichmentStepTechniqueGetContactCardPicklists } from "../EnrichmentStepTechniqueGetContactCard";
import classNames from "classnames";
import CiroCreateableTextInput from "../../../../shared/CiroCreateableTextInput";
import EnrichmentStepTechniqueFindContactDropdown from "./EnrichmentStepTechniqueFindContactDropdown";

interface IEnrichmentTechniqueFindContactLogicSelector {
  index: number;
  picklist: IEnrichmentStepTechniqueGetContactCardPicklists;
  setValue: (name: any, value: any, options?: {}) => void;
  titlePicklists: IEnrichmentStepTechniqueGetContactCardPicklists[];
}

function EnrichmentTechniqueFindContactLogicSelector({
  index,
  picklist,
  setValue,
  titlePicklists,
}: IEnrichmentTechniqueFindContactLogicSelector) {

  return (
    <>
      <div
        className={classNames("text-neutral-500", "text-xs")}
      >
        Title must include
      </div>
      <div
        className={classNames(
          "flex",
          "flex-row",
          "justify-between",
          "items-center",
        )}
      >
        <div
          className={classNames(
            "flex",
            "flex-row",
            "gap-1",
          )}
        >
          <EnrichmentStepTechniqueFindContactDropdown
            setLogicType={(logicType) => {
              setValue(`titlePicklists.${index}.logic`, logicType);
            }}
            logicType={picklist?.logic}
          />
          <CiroCreateableTextInput
            value={picklist.titles}
            setValue={(newValue) => {
              setValue(`titlePicklists.${index}.titles`, newValue);
            }}
            placeholder={"Director, Marketing, Operations, etc."}
          />
        </div>

        <CiroButton
          analyticsField="Removed title logic option"
          onClick={() => {
            setValue(
              "titlePicklists",
              titlePicklists.filter((_, j) => j !== index),
            );
          }}
          style={CiroButtonStyleEnum.UNSTYLED}
        >
          <TrashIcon
            className={classNames(
              "w-5",
              "text-gray-400",
              "hover:text-gray-500",
              "ml-2",
            )}
          />
        </CiroButton>
      </div>
    </>
  );
}

export default EnrichmentTechniqueFindContactLogicSelector;