import React, { useState } from "react";
import classNames from "classnames";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import CiroModal from "../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroSpinner from "../shared/CiroSpinner";
import {
  UpsertContactListModal_UpsertContactListMutation,
  UpsertContactListModal_UpsertContactListMutationVariables
} from "../../__generated__/graphql";

const UpsertContactListModal_UpsertContactList = gql`
  mutation UpsertContactListModal_UpsertContactList($input: UpsertContactListInput!) {
    upsertContactList(input: $input) {
      success
      message
      contactList {
        id
        name
      }
    }
  }
`;

interface IUpsertContactListModal {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  initialData?: {
    id: number;
    name: string;
  };
}

const UpsertContactListModal = ({
  isOpen,
  onClose,
  onSuccess,
  initialData
}: IUpsertContactListModal) => {
  const [name, setName] = useState(initialData?.name || "");
  const [isRefetching, setIsRefetching] = useState(false);

  const [upsertContactList, { loading }] = useMutation<
    UpsertContactListModal_UpsertContactListMutation,
    UpsertContactListModal_UpsertContactListMutationVariables
  >(UpsertContactListModal_UpsertContactList, {
    async onCompleted(data) {
      if (data.upsertContactList.success) {
        setIsRefetching(true);
        try {
          await onSuccess();
          toast.success(initialData ? "List updated successfully." : "List created successfully.");
          setName("");
        } finally {
          setIsRefetching(false);
          onClose();
        }
      } else {
        toast.error(data.upsertContactList.message || "Error saving contact list.");
      }
    }
  });

  const handleSubmit = async () => {
    if (!name.trim()) {
      toast.error("Please enter a list name");
      return;
    }

    try {
      await upsertContactList({
        variables: {
          input: {
            id: initialData?.id,
            name: name.trim()
          }
        }
      });
    } catch (error) {
      toast.error("Error saving contact list.");
      console.error("Error saving contact list: ", error);
    }
  };

  const isLoading = loading || isRefetching;

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div className={classNames("flex", "flex-col", "gap-4")}>
        <div className={classNames("text-lg", "font-medium")}>
          {initialData ? "Edit list" : "Create new list"}
        </div>
        <div className={classNames("flex", "flex-col", "gap-2")}>
          <label htmlFor="listName" className="text-sm font-medium">
            List name
          </label>
          <input
            id="listName"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classNames(
              "rounded-md",
              "border",
              "border-gray-300",
              "px-3",
              "py-2",
              "focus:border-blue-500",
              "focus:outline-none"
            )}
            placeholder="Enter list name"
            disabled={isLoading}
          />
        </div>
        <div className={classNames("flex", "justify-end", "gap-4")}>
          <CiroButton
            analyticsField="cancel-upsert-list"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CiroButton>
          <CiroButton
            analyticsField="confirm-upsert-list"
            style={CiroButtonStyleEnum.LOUD}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className={classNames("flex", "items-center", "gap-2")}>
                <CiroSpinner loading={true} />
                <div>Saving...</div>
              </div>
            ) : initialData ? "Save changes" : "Create list"}
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default UpsertContactListModal; 