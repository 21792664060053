import { gql, useLazyQuery, useMutation } from "@apollo/client";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Collections_collections } from "../../routes/collections/Collections";
import {
  EditCollectionModal_CollectionQuery,
  EditCollectionModal_CollectionQueryVariables,
  EditCollectionModal_DeleteCollectionMutation,
  EditCollectionModal_DeleteCollectionMutationVariables,
  EditCollectionModal_EditCollectionMutation,
  EditCollectionModal_EditCollectionMutationVariables,
  EditCollectionModal_PermissionsFragment,
  Maybe,
} from "../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import CiroTextInput from "../shared/CiroTextInput";
import Loading from "../shared/Loading";

const EditCollectionModal_Collection = gql`
  query EditCollectionModal_Collection($collectionId: Int!) {
    collection(id: $collectionId) {
      name
    }
  }
`;

const EditCollectionModal_EditCollection = gql`
  mutation EditCollectionModal_EditCollection(
    $collectionId: Int
    $name: String
  ) {
    upsertCollections(
      changes: [{ method: ADD, collectionId: $collectionId, name: $name }]
    ) {
      success
    }
  }
`;

export const EditCollectionModal_Permissions = gql`
  fragment EditCollectionModal_Permissions on Permissions {
    canDestroyCollections
  }
`;

const EditCollectionModal_DeleteCollection = gql`
  mutation EditCollectionModal_DeleteCollection($collectionId: Int!) {
    destroyCollection(collectionId: $collectionId) {
      success
    }
  }
`;

interface IEditCollectionModalProps {
  closeModal: () => void;
  collectionId: string | null;
  permissions?: Maybe<EditCollectionModal_PermissionsFragment>;
}

const EditCollectionModal = ({
  closeModal,
  collectionId,
  permissions,
}: IEditCollectionModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingText, setDeletingText] = useState("");
  const [collectionName, setCollectionName] = useState("");

  const [getCollection, { data: collectionData, loading: loadingCollection }] =
    useLazyQuery<
      EditCollectionModal_CollectionQuery,
      EditCollectionModal_CollectionQueryVariables
    >(EditCollectionModal_Collection, {
      variables: {
        collectionId: Number(collectionId),
      },
    });

  const [
    editCollection,
    { data: editedCollectionData, reset: resetEditedCollectionData },
  ] = useMutation<
    EditCollectionModal_EditCollectionMutation,
    EditCollectionModal_EditCollectionMutationVariables
  >(EditCollectionModal_EditCollection);

  const [
    deleteCollection,
    { data: deletedCollectionData, reset: resetDeletedCollectionData },
  ] = useMutation<
    EditCollectionModal_DeleteCollectionMutation,
    EditCollectionModal_DeleteCollectionMutationVariables
  >(EditCollectionModal_DeleteCollection);

  useEffect(() => {
    // Reset modal on close
    if (!collectionId) {
      setIsDeleting(false);
      setDeletingText("");
      resetEditedCollectionData();
      resetDeletedCollectionData();
    }

    if (collectionId) {
      getCollection();
    }
  }, [
    collectionId,
    getCollection,
    resetDeletedCollectionData,
    resetEditedCollectionData,
  ]);

  useEffect(() => {
    if (collectionData?.collection?.name) {
      setCollectionName(collectionData?.collection?.name);
    }
  }, [collectionData]);

  const isEdited = useMemo(() => {
    return Boolean(
      editedCollectionData?.upsertCollections &&
        editedCollectionData?.upsertCollections[0]?.success,
    );
  }, [editedCollectionData?.upsertCollections]);

  const isDeleted = useMemo(() => {
    return Boolean(deletedCollectionData?.destroyCollection?.success);
  }, [deletedCollectionData?.destroyCollection?.success]);

  useEffect(() => {
    if (isEdited || isDeleted) {
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  }, [closeModal, isEdited, isDeleted]);

  return (
    <CiroModal size="LARGE" onClose={closeModal} isOpen={Boolean(collectionId)}>
      <div className={classNames(["font-medium"])}>Edit Collection</div>
      {loadingCollection && <Loading size="SMALL" />}
      {!loadingCollection && (
        <>
          <div className={classNames("py-4")}>
            <CiroTextInput
              value={collectionName}
              placeholder="Collection Name"
              onChange={(v) => setCollectionName(v.target.value)}
              disabled={isDeleting || isDeleted || isEdited}
            />
          </div>
          <div
            className={classNames([
              "flex",
              "flex-row-reverse",
              "justify-between",
              "pt-4",
            ])}
          >
            {!isDeleting ? (
              <CiroButton
                style={CiroButtonStyleEnum.INVERTED}
                analyticsField="Submit changes to editing collection"
                onClick={() => {
                  editCollection({
                    variables: {
                      collectionId: Number(collectionId),
                      name: collectionName,
                    },
                    refetchQueries: [{ query: Collections_collections }],
                  });
                }}
                disabled={isDeleted || isEdited}
              >
                Submit
              </CiroButton>
            ) : (
              <div />
            )}
            {permissions?.canDestroyCollections && !isDeleting && (
              <CiroButton
                style={CiroButtonStyleEnum.UNSTYLED}
                analyticsField="Begin Deleting Collection"
                onClick={() => setIsDeleting(true)}
                disabled={isDeleted || isEdited}
              >
                <span className={classNames("text-rose-500")}>
                  Delete
                </span>
              </CiroButton>
            )}
          </div>
          {isEdited && (
            <div
              className={classNames([
                "text-xs",
                "text-green-600",
                "py-2",
              ])}
            >
              Edits Saved
            </div>
          )}
          {isDeleting && (
            <div>
              <div className={classNames(["py-4"])}>
                <CiroTextInput
                  value={deletingText}
                  placeholder="Enter DELETE to confirm"
                  onChange={(v) => setDeletingText(v.target.value)}
                />
              </div>
              <div className={classNames("flex")}>
                <span className={classNames("pr-2")}>
                  <CiroButton
                    style={CiroButtonStyleEnum.INVERTED}
                    analyticsField="Confirm Deleting Collection"
                    disabled={
                      deletingText.toUpperCase() !== "DELETE" ||
                      isDeleted ||
                      isEdited
                    }
                    onClick={() => {
                      deleteCollection({
                        variables: {
                          collectionId: Number(collectionId),
                        },
                        refetchQueries: [{ query: Collections_collections }],
                      });
                    }}
                  >
                    Confirm Delete
                  </CiroButton>
                </span>
                <CiroButton
                  style={CiroButtonStyleEnum.INVERTED}
                  analyticsField="Canceled Deleting Collection"
                  onClick={() => {
                    setDeletingText("");
                    setIsDeleting(false);
                  }}
                  disabled={isDeleted || isEdited}
                >
                  Cancel
                </CiroButton>
              </div>
              {isDeleted && (
                <div
                  className={classNames([
                    "text-xs",
                    "text-green-600",
                    "py-2",
                  ])}
                >
                  Collection Deleted
                </div>
              )}
            </div>
          )}
        </>
      )}
    </CiroModal>
  );
};

export default EditCollectionModal;
