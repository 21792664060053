import classNames from "classnames";

export enum loadingBarHeight {
  SMALL,
  MEDIUM,
  LARGE,
}

interface LoadingBarProps {
  customFillPercentage?: number;
  height?: number;
  showPercentage?: boolean;
  longLoad?: boolean; // New prop
}

const LoadingBar = ({
  customFillPercentage,
  height = loadingBarHeight.SMALL,
  showPercentage = false,
  longLoad = false, // Default value for longLoad
}: LoadingBarProps) => {
  return (
    <div
      className={classNames(
        "w-full",
        "bg-zinc-100",
        "rounded-full",
        {
          "h-2": height === loadingBarHeight.SMALL,
          "h-3": height === loadingBarHeight.MEDIUM,
          "h-4": height === loadingBarHeight.LARGE,
        },
      )}
    >
      <div
        className={classNames(
          "bg-orange-400",
          {
            "h-2": height === loadingBarHeight.SMALL,
            "h-3": height === loadingBarHeight.MEDIUM,
            "h-4": height === loadingBarHeight.LARGE,
          },
          "rounded-full",
          "text-white",
          "text-xs",
          "flex",
          "justify-center",
          customFillPercentage === undefined && !longLoad && "animate-load-10s",
          customFillPercentage === undefined && longLoad && "animate-load-20s",
        )}
        //Tailwind does not support dynamic style strings, so we need to use basic react styling
        style={
          customFillPercentage !== undefined
            ? { width: Math.round(customFillPercentage * 100) + "%" }
            : { width: "0%" }
        }
      >
        {showPercentage &&
          customFillPercentage &&
          Math.round(customFillPercentage * 100) + "%"}
      </div>
    </div>
  );
};

export default LoadingBar;
