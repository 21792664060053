import { gql } from "@apollo/client";
import classNames from "classnames";
import { useFragment } from "../../__generated__";
import {
  IntegrationEnum,
  CreateIntegrationButton_UserAccountFragmentDoc,
  ProfileIntegrationDetails_UserAccountFragment,
} from "../../__generated__/graphql";
import {
  ManageIntegrationsButton,
  CreateIntegrationButton_UserAccount,
} from "./manageIntegrationButton/ManageIntegrationsButton";
import IntegrationSyncStatus from "./ProfileIntegrationStatus";

export const ProfileIntegrationDetails_UserAccount = gql`
  fragment ProfileIntegrationDetails_UserAccount on UserAccount {
    id
    hasCrmIntegrationEnabled
    hasCRMConnection
    org {
      organizationMergeIntegration {
        last_matched_at
        integration
      }
      apolloIntegration {
        api_key
      }
    }
    ...CreateIntegrationButton_UserAccount
  }
  ${CreateIntegrationButton_UserAccount}
`;

interface IProfileIntegrationDetails {
  userAccount: ProfileIntegrationDetails_UserAccountFragment;
  refetchUserAccount: () => void;
}

export function ProfileIntegrationDetails({
  userAccount,
}: IProfileIntegrationDetails) {
  const mergeIntegration = userAccount?.org?.organizationMergeIntegration;
  const apolloIntegration = userAccount?.org?.apolloIntegration;

  const integrations = [
    ...(mergeIntegration ? [mergeIntegration.integration] : []),
    ...(apolloIntegration ? [IntegrationEnum.Apollo] : []),
  ];

  const userAccountForCreateIntegrationButton = useFragment(
    CreateIntegrationButton_UserAccountFragmentDoc,
    userAccount,
  );

  if (!integrations.length && userAccountForCreateIntegrationButton) {
    return (
      <div className={classNames("mt-2")}>
        <ManageIntegrationsButton
          userAccount={userAccountForCreateIntegrationButton}
        />
      </div>
    );
  }

  return (
    <div className={classNames("pt-2")}>
      <IntegrationSyncStatus
        integrations={integrations as IntegrationEnum[]}
      />
    </div>
  );
}
