import { CustomHeaderProps } from "@ag-grid-community/react";
import classNames from "classnames";
import { useRef } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { EnrichmentTechniqueEnum } from "../../../__generated__/graphql";
import enrichmentTechniqueEnumToIcon from "../enrichmentTechniqueEnumToIcon";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { EnrichmentStepError } from "../../../reactHooks/enrichmentFlow/useEnrichmentInputErrors";

// For some reason, TSLint doesn't seem to work with ag-grid
// So using this "extends" but just redeclaring the props
interface IEnrichmentFlowTableContainerProps extends CustomHeaderProps {
  displayName: string;
  enrichmentStepId: number;
  handleOpenEnrichmentStep: () => void;
  showFilter: (source: HTMLElement) => void;
  setRunPressed: (v: boolean) => void;
  enrichmentTechnique: EnrichmentTechniqueEnum;
  inputErrors: EnrichmentStepError[];
}

const EnrichmentFlowTableHeader = ({
  displayName,
  showFilter,
  enrichmentTechnique,
  inputErrors,
}: IEnrichmentFlowTableContainerProps) => {
  const Icon = enrichmentTechniqueEnumToIcon[enrichmentTechnique];

  const refButton = useRef<null | HTMLDivElement>(null);

  return (
    <div
      className={classNames(
        "flex",
        "justify-between",
        "w-full",
      )}
    >
      <div className={classNames("flex", "w-5/6")}>
        {Icon && (
          <Icon
            className={classNames(
              "w-4",
              "h-4",
              "mr-2",
              "mt-0.5",
            )}
          />
        )}
        <div className={classNames("truncate", "w-full")}>
          {displayName}
        </div>
      </div>
      <div ref={refButton}>
        <CiroButton
          analyticsField="View header dropdown"
          style={CiroButtonStyleEnum.UNSTYLED}
          passedRef={refButton}
          onClick={() => {
            showFilter(refButton.current!);
          }}
        >
          <EllipsisVerticalIcon
            className={classNames("h-5", "text-neutral-500")}
          />
        </CiroButton>
      </div>
      {inputErrors.length > 0 && (
        <div
          className={classNames(
            "ml-2",
            "text-rose-500",
            "text-sm",
          )}
        >
          <CiroTooltipContainer
            tooltip={<div>Fix configuration for this step</div>}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color="text-red-500"
            />
          </CiroTooltipContainer>
        </div>
      )}
    </div>
  );
};

export default EnrichmentFlowTableHeader;
