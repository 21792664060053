import React from "react";
import classNames from "classnames";

interface ICiroTableHeaderProps {
  align?: CiroTableHeaderAlignEnum;
  children: any;
  className?: string;
  colSpan?: number;
  isFirst?: boolean;
  isLast?: boolean;
  width?: string;
}

export enum CiroTableHeaderAlignEnum {
  LEFT,
  RIGHT,
  CENTER,
}

const CiroTableHeader = ({
  align = CiroTableHeaderAlignEnum.LEFT,
  children,
  colSpan = 1,
  className = "",
  isFirst = false,
  isLast = false,
  width = "",
}: ICiroTableHeaderProps) => {
  return (
    <td
      colSpan={colSpan}
      className={classNames(
        "font-medium",
        "px-4",
        "py-3",
        "table-cell",
        "text-gray-500",
        "border-b-1",
        "text-xs",
        {
          "rounded-tl-lg": isFirst,
          "rounded-tr-lg": isLast,
          "text-center": align === CiroTableHeaderAlignEnum.CENTER,
          "text-left": align === CiroTableHeaderAlignEnum.LEFT,
          "text-right": align === CiroTableHeaderAlignEnum.RIGHT,
        },
        className,
        width,
      )}
    >
      {children}
    </td>
  );
};

export default CiroTableHeader;
