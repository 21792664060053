import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import {
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { EnrichmentFilterTechnique } from "../EnrichmentStepCardFilters/EnrichmentStepFilterDropdown";
import { useFragment } from "../../../../__generated__";
import { useEffect, useMemo } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import { IEnrichmentStepTechniqueCardProps } from ".";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

const EnrichmentStepTechniqueCoalesceCardSchema = yup
  .object({
    enrichment_technique: yup
      .string()
      .required("Enrichment Technique is required"),
    parentEnrichmentStepInputs: yup.array().of(
      yup.object({
        key: yup.string().required("Key is required"),
        input: yup.string().nullable(),
        required: yup.boolean().required("Required is required"),
        sourceEnrichmentStepId: yup.number().nullable(),
      }),
    ),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required();

interface CoalesceCardFormData {
  enrichment_technique: EnrichmentTechniqueEnum;
  parentEnrichmentStepInputs: {
    key: string;
    input: string | null;
    required: boolean;
    sourceEnrichmentStepId: number | null;
  }[];
  filterEnrichmentSteps: {
    name: string;
    enrichment_technique: EnrichmentFilterTechnique;
    parentEnrichmentStepInputs: {
      input: string | null;
      sourceEnrichmentStepId: number | null;
      key: string;
    }[];
  }[];
}

const EnrichmentStepTechniqueCoalesceCard = ({
  confirmUpdateEnrichmentStep,
  enrichmentStep,
  onClose,
  error,
  loading,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const defaultValues = useMemo(() => {
    const filterEnrichmentSteps = parseEnrichmentStepsForList(
      enrichmentStepWithFilters,
    );

    // Need to copy for sorting
    const parentEnrichmentStepInputsUnsorted = [
      ...(enrichmentStep?.parentEnrichmentStepInputs || []),
    ];

    return (
      {
        enrichment_technique: EnrichmentTechniqueEnum.Coalesce,
        parentEnrichmentStepInputs: parentEnrichmentStepInputsUnsorted
          .sort((a, b) => {
            return a.key.localeCompare(b.key);
          })
          .map((input, i) => {
            return {
              key: `coalesce_variable_${i}`,
              input: input.input || "null",
              required: input.required,
              sourceEnrichmentStepId: input.sourceEnrichmentStep?.id || null,
            };
          }),
        filterEnrichmentSteps,
      } || []
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const methods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueCoalesceCardSchema),
    defaultValues,
  });
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    return () => {
      reset();
    };
  }, [defaultValues, reset]);

  const [parentEnrichmentStepInputs, filterEnrichmentSteps] = useWatch({
    control,
    name: ["parentEnrichmentStepInputs", "filterEnrichmentSteps"],
  });

  const formatAndSubmitRequest = (data: CoalesceCardFormData) => {
    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: data.enrichment_technique,
          selected_input: null,
          parentEnrichmentStepInputs: data.parentEnrichmentStepInputs,
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div className={classNames("pt-4")}>
        <div className={classNames("flex", "items-center")}>
          <span className={classNames("pr-2")}>
            Return the first non-empty value
          </span>
          <CiroButton
            analyticsField="Add coalesce option"
            onClick={() => {
              setValue("parentEnrichmentStepInputs", [
                ...parentEnrichmentStepInputs,
                {
                  key: `coalesce_variable_${parentEnrichmentStepInputs.length}`,
                  input: "null",
                  required: false,
                  sourceEnrichmentStepId: null,
                },
              ]);
            }}
            style={CiroButtonStyleEnum.UNSTYLED}
          >
            <PlusCircleIcon
              className={classNames(
                "w-5",
                "text-gray-400",
                "hover:text-gray-500",
              )}
            />
          </CiroButton>
        </div>
        <div>
          {parentEnrichmentStepInputs.map((parentEnrichmentStepInput, i) => {
            return (
              <div
                className={classNames("flex", "items-center")}
              >
                <div
                  className={classNames(
                    "pr-4",
                    "flex",
                    "items-center",
                  )}
                >
                  <CiroButton
                    analyticsField="Removed coalesce option"
                    onClick={() => {
                      setValue(
                        "parentEnrichmentStepInputs",
                        parentEnrichmentStepInputs.filter((_, j) => j !== i),
                      );
                    }}
                    style={CiroButtonStyleEnum.UNSTYLED}
                  >
                    <MinusCircleIcon
                      className={classNames(
                        "w-5",
                        "text-gray-400",
                        "hover:text-gray-500",
                      )}
                    />
                  </CiroButton>
                </div>
                <EnrichmentStepSelector
                  inputError={errors.parentEnrichmentStepInputs?.[i]?.message}
                  stepVariable={{
                    stepId:
                      parentEnrichmentStepInput.sourceEnrichmentStepId || null,
                    stepInput: JSON.parse(parentEnrichmentStepInput.input),
                  }}
                  setStepVariable={(newVariable) => {
                    setValue(
                      `parentEnrichmentStepInputs.${i}.sourceEnrichmentStepId`,
                      newVariable.stepId || null,
                    );
                    setValue(
                      `parentEnrichmentStepInputs.${i}.input`,
                      JSON.stringify(newVariable?.stepInput),
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames("mt-12")}>
        <FormProvider {...methods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueCoalesceCard;
