import { useParams, useSearchParams } from "react-router-dom";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import classNames from "classnames";
import { gql, useQuery } from "@apollo/client";
import {
  AutomationList_ContactListQuery,
  AutomationList_ContactListQueryVariables,
  AutomationListNavigationLayer_ContactListFragmentDoc,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import { useFragment } from "../../__generated__";
import PersonasContext from "../../contexts/PersonasContext";
import IntegrationTypeContext from "../../contexts/IntegrationTypeContext";
import useContactListFiltersAndSort from "../../reactHooks/filters/contactList/useContactListFiltersAndSort";
import AutomationListNavigationLayer, {
  AutomationListNavigationLayer_ContactList,
} from "../../components/automationList/AutomationListNavigationLayer";
import ListContext from "../../contexts/ListContext";
import { useState } from "react";

const AutomationList_ContactList = gql`
  query AutomationList_ContactList($listId: Int!) {
    contactList(id: $listId) {
      ...AutomationListNavigationLayer_ContactList
      id
      name
    }
    personas {
      id
      name
    }
    userAccount {
      org {
        organizationMergeIntegration {
          integration
        }
      }
    }
  }
  ${AutomationListNavigationLayer_ContactList}
`;

export enum AutomationListStateEnum {
  Home = "home",
  ReviewDuplicates = "reviewDuplicates",
  FinalizePushToCrm = "finalizePushToCrm",
}

const AutomationList = () => {
  const { listId } = useParams();

  const { data, error, loading } = useQuery<
    AutomationList_ContactListQuery,
    AutomationList_ContactListQueryVariables
  >(AutomationList_ContactList, {
    variables: { listId: parseInt(listId || "0", 10) },
  });

  const navigationLayerContactList = useFragment(
    AutomationListNavigationLayer_ContactListFragmentDoc,
    data?.contactList,
  );

  const [
    selectedContactIdsToOrgContactIds,
    setSelectedContactIdsToOrgContactIds,
  ] = useState<Map<number, number>>(new Map());

  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useContactListFiltersAndSort({
    searchParams,
    setSearchParams,
  });

  if (error || (!data && !loading)) {
    return (
      <CiroContainer>
        <CiroBreadCrumbs values={["Automation"]} href="/autopilot" />
        <div>No contact list found</div>
      </CiroContainer>
    );
  }

  const listName = data?.contactList?.name;

  if (loading) {
    return (
      <div className={classNames("w-full")}>
        <div
          className={classNames(
            "flex",
            "flex-col",
            "items-start",
            "gap-4",
            "pt-8",
            "p-4",
            "w-full",
            "sm:px-8",
            "lg:px-16",
          )}
        >
          <CiroBreadCrumbs values={["Automation", "..."]} href="/autopilot" />
          <div className={classNames("w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <PersonasContext.Provider value={data?.personas || []}>
      <ListContext.Provider
        value={{
          selectedContactIdsToOrgContactIds,
          setSelectedContactIdsToOrgContactIds,
        }}
      >
        <IntegrationTypeContext.Provider
          value={{
            integrationType:
              data?.userAccount?.org?.organizationMergeIntegration
                ?.integration || null,
          }}
        >
          <CiroContainer>
            <CiroBreadCrumbs
              values={["Autopilot", listName || ""]}
              href="/autopilot"
            />
            <AutomationListNavigationLayer
              contactList={navigationLayerContactList ?? null}
              filters={filters}
            />
          </CiroContainer>
        </IntegrationTypeContext.Provider>
      </ListContext.Provider>
    </PersonasContext.Provider>
  );
};

export default AutomationList;
