const ChromeOneColorIcon = ({
  color = "white",
  transform = 1,
}: {
  color?: string;
  transform?: number;
}) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`scale(${transform})`}
    >
      <path
        d="M3.58203 6.71094C4.10156 5.20703 5.60547 4.30469 7.21875 4.41406L12.8242 4.71484C12.25 3.56641 11.3477 2.58203 10.1445 1.89844C9.07812 1.26953 7.90234 0.96875 6.75391 0.96875C4.75781 0.996094 2.78906 1.87109 1.50391 3.51172L3.58203 6.71094ZM4.48438 7.75C4.48438 6.49219 5.49609 5.48047 6.78125 5.48047C8.03906 5.48047 9.05078 6.49219 9.05078 7.75C9.05078 9.03516 8.03906 10.0469 6.78125 10.0469C5.49609 10.0469 4.48438 9.03516 4.48438 7.75ZM13.0977 5.31641C14.2734 8.37891 13.0977 11.9336 10.1445 13.6289C8.96875 14.3125 7.65625 14.6133 6.39844 14.5312L9.46094 9.82812C10.3359 8.48828 10.3086 6.73828 9.26953 5.50781L13.0977 5.31641ZM3.63672 9.0625C4.375 10.4844 5.87891 11.332 7.46484 11.0586L5.71484 14.4492C2.46094 13.957 0 11.1406 0 7.75C0 6.38281 0.382812 5.125 1.09375 4.05859L3.63672 9.0625Z"
        fill="white"
      />
    </svg>
  );
};

export default ChromeOneColorIcon;
