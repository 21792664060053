import React from "react";
import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  MergeIntegrationEnum,
  ReviewDuplicatesCrmContactDetails_CrmContactDetailsFragment,
} from "../../../__generated__/graphql";
import { useContext } from "react";
import IntegrationTypeContext from "../../../contexts/IntegrationTypeContext";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import SalesforceIcon from "../../../assets/img/icons/SalesforceIcon";
import HubspotIcon from "../../../assets/img/icons/HubspotIcon";

export const ReviewDuplicatesCrmContactDetails_CrmContactDetails = gql`
  fragment ReviewDuplicatesCrmContactDetails_CrmContactDetails on CrmContactDetails {
    ... on MergeCrmContact {
      id
      firstName
      lastName
      companyName
      externalUrl
      title
    }
    ... on MergeCrmLead {
      id
      firstName
      lastName
      companyName
      externalUrl
      title
    }
  }
`;

const ReviewDuplicatesCrmContactDetails = ({
  crmContact,
}: {
  crmContact: ReviewDuplicatesCrmContactDetails_CrmContactDetailsFragment;
}) => {
  const { integrationType } = useContext(IntegrationTypeContext);

  return (
    <div>
      <div
        className={classNames(
          "border-b-1",
          "border-gray-200",
          "pb-1",
          "-mx-2",
        )}
      >
        <div
          className={classNames(
            "text-sm",
            "px-2",
            "pt-2",
            "text-gray-600",
          )}
        >
          Existing{" "}
          <span className={classNames("font-semibold")}>
            {integrationType}
          </span>{" "}
          record
        </div>
      </div>
      <div className={classNames("py-2")}>
        <div
          className={classNames(
            "flex",
            "items-center",
            "justify-between",
          )}
        >
          <div className={classNames("flex", "items-center")}>
            <div className={classNames("mr-2")}>
              {integrationType === MergeIntegrationEnum.Salesforce && (
                <SalesforceIcon />
              )}
              {integrationType === MergeIntegrationEnum.HubSpot && (
                <HubspotIcon />
              )}
            </div>
            <div>
              <div
                className={classNames("font-medium", "text-md")}
              >
                {crmContact.firstName ?? ""} {crmContact.lastName ?? ""}
              </div>
              {crmContact.title && (
                <div
                  className={classNames(
                    "text-md",
                    "text-gray-500",
                  )}
                >
                  {crmContact.title}
                </div>
              )}
              <div
                className={classNames(
                  "text-md",
                  "text-gray-500",
                )}
              >
                {crmContact.companyName ?? ""}
              </div>
            </div>
          </div>
          <div className={classNames("pl-2")}>
            {crmContact.externalUrl && (
              <a href={crmContact.externalUrl} target="_blank" rel="noreferrer">
                <ArrowTopRightOnSquareIcon
                  className={classNames("w-4", "text-sky-500")}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewDuplicatesCrmContactDetails;
