import classNames from "classnames";
import CiroCheckbox from "../shared/CiroCheckbox";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  AdminPersonalSettings_UpdateUserSettingsMutation,
  AdminPersonalSettings_UpdateUserSettingsMutationVariables,
  AdminPersonalSettings_UserAccountFragment,
} from "../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";

const AdminPersonalSettings_UpdateUserSettings = gql`
  mutation AdminPersonalSettings_UpdateUserSettings(
    $input: UpdateUserSettingsInput!
  ) {
    updateUserSettings(input: $input) {
      success
      error
    }
  }
`;

export const AdminPersonalSettings_UserAccount = gql`
  fragment AdminPersonalSettings_UserAccount on UserAccount {
    id
    userSettings {
      hideMinimizedChromeExtension
      showDeletePhoneRequestButton
      showChromeExtensionNav
    }
  }
`;

interface IAdminPersonalSettingsProps {
  userAccount: AdminPersonalSettings_UserAccountFragment;
}

const AdminPersonalSettings = ({
  userAccount,
}: IAdminPersonalSettingsProps) => {
  const [updateUserSettings, { loading }] = useMutation<
    AdminPersonalSettings_UpdateUserSettingsMutation,
    AdminPersonalSettings_UpdateUserSettingsMutationVariables
  >(AdminPersonalSettings_UpdateUserSettings);

  const [hideMinimizedChromeExtension, sethideMinimizedChromeExtension] =
    useState(Boolean(userAccount.userSettings.hideMinimizedChromeExtension));
  const [showDeletePhoneRequestButton, setShowDeletePhoneRequestButton] =
    useState(Boolean(userAccount.userSettings.showDeletePhoneRequestButton));
  const [showChromeExtensionNav, setShowChromeExtensionNav] = 
    useState(Boolean(userAccount.userSettings.showChromeExtensionNav));

  return (
    <div>
      <div className={classNames("font-medium")}>My Settings</div>
      <div className={classNames("pt-4")}>
        <div
          className={classNames(
            "grid",
            "grid-cols-2",
            "gap-4",
          )}
        >
          <CiroCheckbox
            checked={hideMinimizedChromeExtension}
            label="Hide minimized Chrome Extension"
            onClick={() =>
              sethideMinimizedChromeExtension(!hideMinimizedChromeExtension)
            }
          />
          <CiroCheckbox
            checked={showDeletePhoneRequestButton}
            label="Show delete phone request button"
            onClick={() =>
              setShowDeletePhoneRequestButton(!showDeletePhoneRequestButton)
            }
          />
          <CiroCheckbox
            checked={showChromeExtensionNav}
            label="Show Chrome Extension navigation"
            onClick={() =>
              setShowChromeExtensionNav(!showChromeExtensionNav)
            }
          />
        </div>
      </div>
      <div className={classNames("pt-4")}>
        <CiroButton
          analyticsField="Save User Settings"
          style={CiroButtonStyleEnum.LOUD}
          disabled={loading}
          onClick={() => {
            updateUserSettings({
              variables: {
                input: {
                  hideMinimizedChromeExtension: hideMinimizedChromeExtension,
                  showDeletePhoneRequestButton: showDeletePhoneRequestButton,
                  showChromeExtensionNav: showChromeExtensionNav,
                },
              },
            });
          }}
        >
          {loading ? "Saving..." : "Save"}
        </CiroButton>
      </div>
    </div>
  );
};

export default AdminPersonalSettings;
