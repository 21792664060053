import classNames from "classnames";
import { CiroDropdownButton, CiroDropdownButtonItem } from "../../../../shared/CiroDropdownButton";


interface IEnrichmentStepTechniqueFindContactLogicDropdown {
  setLogicType: (logicType: LogicTypeEnum) => void;
  logicType: LogicTypeEnum | null;
}

export enum LogicTypeEnum {
  ALL_OF = "ALL_OF",
  ANY_OF = "ANY_OF",
  NONE_OF = "NONE_OF",
}

const logicTypeToTitle = {
  [LogicTypeEnum.ALL_OF]: "All of",
  [LogicTypeEnum.ANY_OF]: "Any of",
  [LogicTypeEnum.NONE_OF]: "None of",
};



const FindContactLogicTypeOption = ({
  logicType,
  setLogicType,
}: {
  logicType: LogicTypeEnum;
  setLogicType: (logicType: LogicTypeEnum) => void;
  }) => {
  
  const title = logicTypeToTitle[logicType];

  return (
    <CiroDropdownButtonItem
      onClick={() => {
        setLogicType(logicType);
      }}
      analyticsField={`Set search type to ${logicType}`}
    >
      <div
        className={classNames(
          "flex",
          "flex-row",
          "items-start",
          "gap-1",
        )}
      >
        <div className={classNames("flex", "flex-col")}>
          <div>{title}</div>
        </div>
      </div>
    </CiroDropdownButtonItem>
  );
};

const EnrichmentStepTechniqueFindContactLogicDropdown = ({
  setLogicType,
  logicType,
}: IEnrichmentStepTechniqueFindContactLogicDropdown) => {
  return (
    <CiroDropdownButton
      customPadding={classNames("px-3")}
      customClassName={classNames("min-w-fit")}
      customButtonClassName={classNames(
        "shadow-none",
        "flex",
        "flex-row",
        "items-center",
        "h-full",
        "min-h-[38px]",
        "bg-gray-50",
      )}
      menuPosition="right"
      label={logicType ? logicTypeToTitle[logicType] : "Search type"}
    >
      {Object.values(LogicTypeEnum).map((logicType) => {
        return (
          <FindContactLogicTypeOption
            logicType={logicType}
            setLogicType={setLogicType}
          />
        );
      })}
    </CiroDropdownButton>
  );
};

export default EnrichmentStepTechniqueFindContactLogicDropdown;
