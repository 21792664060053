import React, { useState } from "react";
import { gql } from "@apollo/client";
import { BusinessDetailsKeyDetailsCard_CompanyFragment } from "../../../__generated__/graphql";
import CiroCard from "../../shared/CiroCard";
import CiroLink from "../../shared/CiroLink";
import classNames from "classnames";
import CiroTitle from "../../shared/CiroTitle";
import LinkArrowIcon from "../../../assets/img/icons/LinkArrowIcon";
import CiroPillTag from "../../shared/CiroPillTag";
import BusinessDetailsKeyDetailsGroup, {
  BusinessDetailsCardLabel,
} from "./BusinessDetailsKeyDetailsGroup";
import BusinessDetailsKeyDetailsValue from "./BusinessDetailsKeyDetailsValue";
import url from "url";
import {
  externalUrlWithProtocol,
  prettifyUrl,
} from "../../../utils/formatters";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import BusinessDetailsKeyDetailsUnlockModal from "./BusinessDetailsKeyDetailsUnlockModal";

export const BusinessDetailsKeyDetailsCard_Company = gql`
  fragment BusinessDetailsKeyDetailsCard_Company on Company {
    pk
    industry {
      populated
      value
    }
    company_industry_associations {
      value {
        vendor {
          id
          vendor_name
        }
      }
      populated
      count
    }
    description {
      value
      populated
    }
    email {
      value
      populated
    }
    fax_number {
      value
      populated
    }
    phone_number {
      value
      populated
    }
    pms_software_provider {
      value
      populated
    }
    company_technologies {
      value {
        vendor {
          id
          vendor_name
        }
      }
      populated
      count
    }
    website {
      value
      populated
    }
    is_parent {
      value
      populated
    }
    company_specialties {
      value
      populated
      count
    }
    founded_year {
      value
      populated
    }
    stock_symbol {
      value
      populated
    }
    company_size_min {
      value
      populated
    }
    company_size_max {
      value
      populated
    }
  }
`;

const TruncatedText = ({ children }: { children: any }) => {
  return <span className={classNames("truncate")}>{children}</span>;
};

interface IBusinessDetailsKeyDetailsCardProps {
  company: BusinessDetailsKeyDetailsCard_CompanyFragment;
}

const BusinessDetailsKeyDetailsCard = ({
  company,
}: IBusinessDetailsKeyDetailsCardProps) => {
  const [techExpanded, setTechExpanded] = useState(false);
  const [assocExpanded, setAssocExpanded] = useState(false);

  const websiteUrlObj = company.website?.value
    ? url.parse(company.website?.value)
    : null;
  const technologiesSeen = new Set(
    company.company_technologies?.value?.map((x) => x?.vendor?.vendor_name) ||
      [],
  );
  const { pms_software_provider } = company;
  if (pms_software_provider?.value) {
    technologiesSeen.add(pms_software_provider.value);
  }
  const [unlockPmsModalIsOpen, setUnlockPmsModalIsOpen] = useState(false);

  let technologiesSeenDisplay = company.company_technologies;
  const industryLabel = company.industry?.value;

  // Needed logic for when no other technologies seen besides phone verified pms_software_provider
  if (
    !company.company_technologies?.populated &&
    pms_software_provider?.populated
  ) {
    technologiesSeenDisplay = {
      ...pms_software_provider,
      value: [],
      __typename: undefined,
    };
  }

  const shownTech = techExpanded
    ? [...technologiesSeen]
    : [...technologiesSeen].slice(0, 5);

  const shownAssoc = assocExpanded
    ? company.company_industry_associations?.value
    : (company.company_industry_associations?.value || []).slice(0, 3);

  return (
    <div className={classNames(["mt-4"])}>
      <CiroCard size="small">
        <CiroTitle title="Account Information" size="small" />
        <div
          className={classNames([
            "grid-cols-2",
            "grid",
            "gap-x-4",
            "gap-y-2",
          ])}
        >
          {industryLabel && (
            <BusinessDetailsKeyDetailsGroup
              title="Industry"
              field={company.industry}
            >
              <BusinessDetailsKeyDetailsValue>
                <span className={classNames("mr-1", "overflow-hidden")}>
                  <CiroPillTag text={industryLabel} />
                </span>
              </BusinessDetailsKeyDetailsValue>
            </BusinessDetailsKeyDetailsGroup>
          )}
          <BusinessDetailsKeyDetailsGroup
            title="Categories & Services"
            field={company.company_specialties}
          >
            <div
              className={classNames(
                "flex",
                "flex-wrap",
                "overflow-hidden",
              )}
            >
              {company.company_specialties?.value?.map((specialty) => {
                return (
                  <span
                    key={specialty}
                    className={classNames(
                      "flex-shrink-0",
                      "mr-1",
                      "max-w-full",
                      "mb-1",
                    )}
                  >
                    <CiroPillTag text={String(specialty)} />
                  </span>
                );
              })}
            </div>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Website"
            primary={true}
            field={company.website}
          >
            <div>
              <CiroLink href={externalUrlWithProtocol(websiteUrlObj)}>
                <BusinessDetailsKeyDetailsValue>
                  <TruncatedText>{prettifyUrl(websiteUrlObj)}</TruncatedText>
                  <span className="pl-2">
                    <LinkArrowIcon />
                  </span>
                </BusinessDetailsKeyDetailsValue>
              </CiroLink>
            </div>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Phone Number"
            field={company.phone_number}
          >
            <BusinessDetailsKeyDetailsValue>
              <TruncatedText>{company.phone_number?.value}</TruncatedText>
              <span className="pl-2">
                <CiroLink
                  href={`tel:${company.phone_number?.value?.replace(/\D/g, "")}`}
                />
              </span>
            </BusinessDetailsKeyDetailsValue>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup title="Email" field={company.email}>
            <BusinessDetailsKeyDetailsValue>
              <TruncatedText>{company.email?.value}</TruncatedText>
              <span className="pl-2">
                <CiroLink href={`mailto:${company.email?.value}`} />
              </span>
            </BusinessDetailsKeyDetailsValue>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Fax Number"
            field={company.fax_number}
          >
            <BusinessDetailsKeyDetailsValue>
              <TruncatedText>{company.fax_number?.value}</TruncatedText>
            </BusinessDetailsKeyDetailsValue>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Business Type"
            field={company.is_parent}
          >
            <BusinessDetailsKeyDetailsValue>
              {company.is_parent?.value && (
                <span className={classNames("mr-1")}>
                  <CiroPillTag text="Enterprise" />
                </span>
              )}
            </BusinessDetailsKeyDetailsValue>
          </BusinessDetailsKeyDetailsGroup>
        </div>
        <div
          className={classNames([
            "grid-cols-2",
            "grid",
            "gap-x-4",
            "gap-y-2",
          ])}
        >
          <BusinessDetailsKeyDetailsGroup
            title="Industry Associations"
            field={company.company_industry_associations}
          >
            <div
              className={classNames(
                "flex",
                "flex-wrap",
                "overflow-hidden",
              )}
            >
              {shownAssoc &&
                Boolean(shownAssoc.length) &&
                shownAssoc.map((companyIndustryAssociation) => {
                  return (
                    <span
                      key={companyIndustryAssociation?.vendor?.vendor_name}
                      className={classNames(
                        "flex-shrink-0",
                        "mr-1",
                        "max-w-full",
                        "mb-1",
                      )}
                    >
                      <CiroPillTag
                        text={String(
                          companyIndustryAssociation?.vendor?.vendor_name,
                        )}
                        className="test-industry-association-badge"
                      />
                    </span>
                  );
                })}
              {company.company_industry_associations?.value?.length &&
                company.company_industry_associations?.value?.length > 3 && (
                  <span
                    className={classNames(
                      "flex-shrink-0",
                      "mr-1",
                      "max-w-full",
                      "mb-1",
                      "border-1",
                      "border-gray-400",
                      "font-medium",
                      "px-2",
                      "rounded-full",
                      "text-sm",
                      "truncate",
                      "text-orange-400",
                    )}
                  >
                    <CiroButton
                      customClassName={"test-expand-associations-button"}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      analyticsField="expand industry associations list"
                      onClick={() => {
                        setAssocExpanded(!assocExpanded);
                      }}
                    >
                      {assocExpanded
                        ? "-"
                        : "+" +
                          (company.company_industry_associations.value.length -
                            3)}
                    </CiroButton>
                  </span>
                )}
            </div>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Technologies"
            field={technologiesSeenDisplay}
          >
            <div
              className={classNames(
                "flex",
                "flex-wrap",
                "overflow-hidden",
              )}
            >
              {Boolean(shownTech.length) &&
                shownTech.map((companyTechnology) => {
                  const isPhoneVerified =
                    companyTechnology === pms_software_provider?.value;
                  return (
                    <span
                      key={companyTechnology}
                      className={classNames(
                        "flex-shrink-0",
                        "mr-1",
                        "max-w-full",
                        "mb-1",
                      )}
                    >
                      <CiroTooltipContainer
                        disabled={!isPhoneVerified}
                        tooltip="Ciro verified Practice Management System"
                      >
                        <CiroPillTag
                          color={
                            isPhoneVerified
                              ? "text-orange-400"
                              : "text-stone-500"
                          }
                          text={String(companyTechnology)}
                        />
                      </CiroTooltipContainer>
                    </span>
                  );
                })}
              {technologiesSeen?.size > 5 && (
                <span
                  className={classNames(
                    "flex-shrink-0",
                    "mr-1",
                    "max-w-full",
                    "mb-1",
                    "border-1",
                    "border-gray-400",
                    "font-medium",
                    "px-2",
                    "rounded-full",
                    "text-sm",
                    "truncate",
                    "text-orange-400",
                  )}
                >
                  <CiroButton
                    style={CiroButtonStyleEnum.UNSTYLED}
                    analyticsField="expand technology list"
                    onClick={() => {
                      setTechExpanded(!techExpanded);
                    }}
                  >
                    {techExpanded ? "-" : "+" + (technologiesSeen.size - 5)}
                  </CiroButton>
                </span>
              )}
              {!pms_software_provider?.value &&
                pms_software_provider?.populated && (
                  <span
                    className={classNames(
                      "cursor-pointer",
                      "flex-shrink-0",
                      "mr-1",
                      "max-w-full",
                      "mb-1",
                    )}
                  >
                    <CiroTooltipContainer
                      tooltip={
                        "Unlock phone verified practice management system"
                      }
                    >
                      <CiroButton
                        style={CiroButtonStyleEnum.UNSTYLED}
                        analyticsField="Unlock PMS from Business Details"
                        onClick={() => setUnlockPmsModalIsOpen(true)}
                      >
                        <CiroPillTag
                          color="text-orange-400"
                          text={
                            <div className={classNames("blur-sm")}>
                              Hidden
                            </div>
                          }
                        />
                      </CiroButton>
                    </CiroTooltipContainer>
                    <BusinessDetailsKeyDetailsUnlockModal
                      isOpen={unlockPmsModalIsOpen}
                      setIsOpen={setUnlockPmsModalIsOpen}
                      companyPk={company.pk}
                    />
                  </span>
                )}
            </div>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Founded Year"
            field={company.founded_year}
          >
            <div>{company.founded_year?.value}</div>
          </BusinessDetailsKeyDetailsGroup>
          <BusinessDetailsKeyDetailsGroup
            title="Stock"
            field={company.stock_symbol}
          >
            <div>{company.stock_symbol?.value}</div>
          </BusinessDetailsKeyDetailsGroup>
        </div>
        {company.description?.value && (
          <div
            className={classNames(
              "border-t-1",
              "pt-6",
              "mt-2",
            )}
          >
            <BusinessDetailsCardLabel>
              Company Description
            </BusinessDetailsCardLabel>
            <div>{company.description?.value}</div>
          </div>
        )}
      </CiroCard>
    </div>
  );
};

export default BusinessDetailsKeyDetailsCard;
