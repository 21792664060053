import React from "react";
import classNames from "classnames";

function Title({ size, children }: { size: string; children: any }) {
  return (
    <h1
      className={classNames([
        "text-gray-900",
        "font-medium",
        "leading-9",
        {
          "text-xl": size === "small",
          "text-3xl": size === "medium",
          "text-4xl": size === "large",
          "leading-6": size === "small",
          "leading-9": size === "medium",
          "leading-12": size === "large",
        },
      ])}
    >
      {children}
    </h1>
  );
}

function Subtitle({
  size,
  children,
  className,
}: {
  size: string;
  children: any;
  className: string;
}) {
  return (
    <h2
      className={classNames([
        "text-gray-500",
        "font-normal",
        "leading-6",
        "text-sm",
        className,
        {
          "text-sm": size === "small",
          "text-base": size === "medium",
          "text-lg": size === "large",
          "leading-3": size === "small",
          "leading-6": size === "medium",
          "leading-9": size === "large",
        },
      ])}
    >
      {children}
    </h2>
  );
}

export default function CiroTitle({
  title,
  icon,
  subtitle,
  size = "medium",
  subtitleClassName = "",
}: {
  title: any;
  icon?: any;
  subtitle?: any;
  size?: "tiny" | "small" | "medium" | "large";
  subtitleClassName?: string;
}) {
  return (
    <div>
      <div
        className={classNames(
          "flex",
          "flex-row",
          "items-center",
          "mb-1",
        )}
      >
        {icon && (
          <div
            className={classNames("mr-2", "text-orange-600")}
          >
            {icon}
          </div>
        )}
        <Title size={size}>{title}</Title>
      </div>
      {subtitle && (
        <Subtitle size={size} className={subtitleClassName}>
          {subtitle}
        </Subtitle>
      )}
    </div>
  );
}
