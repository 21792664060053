import { gql } from "@apollo/client";
import classNames from "classnames";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import SkeletonLoading from "../shared/SkeletonLoading";
import { useState } from "react";
import {
  DeletePersonaModal_PersonaFragmentDoc,
  PersonaTable_PersonaFragment,
} from "../../__generated__/graphql";
import DeletePersonaModal from "./DeletePersonaModal";
import UpsertPersonaModal from "./UpsertPersonaModal";
import { dateToTimeAgo } from "../../utils/formatters";
import { DeletePersonaModal_Persona } from "./DeletePersonaModal";
import { useFragment } from "../../__generated__";

export const PersonaTable_Persona = gql`
  fragment PersonaTable_Persona on Persona {
    id
    name
    description
    created_at
    ...DeletePersonaModal_Persona
  }
  ${DeletePersonaModal_Persona}
`;

interface PersonaTableProps {
  personas: PersonaTable_PersonaFragment[];
  loading: boolean;
  refetch: () => Promise<any>;
}

const PersonaTable = ({ personas, loading, refetch }: PersonaTableProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] =
    useState<PersonaTable_PersonaFragment | null>(null);

  const handleDeletePersona = (persona: PersonaTable_PersonaFragment) => {
    setSelectedPersona(persona);
    setDeleteModalOpen(true);
  };

  const handleEditPersona = (persona: PersonaTable_PersonaFragment) => {
    setSelectedPersona(persona);
    setEditModalOpen(true);
  };

  const selectedPersonaFragment = useFragment(
    DeletePersonaModal_PersonaFragmentDoc,
    selectedPersona,
  );

  if (loading) {
    return <SkeletonLoading numSkeletons={5} skeletonHeight={"3rem"} />;
  }

  const headers = [
    <CiroTableHeader key="name" isFirst={true}>
      Persona name
    </CiroTableHeader>,
    <CiroTableHeader
      key="description"
      className={classNames("hidden", "md:table-cell")}
    >
      Description
    </CiroTableHeader>,
    <CiroTableHeader key="created">Created</CiroTableHeader>,
    <CiroTableHeader key="actions" isLast={true}>
      Actions
    </CiroTableHeader>,
  ];

  return (
    <>
      <CiroTable>
        <thead className={classNames("table-header-group")}>
          <CiroTableRow clickable={false}>{headers}</CiroTableRow>
        </thead>
        <tbody className={classNames("table-row-group")}>
          {personas?.map((persona, i) => {
            const isLastRow = i === personas.length - 1;
            return (
              <CiroTableRow key={persona.id} clickable={false}>
                <CiroTableCell
                  lastLeft={isLastRow}
                  isLastRow={isLastRow}
                >
                  {persona.name}
                </CiroTableCell>
                <CiroTableCell
                  isLastRow={isLastRow}
                  className={classNames(
                    "hidden",
                    "md:table-cell",
                  )}
                >
                  {persona.description?.length > 100
                    ? `${persona.description.slice(0, 100)}...`
                    : persona.description}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {dateToTimeAgo(persona.created_at)}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow} lastRight={isLastRow}>
                  <div
                    className={classNames(
                      "flex",
                      "gap-4",
                      "justify-start",
                    )}
                  >
                    <CiroButton
                      analyticsField="edit-persona"
                      analyticsProps={{ personaId: persona.id }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      onClick={() => handleEditPersona(persona)}
                    >
                      <PencilIcon
                        className={classNames("h-4", "w-4")}
                      />
                    </CiroButton>
                    <CiroButton
                      analyticsField="delete-persona"
                      analyticsProps={{ personaId: persona.id }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      onClick={() => handleDeletePersona(persona)}
                    >
                      <TrashIcon
                        className={classNames("h-4", "w-4")}
                      />
                    </CiroButton>
                  </div>
                </CiroTableCell>
              </CiroTableRow>
            );
          })}
        </tbody>
      </CiroTable>

      {selectedPersona && (
        <>
          <DeletePersonaModal
            isOpen={deleteModalOpen}
            personaId={selectedPersona.id}
            personaName={selectedPersona.name}
            selectedPersona={selectedPersonaFragment}
            onClose={() => {
              setDeleteModalOpen(false);
              setSelectedPersona(null);
            }}
            onSuccess={async () => {
              await refetch();
            }}
          />
          <UpsertPersonaModal
            isOpen={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedPersona(null);
            }}
            onSuccess={async () => {
              await refetch();
            }}
            initialData={{
              id: selectedPersona.id,
              name: selectedPersona.name,
              description: selectedPersona.description,
            }}
          />
        </>
      )}
    </>
  );
};

export default PersonaTable;
