import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

const FirstLastNameFilter = ({
  firstNameFilter,
  lastNameFilter,
  setFirstNameFilter,
  setLastNameFilter,
}: {
  firstNameFilter: string[];
  lastNameFilter: string[];
  setFirstNameFilter: (v: string[]) => void;
  setLastNameFilter: (v: string[]) => void;
}) => {
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={firstNameFilter.length ? 1 : 0}
      sectionTitle={"First & last name"}
      filterOptions={[
        {
          label: "First name includes any of:",
          filter: (
            <CiroDropDown
              creatable={true}
              options={firstNameFilter.map((name) => {
                return { value: name, label: name };
              })}
              isMulti={true}
              value={firstNameFilter}
              onChange={(newValue) => {
                setFirstNameFilter(newValue);
              }}
            />
          ),
        },
        {
          label: "Last name includes any of:",
          filter: (
            <CiroDropDown
              creatable={true}
              options={lastNameFilter.map((name) => {
                return { value: name, label: name };
              })}
              isMulti={true}
              value={lastNameFilter}
              onChange={(newValue) => {
                setLastNameFilter(newValue);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default FirstLastNameFilter;
