import React from "react";
import classNames from "classnames";
import { Link, matchPath, useLocation } from "react-router-dom";

interface INavLinkProps {
  additionalMatches?: string[];
  className?: string;
  fullWidth?: boolean;
  href?: string;
  icon: JSX.Element;
  onClick?: () => void;
  title: string;
}

const getLinkClasses = (fullWidth: boolean) => {
  return classNames(
    "cursor-pointer",
    "flex",
    "my-1",
    "no-underline",
    "py-2",
    "rounded",
    "text-zinc-500",
    "hover:text-neutral-600",
    {
      "mx-4": !fullWidth,
    },
  );
};

const NavLink = ({
  additionalMatches = [],
  className = "",
  fullWidth = false,
  href,
  icon,
  onClick,
  title,
}: INavLinkProps) => {
  const { pathname } = useLocation();
  if (href) {
    return (
      <Link
        onClick={onClick}
        to={href}
        className={classNames(getLinkClasses(fullWidth), className, {
          "bg-neutral-200": [href, ...additionalMatches].some((match) =>
            matchPath(match, pathname),
          ),
        })}
      >
        <span className={classNames("flex", "items-center")}>
          <span className="mx-4">{icon}</span>
          <span>{title}</span>
        </span>
      </Link>
    );
  }
  if (onClick) {
    return (
      <div onClick={onClick} className={classNames(getLinkClasses(fullWidth))}>
        <span className={classNames("flex", "items-center")}>
          <span className="mx-4">{icon}</span>
          {title}
        </span>
      </div>
    );
  }
  throw new Error("NavLink must include onClick or href");
};

export default NavLink;
