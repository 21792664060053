import classNames from "classnames";

import "react-datepicker/dist/react-datepicker.css";
import "./DateRangeFilter.css";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { DEFAULT_FILTER_TOOLTIP_TEXT } from "../../utils/consts";
import CiroDatePicker from "../shared/CiroDatePicker";

interface IDateRangeFilterProps {
  blurred?: boolean;
  blurredTooltip?: string;
  displayDirection?: "horizontal" | "vertical";
  endDate: Date | null;
  endDateLabel?: string;
  endDatePlaceholderText?: string;
  label?: string;
  resetOffset: () => void;
  setEndDate: (v: Date | null) => void;
  setStartDate: (v: Date | null) => void;
  startDate: Date | null;
  startDateLabel?: string;
  startDatePlaceholderText?: string;
}

const DateRangeFilter = ({
  blurred = false,
  blurredTooltip = DEFAULT_FILTER_TOOLTIP_TEXT,
  displayDirection = "horizontal",
  endDate,
  endDateLabel,
  endDatePlaceholderText = "End Date",
  label,
  resetOffset,
  setEndDate,
  setStartDate,
  startDate,
  startDateLabel,
  startDatePlaceholderText = "Start Date",
}: IDateRangeFilterProps) => {
  const isVertical = displayDirection === "vertical";
  return (
    <div
      className={classNames(
        "flex",
        "flex-col",
        "items-start",
      )}
    >
      {label && <div className={classNames("pb-2")}>{label}</div>}
      <div
        className={classNames(
          "items-stretch",
          "w-full",
          "flex",
          {
            "space-x-2": !isVertical,
            "space-y-2": isVertical,
            "flex-col": isVertical,
          },
        )}
      >
        <div
          className={classNames(
            "flex",
            "flex-col",
            "w-full",
          )}
        >
          {startDateLabel && (
            <div className={classNames("text-sm")}>
              {startDateLabel}
            </div>
          )}
          <CiroTooltipContainer
            className={classNames("w-full")}
            tooltip={blurredTooltip}
            disabled={!blurred}
          >
            <div
              className={classNames("w-full", {
                "blur-sm": blurred,
                "pointer-events-none": blurred,
              })}
            >
              <CiroDatePicker
                selectedDate={startDate}
                setSelectedDate={(v) => {
                  setStartDate(v);
                  resetOffset();
                }}
                maxDate={endDate}
                placeholderText={startDatePlaceholderText}
              />
            </div>
          </CiroTooltipContainer>
        </div>
        <div
          className={classNames(
            "flex",
            "flex-col",
            "w-full",
          )}
        >
          {endDateLabel && (
            <div className={classNames("text-sm")}>{endDateLabel}</div>
          )}
          <CiroTooltipContainer
            className={classNames("w-full")}
            tooltip={blurredTooltip}
            disabled={!blurred}
          >
            <div
              className={classNames("w-full", {
                "blur-sm": blurred,
                "pointer-events-none": blurred,
              })}
            >
              <CiroDatePicker
                selectedDate={endDate}
                setSelectedDate={(v) => {
                  setEndDate(v);
                  resetOffset();
                }}
                minDate={startDate}
                placeholderText={endDatePlaceholderText}
              />
            </div>
          </CiroTooltipContainer>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
