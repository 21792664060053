import classNames from "classnames";
import SkeletonLoading from "./SkeletonLoading";

interface CiroNumbersFormSectionProps {
  title: string;
  description: string;
  children: any;
  isLoading?: boolean;
}

const CiroNumbersFormSection = ({
  title,
  description,
  children,
  isLoading = false,
}: CiroNumbersFormSectionProps) => {
  return (
    <div
      className={classNames("flex", "w-full", "pt-4")}
    >
      <div className={classNames("w-2/5")}>
        <div className={classNames("font-bold")}>{title}</div>
        <div className={classNames("text-sm", "text-gray-500")}>
          {description}
        </div>
      </div>
      <div className={classNames("w-3/5")}>
        <div
          className={classNames(
            "w-full",
            "bg-white",
            "rounded-lg",
            "p-8",
          )}
        >
          {isLoading && (
            <SkeletonLoading numSkeletons={2} skeletonHeight={"3rem"} />
          )}
          {!isLoading && children}
        </div>
      </div>
    </div>
  );
};

export default CiroNumbersFormSection;
