import React from "react";
import classNames from "classnames";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { Maybe, ProtectedString } from "../../__generated__/graphql";

interface IBusinessDetailsSocialLinkProps {
  field?: Maybe<ProtectedString>;
  icon: JSX.Element;
  inline?: Boolean;
  mini?: Boolean;
}

const BusinessDetailsSocialLink = ({
  field,
  icon,
  inline,
}: IBusinessDetailsSocialLinkProps) => {
  if (!field?.populated) {
    return null;
  }

  return (
    <span
      className={classNames(
        inline
          ? ["filter", "grayscale"]
          : ["px-3", "py-1"],
      )}
    >
      {field?.value ? (
        <a href={String(field?.value)} target="_blank" rel="noreferrer">
          {icon}
        </a>
      ) : (
        <CiroTooltipContainer tooltip="Please upgrade your plan to get access to social media accounts">
          {icon}
        </CiroTooltipContainer>
      )}
    </span>
  );
};

export default BusinessDetailsSocialLink;
