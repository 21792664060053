import { useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";
import CiroTextArea from "../../shared/CiroTextArea";

interface IContactListRationaleTextBoxProps {
  rationale: string;
  contactId: number;
  onSave: (rationale: string) => void;
}

const ContactListRationaleTextBox = ({
  rationale,
  contactId,
  onSave,
}: IContactListRationaleTextBoxProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentRationale, setCurrentRationale] = useState(rationale);
  const [savedRationale, setSavedRationale] = useState(rationale);

  if (isEditing) {
    return (
      <div>
        <CiroTextArea
          value={currentRationale}
          onChange={(e) => setCurrentRationale(e.target.value)}
          fitToContentOnMount={true}
          className={classNames(
            "text-xs",
            "w-full",
            "p-3.5",
            "border-gray-300",
            "border-1",
            "rounded-md",
          )}
        />
        <div
          className={classNames(
            "flex",
            "justify-end",
            "gap-2",
          )}
        >
          <CiroButton
            analyticsField="save-rationale"
            customClassName={classNames(
              "text-sm",
              "text-orange-500",
              "hover:text-orange-600",
            )}
            analyticsProps={{ contactId }}
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              onSave(currentRationale);
              setSavedRationale(currentRationale);
              setIsEditing(false);
            }}
          >
            Save
          </CiroButton>
          <CiroButton
            analyticsField="cancel-rationale"
            customClassName={classNames(
              "text-sm",
              "text-orange-500",
              "hover:text-orange-600",
            )}
            analyticsProps={{ contactId }}
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              setCurrentRationale(savedRationale);
              setIsEditing(false);
            }}
          >
            Cancel
          </CiroButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "cursor-pointer",
        "border-gray-300",
        "border-1",
        "p-3.5",
        "rounded-md",
        "hover:border-orange-500",
        "text-xs",
      )}
    >
      <CiroButton
        analyticsField="edit-rationale"
        analyticsProps={{ contactId }}
        onClick={() => setIsEditing(true)}
        style={CiroButtonStyleEnum.UNSTYLED}
      >
        <div className={classNames("text-left")}>
          {currentRationale}
        </div>
      </CiroButton>
    </div>
  );
};

export default ContactListRationaleTextBox;
