import { createContext } from "react";

import type { MergeIntegrationEnum } from "../__generated__/graphql";

const IntegrationTypeContext = createContext({
  integrationType: null,
} as {
  integrationType: MergeIntegrationEnum | null;
});

export default IntegrationTypeContext;
