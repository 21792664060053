import React, { useState } from "react";
import classNames from "classnames";
import BusinessProfileIcon from "../../assets/img/icons/BusinessProfileIcon";
import { gql } from "@apollo/client";
import { BusinessDetailsContactCard_CompanyContactExperienceFragment } from "../../__generated__/graphql";
import DownArrowIcon from "../../assets/img/icons/DownArrowIcon";
import CiroCard from "../shared/CiroCard";
import CiroLink from "../shared/CiroLink";
import BusinessDetailsSocialLink from "./BusinessDetailsSocialLink";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import pluralize from "pluralize";

export const BusinessDetailsContactCard_CompanyContactExperience = gql`
  fragment BusinessDetailsContactCard_CompanyContactExperience on CompanyContactExperience {
    id
    description
    title
    contact {
      age_range
      full_name
      linkedin_id
      emails {
        email
      }
      phones {
        phone_number
      }
    }
  }
`;

interface IBusinessDetailsContactCardProps {
  contactExperience: BusinessDetailsContactCard_CompanyContactExperienceFragment;
  beginExpanded: Boolean;
}

const BusinessDetailsContactCard = ({
  contactExperience,
  beginExpanded,
}: IBusinessDetailsContactCardProps) => {
  const { contact } = contactExperience;
  const expandable = Boolean(contactExperience.description);
  const [expanded, setExpanded] = useState(beginExpanded);

  return (
    <div className={classNames(["mt-4"])}>
      <CiroCard>
        <div
          className={classNames(["flex", "justify-between"])}
        >
          <div className={classNames(["flex"])}>
            <BusinessProfileIcon />
            <div className={classNames(["pl-2"])}>
              <div
                className={classNames([
                  "font-semibold",
                  "slate-700",
                  "text-base",
                  "flex",
                ])}
              >
                <div className="ml-2">
                  <span>{contact.full_name}</span>
                  <div className="flex items-center">
                    <div
                      className={classNames([
                        "font-normal",
                        "slate-600",
                        "text-sm",
                      ])}
                    >
                      {contactExperience.title || "Contact"}
                      {contact.age_range && ` - ${contact.age_range} years old`}
                    </div>
                    {contact?.linkedin_id && (
                      <div className={classNames(["pl-2"])}>
                        <BusinessDetailsSocialLink
                          field={{
                            populated: true,
                            value: `https://www.linkedin.com/in/${contact?.linkedin_id}`,
                          }}
                          inline={true}
                          icon={<LinkedInIcon mini={true} />}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {expandable && (
            <div
              className={classNames(
                [
                  "cursor-pointer",
                  "duration-500",
                  "ease-in-out",
                  "flex",
                  "items-center",
                  "origin-center",
                  "transform",
                  "transition-all",
                ],
                {
                  "rotate-180": expanded,
                },
              )}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <DownArrowIcon stroke="black" />
            </div>
          )}
        </div>
        <div
          className={classNames(
            ["ease", "transition-all", "duration-500"],
            {
              "overflow-auto": expandable,
              "max-h-0": !expanded,
              "max-h-96": expanded || !expandable,
            },
          )}
        >
          <div>
            {Boolean(contact?.emails?.length) && (
              <div
                className={classNames([
                  "flex",
                  "font-medium",
                  "slate-900",
                  "text-base",
                  "flex-row",
                  "items-center",
                  "pt-2",
                ])}
              >
                <span
                  className={classNames([
                    "text-base",
                    "text-gray-500",
                  ])}
                >{`${pluralize("Email", contact?.emails?.length)}:`}</span>
                <ul
                  className={classNames([
                    "flex",
                    "flex-wrap",
                    "pl-2",
                    "my-0",
                  ])}
                >
                  {contact?.emails?.map((email, idx) => (
                    <div>
                      <CiroLink href={`mailto:${email?.email}`}>
                        {email?.email}
                      </CiroLink>
                      {contact?.emails && idx < contact?.emails?.length - 1 && (
                        <span
                          className={classNames([
                            "pr-2",
                            "pl-2",
                            "text-stone-400",
                          ])}
                        >
                          •
                        </span>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {Boolean(contact?.phones?.length) && (
              <div
                className={classNames([
                  "flex",
                  "font-medium",
                  "slate-900",
                  "text-base",
                  "flex-row",
                  "items-center",
                  "pt-2",
                ])}
              >
                <span
                  className={classNames([
                    "text-base",
                    "text-gray-500",
                  ])}
                >{`${pluralize("Phone", contact?.phones?.length)}:`}</span>
                <ul
                  className={classNames([
                    "flex",
                    "flex-wrap",
                    "pl-2",
                    "my-0",
                  ])}
                >
                  {contact?.phones?.map((phone, idx) => (
                    <div>
                      <CiroLink href={`tel:${phone?.phone_number}`}>
                        {phone?.phone_number}
                      </CiroLink>
                      {contact?.phones && idx < contact?.phones?.length - 1 && (
                        <span
                          className={classNames([
                            "pr-2",
                            "pl-2",
                            "text-stone-400",
                          ])}
                        >
                          •
                        </span>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            )}
            <div
              className={classNames([
                "font-medium",
                "slate-900",
                "text-base",
                "pt-2",
              ])}
            >
              {contactExperience.description && `Description`}
            </div>
            <div
              className={classNames([
                "text-slate-600",
                "font-400",
                "mt-2",
              ])}
            >
              {contactExperience.description}
            </div>
          </div>
        </div>
      </CiroCard>
    </div>
  );
};
export default BusinessDetailsContactCard;
