import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import AppContext from "../../contexts/AppContext";
import CiroSpinner from "../shared/CiroSpinner";

const MobileNumbersDownloadButton = ({
  ids,
  phoneNumberRequest,
  children,
}: {
  ids: number[];
  phoneNumberRequest?: boolean;
  children?: React.ReactNode;
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const { accessToken } = useContext(AppContext);

  const downloadPhoneNumbersRequestTransaction = useCallback(async () => {
    const fetchUrl = phoneNumberRequest
      ? "/export-phone-number-request"
      : "/export-phone-numbers-request-transaction";
    try {
      setIsExporting(true);
      const response = await fetch(fetchUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ids,
        }),
      });
      const blob = await response.blob();
      fileDownload(blob, `Ciro Phone Numbers.csv`);
      toast.success("CSV downloaded successfully");
    } catch (error) {
      console.error("Error during CSV download:", error);
      toast.error("Error during CSV download");
    } finally {
      setIsExporting(false);
    }
  }, [ids, phoneNumberRequest, accessToken]);

  return (
    <CiroButton
      analyticsField="Download Phone Numbers Request Transaction"
      style={CiroButtonStyleEnum.UNSTYLED}
      onClick={downloadPhoneNumbersRequestTransaction}
      disabled={isExporting}
    >
      {isExporting && <CiroSpinner loading={isExporting} />}
      {!isExporting &&
        (children ? (
          children
        ) : (
          <ArrowDownTrayIcon
            className={classNames("w-4", "h-4")}
          />
        ))}
    </CiroButton>
  );
};

export default MobileNumbersDownloadButton;
