import classNames from "classnames";
import { EnrichmentTechniqueEnum } from "../../../../__generated__/graphql";
import EnrichmentStepFilterIsEqual from "./EnrichmentStepFilterIsEqual";
import {
  EnrichmentFilterTechnique,
  enrichmentStepFilterDropdownOptions,
} from "./EnrichmentStepFilterDropdown";
import { TrashIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import EnrichmentStepFilterIncludes from "./EnrichmentStepFilterIncludes";
import ArrowheadIcon from "../../../../assets/img/icons/ArrowheadIcon";
import { useEffect, useRef, useState } from "react";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../../shared/CiroDropdownButton";
import { IFilterEnrichmentStep } from "./EnrichmentStepCardFiltersList";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import { useFormContext } from "react-hook-form";

interface IEnrichmentStepFilter {
  deleteStep: (stepIndex: number) => void;
  filterEnrichmentStep: IFilterEnrichmentStep;
  setFilterEnrichmentStep: (
    filterEnrichmentStep: IFilterEnrichmentStep,
  ) => void;
  stepIndex: number;
}

const EnrichmentStepFilter = ({
  deleteStep,
  filterEnrichmentStep,
  setFilterEnrichmentStep,
  stepIndex,
}: IEnrichmentStepFilter) => {
  const [logicalOperator, setLogicalOperator] =
    useState<EnrichmentFilterTechnique | null>(
      filterEnrichmentStep.enrichment_technique,
    );
  const filterBodyRef = useRef<HTMLDivElement>(null);
  const [filterBodyHeight, setFilterBodyHeight] = useState(0);

  useEffect(() => {
    if (filterBodyRef.current) {
      const { height } = filterBodyRef?.current?.getBoundingClientRect();
      setFilterBodyHeight(height);
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        setFilterBodyHeight(height);
      }
    });

    if (filterBodyRef.current) {
      resizeObserver.observe(filterBodyRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const {
    formState: { errors },
    setValue,
  } = useFormContext<{
    filterEnrichmentSteps: IFilterEnrichmentStep[];
  }>();

  return (
    <div className={classNames("rounded-md", "p-4")}>
      <div
        className={classNames(
          "inline-flex",
          "items-center",
          "gap-3",
          "w-full",
        )}
      >
        <div
          className={classNames(
            "flex",
            "flex-row",
            "justify-start",
            "items-start",
            "w-full",
          )}
        >
          <div className={classNames("relative")}>
            <div
              className={classNames(
                "inline-flex",
                "h-7",
                "p-2",
                "content-center",
                "items-center",
                "gap-2",
                "rounded-md",
                "bg-neutral-100",
                "text-neutral-500",
                "mr-2",
              )}
            >
              <div
                className={classNames(
                  "w-4",
                  "h-4",
                  "origin-top-left",
                  "justify-center",
                  "justify-center",
                  "items-center",
                  "flex",
                )}
              >
                <div
                  className={classNames(
                    "w-4",
                    "h-4",
                    "relative",
                  )}
                >
                  <ArrowheadIcon />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "w-px",
                "bg-neutral-100",
                "ml-4",
              )}
              style={{ height: `${filterBodyHeight - 3}px` }}
            ></div>
          </div>
          <div
            ref={filterBodyRef}
            className={classNames(
              "flex",
              "flex-col",
              "w-full",
              "gap-2",
            )}
          >
            <div
              className={classNames(
                "flex",
                "flex-row",
                "justify-between",
                "w-full",
              )}
            >
              <div
                className={classNames(
                  "flex",
                  "flex-row",
                  "gap-2",
                  "items-center",
                )}
              >
                <div className={classNames("neutral-600")}>
                  Run when
                </div>
              </div>

              <CiroButton
                analyticsField="Delete Enrichment Step"
                style={CiroButtonStyleEnum.UNSTYLED}
              >
                <TrashIcon
                  className={classNames("w-5", "text-gray-400")}
                  onClick={() => deleteStep(stepIndex)}
                />
              </CiroButton>
            </div>
            <div
              className={classNames(
                "text-gray-500",
                "flex",
                "flex-col",
                "gap-2",
              )}
            >
              <EnrichmentStepSelector
                stepVariable={{
                  stepId:
                    filterEnrichmentStep.parentEnrichmentStepInputs[0]
                      .sourceEnrichmentStepId,
                  stepInput:
                    filterEnrichmentStep.parentEnrichmentStepInputs[0].input,
                }}
                setStepVariable={(newVariable) => {
                  setValue(
                    `filterEnrichmentSteps.${stepIndex}.parentEnrichmentStepInputs.${0}.sourceEnrichmentStepId`,
                    newVariable.stepId,
                  );
                  setValue(
                    `filterEnrichmentSteps.${stepIndex}.parentEnrichmentStepInputs.${0}.input`,
                    newVariable.stepInput,
                  );
                }}
                freeText={true}
                inputError={
                  errors.filterEnrichmentSteps?.[stepIndex]
                    ?.parentEnrichmentStepInputs?.[0]?.input?.message
                }
              />
              <CiroDropdownButton
                borderless
                customClassName={classNames(
                  "!bg-neutral-100",
                  "rounded",
                  "w-fit",
                )}
                customButtonClassName={classNames(
                  "w-max",
                  "!px-1",
                  "!py-1",
                )}
                hideChevron
                label={
                  enrichmentStepFilterDropdownOptions.filter((option) => {
                    return option.value === logicalOperator;
                  })[0]?.label
                }
              >
                {enrichmentStepFilterDropdownOptions.map((option) => {
                  return (
                    <CiroDropdownButtonItem
                      key={option.value}
                      onClick={() => {
                        const techniqueToEnrichmentStepMap = {
                          [EnrichmentTechniqueEnum.FiltersEquals]: {
                            name: "Is Equal",
                            additionalParentEnrichmentStepInputs: [
                              {
                                input: "",
                                key: "value2",
                                sourceEnrichmentStepId: null,
                              },
                            ],
                          },
                          [EnrichmentTechniqueEnum.FiltersIsNotNull]: {
                            name: "Exists",
                            additionalParentEnrichmentStepInputs: [],
                          },
                          [EnrichmentTechniqueEnum.FiltersIncludes]: {
                            name: "Includes",
                            additionalParentEnrichmentStepInputs: [
                              {
                                input: "[]",
                                key: "array",
                                sourceEnrichmentStepId: null,
                              },
                            ],
                          },
                          [EnrichmentTechniqueEnum.FiltersIsNull]: {
                            name: "Is null",
                            additionalParentEnrichmentStepInputs: [],
                          },
                        };
                        setLogicalOperator(option.value);

                        const newStepMapAttrs =
                          techniqueToEnrichmentStepMap[option.value];

                        setFilterEnrichmentStep({
                          name: newStepMapAttrs.name,
                          enrichment_technique: option.value,
                          parentEnrichmentStepInputs: [
                            filterEnrichmentStep.parentEnrichmentStepInputs[0],
                            ...newStepMapAttrs.additionalParentEnrichmentStepInputs,
                          ],
                        });
                      }}
                      analyticsField={"Selected logical operator"}
                    >
                      {option.label}
                    </CiroDropdownButtonItem>
                  );
                })}
              </CiroDropdownButton>
              {filterEnrichmentStep.enrichment_technique ===
                EnrichmentTechniqueEnum.FiltersEquals && (
                <EnrichmentStepFilterIsEqual
                  stepIndex={stepIndex}
                  filterEnrichmentStep={filterEnrichmentStep}
                />
              )}
              {filterEnrichmentStep.enrichment_technique ===
                EnrichmentTechniqueEnum.FiltersIncludes && (
                <EnrichmentStepFilterIncludes
                  stepIndex={stepIndex}
                  filterEnrichmentStep={filterEnrichmentStep}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrichmentStepFilter;
