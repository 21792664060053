import EnrichmentFlowTableDropdownOption from "./EnrichmentFlowTableDropdownOption";
import classNames from "classnames";
import SelectionListIcon from "../../../../assets/img/icons/SelectionListIcon";
import CiroTextInput from "../../../shared/CiroTextInput";
import { useContext, useState } from "react";
import EnrichmentFlowContext from "../../../../contexts/EnrichmentFlowContext";

const EnrichmentFlowTableSelectionOption = ({
  lowerBound,
  upperBound,
  setLowerBound,
  setUpperBound,
  handleKeypress,
  maxIndex,
}: {
  lowerBound: number | null;
  upperBound: number | null;
  setLowerBound: (v: number) => void;
  setUpperBound: (v: number) => void;
  handleKeypress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxIndex: number;
}) => {
  return (
    <div
      className={classNames(
        "ml-2",
        "flex",
        "flex-row",
        "grid-row",
        "gap-2",
        "items-center",
      )}
    >
      Select rows
      <div className={classNames("w-16")}>
        <CiroTextInput
          type="number"
          defaultValue={lowerBound !== null ? lowerBound + 1 : ""}
          error={lowerBound && lowerBound < 0 ? "Invalid number" : undefined}
          showErrorAsBorder
          onChange={(e) => {
            setLowerBound(Number(e.target.value) - 1);
          }}
          onClick={(e) => e.stopPropagation()}
          onKeyPress={handleKeypress}
        />
      </div>
      to
      <div className={classNames("w-16")}>
        <CiroTextInput
          type="number"
          defaultValue={upperBound ? upperBound + 1 : ""}
          error={
            (upperBound && upperBound < 0) ||
            (upperBound && upperBound > maxIndex) ||
            (lowerBound && upperBound && lowerBound > upperBound)
              ? "Invalid number"
              : undefined
          }
          showErrorAsBorder
          onChange={(e) => {
            setUpperBound(Number(e.target.value) - 1);
          }}
          onClick={(e) => e.stopPropagation()}
          onKeyPress={handleKeypress}
        />
      </div>
    </div>
  );
};

const EnrichmentFlowTableRangeDropdown = () => {
  const { selectedRows, setSelectedRows, enrichmentFlowRowIds } = useContext(
    EnrichmentFlowContext,
  );
  const maxIndex = enrichmentFlowRowIds.length - 1;
  const [lowerBound, setLowerBound] = useState<number | null>(
    selectedRows.tableIndexStart,
  );
  const [upperBound, setUpperBound] = useState<number | null>(
    selectedRows.tableIndexEnd,
  );
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      lowerBound !== null &&
      upperBound !== null &&
      lowerBound <= upperBound &&
      upperBound <= maxIndex
    ) {
      setSelectedRows({
        tableIndexStart: lowerBound,
        tableIndexEnd: upperBound,
      });
    }
  };

  return (
    <div
      className={classNames(
        "overflow-hidden",
        "text-neutral-400",
        "mr-2",
      )}
    >
      <EnrichmentFlowTableDropdownOption
        option={
          <EnrichmentFlowTableSelectionOption
            lowerBound={lowerBound}
            upperBound={upperBound}
            setLowerBound={setLowerBound}
            setUpperBound={setUpperBound}
            handleKeypress={handleKeyPress}
            maxIndex={maxIndex}
          />
        }
        onClick={() => {
          setSelectedRows({
            tableIndexStart: lowerBound,
            tableIndexEnd: upperBound,
          });
        }}
        Icon={SelectionListIcon}
      />
    </div>
  );
};

export default EnrichmentFlowTableRangeDropdown;
