import classNames from "classnames";
import NavLink from "./NavLink";
import {
  BriefcaseIcon,
  LockClosedIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import PuzzleIcon from "../../assets/img/icons/PuzzleIcon";
import DatabaseIcon from "../../assets/img/icons/DatabaseIcon";
import { NavigationContainer_PermissionsQuery } from "../../__generated__/graphql";
import BookmarkSearch from "../../assets/img/icons/BookmarkSearch";

interface INavigationLinksSection {
  fullWidth?: boolean;
  navData?: NavigationContainer_PermissionsQuery;
  stopImpersonating: () => void;
}

const NavigationLinksSection = ({
  fullWidth,
  navData,
  stopImpersonating,
}: INavigationLinksSection) => {
  const canViewEnrichments = navData?.permissions?.canViewEnrichments;
  const canViewCiroNumbers = navData?.permissions?.canViewCiroNumbers;
  const canViewAccountSearch = navData?.permissions?.canViewAccountSearch;
  const canViewCollections = navData?.permissions?.canViewCollections;
  const hasAdminPermissions = navData?.permissions?.hasAdminPermissions;

  return (
    <>
      <NavLink
        href="/"
        title="Home"
        fullWidth={fullWidth}
        icon={<Squares2X2Icon height="20" width="20" />}
      />
      <NavLink
        href="/autopilot"
        title="Autopilot"
        fullWidth={fullWidth}
        icon={<BookmarkSearch height="20" width="20" />}
      />
      {canViewEnrichments && (
        <NavLink
          href="/lists"
          title="Enrich CSV"
          fullWidth={fullWidth}
          icon={<PuzzleIcon height="20" width="20" />}
        />
      )}
      {canViewAccountSearch && (
        <NavLink
          href="/accounts"
          title="Accounts"
          fullWidth={fullWidth}
          icon={<BriefcaseIcon className={classNames("h-5")} />}
        />
      )}
      {canViewCollections && (
        <NavLink
          className="test-collections-link"
          href="/collections"
          additionalMatches={["/collections/:id"]}
          title="Collections"
          fullWidth={fullWidth}
          icon={<Square3Stack3DIcon className={classNames("h-5")} />}
        />
      )}
      {canViewCiroNumbers && (
        <NavLink
          href="/enrich-crm"
          title="Enrich CRM"
          fullWidth={fullWidth}
          icon={<DatabaseIcon height="20" width="20" />}
        />
      )}
      <NavLink
        href="/profile"
        title="Profile"
        fullWidth={fullWidth}
        icon={<UserCircleIcon className={classNames("h-5")} />}
      />
      {hasAdminPermissions && (
        <NavLink
          href="/admin"
          title="Admin"
          fullWidth={fullWidth}
          icon={<LockClosedIcon className={classNames("h-5")} />}
        />
      )}
    </>
  );
};

export default NavigationLinksSection;
