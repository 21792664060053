import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import { gql } from "@apollo/client";
import SkeletonLoading from "../shared/SkeletonLoading";
import TimeAgo from "javascript-time-ago";
import ChromeExtensionNumbersDownloadButton from "./ChromeExtensionNumbersDownloadButton";
import CiroLink from "../shared/CiroLink";
import { ChromeExtensionCard_ChromeExtensionTransactionsFragment } from "../../__generated__/graphql";
import pluralize from "pluralize";

export const ChromeExtensionCard_ChromeExtensionTransactions = gql`
  fragment ChromeExtensionCard_ChromeExtensionTransactions on Query {
    chromeExtensionPhoneNumberTransactions: phoneNumbersRequestTransactions(
      requestType: chrome_extension
    ) {
      transactions {
        id
        updated_at
        newNumbersCount
      }
    }
    chromeExtensionEmailTransactions: emailRequestTransactions(
      requestType: chrome_extension
    ) {
      transactions {
        id
        newEmailsCount
      }
    }
  }
`;

export const CHROME_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/ciro/poiofceiibpjdmhihnpaembimmnioifk?hl=en";

interface ChromeExtensionCardProps {
  chromeExtensionTransactions: ChromeExtensionCard_ChromeExtensionTransactionsFragment | null;
}

const ChromeExtensionCard = ({
  chromeExtensionTransactions,
}: ChromeExtensionCardProps) => {
  const formatLastEnrichedAt = (dateString: string): React.ReactNode => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    const isYesterday =
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();

    if (isToday || isYesterday) {
      const timeString = date
        .toLocaleTimeString([], {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .replace(/\s?([AP]M)$/, (match, p1) => p1.toLowerCase());

      let datePart: string;
      if (isToday) {
        datePart = "Today";
      } else {
        datePart = "Yesterday";
      }

      return (
        <>
          <span className="font-medium">{datePart}</span> at{" "}
          <span className="font-medium">{timeString}</span>
        </>
      );
    } else {
      const timeAgo = new TimeAgo("en-US");
      return timeAgo.format(date);
    }
  };

  const mostRecentTransaction =
    chromeExtensionTransactions?.chromeExtensionPhoneNumberTransactions?.transactions?.reduce(
      (latest, transaction) =>
        !latest || new Date(transaction.updated_at) > new Date(latest)
          ? transaction.updated_at
          : latest,
      null as string | null,
    );
  const newNumbersCount =
    chromeExtensionTransactions?.chromeExtensionPhoneNumberTransactions?.transactions?.reduce(
      (acc, transaction) => acc + transaction.newNumbersCount,
      0,
    );
  const newEmailsCount =
    chromeExtensionTransactions?.chromeExtensionEmailTransactions?.transactions?.reduce(
      (acc, transaction) => acc + transaction.newEmailsCount,
      0,
    );

  if (!chromeExtensionTransactions) {
    return (
      <div className={classNames("w-full", "p-5")}>
        <CiroTitle title="Chrome extension" size="small" />
        <SkeletonLoading numSkeletons={1} skeletonHeight="7rem" />
      </div>
    );
  }

  const hasChromeExtensionTransactions =
    chromeExtensionTransactions.chromeExtensionPhoneNumberTransactions
      .transactions.length > 0;

  if (!hasChromeExtensionTransactions) {
    return null;
  }

  return (
    <div className={classNames("w-full", "p-5")}>
      <div
        className={classNames(
          "flex",
          "gap-2",
          "items-center",
          "flex-row",
        )}
      >
        <CiroTitle title="Chrome extension" size="small" />
        <CiroLink href={CHROME_EXTENSION_URL} shouldOpenInNewTab></CiroLink>
      </div>
      <div
        className={classNames(
          "flex",
          "border-1",
          "rounded-xl",
          "justify-between",
          "bg-white",
          "text-neutral-700",
        )}
      >
        <div
          className={classNames(
            "w-full",
            "flex",
            "justify-between",
          )}
        >
          <div
            className={classNames(
              "flex",
              "h-28",
              "items-center",
            )}
          >
            <div className={classNames("ml-5")}>
              <div
                className={classNames(
                  "text-xl",
                  "text-neutral-700",
                )}
              >
                {newEmailsCount || 0}
              </div>
              <div
                className={classNames(
                  "text-sm",
                  "text-gray-500",
                )}
              >
                New {pluralize("email", newEmailsCount || 0)}
              </div>
            </div>
            <div className={classNames("ml-10")}>
              <div
                className={classNames(
                  "text-xl",
                  "text-neutral-700",
                )}
              >
                {newNumbersCount || 0}
              </div>
              <div
                className={classNames(
                  "text-sm",
                  "text-gray-500",
                )}
              >
                New {pluralize("number", newNumbersCount || 0)}
              </div>
            </div>
            <div className={classNames("ml-16")}>
              <div
                className={classNames(
                  "text-lg",
                  "text-neutral-700",
                )}
              >
                {mostRecentTransaction
                  ? formatLastEnrichedAt(mostRecentTransaction)
                  : "-"}
              </div>
              <div
                className={classNames(
                  "text-sm",
                  "text-gray-500",
                )}
              >
                Last enriched
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "flex",
              "items-center",
              "mr-5",
            )}
          >
            <ChromeExtensionNumbersDownloadButton
              ids={chromeExtensionTransactions.chromeExtensionPhoneNumberTransactions.transactions.map(
                (transaction) => transaction.id,
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChromeExtensionCard;
