import { gql, useLazyQuery } from "@apollo/client";
import {
  DownloadLinkLoadingBar_ScheduledExportStatusQuery,
  DownloadLinkLoadingBar_ScheduledExportStatusQueryVariables,
} from "../../../__generated__/graphql";
import { useEffect } from "react";
import Loading from "../Loading";
import classNames from "classnames";

interface IDownloadLinkLoadingBarProps {
  scheduleToken: string;
}

const DownloadLinkLoadingBar_ScheduledExportStatus = gql`
  query DownloadLinkLoadingBar_ScheduledExportStatus($scheduleToken: String!) {
    scheduledExportStatus(scheduleToken: $scheduleToken) {
      totalCompanies
      currentRow
    }
  }
`;

const DownloadLinkLoadingBar = ({
  scheduleToken,
}: IDownloadLinkLoadingBarProps) => {
  const [fetchExportStatus, { data: exportStatusData }] = useLazyQuery<
    DownloadLinkLoadingBar_ScheduledExportStatusQuery,
    DownloadLinkLoadingBar_ScheduledExportStatusQueryVariables
  >(DownloadLinkLoadingBar_ScheduledExportStatus);

  const scheduledExportStatus = exportStatusData?.scheduledExportStatus;
  const currentRow = scheduledExportStatus?.currentRow;
  const totalCompanies = scheduledExportStatus?.totalCompanies;

  let customFillPercentage = 0;
  if (totalCompanies && currentRow) {
    customFillPercentage = currentRow / totalCompanies;
  }

  useEffect(() => {
    const pollExportStatus = setInterval(() => {
      if (scheduleToken) {
        fetchExportStatus({
          variables: {
            scheduleToken,
          },
          fetchPolicy: "no-cache",
        });
      }
    }, 2000);

    return () => {
      clearInterval(pollExportStatus);
    };
  }, [fetchExportStatus, scheduleToken]);

  return (
    <div
      className={classNames(
        "flex",
        "flex-col",
        "items-center",
        "py-4",
      )}
    >
      <Loading size="SMALL" customFillPercentage={customFillPercentage} />
      <div className={classNames("text-sm", "text-gray-500")}>
        {Boolean(totalCompanies && currentRow) && (
          <div>
            Downloading {currentRow} of {totalCompanies} accounts...
          </div>
        )}
        {!(totalCompanies && currentRow) && <div>Preparing download...</div>}
      </div>
    </div>
  );
};

export default DownloadLinkLoadingBar;
