import classNames from "classnames";
interface ICiroPillProps {
  children: any;
  background?: string;
  fontColor?: string;
}

const CiroPill = ({
  children,
  background = "bg-gray-100",
  fontColor = "text-slate-700",
}: ICiroPillProps) => {
  return (
    <div
      className={classNames([
        "flex",
        "font-medium",
        "items-center",
        "px-2",
        "py-1",
        "rounded-full",
        "text-xs",
        "w-min",
        "whitespace-nowrap",
        background,
        fontColor,
      ])}
    >
      {children}
    </div>
  );
};

export default CiroPill;
