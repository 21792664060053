import { createContext } from "react";

interface IListContext {
  selectedContactIdsToOrgContactIds: Map<number, number>;
  setSelectedContactIdsToOrgContactIds: (ids: Map<number, number>) => void;
}

export const ListContext = createContext<IListContext>({
  selectedContactIdsToOrgContactIds: new Map(),
  setSelectedContactIdsToOrgContactIds: () => {},
});
export default ListContext;
