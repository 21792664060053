import { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import {
  CollectionsTable_CollectionsFragmentDoc,
  Collections_CollectionsQuery,
  Collections_CollectionsQueryVariables,
} from "../../__generated__/graphql";
import CiroContainer, {
  FULL_SCREEN_CONTAINER_CLASSES,
} from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import classNames from "classnames";
import CollectionsTable, {
  CollectionsTable_Collections,
} from "../../components/collections/CollectionsTable";
import CiroCTACard from "../../components/shared/CiroCTACard";
import NewCollectionBtnAndModal, {
  NewCollectionBtnAndModal_Permission,
} from "../../components/collections/NewCollectionBtnAndModal";
import { useFragment } from "../../__generated__";
import { useLocation } from "react-router-dom";
import { DownloadPicklist } from "../../components/shared/DownloadPicklist/DownloadPicklist";
import { downloadLinkStyles } from "../../components/shared/DownloadLink/DownloadLink";
import CiroNoRecordsCard from "../../components/shared/CiroNoRecordsCard";
import SkeletonLoading from "../../components/shared/SkeletonLoading";

// Exported to Refetch in NewCollectionBtnAndModal
// Exported to Refetch in EditCollectionModal
export const Collections_collections = gql`
  query Collections_collections {
    ...CollectionsTable_Collections
    permissions {
      canDownloadPicklist
      canViewCollections
      ...NewCollectionBtnAndModal_Permission
    }
  }
  ${CollectionsTable_Collections}
  ${NewCollectionBtnAndModal_Permission}
`;

const Collections = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const {
    refetch: refetchCollections,
    loading: collectionsLoading,
    data: collectionsData,
  } = useQuery<
    Collections_CollectionsQuery,
    Collections_CollectionsQueryVariables
  >(Collections_collections);

  useEffect(() => {
    refetchCollections();
  }, [location, refetchCollections]);

  const isLoading = !user || collectionsLoading || !collectionsData;
  const canViewCollections = collectionsData?.permissions?.canViewCollections;
  const canDownloadPicklist = collectionsData?.permissions?.canDownloadPicklist;

  const collectionsTableData = useFragment(
    CollectionsTable_CollectionsFragmentDoc,
    collectionsData,
  );

  if (isLoading) {
    return (
      <div className={classNames(FULL_SCREEN_CONTAINER_CLASSES)}>
        <SkeletonLoading
          containerCustomerClassName={classNames(
            "px-60",
            "pt-8",
          )}
          numSkeletons={1}
          skeletonHeight={"8rem"}
        />
        <SkeletonLoading
          containerCustomerClassName={classNames(
            "px-60",
            "py-16",
          )}
          numSkeletons={25}
          skeletonHeight={"3rem"}
        />
      </div>
    );
  }

  return (
    <CiroContainer className={classNames("bg-zinc-100")}>
      <div
        className={classNames(
          "mb-8",
          "text-left",
          "w-full",
        )}
      >
        <div
          className={classNames(
            "flex",
            "justify-between",
            "text-left",
          )}
        >
          <CiroTitle
            title="Collections"
            subtitle="Save lists of accounts to come back to later"
          />
          <div
            className={classNames(
              "space-x-1",
              "flex",
              "justify-end",
            )}
          >
            {canDownloadPicklist && (
              <div onClick={(event) => event.stopPropagation()}>
                <DownloadPicklist style={downloadLinkStyles.ICON} />
              </div>
            )}
            <NewCollectionBtnAndModal
              permissions={collectionsData.permissions}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "flex",
          "justify-center",
          "overflow-auto",
          "w-full",
        )}
      >
        <div className={classNames("max-w-4xl")}>
          {!canViewCollections && (
            <CiroCTACard
              title="Upgrade to get your own collections"
              subtitle="Upgrade your account to get access to collections, a selection of regularly refreshed, high-quality prospects curated just for your team"
            />
          )}
          {canViewCollections &&
            collectionsTableData &&
            collectionsTableData.collections.length > 0 && (
              <CollectionsTable collectionsData={collectionsTableData} />
            )}
          {canViewCollections && !collectionsTableData?.collections?.length && (
            <CiroNoRecordsCard
              title="No collections found"
              subtitle="Try creating a collection and adding some accounts to it."
            ></CiroNoRecordsCard>
          )}
        </div>
      </div>
    </CiroContainer>
  );
};

export default withAuthenticationRequired(Collections, {
  onRedirecting: () => {
    return <Loading />;
  },
});
