import CiroButton from "../../shared/CiroButton";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { gql } from "@apollo/client";
import { AutomationListHomeDownloadButton_ContactListFragment } from "../../../__generated__/graphql";
import useHandleDownloadContactList from "../../../reactHooks/automationList/useHandleDownloadContactList";
import CiroSpinner from "../../shared/CiroSpinner";

export const AutomationListHomeDownloadButton_ContactList = gql`
  fragment AutomationListHomeDownloadButton_ContactList on ContactList {
    id
    name
  }
`;

const AutomationListHomeDownloadButton = ({
  list,
  contactIds,
  showNumberSelected,
}: {
  list: AutomationListHomeDownloadButton_ContactListFragment | null;
  contactIds: number[];
  showNumberSelected: boolean;
}) => {
  const { handleDownloadContactList, isDownloading } =
    useHandleDownloadContactList();

  if (!list) {
    return null;
  }

  return (
    <CiroButton
      analyticsField="export-contacts"
      analyticsProps={{
        listId: list?.id,
      }}
      customPaddingY="py-1"
      disabled={isDownloading}
      onClick={() =>
        handleDownloadContactList({
          listId: list.id,
          listName: list.name,
          customContactIds: contactIds,
        })
      }
    >
      {isDownloading ? (
        <CiroSpinner loading={true} />
      ) : (
        <CloudArrowDownIcon
          className={classNames("w-5", "h-5")}
        />
      )}
      {showNumberSelected && (
        <div className={classNames("ml-2")}>{contactIds.length}</div>
      )}
    </CiroButton>
  );
};

export default AutomationListHomeDownloadButton;
