import { gql, useMutation, useQuery } from "@apollo/client";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../shared/CiroDropdownButton";
import { ClipboardDocumentIcon, UsersIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import CiroDropDown from "../shared/CiroDropdown";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CollectionSearchShareCollection_CollectionFragment,
  CollectionSearchShareCollection_PermissionsFragment,
  CollectionSearchShareCollection_TeamMembersQuery,
  CollectionSearchShareCollection_TeamMembersQueryVariables,
  CollectionSearchShareCollection_UpsertCollectionPermissionsMutation,
  CollectionSearchShareCollection_UpsertCollectionPermissionsMutationVariables,
  Maybe,
} from "../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import Loading from "../shared/Loading";
import { CollectionSearch_Collection } from "../../routes/collections/CollectionSearch";
import { UPGRADE_PLAN_FOR_FEATURE } from "../../utils/consts";

export enum UserCollectionRoleEnum {
  CAN_VIEW = 1,
  CAN_EDIT = 2,
  REMOVE = -1,
}

const USER_COLLECTION_ROLE_NUMBERS = Object.keys(UserCollectionRoleEnum)
  .filter((v) => Number(v))
  .map(Number);

const UserCollectionRoleEnumToCopy = {
  [UserCollectionRoleEnum.CAN_VIEW]: "Can view",
  [UserCollectionRoleEnum.CAN_EDIT]: "Can edit",
  [UserCollectionRoleEnum.REMOVE]: "Remove",
};

const CollectionSearchShareCollection_TeamMembers = gql`
  query CollectionSearchShareCollection_TeamMembers {
    teamMembers {
      id
      email
    }
  }
`;

export const CollectionSearchShareCollection_Collection = gql`
  fragment CollectionSearchShareCollection_Collection on Collection {
    id
    currentUserCollection {
      userAccount {
        id
      }
      role
    }
    sharedWith {
      userAccount {
        id
        email
      }
      role
    }
  }
`;

export const CollectionSearchShareCollection_Permissions = gql`
  fragment CollectionSearchShareCollection_Permissions on Permissions {
    canShareCollections
  }
`;

const CollectionSearchShareCollection_UpsertCollectionPermissions = gql`
  mutation CollectionSearchShareCollection_UpsertCollectionPermissions(
    $collectionId: Int!
    $changes: [UpsertCollectionPermissionsArgs!]!
  ) {
    upsertCollectionPermissions(
      collectionId: $collectionId
      changes: $changes
    ) {
      success
      message
    }
  }
`;

interface ICollectionSearchShareCollectionProps {
  collection?: Maybe<CollectionSearchShareCollection_CollectionFragment>;
  permissions?: Maybe<CollectionSearchShareCollection_PermissionsFragment>;
}

interface IUserRoles {
  [userAccountId: number]: UserCollectionRoleEnum;
}

const getInitialUserRoles = (
  collection?: Maybe<CollectionSearchShareCollection_CollectionFragment>,
) => {
  const userRoles = {} as IUserRoles;
  collection?.sharedWith.forEach((userCollection) => {
    userRoles[userCollection.userAccount.id] = userCollection.role;
  });
  return userRoles;
};

const CollectionSearchShareCollection = ({
  collection,
  permissions,
}: ICollectionSearchShareCollectionProps) => {
  const currentUserCollection = useMemo(
    () => collection?.currentUserCollection,
    [collection?.currentUserCollection],
  );
  const [newCollectionPermissionAction, setNewCollectionPermissionAction] =
    useState<UserCollectionRoleEnum>(UserCollectionRoleEnum.CAN_VIEW);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);

  const [currentUserRoles, setCurrentUserRoles] = useState<IUserRoles>(
    getInitialUserRoles(collection),
  );

  const { data: teamMemberData, loading: teamMemberLoading } = useQuery<
    CollectionSearchShareCollection_TeamMembersQuery,
    CollectionSearchShareCollection_TeamMembersQueryVariables
  >(CollectionSearchShareCollection_TeamMembers);

  const [
    upsertCollectionPermissions,
    {
      loading: upsertCollectionPermissionsLoading,
      data: upsertCollectionPermissionsData,
      reset: resetUpsertCollectionPermissions,
    },
  ] = useMutation<
    CollectionSearchShareCollection_UpsertCollectionPermissionsMutation,
    CollectionSearchShareCollection_UpsertCollectionPermissionsMutationVariables
  >(CollectionSearchShareCollection_UpsertCollectionPermissions);

  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (upsertCollectionPermissionsData?.upsertCollectionPermissions.success) {
      dropdownButtonRef.current?.click();
      resetUpsertCollectionPermissions();
    }
  });

  const handleUpdatePermissionsLevel = useCallback(
    ({
      userAccountId,
      role,
    }: {
      userAccountId: number;
      role: UserCollectionRoleEnum;
    }) => {
      setCurrentUserRoles({
        ...currentUserRoles,
        [userAccountId]: role,
      });
    },
    [currentUserRoles],
  );

  useEffect(() => {
    setCurrentUserRoles(getInitialUserRoles(collection));
  }, [collection]);

  const handleSubmit = useCallback(() => {
    const changes = [] as {
      userAccountId: number;
      role: UserCollectionRoleEnum;
    }[];
    Object.keys(currentUserRoles).forEach((userAccountId) => {
      changes.push({
        userAccountId: Number(userAccountId),
        role: currentUserRoles[Number(userAccountId)],
      });
    });
    selectedUsers.forEach((userAccountId) => {
      changes.push({
        userAccountId: Number(userAccountId),
        role: newCollectionPermissionAction,
      });
    });

    upsertCollectionPermissions({
      refetchQueries: [
        {
          query: CollectionSearch_Collection,
          variables: {
            collectionId: Number(collection?.id),
          },
        },
      ],
      variables: {
        collectionId: Number(collection?.id),
        changes,
      },
    });
  }, [
    collection?.id,
    currentUserRoles,
    newCollectionPermissionAction,
    selectedUsers,
    upsertCollectionPermissions,
  ]);

  if (
    !collection ||
    currentUserCollection?.role !== UserCollectionRoleEnum.CAN_EDIT
  ) {
    return null;
  }

  const dropdownOptions =
    teamMemberData?.teamMembers
      .filter((teamMember) => {
        return !collection.sharedWith.some(
          (userCollection) => userCollection.userAccount.id === teamMember.id,
        );
      })
      .map((teamMember) => ({
        label: teamMember.email,
        value: teamMember.id.toString(),
      })) || [];

  return (
    <CiroTooltipContainer
      tooltip={UPGRADE_PLAN_FOR_FEATURE}
      disabled={Boolean(permissions?.canShareCollections)}
    >
      <CiroDropdownButton
        buttonRef={dropdownButtonRef}
        isPopover={true}
        label={
          <span className="flex">
            <span className="mr-4">
              <UsersIcon className={classNames("h-5")} />
            </span>
            Share Collection
          </span>
        }
        menuPosition="left"
        disabled={!permissions?.canShareCollections}
      >
        <div className={classNames("p-4")}>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "w-96",
            )}
          >
            <div className={classNames("pr-2", "w-full")}>
              {teamMemberLoading && <Loading size="SMALL" />}
              {!teamMemberLoading && (
                <CiroDropDown
                  isMulti
                  placeholder="Share with others"
                  options={dropdownOptions}
                  value={selectedUsers}
                  onChange={(newUsers) => setSelectedUsers(newUsers)}
                />
              )}
            </div>
            <CiroDropdownButton
              customClassName="w-48"
              label={
                UserCollectionRoleEnumToCopy[newCollectionPermissionAction]
              }
            >
              {USER_COLLECTION_ROLE_NUMBERS.map((userCollectionRoleNumber) => {
                if (
                  userCollectionRoleNumber === UserCollectionRoleEnum.REMOVE
                ) {
                  return null;
                }
                return (
                  <CiroDropdownButtonItem
                    key={userCollectionRoleNumber}
                    onClick={() =>
                      setNewCollectionPermissionAction(userCollectionRoleNumber)
                    }
                    analyticsField={`Selected ${userCollectionRoleNumber} for collection permissions`}
                  >
                    {
                      UserCollectionRoleEnumToCopy[
                        userCollectionRoleNumber as UserCollectionRoleEnum
                      ]
                    }
                  </CiroDropdownButtonItem>
                );
              })}
            </CiroDropdownButton>
          </div>
          <div
            className={classNames(
              "pt-4",
              "text-md",
              "font-semibold",
            )}
          >
            Current Access
          </div>
          <div
            className={classNames("h-48", "overflow-y-auto")}
          >
            {collection.sharedWith.length === 1 && <div>Just you</div>}
            {collection.sharedWith.map((userCollection) => {
              if (
                userCollection.userAccount.id ===
                currentUserCollection.userAccount.id
              ) {
                return null;
              }
              const userRole = currentUserRoles[
                userCollection.userAccount.id
              ] as UserCollectionRoleEnum;
              return (
                <div
                  key={userCollection.userAccount.id}
                  className={classNames(
                    "border-b-1",
                    "border-gray-200",
                    "flex",
                    "items-center",
                    "justify-between",
                    "py-3",
                    "w-full",
                  )}
                >
                  <div className={classNames("font-medium")}>
                    {userCollection.userAccount.email}
                  </div>
                  <CiroDropdownButton
                    borderless
                    label={UserCollectionRoleEnumToCopy[userRole]}
                  >
                    {USER_COLLECTION_ROLE_NUMBERS.map(
                      (userCollectionRoleNumber) => {
                        return (
                          <CiroDropdownButtonItem
                            key={userCollectionRoleNumber}
                            onClick={() =>
                              handleUpdatePermissionsLevel({
                                userAccountId: userCollection.userAccount.id,
                                role: userCollectionRoleNumber as UserCollectionRoleEnum,
                              })
                            }
                            analyticsField={`Selected ${userCollectionRoleNumber} for collection permissions`}
                          >
                            {
                              UserCollectionRoleEnumToCopy[
                                userCollectionRoleNumber as UserCollectionRoleEnum
                              ]
                            }
                          </CiroDropdownButtonItem>
                        );
                      },
                    )}
                  </CiroDropdownButton>
                </div>
              );
            })}
          </div>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "items-center",
            )}
          >
            <CiroTooltipContainer visible={showCopiedTooltip} tooltip="Copied!">
              <CiroButton
                analyticsField="Copy collection link"
                style={CiroButtonStyleEnum.UNSTYLED}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setShowCopiedTooltip(true);
                  setTimeout(() => {
                    setShowCopiedTooltip(false);
                  }, 1000);
                }}
              >
                <div
                  className={classNames(
                    "flex",
                    "items-center",
                    "text-orange-400",
                    "text-xs",
                  )}
                >
                  <ClipboardDocumentIcon
                    className={classNames("h-5", "mr-2")}
                  />
                  Copy Link
                </div>
              </CiroButton>
            </CiroTooltipContainer>
            {upsertCollectionPermissionsLoading && <Loading size="SMALL" />}
            {!upsertCollectionPermissionsLoading && (
              <CiroButton
                style={CiroButtonStyleEnum.LOUD}
                analyticsField={"Submit share collection"}
                onClick={handleSubmit}
              >
                Save
              </CiroButton>
            )}
          </div>
        </div>
      </CiroDropdownButton>
    </CiroTooltipContainer>
  );
};

export default CollectionSearchShareCollection;
