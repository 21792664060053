import { gql, useMutation, useQuery } from "@apollo/client";
import {
  EnrichmentFlowFromCollectionModal_CollectionsFilterQuery,
  EnrichmentFlowFromCollectionModal_CollectionsFilterQueryVariables,
  EnrichmentFlowFromCollectionModal_CreateFlowMutation,
  EnrichmentFlowFromCollectionModal_CreateFlowMutationVariables,
  EnrichmentFlowFromCollectionModal_PermissionsFragment,
} from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import { useState } from "react";
import CiroModal from "../shared/CiroModal";
import CiroTextInput from "../shared/CiroTextInput";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import Loading from "../shared/Loading";
import { useNavigate } from "react-router-dom";
import CiroTemplateModal from "../shared/CiroTemplateModal";

export const EnrichmentFlowFromCollectionModal_Permissions = gql`
  fragment EnrichmentFlowFromCollectionModal_Permissions on Permissions {
    enrichmentAllowedRowLimit
  }
`;

export const EnrichmentFlowFromCollectionModal_CreateFlow = gql`
  mutation EnrichmentFlowFromCollectionModal_CreateFlow(
    $initializationCollection: Int
    $name: String!
    $template: String
  ) {
    createEnrichmentFlow(
      initializationCollection: $initializationCollection
      name: $name
      template: $template
    ) {
      success
      truncated
      enrichmentFlow {
        id
      }
      error
    }
  }
`;

export const EnrichmentFlowFromCollectionModal_CollectionsFilter = gql`
  query EnrichmentFlowFromCollectionModal_CollectionsFilter {
    collections {
      id
      name
    }
  }
`;

enum EnrichmentFlowFronCollectionModalViewEnum {
  SELECT_COLLECTION,
  ENRICHMENT_CREATE_ATTEMPTED,
}

interface ICollectionToEnrichmentCardProps {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  onClose: () => void;
  clickedCollection: number | null;
  permissions?: EnrichmentFlowFromCollectionModal_PermissionsFragment | null;
}

const EnrichmentFlowFromCollectionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  clickedCollection,
  permissions,
}: ICollectionToEnrichmentCardProps) => {
  const [selectedCollection, setSelectedCollection] = useState<number | null>(
    clickedCollection,
  );
  const [name, setName] = useState("");
  const [nameByCollectionName, setNameByCollectionName] = useState(
    clickedCollection ? true : false,
  );
  const [templateSelected, setTemplateSelected] = useState<string | null>(null);
  const [templateSelectorOpen, setTemplateSelectorOpen] = useState(true);
  const [nameSelectorOpen, setNameSelectorOpen] = useState(false);
  let navigate = useNavigate();
  const [view, setView] = useState<EnrichmentFlowFronCollectionModalViewEnum>(
    EnrichmentFlowFronCollectionModalViewEnum.SELECT_COLLECTION,
  );
  const { data: collectionFiltersData } = useQuery<
    EnrichmentFlowFromCollectionModal_CollectionsFilterQuery,
    EnrichmentFlowFromCollectionModal_CollectionsFilterQueryVariables
  >(EnrichmentFlowFromCollectionModal_CollectionsFilter, {
    onCompleted: () => {
      setName(
        clickedCollection
          ? collectionOptions.find(
              (option) => option.value === clickedCollection,
            )?.label + " enrichment"
          : "",
      );
    },
  });

  const savedCollections = collectionFiltersData?.collections || [];
  const collectionOptions = savedCollections.map((collection) => {
    return {
      value: Number(collection?.id),
      label: String(collection?.name),
    };
  });

  const templateNav = () => {
    return `${templateSelected ? `?template=${templateSelected}` : ""}`;
  };

  const [createEnrichment, { data, loading, error }] = useMutation<
    EnrichmentFlowFromCollectionModal_CreateFlowMutation,
    EnrichmentFlowFromCollectionModal_CreateFlowMutationVariables
  >(EnrichmentFlowFromCollectionModal_CreateFlow);

  const attemptCreateEnrichment = () => {
    createEnrichment({
      variables: {
        name,
        initializationCollection: selectedCollection,
        template: templateSelected,
      },
    });
    setView(
      EnrichmentFlowFronCollectionModalViewEnum.ENRICHMENT_CREATE_ATTEMPTED,
    );
    onClose();
  };

  return (
    <>
      <CiroTemplateModal
        isOpen={templateSelectorOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        setTemplate={setTemplateSelected}
        onTemplateSelected={() => {
          setTemplateSelectorOpen(false);
          setNameSelectorOpen(true);
        }}
      />
      <CiroModal
        isOpen={nameSelectorOpen}
        onClose={() => {
          setNameSelectorOpen(false);
          setTemplateSelectorOpen(true);
          setIsOpen(false);
        }}
      >
        {loading && <Loading size="SMALL" />}
        {!loading &&
          view ===
            EnrichmentFlowFronCollectionModalViewEnum.SELECT_COLLECTION && (
            <>
              <CiroDropDown
                label="Collection"
                options={collectionOptions}
                value={selectedCollection}
                onChange={(newValue) => {
                  setSelectedCollection(newValue);
                  if (name === "" || nameByCollectionName) {
                    const newName = collectionOptions.find(
                      (option) => option.value === newValue,
                    )?.label;
                    setName(newName ? newName + " enrichment" : "");
                    setNameByCollectionName(true);
                  }
                }}
              />
              <div className={classNames("pt-4")}>
                <CiroTextInput
                  label="Name for the new enrichment table"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameByCollectionName(false);
                  }}
                />
              </div>
              <div
                className={classNames(
                  "flex",
                  "justify-between",
                  "mt-10",
                )}
              >
                <CiroButton
                  analyticsField="Cancel create enrichment with existing collection"
                  onClick={() => {
                    setNameSelectorOpen(false);
                    setTemplateSelectorOpen(true);
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </CiroButton>
                <CiroButton
                  analyticsField="Enrichment created with existing collection"
                  style={CiroButtonStyleEnum.LOUD}
                  onClick={attemptCreateEnrichment}
                >
                  Create Enrichment
                </CiroButton>
              </div>
            </>
          )}
        {!loading &&
          view ===
            EnrichmentFlowFronCollectionModalViewEnum.ENRICHMENT_CREATE_ATTEMPTED && (
            <div>
              {error
                ? "Error while creating flow, please refresh and try again"
                : "Successfully created " + name}
              <div
                className={classNames("float-right", "mt-10")}
              >
                <CiroButton
                  analyticsField="Enrichment created modal closed"
                  style={CiroButtonStyleEnum.LOUD}
                  onClick={() => {
                    navigate(
                      `/lists/${data?.createEnrichmentFlow?.enrichmentFlow?.id}${
                        data?.createEnrichmentFlow?.truncated
                          ? `?truncated=${permissions?.enrichmentAllowedRowLimit}&`
                          : ""
                      }${templateNav()}`,
                    );
                  }}
                >
                  OK
                </CiroButton>
              </div>
            </div>
          )}
      </CiroModal>
    </>
  );
};

export default EnrichmentFlowFromCollectionModal;
