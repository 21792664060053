import React from "react";
import classNames from "classnames";
import { gql } from "@apollo/client";
import {
  BusinessDetailsOpenDays_CompanyFragment,
  OpenDayOptions,
} from "../../__generated__/graphql";
import CiroPill from "../shared/CiroPill";
import { ClockIcon } from "@heroicons/react/24/outline";

const daysOfTheWeek = [
  { value: OpenDayOptions.Monday, label: "Mon" },
  { value: OpenDayOptions.Tuesday, label: "Tue" },
  { value: OpenDayOptions.Wednesday, label: "Wed" },
  { value: OpenDayOptions.Thursday, label: "Thu" },
  { value: OpenDayOptions.Friday, label: "Fri" },
  { value: OpenDayOptions.Saturday, label: "Sat" },
  { value: OpenDayOptions.Sunday, label: "Sun" },
];

export const BusinessDetailsOpenDays_Company = gql`
  fragment BusinessDetailsOpenDays_Company on Company {
    open_days
  }
`;

interface IBusinessDetailsOpenDaysProps {
  company: BusinessDetailsOpenDays_CompanyFragment;
}

const BusinessDetailsOpenDays = ({
  company,
}: IBusinessDetailsOpenDaysProps) => {
  if (!company?.open_days) {
    return null;
  }

  return (
    <div className={classNames("pt-1")}>
      <div
        className={classNames([
          "flex",
          "flex-row",
          "mt-2",
          "items-center",
        ])}
      >
        <ClockIcon
          className={classNames([
            "mr-2",
            "text-gray-400",
            "w-6",
          ])}
        />
        {daysOfTheWeek.map((day) => {
          if (company.open_days?.includes(day.value)) {
            return (
              <div className={classNames(["mx-2"])}>
                <CiroPill>{day.label}</CiroPill>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default BusinessDetailsOpenDays;
