import classNames from "classnames";

interface ICiroPillTagProps {
  text: any;
  className?: string;
  color?: string;
}

const CiroPillTag = ({
  text,
  className,
  color = "text-stone-500",
}: ICiroPillTagProps) => {
  return (
    <div
      className={classNames([
        "border-1",
        "border-gray-400",
        "font-medium",
        "px-2",
        "rounded-full",
        "text-sm",
        "truncate",
        className,
        color,
      ])}
    >
      {text}
    </div>
  );
};

export default CiroPillTag;
