import classNames from "classnames";
import ApolloIcon from "../../../assets/img/icons/ApolloIcon";
import ConnectNewIntegrationOption from "./integrationOption/ConnectNewIntegrationOption";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SalesforceIcon from "../../../assets/img/icons/SalesforceIcon";
import { gql } from "@apollo/client";
import {
  CrmChooseIntegration_UserAccountFragment,
  IntegrationEnum,
  MergeIntegrationEnum,
} from "../../../__generated__/graphql";
import HubspotIcon from "../../../assets/img/icons/HubspotIcon";
import CiroModal from "../../shared/CiroModal";
import { IntegrationModalPhases } from "../manageIntegrationButton/ManageIntegrationsButton";
import { useState } from "react";
import RemoveIntegrationModal from "../RemoveIntegrationModal";
import ManageExistingIntegrationOption from "./integrationOption/ManageExistingIntegrationOption";

interface ICrmChooseIntegrationProps {
  connectedIntegrations: IntegrationEnum[];
  setCrmIntegrationPhase: (phase: IntegrationModalPhases) => void;
  setTargetMergeIntegration: (integration: MergeIntegrationEnum | null) => void;
  userAccount: CrmChooseIntegration_UserAccountFragment;
  refetchUserAccount: () => void;
}

export const CrmChooseIntegration_UserAccount = gql`
  fragment CrmChooseIntegration_UserAccount on UserAccount {
    id
    mergeLinkTokens {
      linkToken
      integration
    }
    hasCrmIntegrationEnabled
    org {
      organizationMergeIntegration {
        integration
        last_matched_at
      }
    }
  }
`;

const ChooseIntegration = ({
  connectedIntegrations,
  setCrmIntegrationPhase,
  setTargetMergeIntegration: setTargetIntegration,
  userAccount,
  refetchUserAccount,
}: ICrmChooseIntegrationProps) => {
  const onClose = () => {
    setCrmIntegrationPhase(IntegrationModalPhases.GET_STARTED);
  };
  const [showRemoveIntegrationModal, setShowRemoveIntegrationModal] =
    useState(false);
  const [integrationToRemove, setIntegrationToRemove] =
    useState<IntegrationEnum | null>(null);

  const integrationDetails = [
    {
      icon: <ApolloIcon />,
      integrationName: "Apollo",
      onClick: () =>
        setCrmIntegrationPhase(IntegrationModalPhases.APOLLO_API_KEY),
      onRemove: () => {
        setIntegrationToRemove(IntegrationEnum.Apollo);
        setShowRemoveIntegrationModal(true);
      },
    },
    {
      icon: <SalesforceIcon />,
      integrationName: "Salesforce",
      onClick: () => {
        setTargetIntegration(MergeIntegrationEnum.Salesforce);
        setCrmIntegrationPhase(IntegrationModalPhases.MERGE_MODAL);
      },
      onRemove: () => {
        setIntegrationToRemove(IntegrationEnum.Salesforce);
        setShowRemoveIntegrationModal(true);
      },
    },
    {
      icon: <HubspotIcon />,
      integrationName: "HubSpot",
      onClick: () => {
        setTargetIntegration(MergeIntegrationEnum.HubSpot);
        setCrmIntegrationPhase(IntegrationModalPhases.MERGE_MODAL);
      },
      onRemove: () => {
        setIntegrationToRemove(IntegrationEnum.HubSpot);
        setShowRemoveIntegrationModal(true);
      },
    },
  ] as {
    icon: JSX.Element;
    integrationName: IntegrationEnum;
    onClick: () => void;
    onRemove?: () => void;
  }[];

  const safeToConnectNewIntegration = (integration: IntegrationEnum) => {
    switch (integration) {
      case IntegrationEnum.Apollo:
        return true;
      default:
        return !userAccount?.org?.organizationMergeIntegration;
    }
  }

  return (
    <>
      <CiroModal isOpen={true} onClose={onClose}>
        <div className={classNames("mb-5")}>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "items-center",
            )}
          >
            <div
              className={classNames(
                "text-xl",
                "font-bold",
                "pb-2",
                "text-neutral-900",
              )}
            >
              {connectedIntegrations.length > 0
                ? "Manage integrations"
                : "Choose integration"}
            </div>
            <div>
              <CiroButton
                style={CiroButtonStyleEnum.UNSTYLED}
                analyticsField="Close Choose Integration Modal"
                onClick={() => onClose()}
              >
                <XMarkIcon
                  className={classNames("h-6", "w-6")}
                />
              </CiroButton>
            </div>
          </div>
          <div
            className={classNames(
              "text-md",
              "text-neutral-900",
            )}
          >
            {connectedIntegrations.length > 0
              ? "Manage your integrations or add a new one."
              : "Choose a platform to connect to Ciro."}
          </div>
          {integrationDetails.map((integration) =>
            connectedIntegrations.includes(integration.integrationName) ? (
              <ManageExistingIntegrationOption
                key={integration.integrationName}
                icon={integration.icon}
                integrationName={integration.integrationName}
                onRemove={integration.onRemove}
              />
            ) : (
              <>
                  {safeToConnectNewIntegration(integration.integrationName) && <ConnectNewIntegrationOption
                    key={integration.integrationName}
                    icon={integration.icon}
                    integrationName={integration.integrationName}
                    onClick={integration.onClick}
                  />}
              </>
            ),
          )}
        </div>
      </CiroModal>
      {showRemoveIntegrationModal && integrationToRemove && (
        <RemoveIntegrationModal
          integration={integrationToRemove}
          onClose={() => setShowRemoveIntegrationModal(false)}
          refetchUserAccount={refetchUserAccount}
        />
      )}
    </>
  );
};

export default ChooseIntegration;
