import { useEffect } from "react";
import classNames from "classnames";
import Loading from "../Loading";
import { gql, useLazyQuery } from "@apollo/client";
import {
  CiroCollectionUpsertStatusFetcher_CollectionJobStatusQuery,
  CiroCollectionUpsertStatusFetcher_CollectionJobStatusQueryVariables,
  CreateUpsertCollectionJobResponse,
  JobStateEnum,
} from "../../../__generated__/graphql";
import pluralize from "pluralize";
import CiroButton from "../CiroButton";
import NumberFormat from "react-number-format";

const CiroCollectionUpsertStatusFetcher_CollectionJobStatus = gql`
  query CiroCollectionUpsertStatusFetcher_CollectionJobStatus($jobId: String!) {
    collectionJobStatus(jobId: $jobId) {
      state
      details {
        collectionId
        numAdded
        numProcessed
        numTotal
      }
    }
  }
`;

const FINISHED_JOB_STATES = new Set<JobStateEnum | null | undefined>([
  JobStateEnum.Completed,
  JobStateEnum.Failed,
]);

interface ICiroCollectionUpsertStatusFetcherProps {
  collectionName: string;
  completedText: string;
  preparingText: string;
  upsertJobCollectionResponse: CreateUpsertCollectionJobResponse;
  setCheckedCompanies?: (v: Set<string>) => void;
  setDoneUpserting?: (v: boolean) => void;
}

const CiroCollectionUpsertStatusFetcher = ({
  collectionName,
  completedText,
  preparingText,
  upsertJobCollectionResponse,
  setCheckedCompanies,
  setDoneUpserting
}: ICiroCollectionUpsertStatusFetcherProps) => {
  const [fetchCsvUploadStatus, { data: collectionJobStatusData }] =
    useLazyQuery<
      CiroCollectionUpsertStatusFetcher_CollectionJobStatusQuery,
      CiroCollectionUpsertStatusFetcher_CollectionJobStatusQueryVariables
    >(CiroCollectionUpsertStatusFetcher_CollectionJobStatus);

  const collectionJobStatusDetails =
    collectionJobStatusData?.collectionJobStatus?.details;
  const collectionJobStatusVal =
    collectionJobStatusData?.collectionJobStatus?.state;
  const doneProcessing = FINISHED_JOB_STATES.has(collectionJobStatusVal);
  const numAdded = collectionJobStatusDetails?.numAdded;
  const numProcessed = collectionJobStatusDetails?.numProcessed;
  const numTotal = collectionJobStatusDetails?.numTotal;

  let customFillPercentage = 0;
  if (numTotal && numProcessed) {
    customFillPercentage = numProcessed / numTotal;
  }

  useEffect(() => {
    if (
      doneProcessing &&
      collectionJobStatusVal === JobStateEnum.Completed
    ) {
      setDoneUpserting?.(true);
    }

    const { jobId } = upsertJobCollectionResponse;
    if (!jobId) {
      return;
    }

    const pollCsvUploadStatus = setInterval(() => {
      const collectionJobStatusVal =
        collectionJobStatusData?.collectionJobStatus?.state;
      const doneProcessing = FINISHED_JOB_STATES.has(collectionJobStatusVal);

      if (!doneProcessing) {
        fetchCsvUploadStatus({
          variables: {
            jobId,
          },
          fetchPolicy: "no-cache",
        });
      }
    }, 2000);

    return () => {
      clearInterval(pollCsvUploadStatus);
    };
  }, [collectionJobStatusData?.collectionJobStatus?.state, collectionJobStatusVal, doneProcessing, fetchCsvUploadStatus, setDoneUpserting, upsertJobCollectionResponse]);

  return (
    <div>
      <div className={classNames("py-4", "text-slate-600")}>
        {!collectionJobStatusDetails && <span>{preparingText}</span>}
        {collectionJobStatusDetails && (
          <div>
            Added{" "}
            <NumberFormat
              value={numAdded}
              thousandSeparator={true}
              displayType="text"
            />{" "}
            of{" "}
            <NumberFormat
              value={numProcessed}
              thousandSeparator={true}
              displayType="text"
            />{" "}
            processed {pluralize("account", numAdded)} to "{collectionName}"
          </div>
        )}
      </div>
      {!doneProcessing && (
        <Loading size="SMALL" customFillPercentage={customFillPercentage} />
      )}
      {doneProcessing && (
        <div>
          {collectionJobStatusVal === JobStateEnum.Failed && (
            <div className={classNames("text-red-700")}>
              Something went wrong
            </div>
          )}
          {collectionJobStatusVal === JobStateEnum.Completed && (
            <div>
              <div className={classNames("text-green-600")}>
                {completedText}
              </div>
              <div
                className={classNames(
                  "flex",
                  "justify-end",
                  "pt-4",
                )}
              >
                <CiroButton
                  analyticsField="View Collection From Collection Upsert"
                  href={`/collections/${collectionJobStatusDetails?.collectionId}`}
                  onClick={() => {
                    setCheckedCompanies?.(new Set());
                  }}
                >
                  View Collection
                </CiroButton>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CiroCollectionUpsertStatusFetcher;
