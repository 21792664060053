import classNames from "classnames";
import { Link } from "react-router-dom";

export enum CiroTableCellAlignEnum {
  CENTER,
  LEFT,
  RIGHT,
}

interface ICiroTableCellProps {
  align?: CiroTableCellAlignEnum;
  children: any;
  className?: string;
  colSpan?: number;
  href?: string;
  onRowClick?: () => void;
  lastLeft?: boolean;
  lastRight?: boolean;
  isLastRow?: boolean;
  padding?: number;
  useFlex?: boolean;
  minWidth?: string;
}

const CiroTableCell = ({
  align = CiroTableCellAlignEnum.LEFT,
  children,
  className,
  colSpan = 1,
  href,
  onRowClick,
  lastLeft = false,
  lastRight = false,
  isLastRow = false,
  padding = 4,
  useFlex = false,
  minWidth,
}: ICiroTableCellProps) => {
  return (
    <td
      align={align as any}
      colSpan={colSpan}
      className={classNames(
        "font-normal",
        "table-cell",
        "text-gray-500",
        "border-x-0",
        "text-sm",
        className,
        {
          "items-center": align === CiroTableCellAlignEnum.CENTER,
          "text-center": align === CiroTableCellAlignEnum.CENTER,
          "text-left": align === CiroTableCellAlignEnum.LEFT,
          "text-right": align === CiroTableCellAlignEnum.RIGHT,
          "items-right": align === CiroTableCellAlignEnum.RIGHT,
          "justify-end": align === CiroTableCellAlignEnum.RIGHT,
        },
        {
          "rounded-bl-lg": lastLeft,
          "rounded-br-lg": lastRight,
          "border-b-1": !isLastRow,
        },
      )}
    >
      {href ? (
        <Link to={href}>
          <div
            style={{ minWidth: minWidth }}
            className={classNames(`p-${padding}`, {
              "flex": useFlex,
              "justify-end": align === CiroTableCellAlignEnum.RIGHT,
              "items-center": align === CiroTableCellAlignEnum.CENTER,
            })}
          >
            {children}
          </div>
        </Link>
      ) : (
        <div
          onClick={() => {
            onRowClick?.();
          }}
          style={{ minWidth: minWidth }}
          className={classNames(`p-${padding}`, {
            "flex": useFlex,
            "justify-end": align === CiroTableCellAlignEnum.RIGHT,
            "items-center": align === CiroTableCellAlignEnum.CENTER,
          })}
        >
          {children}
        </div>
      )}
    </td>
  );
};

export default CiroTableCell;
