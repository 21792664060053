import classNames from "classnames";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../shared/CiroDropdownButton";
import {
  ArrowRightOnRectangleIcon,
  ClipboardDocumentIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import EnrichmentExecutionDetailsContext from "../../../contexts/EnrichmentExecutionDetailsContext";
import { useContext } from "react";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";
import { gql, useMutation } from "@apollo/client";
import {
  EnrichmentExecutionDetailsNode_CreateEnrichmentStepMutation,
  EnrichmentExecutionDetailsNode_CreateEnrichmentStepMutationVariables,
  EnrichmentExecutionDetailsNode_RunEnrichmentFlowMutation,
  EnrichmentExecutionDetailsNode_RunEnrichmentFlowMutationVariables,
  EnrichmentExecutionDetailsNode_UpdateEnrichmentStepMutation,
  EnrichmentExecutionDetailsNode_UpdateEnrichmentStepMutationVariables,
  EnrichmentTechniqueEnum,
} from "../../../__generated__/graphql";
import toast from "react-hot-toast";

const EnrichmentExecutionDetailsNode_CreateEnrichmentStep = gql`
  mutation EnrichmentExecutionDetailsNode_CreateEnrichmentStep(
    $enrichment_flow_id: Int!
    $input: UpsertEnrichmentStepInput!
  ) {
    createEnrichmentStep(
      enrichment_flow_id: $enrichment_flow_id
      input: $input
    ) {
      id
    }
  }
`;

const EnrichmentExecutionDetailsNode_UpdateEnrichmentStep = gql`
  mutation EnrichmentExecutionDetailsNode_UpdateEnrichmentStep(
    $id: Int
    $enrichmentStepInput: UpsertEnrichmentStepInput!
  ) {
    updateEnrichmentStep(id: $id, enrichmentStepInput: $enrichmentStepInput) {
      id
    }
  }
`;

const EnrichmentExecutionDetailsNode_RunEnrichmentFlow = gql`
  mutation EnrichmentExecutionDetailsNode_RunEnrichmentFlow(
    $input: RunEnrichmentFlowInput!
  ) {
    runEnrichmentFlow(input: $input) {
      success
      jobId
    }
  }
`;

interface IEnrichmentExecutionDetailsNodeProps {
  data: any;
  currentPath: string[];
  isCollapsed: boolean;
  isCollapsible: boolean;
  jsonObjectkey: string;
  toggleCollapsed: () => void;
}

const EnrichmentExecutionDetailsNode = ({
  data,
  currentPath,
  isCollapsed,
  isCollapsible,
  jsonObjectkey,
  toggleCollapsed,
}: IEnrichmentExecutionDetailsNodeProps) => {
  const { enrichmentStepId, onClose } = useContext(
    EnrichmentExecutionDetailsContext,
  );
  const {
    enrichmentFlowId,
    EnrichmentFlow_refetchEnrichmentFlow,
    setLoadingStepIds,
    loadingStepIds,
    setRunningJobId,
    setEnrichmentFlowLoading,
  } = useContext(EnrichmentFlowContext);

  const [createEnrichmentStep] = useMutation<
    EnrichmentExecutionDetailsNode_CreateEnrichmentStepMutation,
    EnrichmentExecutionDetailsNode_CreateEnrichmentStepMutationVariables
  >(EnrichmentExecutionDetailsNode_CreateEnrichmentStep);

  const [updateEnrichmentStep] = useMutation<
    EnrichmentExecutionDetailsNode_UpdateEnrichmentStepMutation,
    EnrichmentExecutionDetailsNode_UpdateEnrichmentStepMutationVariables
  >(EnrichmentExecutionDetailsNode_UpdateEnrichmentStep);

  const [runEnrichmentFlow] = useMutation<
    EnrichmentExecutionDetailsNode_RunEnrichmentFlowMutation,
    EnrichmentExecutionDetailsNode_RunEnrichmentFlowMutationVariables
  >(EnrichmentExecutionDetailsNode_RunEnrichmentFlow);

  return (
    <CiroDropdownButton
      hideChevron
      customClassName={classNames("text-orange-500")}
      menuPosition="right"
      customPadding={classNames("px-1")}
      label={`"${jsonObjectkey}"`}
    >
      {isCollapsible && (
        <CiroDropdownButtonItem
          onClick={toggleCollapsed}
          analyticsField={"Toggle collapse for json in executiondetails"}
        >
          <div className={classNames("flex")}>
            {isCollapsed && (
              <>
                <PlusCircleIcon
                  className={classNames(
                    "w-5",
                    "text-neutral-400",
                  )}
                />
                <div className={classNames("pl-2")}>Expand</div>
              </>
            )}
            {!isCollapsed && (
              <>
                <MinusCircleIcon
                  className={classNames(
                    "w-5",
                    "text-neutral-400",
                  )}
                />
                <div className={classNames("pl-2")}>Collapse</div>
              </>
            )}
          </div>
        </CiroDropdownButtonItem>
      )}
      <CiroDropdownButtonItem
        onClick={() => {
          onClose();
          setEnrichmentFlowLoading(true);
          createEnrichmentStep({
            variables: {
              enrichment_flow_id: enrichmentFlowId,
              input: {
                name: jsonObjectkey,
                enrichment_technique: EnrichmentTechniqueEnum.ReturnValue,
              },
            },
            onCompleted: (data) => {
              updateEnrichmentStep({
                variables: {
                  id: data.createEnrichmentStep.id,
                  enrichmentStepInput: {
                    parentEnrichmentStepInputs: [
                      {
                        key: "value",
                        input: JSON.stringify(
                          [...currentPath, jsonObjectkey].join("."),
                        ),
                        required: true,
                        sourceEnrichmentStepId: enrichmentStepId,
                      },
                    ],
                  },
                },
                onCompleted: () => {
                  EnrichmentFlow_refetchEnrichmentFlow().then(() => {
                    setEnrichmentFlowLoading(false);
                  });
                  loadingStepIds.add(data.createEnrichmentStep.id);
                  setLoadingStepIds(new Set(loadingStepIds));

                  runEnrichmentFlow({
                    variables: {
                      input: {
                        cascade: false,
                        enrichmentStepId: data.createEnrichmentStep.id,
                        enrichmentFlowId: enrichmentFlowId,
                      },
                    },
                  })
                    .then(({ data }) => {
                      setRunningJobId(data?.runEnrichmentFlow?.jobId!);
                    })
                    .catch((error) => {
                      toast.error(`Run failed with error: ${error.message}`);
                    });
                },
              });
            },
            onError: (error) => {
              toast.error(
                `Failed to create column with error: ${error.message}`,
              );
            },
          });
        }}
        analyticsField={"Convert to column"}
      >
        <div className={classNames("flex")}>
          <ArrowRightOnRectangleIcon
            className={classNames("w-5", "text-neutral-400")}
          />
          <div className={classNames("pl-2")}>Convert to Column</div>
        </div>
      </CiroDropdownButtonItem>
      <CiroDropdownButtonItem
        onClick={() => {
          navigator.clipboard.writeText(data[jsonObjectkey]);
        }}
        analyticsField={"Copy value from column JSON"}
      >
        <div className={classNames("flex")}>
          <ClipboardDocumentIcon
            className={classNames("w-5", "text-neutral-400")}
          />
          <div className={classNames("pl-2")}>Copy Value</div>
        </div>
      </CiroDropdownButtonItem>
    </CiroDropdownButton>
  );
};

export default EnrichmentExecutionDetailsNode;
