import { useQuery, gql } from "@apollo/client";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/shared/Loading";
import {
  BusinessDetailsKeyDetailsCard_CompanyFragmentDoc,
  BusinessDetails_CompanyQuery,
  BusinessDetails_CompanyQueryVariables,
} from "../../__generated__/graphql";
import CiroContainer from "../../components/shared/CiroContainer";
import classNames from "classnames";
import BusinessDetailsKeyDetails, {
  BusinessDetailsKeyDetailsCard_Company,
} from "../../components/businessDetails/BusinessDetailKeyDetails/BusinessDetailsKeyDetailsCard";
import BusinessDetailsExtraDetails, {
  BusinessDetailsExtraDetails_Company,
} from "../../components/businessDetails/BusinessDetailsExtraDetails";
import BusinessDetailsReportModalAndButton from "../../components/businessDetails/BusinessDetailsReportModalAndButton";
import BusinessDetailsOrgCrmDetails, {
  BusinessDetailsOrgCrmDetails_OrganizationCompanyStatus,
} from "../../components/businessDetails/BusinessDetailsOrgCrmDetails";
import BusinessDetailsLocalFunFacts, {
  BusinessDetailsLocalFunFacts_Company,
} from "../../components/businessDetails/BusinessDetailsLocalFunFacts";
import { useFragment } from "../../__generated__";
import BusinessDetailsInternalCompanyId, {
  BusinessDetailsInternalCompanyId_Company,
} from "../../components/businessDetails/BusinessDetailsInternalCompanyId";
import { BusinessDetailsOpenDays_Company } from "../../components/businessDetails/BusinessDetailsOpenDays";
import { BusinessDetailsMultilocationLinks_Company } from "../../components/businessDetails/BusinessDetailsMultilocationLinks";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import BusinessDetailsTitle, {
  BusinessDetailsTitle_Company,
} from "../../components/businessDetails/BusinessDetailsTitle";
import BusinessDetailsContacts from "../../components/businessDetails/BusinessDetailsContacts";

// Used in BusinessDetailsKeyDetailsCard.tsx
export const BusinessDetails_Company = gql`
  query BusinessDetails_Company($companyPk: ID!) {
    company(pk: $companyPk) {
      company_name {
        value
        populated
      }
      dba {
        value
        populated
      }
      formatted_address {
        value
        populated
      }
      crmMatchStats {
        bestCrmMatch {
          crm_url
          current_status
          ...BusinessDetailsOrgCrmDetails_OrganizationCompanyStatus
        }
        totalCrmMatches
      }
      ...BusinessDetailsTitle_Company
      ...BusinessDetailsExtraDetails_Company
      ...BusinessDetailsInternalCompanyId_Company
      ...BusinessDetailsKeyDetailsCard_Company
      ...BusinessDetailsLocalFunFacts_Company
      ...BusinessDetailsMultilocationLinks_Company
      ...BusinessDetailsOpenDays_Company
    }
  }
  ${BusinessDetailsTitle_Company}
  ${BusinessDetailsExtraDetails_Company}
  ${BusinessDetailsInternalCompanyId_Company}
  ${BusinessDetailsKeyDetailsCard_Company}
  ${BusinessDetailsLocalFunFacts_Company}
  ${BusinessDetailsMultilocationLinks_Company}
  ${BusinessDetailsOpenDays_Company}
  ${BusinessDetailsOrgCrmDetails_OrganizationCompanyStatus}
`;

const BusinessDetails = function () {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [searchParams, _setSearchParams] = useSearchParams();
  let companyPk = searchParams.get("businessId") as string;
  const companyDetailsQueryResult = useQuery<
    BusinessDetails_CompanyQuery,
    BusinessDetails_CompanyQueryVariables
  >(BusinessDetails_Company, {
    variables: {
      companyPk: companyPk,
    },
  });
  const businessDetailsKeyDetailsCompany = useFragment(
    BusinessDetailsKeyDetailsCard_CompanyFragmentDoc,
    companyDetailsQueryResult.data?.company,
  );

  const company = companyDetailsQueryResult?.data?.company;
  if (companyDetailsQueryResult.loading) {
    return (
      <>
        <div
          className={classNames(
            "flex",
            "flex-col",
            "lg:flex-row",
          )}
        >
          <div
            className={classNames(
              "lg:basis-2/3",
              "sm:max-md:order-1",
              "pl-16",
              "pr-4",
              "pt-16",
            )}
          >
            <SkeletonLoading numSkeletons={1} skeletonHeight="7rem" />
            <SkeletonLoading numSkeletons={1} skeletonHeight="7rem" />
            <SkeletonLoading numSkeletons={1} skeletonHeight="12rem" />
            <SkeletonLoading numSkeletons={3} skeletonHeight="7rem" />
          </div>
          <div
            className={classNames(
              "lg:basis-1/3",
              "sm:max-md:order-2",
              "pr-16",
              "pt-16",
            )}
          >
            <div
              className={classNames("lg:ml-16", "lg:max-w-xl")}
            >
              <SkeletonLoading numSkeletons={7} skeletonHeight="6.5rem" />
            </div>
          </div>
        </div>
      </>
    );
  }

  if (
    companyDetailsQueryResult.error &&
    !companyDetailsQueryResult?.data?.company
  ) {
    return (
      <p>
        Company details error :({" "}
        {companyDetailsQueryResult.error?.message || "Cannot find company"}{" "}
      </p>
    );
  }

  if (!company || !businessDetailsKeyDetailsCompany) {
    return <div>Something went wrong...</div>;
  }

  return (
    <CiroContainer className={classNames("bg-gray-100")}>
      <div
        className={classNames(
          "flex",
          "flex-col",
          "lg:flex-row",
        )}
      >
        <div
          className={classNames(
            "lg:basis-2/3",
            "sm:max-md:order-1",
          )}
        >
          <BusinessDetailsTitle company={company} />
          <BusinessDetailsLocalFunFacts
            company={company}
            beginExpanded={false}
          />
          <BusinessDetailsKeyDetails
            company={businessDetailsKeyDetailsCompany}
          />
          <BusinessDetailsContacts companyPk={companyPk} />
        </div>
        <div
          className={classNames(
            "lg:basis-1/3",
            "sm:max-md:order-2",
          )}
        >
          <div className={classNames("lg:ml-8", "lg:max-w-xl")}>
            <BusinessDetailsOrgCrmDetails
              organizationCompanyStatus={company.crmMatchStats?.bestCrmMatch}
              totalMatches={company?.crmMatchStats?.totalCrmMatches}
            />
            <BusinessDetailsExtraDetails company={company} />
            <div className={classNames("my-3")}>
              <BusinessDetailsReportModalAndButton companyPk={companyPk} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <BusinessDetailsInternalCompanyId company={company} />
      </div>
    </CiroContainer>
  );
};

export default withAuthenticationRequired(BusinessDetails, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loading />,
});
