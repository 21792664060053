import classNames from "classnames";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import CiroButton from "../../../shared/CiroButton";

interface IntegrationOptionProps {
  icon: JSX.Element;
  integrationName: string;
  onClick: () => void;
}

const ConnectNewIntegrationOption = ({
  icon,
  integrationName,
  onClick,
}: IntegrationOptionProps) => {
  return (
    <CiroButton
      onClick={onClick}
      analyticsField={"Choose integration option"}
      analyticsProps={{
        integrationOption: integrationName,
      }}
      customClassName={classNames(
        "rounded-md",
        "border-1",
        "border-neutral-200",
        "text-neutral-600",
        "mt-4",
        "flex",
        "justify-between",
        "items-center",
        "w-full",
      )}
    >
      <div className={classNames("flex", "items-center")}>
        <span className={classNames("mr-2")}>{icon}</span>
        Link {integrationName}
      </div>
      <div className="text-orange-500">
        <ChevronRightIcon className={classNames("h-4")} />
      </div>
    </CiroButton>
  ) 
};

export default ConnectNewIntegrationOption;
