import { useContext, useMemo, useState } from "react";
import {
  EnrichmentFlowTableContainer_EnrichmentFlowFragment,
  EnrichmentFlowTableContainer_EnrichmentFlowRowsQuery,
  EnrichmentTechniqueEnum,
  Maybe,
  UseEnrichmentFlowGridInputs_DeleteEnrichmentStepMutation,
  UseEnrichmentFlowGridInputs_DeleteEnrichmentStepMutationVariables,
} from "../../__generated__/graphql";
import { EnrichmentFilterTechnique } from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepCardFilters/EnrichmentStepFilterDropdown";
import EnrichmentExecutionCell, {
  IEnrichmentExecutionValue,
} from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentExecutionCell";
import { ENRICHMENT_FLOW_ROW_LIMIT } from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentFlowTableContainer";
import classNames from "classnames";
import EnrichmentFlowTableIndexDropdown from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentFlowTableDropdown/EnrichmentFlowTableIndexDropdown";
import EnrichmentFlowOverflowHeader from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentFlowOverflowHeader";
import { ColDef, ColGroupDef } from "@ag-grid-community/core";
import EnrichmentFlowTableHeaderDropdown from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentFlowTableDropdown/EnrichmentFlowTableHeaderDropdown";
import EnrichmentFlowTableHeader from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentFlowTableHeader";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";
import { gql, useMutation } from "@apollo/client";
import useEnrichmentInputErrors from "./useEnrichmentInputErrors";
import { IExecutionCellData } from "../../components/enrichmentFlow/EnrichmentExecutionDetailsViewer/EnrichmentExecutionDetailsViewer";

const useEnrichmentFlowGridInputs_DeleteEnrichmentStep = gql`
  mutation useEnrichmentFlowGridInputs_DeleteEnrichmentStep($id: Int!) {
    deleteEnrichmentStep(id: $id)
  }
`;

const useEnrichmentFlowGridInputs = ({
  enrichmentFilters,
  enrichmentFlow,
  enrichmentFlowRowsData,
  enrichmentFlowRowsLoading,
  enrichmentStepOrder,
  offset,
  setExecutionCellData,
  setShowFiltersAsColumns,
  setSelectedEnrichmentStepId,
  setSelectedEnrichmentTechnique,
  showFiltersAsColumns,
}: {
  enrichmentFilters: EnrichmentFilterTechnique[];
  enrichmentFlow:
    | Maybe<EnrichmentFlowTableContainer_EnrichmentFlowFragment>
    | undefined;
  enrichmentFlowRowsData:
    | EnrichmentFlowTableContainer_EnrichmentFlowRowsQuery
    | undefined
    | null;
  enrichmentFlowRowsLoading: boolean;
  enrichmentStepOrder: number[];
  offset: number;
  setExecutionCellData: (v: IExecutionCellData | null) => void;
  setShowFiltersAsColumns: (v: boolean) => void;
  setSelectedEnrichmentStepId: (v: number) => void;
  setSelectedEnrichmentTechnique: (v: EnrichmentTechniqueEnum) => void;
  showFiltersAsColumns: boolean;
}) => {
  const {
    EnrichmentFlow_refetchEnrichmentFlow,
    loadingStepIds,
    loadingRowIds,
    setEnrichmentFlowLoading,
    enrichmentFlowRowIdIndexMap,
  } = useContext(EnrichmentFlowContext);

  const inputErrors = useEnrichmentInputErrors(enrichmentFlow?.id);
  const [hiddenStepIds, setHiddenStepIds] = useState(new Set());
  const [pinnedStepIds, setPinnedStepIds] = useState(new Set());

  const [deleteEnrichmentStep] = useMutation<
    UseEnrichmentFlowGridInputs_DeleteEnrichmentStepMutation,
    UseEnrichmentFlowGridInputs_DeleteEnrichmentStepMutationVariables
  >(useEnrichmentFlowGridInputs_DeleteEnrichmentStep);

  const { rowData, rowIds, columnDefs } = useMemo(() => {
    if (!enrichmentFlow) {
      return {
        rowData: [],
        rowIds: [],
        columnDefs: [],
      };
    }
    const memoizedRowData = [] as {
      [v: string]: IEnrichmentExecutionValue;
    }[];
    const memoizedRowIds = [] as number[];
    enrichmentFlowRowsData?.enrichmentFlowRows.forEach((enrichmentFlowRow) => {
      memoizedRowIds.push(enrichmentFlowRow.id);
      const enrichmentRowExecutions =
        enrichmentFlowRow.enrichmentExecutions.reduce(
          (acc, enrichmentExecution) => {
            acc[String(enrichmentExecution.enrichment_step_id)] = {
              output: enrichmentExecution.output,
              error: enrichmentExecution?.error,
              flowRowId: enrichmentExecution.enrichment_flow_row_id,
            };
            return acc;
          },
          {} as { [v: string]: IEnrichmentExecutionValue },
        );
      // Add values for cells that haven't been run yet.
      // This is needed to ensure the cells properly render the loading skeleton
      // in the grid even if they haven't been run yet. In those cases
      // there is no execution (yet).
      enrichmentFlow?.enrichmentSteps.forEach((step) => {
        const stepId = step?.id;
        if (!enrichmentRowExecutions[step?.id]) {
          enrichmentRowExecutions[String(stepId)] = {
            output: null,
            flowRowId: enrichmentFlowRow.id,
          };
        }
      });
      const tableIndex =
        enrichmentFlowRowIdIndexMap.get(enrichmentFlowRow.id) || 0;

      enrichmentRowExecutions["rowId"] = {
        output: tableIndex + 1,
      };
      memoizedRowData.push(enrichmentRowExecutions);
    });

    const initialLoading =
      enrichmentFlowRowsLoading && memoizedRowData.length === 0;
    if (initialLoading) {
      for (
        let i = offset + 1;
        i <=
        Math.min(
          offset + ENRICHMENT_FLOW_ROW_LIMIT,
          enrichmentFlow?.rowCount || 0,
        );
        i++
      ) {
        memoizedRowData.push({ rowId: { output: i } });
      }
    }

    const enrichmentStepsOrdered = enrichmentStepOrder
      .map((stepId) => {
        return enrichmentFlow?.enrichmentSteps.find(
          (step) => step.id === stepId,
        );
      })
      .filter(Boolean) as typeof enrichmentFlow.enrichmentSteps;

    const nonIncludedEnrichmentSteps =
      enrichmentFlow?.enrichmentSteps.filter((step) => {
        return enrichmentStepsOrdered.indexOf(step) === -1;
      }) || [];

    const confirmDeleteEnrichmentStep = (enrichmentStepId: number) => {
      if (!enrichmentStepId) {
        return;
      }
      setHiddenStepIds(new Set(hiddenStepIds.add(enrichmentStepId)));
      setEnrichmentFlowLoading(true);
      deleteEnrichmentStep({
        variables: {
          id: enrichmentStepId,
        },
        onCompleted: () => {
          EnrichmentFlow_refetchEnrichmentFlow().then(() => {
            const newHiddenStepIds = new Set(hiddenStepIds);
            newHiddenStepIds.delete(enrichmentStepId);
            setHiddenStepIds(newHiddenStepIds);
            setEnrichmentFlowLoading(false);
          });
        },
      });
    };

    const memoizedCols = enrichmentStepsOrdered
      .concat(nonIncludedEnrichmentSteps)
      .map((enrichmentStep) => {
        return {
          headerComponent: EnrichmentFlowTableHeader,
          headerName: enrichmentStep.name,
          cellClass: (params) => {
            return params.node.rowPinned === "bottom" &&
              !params.node.data.isPagination
              ? classNames("bg-neutral-100")
              : "";
          },
          filter: () => {
            return (
              <EnrichmentFlowTableHeaderDropdown
                handleOpenEnrichmentStep={() => {
                  setSelectedEnrichmentStepId(enrichmentStep.id);
                  setSelectedEnrichmentTechnique(
                    enrichmentStep.enrichment_technique,
                  );
                  setExecutionCellData(null);
                }}
                handleDeleteEnrichmentStep={confirmDeleteEnrichmentStep}
                enrichmentStepId={enrichmentStep.id}
                rowIds={rowIds}
                enrichmentTechnique={enrichmentStep.enrichment_technique}
                togglePinned={() => {
                  const newPinnedStepIds = new Set(pinnedStepIds);
                  if (pinnedStepIds.has(enrichmentStep.id)) {
                    newPinnedStepIds.delete(enrichmentStep.id);
                  } else {
                    newPinnedStepIds.add(enrichmentStep.id);
                  }
                  setPinnedStepIds(newPinnedStepIds);
                }}
                isPinned={pinnedStepIds.has(enrichmentStep.id)}
              />
            );
          },
          cellRendererSelector: (params) => {
            return {
              component: EnrichmentExecutionCell,
              params: {
                selectedInput: enrichmentStep.selected_input,
                setCellDetailsValue: (v: any) => {
                  setExecutionCellData({
                    output: v,
                    enrichmentStep: {
                      id: enrichmentStep.id,
                      enrichment_technique: enrichmentStep.enrichment_technique,
                      name: enrichmentStep.name,
                    },
                  });
                },
                enrichmentTechnique: enrichmentStep.enrichment_technique,
                isLoadingCol:
                  initialLoading || loadingStepIds.has(enrichmentStep.id),
                loadingRowIds,
                params,
              },
            };
          },
          headerComponentParams: {
            handleOpenEnrichmentStep: () => {
              setSelectedEnrichmentStepId(enrichmentStep.id);
              setSelectedEnrichmentTechnique(
                enrichmentStep.enrichment_technique,
              );
            },
            enrichmentStepId: enrichmentStep.id,
            enrichmentTechnique: enrichmentStep.enrichment_technique,
            inputErrors: inputErrors.filter(
              (error) => error.stepId === enrichmentStep.id,
            ),
          },
          hide:
            (!showFiltersAsColumns &&
              enrichmentFilters.includes(
                enrichmentStep.enrichment_technique as EnrichmentFilterTechnique,
              )) ||
            hiddenStepIds.has(enrichmentStep.id),
          valueGetter: (params: any) => {
            return params.data[enrichmentStep.id];
          },
          pinned: pinnedStepIds.has(enrichmentStep.id) ? "left" : undefined,
        };
      }) as (ColDef | ColGroupDef)[];

    memoizedCols.unshift({
      width: 60,
      headerComponent: EnrichmentFlowOverflowHeader,
      headerName: "Settings",
      cellRendererSelector: (params) => {
        return {
          component: EnrichmentExecutionCell,
          params: {
            selectedInput: null,
            setCellDetailsValue: (v: any) => null,
            enrichmentTechnique: null,
            isLoadingCol: false,
            loadingRowIds,
            params,
          },
        };
      },
      cellClass: (params) => {
        return params.node.rowPinned === "bottom" &&
          !params.node.data.isPagination
          ? classNames("bg-neutral-100")
          : "";
      },
      headerComponentParams: {
        menuIcon: "checkbox",
      } as any,
      filter: () => {
        return (
          <EnrichmentFlowTableIndexDropdown
            filtersShown={showFiltersAsColumns}
            onClick={() => {
              setShowFiltersAsColumns(!showFiltersAsColumns);
            }}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.rowId;
      },
      pinned: "left",
    });

    return {
      rowData: memoizedRowData,
      rowIds: memoizedRowIds,
      columnDefs: memoizedCols,
    };
  }, [
    enrichmentFlow,
    enrichmentFlowRowsData?.enrichmentFlowRows,
    enrichmentStepOrder,
    enrichmentFlowRowsLoading,
    enrichmentFlowRowIdIndexMap,
    offset,
    hiddenStepIds,
    setEnrichmentFlowLoading,
    deleteEnrichmentStep,
    EnrichmentFlow_refetchEnrichmentFlow,
    inputErrors,
    showFiltersAsColumns,
    enrichmentFilters,
    pinnedStepIds,
    setSelectedEnrichmentStepId,
    setSelectedEnrichmentTechnique,
    loadingStepIds,
    loadingRowIds,
    setExecutionCellData,
    setShowFiltersAsColumns,
  ]);

  return { rowData, rowIds, columnDefs };
};

export default useEnrichmentFlowGridInputs;
