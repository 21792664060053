import { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { analytics } from "../../utils/vendors";

interface ICiroDropdownButtonProps {
  borderless?: boolean;
  children: any;
  dropdownButtonClassName?: string;
  prettyDisable?: boolean;
  disabled?: boolean;
  hideChevron?: boolean;
  hideChevronOnDisabled?: boolean;
  label: any;
  onLabelClick?: () => void;
  customClassName?: string;
  customBgColor?: string;
  customPadding?: string;
  customButtonClassName?: string;
  customItemWidth?: string;
  menuPosition?: "left" | "right";
  isPopover?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement> | null;
}

export function CiroDropdownButton({
  buttonRef = null,
  borderless = false,
  children,
  disabled = false,
  hideChevronOnDisabled = true,
  hideChevron = false,
  prettyDisable = false,
  label,
  onLabelClick,
  customBgColor = "bg-white",
  customClassName = "",
  customPadding = classNames("pl-4", "pr-2", "py-2"),
  customButtonClassName = "",
  menuPosition = "left",
  dropdownButtonClassName = "",
  isPopover = false,
}: ICiroDropdownButtonProps) {
  const MenuClass = isPopover ? Popover : Menu;
  const MenuButton = isPopover ? Popover.Button : Menu.Button;
  const MenuPanel = isPopover ? Popover.Panel : Menu.Items;

  return (
    <MenuClass
      as="div"
      className={classNames(
        "inline-block",
        "relative",
        "text-left",
        "rounded-md",
        customClassName,
        customBgColor,
      )}
    >
      <MenuButton
        ref={buttonRef}
        disabled={disabled}
        className={classNames(
          "flex",
          "w-full",
          "justify-center",
          "gap-x-1.5",
          "rounded-md",
          "text-sm",
          "rounded-lg",
          "text-sm",
          "border-gray-300",
          {
            "border-1": !borderless,
            "bg-stone-300": disabled && !prettyDisable,
            "shadow-sm": !borderless,
            "border-gray-500": disabled && !prettyDisable,
            "text-gray-500": disabled && !prettyDisable,
          },
          customPadding,
          customButtonClassName,
        )}
      >
        <div
          className={classNames([
            { "pr-2": disabled && prettyDisable },
          ])}
          onClick={(event: any) => {
            if (onLabelClick) {
              event.preventDefault();
              onLabelClick();
            }
          }}
        >
          {label}
        </div>
        {((!disabled && !hideChevron) ||
          (disabled && !hideChevronOnDisabled)) && (
          <ChevronDownIcon
            className={classNames(
              "mr-1",
              "h-5 w-5",
              "text-gray-400",
              dropdownButtonClassName,
            )}
            aria-hidden="true"
          />
        )}
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuPanel
          className={classNames(
            "absolute",
            "z-20",
            "mt-2",
            "rounded-md",
            "bg-white",
            "shadow-lg",
            "ring-1",
            "ring-black",
            "ring-opacity-5",
            "focus:outline-none",
            {
              "right-0": menuPosition === "left",
              "origin-top-right": menuPosition === "left",
              "left-0": menuPosition === "right",
              "origin-top-left": menuPosition === "right",
            },
          )}
        >
          <div className="py-1">{children}</div>
        </MenuPanel>
      </Transition>
    </MenuClass>
  );
}

interface ICiroDropdownButtonItemProps {
  children: any;
  onClick: () => void;
  analyticsField: string;
  disabled?: boolean;
}

export function CiroDropdownButtonItem({
  analyticsField,
  disabled,
  children,
  onClick,
}: ICiroDropdownButtonItemProps) {
  const handleClick = () => {
    analytics.track("buttonClicked", {
      value: analyticsField,
    });
    onClick();
  };
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          onClick={handleClick}
          className={classNames(
            "block",
            "px-4",
            "py-2",
            "text-sm",
            "whitespace-nowrap",
            {
              "bg-gray-100 text-gray-900 cursor-pointer":
                active,
              "text-gray-700": !active,
              "bg-gray-200 opacity-50 cursor-not-allowed":
                disabled,
            },
          )}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
}
