import React from "react";
import { useCallback, useContext, useState } from "react";
import classNames from "classnames";
import fileDownload from "js-file-download";
import AppContext from "../../../contexts/AppContext";
import download from "../../../assets/img/icons/download.svg";
import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";

const downloadLinkStyles = {
  ICON: "icon",
};

interface IDownloadPicklistProps {
  fileName?: string | null;
  style?: typeof downloadLinkStyles.ICON;
}

function DownloadPicklist(props: IDownloadPicklistProps) {
  const { accessToken } = useContext(AppContext);
  const [isExporting, setIsExporting] = useState(false);

  const downloadCSV = useCallback(async () => {
    setIsExporting(true);
    try {
      const exportResponse = await fetch(`/export-picklist`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const blob = await exportResponse.blob();
      fileDownload(blob, `${props.fileName || "picklist-data"}.csv`);
    } catch (error) {
      console.error("Error during CSV download:", error);
    } finally {
      setIsExporting(false);
    }
  }, [accessToken, props.fileName]);

  return (
    <div className={classNames("flex", "justify-end")}>
      {props.style === downloadLinkStyles.ICON && (
        <div>
          <CiroButton
            analyticsField="Download picklist"
            style={CiroButtonStyleEnum.INVERTED_LOUD}
            disabled={isExporting}
            onClick={() => {
              setIsExporting(true);
              downloadCSV();
            }}
          >
            <span className="mr-4">
              <img src={download} alt="Download" width={18} />
            </span>
            Download Picklist Values
          </CiroButton>
        </div>
      )}
    </div>
  );
}

export { DownloadPicklist, downloadLinkStyles };
