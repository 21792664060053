import classNames from "classnames";
import { gql } from "@apollo/client";
import {
  BusinessDetailsMergeCrmLeadDetails_MergeCrmLeadFragment,
  Maybe,
} from "../../__generated__/graphql";

export const BusinessDetailsMergeCrmLeadDetails_MergeCrmLead = gql`
  fragment BusinessDetailsMergeCrmLeadDetails_MergeCrmLead on MergeCrmLead {
    id
    title
    last_activity_at
    owner {
      name
    }
  }
`;

interface IBusinessDetailsMergeCrmLeadDetailsProps {
  mergeCrmLead?: Maybe<BusinessDetailsMergeCrmLeadDetails_MergeCrmLeadFragment>;
}

const BusinessDetailsMergeCrmLeadDetails = ({
  mergeCrmLead,
}: IBusinessDetailsMergeCrmLeadDetailsProps) => {
  if (!mergeCrmLead) {
    return null;
  }

  const { owner } = mergeCrmLead;

  return (
    <div>
      <div>{mergeCrmLead.title}</div>
      {owner?.name && (
        <div
          className={classNames("text-sm", "text-slate-600")}
        >
          Owner: {owner?.name}
        </div>
      )}
      {mergeCrmLead.last_activity_at && (
        <div
          className={classNames("text-sm", "text-slate-600")}
        >
          Last activity: {mergeCrmLead.last_activity_at}
        </div>
      )}
    </div>
  );
};

export default BusinessDetailsMergeCrmLeadDetails;
