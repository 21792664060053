import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Editable,
  withReact,
  Slate,
  useSelected,
  useFocused,
  ReactEditor,
} from "slate-react";
import { Editor, Transforms, createEditor, BaseRange, Range } from "slate";
import { withHistory } from "slate-history";

import { MentionElement, StepOption } from "./enrichmentStepEditorTypes";
import ReactDOM from "react-dom";
import EnrichmentFlowContext from "../../../../contexts/EnrichmentFlowContext";
import classNames from "classnames";
import { EnrichmentStepsDropdown_EnrichmentStepsFragment } from "../../../../__generated__/graphql";
import { useEnrichmentFlowStepInputs } from "../../../../reactHooks/enrichmentFlow/useEnrichmentFlowStepInputs";
import CiroErrorMsg from "../../../shared/forms/CiroErrorMsg";

// Used for the drop-down of options when typing a mention
const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

// Mention component used inside of the Slate editor
const Mention = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: any;
  element: MentionElement;
}) => {
  const selected = useSelected();
  const focused = useFocused();
  const style: React.CSSProperties = {
    padding: "3px 3px 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: "#FFEDE6",
    color: "#FD5B1D",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
  };

  // Ensure the option label is safely handled
  const safeOptionLabel = element.option?.optionLabel?.replace(" ", "-");

  return (
    <span {...attributes}>
      <span
        contentEditable={false}
        data-cy={`mention-${safeOptionLabel}`}
        style={style}
      >
        {element.option?.optionLabel}
      </span>
      {children}
    </span>
  );
};

// Element component used inside of the Slate editor (Mention is a type of Element)
const Element = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: any;
  element: any;
}) => {
  switch (element.type) {
    case "mention":
      return (
        <Mention attributes element={element as MentionElement}>
          {children}
        </Mention>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  return <span {...attributes}>{children}</span>;
};

// Hook that's used to add mentions to the editor
const withMentions = (editor: any) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element: MentionElement) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element: MentionElement) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  editor.markableVoid = (element: MentionElement) => {
    return element.type === "mention" || markableVoid(element);
  };

  return editor;
};

const insertMention = (editor: any, option: StepOption) => {
  const mention: MentionElement = {
    type: "mention",
    option,
    children: [
      {
        text: "",
      },
    ],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const getInitialValueFromTemplateVariables = (
  variables: Map<string, IEnrichmentTemplateVariable>,
  template: string | null,
  enrichmentFlowWithSteps?: EnrichmentStepsDropdown_EnrichmentStepsFragment | null,
) => {
  if (!template || !variables) {
    return [
      {
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
      },
    ];
  }
  const mentionPattern = /\{\{([^{}]+)\}\}/g;
  const matches = template ? [...template.matchAll(mentionPattern)] : [];
  let currentIndex = 0;
  const children = [];

  matches.forEach((match, index) => {
    const [fullMatch, mentionName] = match;
    const mentionStartIndex = match.index!;

    if (currentIndex < mentionStartIndex) {
      const text = template?.slice(currentIndex, mentionStartIndex);
      if (text.length > 0) children.push({ text });
    }

    const variable = variables.get(mentionName);
    const step = enrichmentFlowWithSteps?.enrichmentSteps?.find(
      (step) => step?.id === variable?.stepId,
    );

    const mentionOption = {
      optionIdx: index,
      stepId: variable?.stepId,
      stepName: step?.name,
      optionLabel: variable?.stepInput
        ? `${step?.name} > ${variable?.stepInput}`
        : step?.name,
      stepInput: variable?.stepInput,
    };

    children.push({
      type: "mention",
      option: mentionOption,
      children: [
        {
          text: "",
        },
      ],
    });

    currentIndex = mentionStartIndex + fullMatch.length;
  });

  if (template && currentIndex < template.length) {
    const remainingText = template?.slice(currentIndex);
    if (remainingText.length > 0) children.push({ text: remainingText });
  }

  return [
    {
      type: "paragraph",
      children,
    },
  ];
};

interface IRichTextEditor {
  label: string;
  variables: Map<string, IEnrichmentTemplateVariable>;
  variableErrors: any;
  setVariables: (variables: Map<string, IEnrichmentTemplateVariable>) => void;
  template: string | null;
  setTemplate: (template: string) => void;
  templateError?: string;
}

export interface IEnrichmentTemplateVariable {
  stepId: number | null;
  stepInput: string | null;
}

const EnrichmentStepRichTextEditor = ({
  label,
  variables,
  variableErrors,
  setVariables,
  template = "",
  setTemplate,
  templateError = "",
}: IRichTextEditor) => {
  const ref = useRef<HTMLDivElement | null>();
  const [target, setTarget] = useState<BaseRange | undefined | null>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const { enrichmentFlowWithSteps } = useContext(EnrichmentFlowContext);
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    [],
  );
  const options = useEnrichmentFlowStepInputs({ search });

  const onKeyDown = useCallback(
    (event: any) => {
      if (target && options.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const prevIndex = index >= options.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            return;
          case "ArrowUp":
            event.preventDefault();
            const nextIndex = index <= 0 ? options.length - 1 : index - 1;
            setIndex(nextIndex);
            return;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, options[index]);
            setTarget(null);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(null);
            return;
        }
      }
    },
    [target, options, editor, index],
  );

  useEffect(() => {
    if (target && options.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      if (el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;
        el.style.left = `${rect.left + window.pageXOffset}px`;
      }
    }
  }, [options.length, editor, index, search, target]);

  const initialValue = getInitialValueFromTemplateVariables(
    variables,
    template,
    enrichmentFlowWithSteps,
  );

  return (
    <>
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={() => {
          const { selection } = editor;

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: "word" });
            const charBefore = Editor.before(editor, start, {
              unit: "character",
            });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const wordBeforeRange =
              before && Editor.range(editor, before, start);
            const charBeforeRange =
              charBefore && Editor.range(editor, charBefore, start);
            const beforeText =
              wordBeforeRange && Editor.string(editor, wordBeforeRange);
            const beforeMatch =
              beforeText && beforeText.match(/(?:^|\s)@(\w+)?$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (beforeMatch && afterMatch) {
              const isOnlyAtSign = /\s@$/.test(beforeText);
              const newTarget = isOnlyAtSign
                ? charBeforeRange
                : wordBeforeRange;
              setTarget(newTarget);
              setSearch(beforeMatch[1]);
              setIndex(0);
              return;
            }
          }

          setTarget(null);

          let template = "";
          const stepVariables = new Map<string, IEnrichmentTemplateVariable>();

          editor.children.forEach((paragraph: any, idx: number) => {
            paragraph.children.forEach((child: any) => {
              if (child.type !== "mention") {
                template += child.text;
              } else {
                const field = child.option.optionLabel;
                template += `{{${field}}}`;
                stepVariables.set(field, {
                  stepId: child.option.stepId,
                  stepInput: child.option.stepInput,
                });
              }
            });
            if (idx < editor.children.length - 1) {
              template += "\n";
            }
          });

          setTemplate(template);
          setVariables(stepVariables);
        }}
      >
        <div>
          {label && (
            <div
              className={classNames("pb-2", "text-gray-500")}
            >
              {label}
            </div>
          )}
        </div>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Use @ to mention a previous field"
          renderPlaceholder={({ children, attributes }) => (
            <div {...attributes} className={classNames("py-3.5")}>
              {children}
            </div>
          )}
          onKeyDown={onKeyDown}
          style={{
            padding: "14px",
          }}
          className={classNames(
            "border-1",
            "border-gray-300",
            "min-h-64",
            "p-3.5",
            "rounded-lg",
            "text-sm",
            "w-full",
            {
              "border-gray-300": !templateError,
              "border-red-500": templateError,
            },
          )}
        />
        <CiroErrorMsg error={templateError} />
        {target && options.length > 0 && (
          <Portal>
            <div
              className={classNames("text-sm")}
              ref={ref as MutableRefObject<HTMLDivElement | null>}
              style={{
                top: "-9999px",
                left: "-9999px",
                position: "absolute",
                zIndex: 1,
                padding: "3px",
                background: "white",
                borderRadius: "4px",
                boxShadow: "0 1px 5px rgba(0,0,0,.2)",
              }}
              data-cy="mentions-portal"
            >
              {options.map((option, i) => (
                <div
                  className={classNames(
                    "px-0.5",
                    "py-1",
                    "rounded",
                    "text-orange-600",
                    {
                      "bg-orange-25": i === index,
                    },
                  )}
                  key={option.optionIdx}
                  onClick={() => {
                    Transforms.select(editor, target);
                    insertMention(editor, option);
                    setTarget(null);
                  }}
                >
                  {option?.optionLabel}
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate>
      {variableErrors?.length > 0 && (
        <p
          className={classNames(
            "text-rose-500",
            "text-xs",
            "scroll-mt-20",
          )}
        >
          One of your variables has an error
        </p>
      )}
    </>
  );
};

export default EnrichmentStepRichTextEditor;
