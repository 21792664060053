import { useContext } from "react";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import PersonasContext from "../../contexts/PersonasContext";

const PersonaFilter = ({
  personaFilter,
  setPersonaFilter,
}: {
  personaFilter: number[];
  setPersonaFilter: (v: number[]) => void;
}) => {
  const personas = useContext<
    {
      id: number;
      name: string;
    }[]
  >(PersonasContext);

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={personaFilter.length ? 1 : 0}
      sectionTitle="Persona"
      filterOptions={[
        {
          label: "Persona is any of:",
          filter: (
            <CiroDropDown
              isClearable={true}
              isMulti={true}
              options={personas.map((persona) => ({
                label: persona.name,
                value: persona.id,
              }))}
              value={personaFilter}
              onChange={(value) => {
                setPersonaFilter(value);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default PersonaFilter;
