import classNames from "classnames";
import CiroModal from "./CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";

interface ICiroConfirmationModalProps {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
  analyticsField: string;
}

const CiroConfirmationModal = ({
  isOpen,
  text,
  onClose,
  onConfirm,
  analyticsField,
}: ICiroConfirmationModalProps) => {
  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div>
        <div>{text}</div>
        <div
          className={classNames(
            "flex",
            "justify-between",
            "pt-4",
          )}
        >
          <CiroButton
            onClick={onClose}
            analyticsField={"Cancel " + analyticsField}
          >
            Cancel
          </CiroButton>
          <CiroButton
            style={CiroButtonStyleEnum.DELETE}
            onClick={onConfirm}
            analyticsField={"Confirm " + analyticsField}
          >
            Confirm
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default CiroConfirmationModal;
