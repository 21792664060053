import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import CiroCard from "../shared/CiroCard";
import CiroAlert from "../shared/CiroAlert";
import FileUploadIcon from "../../assets/img/icons/FileUploadIcon";

interface UploadCSVDropFileCardProps {
  setCsvFile: (csvFile: File) => void;
}

const EnrichmentFlowUploadCSVDropFileCard = ({
  setCsvFile,
}: UploadCSVDropFileCardProps) => {
  const [errorMsg, setErrorMsg] = useState("");

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      accept: { "text/csv": [".csv"] },
      maxFiles: 1,
    });

  useEffect(() => {
    if (fileRejections.length > 1) {
      return setErrorMsg("Please upload only one file");
    }

    if (fileRejections.length === 1) {
      const { errors } = fileRejections[0];
      const errorMsg = errors[0].message;
      return setErrorMsg(errorMsg);
    }

    acceptedFiles.length && setCsvFile(acceptedFiles[0]);
  }, [acceptedFiles, fileRejections, setCsvFile]);

  return (
    <div>
      <div {...getRootProps()}>
        <CiroCard
          customClassName={classNames(
            "rounded",
            "border",
            "border-dashed",
            "border-dashed-1",
            "border-orange-600",
            "bg-orange-50",
          )}
        >
          <input {...getInputProps()} />
          <div
            className={classNames(
              "flex",
              "flex-col",
              "items-center",
              "cursor-pointer",
            )}
          >
            <div
              className={classNames(
                "text-orange-600",
                "text-sm",
                "flex",
                "flex-col",
                "items-center",
              )}
            >
              <div className={classNames("mb-2")}>
                <FileUploadIcon width={"24"} height={"24"} />
              </div>
              <p className={classNames("font-medium")}>Upload CSV</p>
            </div>
          </div>
        </CiroCard>
      </div>
      {errorMsg && (
        <div className={classNames("pt-4")}>
          <CiroAlert message={errorMsg} />
        </div>
      )}
    </div>
  );
};

export default EnrichmentFlowUploadCSVDropFileCard;
