import { gql, useMutation } from "@apollo/client";
import CiroDropDown from "../../../shared/CiroDropdown";
import { ApolloIntegrationUpdateForm_ApolloIntegrationFragment } from "../../../../__generated__/graphql";
import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import CiroSpinner from "../../../shared/CiroSpinner";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const ApolloIntegrationUpdateForm_ApolloIntegration = gql`
  fragment ApolloIntegrationUpdateForm_ApolloIntegration on ApolloIntegration {
    api_key
    bad_number_dispositions
    good_number_dispositions
    overwrite
    disposition_map
  }
`;

export const ApolloIntegrationUpdateForm_UpdateApolloIntegration = gql`
  mutation ApolloIntegrationUpdateForm_UpdateApolloIntegration(
    $input: UpdateApolloIntegrationInput!
  ) {
    updateApolloIntegration(input: $input) {
      bad_number_dispositions
      good_number_dispositions
      overwrite
    }
  }
`;

const ApolloIntegrationUpdateFormSchema = yup.object({
  bad_number_dispositions: yup
    .array()
    .of(yup.string())
    .min(1, "At least one option is required"),
  good_number_dispositions: yup
    .array()
    .of(yup.string())
    .min(1, "At least one option is required"),
  overwrite: yup.boolean(),
});

const ApolloIntegrationUpdateForm = ({
  apolloIntegration,
  initialSetup,
}: {
  apolloIntegration: ApolloIntegrationUpdateForm_ApolloIntegrationFragment;
  initialSetup: boolean;
}) => {
  const navigator = useNavigate();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ApolloIntegrationUpdateFormSchema),
    defaultValues: {
      bad_number_dispositions: apolloIntegration?.bad_number_dispositions || [],
      good_number_dispositions:
        apolloIntegration?.good_number_dispositions || [],
      overwrite: apolloIntegration?.overwrite || false,
    },
    mode: "onBlur",
  });

  const [bad_number_dispositions, good_number_dispositions, overwrite] =
    useWatch({
      control,
      name: [
        "bad_number_dispositions",
        "good_number_dispositions",
        "overwrite",
      ],
    });

  const dispositionMap = JSON.parse(apolloIntegration?.disposition_map || "{}");
  const displayOptions = dispositionMap?.map(
    (dispositionPair: { display_name: string; value: string }) => {
      return {
        label: dispositionPair.display_name,
        value: dispositionPair.value,
      };
    },
  ) as { label: string; value: string }[];

  const [updateApolloIntegration, { loading: updateLoading }] = useMutation(
    ApolloIntegrationUpdateForm_UpdateApolloIntegration,
  );

  const onSubmit = async (data: any) => {
    await updateApolloIntegration({
      variables: {
        input: {
          bad_number_dispositions: data.bad_number_dispositions,
          good_number_dispositions: data.good_number_dispositions,
          overwrite: data.overwrite,
        },
      },
      onCompleted: () => {
        toast.success("Apollo Integration updated");
        navigator("/enrich-crm");
      },
    });
  };

  return (
    <div>
      <div className={classNames("pt-4")}>
        <CiroDropDown
          isMulti={true}
          label="Which call outcomes indicate whether the contact needs a new number, e.g. “Wrong Number”"
          options={displayOptions}
          value={bad_number_dispositions}
          onChange={(v) => {
            setValue("bad_number_dispositions", v);
          }}
          error={errors.bad_number_dispositions?.message}
        />
      </div>
      <div className={classNames("pt-4")}>
        <CiroDropDown
          isMulti={true}
          label="Which call outcomes indicate whether the number was succesfully reached, e.g. “Answered”"
          options={displayOptions}
          value={good_number_dispositions}
          onChange={(v) => {
            setValue("good_number_dispositions", v);
          }}
          error={errors.good_number_dispositions?.message}
          maxMenuHeight={140}
        />
      </div>
      <div className={classNames("pt-4")}>
        <CiroCheckbox
          label="Overwrite bad phone numbers in Apollo with fresh numbers from Ciro"
          checked={overwrite}
          onClick={() => {
            setValue("overwrite", !overwrite);
          }}
        />
      </div>
      <div
        className={classNames(
          "pt-8",
          "flex",
          "justify-end",
        )}
      >
        <CiroButton
          analyticsField="Update Apollo Integration"
          onClick={handleSubmit(onSubmit)}
          style={CiroButtonStyleEnum.LOUD}
          disabled={updateLoading}
        >
          {updateLoading ? (
            <CiroSpinner loading={true} />
          ) : initialSetup ? (
            "Finish"
          ) : (
            "Update"
          )}
        </CiroButton>
      </div>
    </div>
  );
};

export default ApolloIntegrationUpdateForm;
