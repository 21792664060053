import { useContext, useState } from "react";
import PersonasContext from "../../../contexts/PersonasContext";
import CiroDropDown from "../../shared/CiroDropdown";

interface IContactListPersonaDropdownProps {
  personaId: number | null;
  onChange: (personaId: number | null) => void;
}

const ContactListPersonaDropdown = ({
  personaId,
  onChange,
}: IContactListPersonaDropdownProps) => {
  const [currentPersonaId, setCurrentPersonaId] = useState(personaId);
  const personas = useContext(PersonasContext);

  return (
    <CiroDropDown
      className="w-32"
      isClearable={false}
      isMulti={false}
      value={currentPersonaId}
      onChange={(value) => {
        setCurrentPersonaId(value);
        onChange(value);
      }}
      options={[
        ...personas.map((persona) => ({
          label: persona.name,
          value: persona.id,
        })),
        {
          label: "No persona assigned",
          value: null,
        },
      ]}
      menuPortalTarget={document.body}
    />
  );
};

export default ContactListPersonaDropdown;
