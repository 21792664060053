import {
  CrmObjectType,
  FilterContactListContacts_ContactListFragmentDoc,
} from "../../__generated__/graphql";

import { useState } from "react";
import AutomationListHome, {
  AutomationListHome_ContactList,
} from "./AutomationListHomeComponents/AutomationListHome";
import FinalizePushToCrm, {
  FinalizePushToCrm_ContactList,
} from "./FinalizePushToCrmComponents/FinalizePushToCrm";
import AutomationListReviewDuplicates, {
  AutomationListReviewDuplicates_ContactList,
  FormDuplicateActionsType,
} from "./ReviewDuplicatesComponents/AutomationListReviewDuplicates";
import {
  AutomationListNavigationLayer_ContactListFragment,
  FinalizePushToCrm_ContactListFragmentDoc,
} from "../../__generated__/graphql";
import { useFragment } from "../../__generated__";
import { AutomationListHome_ContactListFragmentDoc } from "../../__generated__/graphql";
import { gql } from "@apollo/client";
import useFilterAndSortContactListContacts, {
  FilterContactListContacts_ContactList,
} from "../../reactHooks/filters/contactList/useFilterAndSortContactListContacts";
import { IContactListFilters } from "../../reactHooks/filters/contactList/useContactListFiltersAndSort";

export const AutomationListNavigationLayer_ContactList = gql`
  fragment AutomationListNavigationLayer_ContactList on ContactList {
    id
    ...AutomationListHome_ContactList
    ...AutomationListReviewDuplicates_ContactList
    ...FinalizePushToCrm_ContactList
    ...FilterContactListContacts_ContactList
  }
  ${AutomationListHome_ContactList}
  ${AutomationListReviewDuplicates_ContactList}
  ${FinalizePushToCrm_ContactList}
  ${FilterContactListContacts_ContactList}
`;

export enum AutomationListStateEnum {
  Home = "home",
  ReviewDuplicates = "reviewDuplicates",
  FinalizePushToCrm = "finalizePushToCrm",
}

const AutomationListNavigationLayer = ({
  contactList,
  filters,
}: {
  contactList:
    | AutomationListNavigationLayer_ContactListFragment
    | null
    | undefined;
  filters: IContactListFilters;
}) => {
  const [viewState, setViewState] = useState<AutomationListStateEnum>(
    AutomationListStateEnum.Home,
  );
  const [duplicateActions, setDuplicateActions] = useState<
    FormDuplicateActionsType["formDuplicateActions"]
  >([]);

  const automationListHomeContactList = useFragment(
    AutomationListHome_ContactListFragmentDoc,
    contactList,
  );

  const automationListFinalizePushToCrmContactList = useFragment(
    FinalizePushToCrm_ContactListFragmentDoc,
    contactList,
  );

  const [selectedCrmObject, setSelectedCrmObject] = useState<CrmObjectType>(
    CrmObjectType.Contact,
  );

  const contactListFiltersContacts = useFragment(
    FilterContactListContacts_ContactListFragmentDoc,
    contactList,
  );

  const { filteredContactIdsToOrgContactIds, missingPersonaCount } =
    useFilterAndSortContactListContacts({
      contactList: contactListFiltersContacts ?? null,
      filters,
    });

  if (viewState === "home") {
    return (
      <AutomationListHome
        contactList={automationListHomeContactList ?? null}
        setViewState={setViewState}
        setSelectedCrmObject={setSelectedCrmObject}
        filters={filters}
        filteredContactIdsToOrgContactIds={filteredContactIdsToOrgContactIds}
        missingPersonaCount={missingPersonaCount}
      />
    );
  }

  if (viewState === "reviewDuplicates") {
    return (
      <AutomationListReviewDuplicates
        setViewState={setViewState}
        crmObject={selectedCrmObject}
        setDuplicateActions={setDuplicateActions}
        filteredContactIdsToOrgContactIds={filteredContactIdsToOrgContactIds}
      />
    );
  }

  if (viewState === "finalizePushToCrm") {
    return (
      <FinalizePushToCrm
        setViewState={setViewState}
        crmObject={selectedCrmObject}
        duplicateActions={duplicateActions}
        contactList={automationListFinalizePushToCrmContactList ?? null}
        filteredContactIdsToOrgContactIds={filteredContactIdsToOrgContactIds}
      />
    );
  }

  return null;
};

export default AutomationListNavigationLayer;
