import classNames from "classnames";
import CiroTextInput from "../../../shared/CiroTextInput";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FormProvider, useWatch } from "react-hook-form";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import EnrichmentStepsDropdown from "../../EnrichmentStepsDropdown";
import CiroTextArea from "../../../shared/CiroTextArea";
import {
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentStepCard_EnrichmentStepQuery,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { IUpsertEnrichmentStepVariables } from "../EnrichmentStepCard";
import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { useFragment } from "../../../../__generated__";
import { ApolloError } from "@apollo/client";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

export const EnrichmentStepTechniqueDefaultCardSchema = yup
  .object({
    enrichment_technique: yup
      .string()
      .required("Enrichment Technique is required"),
    parentEnrichmentStepInputs: yup.array().of(
      yup.object({
        key: yup.string().required("Key is required"),
        input: yup.string().nullable(),
        required: yup.boolean().required("Required is required"),
        sourceEnrichmentStepId: yup.number(),
      }),
    ),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required();

interface IEnrichmentStepTechniqueDefaultCardProps {
  confirmUpdateEnrichmentStep: ({
    data,
  }: {
    data: IUpsertEnrichmentStepVariables;
  }) => void;
  selectedEnrichmentTechnique: EnrichmentTechniqueEnum;
  enrichmentStep: EnrichmentStepCard_EnrichmentStepQuery["enrichmentStep"];
  onClose: () => void;
  loading: boolean;
  error: ApolloError | undefined;
}

const EnrichmentStepTechniqueDefaultCard = ({
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  selectedEnrichmentTechnique,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueDefaultCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const getDefaultValues = useCallback(() => {
    const filterEnrichmentSteps = parseEnrichmentStepsForList(
      enrichmentStepWithFilters,
    );

    return (
      {
        enrichment_technique: selectedEnrichmentTechnique,
        parentEnrichmentStepInputs:
          enrichmentStep?.parentEnrichmentStepInputs.map(
            (parentEnrichmentStepInput) => {
              return {
                key: parentEnrichmentStepInput.key,
                input: parentEnrichmentStepInput.input || null,
                required: parentEnrichmentStepInput.required,
                sourceEnrichmentStepId:
                  parentEnrichmentStepInput.sourceEnrichmentStep?.id,
              };
            },
          ) || [],
        filterEnrichmentSteps: filterEnrichmentSteps,
      } || []
    );
  }, [enrichmentStep, selectedEnrichmentTechnique, enrichmentStepWithFilters]);

  const formMethods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueDefaultCardSchema),
    defaultValues: getDefaultValues(),
  });

  const {
    control,
    formState: { errors, isDirty },
    register,
    setValue,
    handleSubmit,
    reset,
  } = formMethods;

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  const [parentEnrichmentStepInputs, filterEnrichmentSteps] = useWatch({
    control,
    name: ["parentEnrichmentStepInputs", "filterEnrichmentSteps"],
  });

  const formatAndSubmitRequest = (data: any) => {
    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: data.enrichment_technique || "",
          selected_input: null,
          parentEnrichmentStepInputs: data.parentEnrichmentStepInputs.map(
            (parentEnrichmentStepInput: any) => {
              return {
                key: parentEnrichmentStepInput.key,
                input: parentEnrichmentStepInput.input || null,
                required: parentEnrichmentStepInput.required,
                sourceEnrichmentStepId:
                  parentEnrichmentStepInput.sourceEnrichmentStepId || undefined,
              };
            },
          ),
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div>
        <div className={classNames("font-semibold", "pt-4")}>
          <div className={classNames("flex", "items-center")}>
            <span className={classNames("pr-4")}>Inputs</span>
            <CiroButton
              analyticsField="Add Enrichment Step Input"
              onClick={() => {
                setValue("parentEnrichmentStepInputs", [
                  {
                    key: "",
                    input: null,
                    required: false,
                    sourceEnrichmentStepId: undefined,
                  },
                  ...parentEnrichmentStepInputs,
                ]);
              }}
              style={CiroButtonStyleEnum.UNSTYLED}
            >
              <PlusCircleIcon
                className={classNames("w-5", "text-gray-400")}
              />
            </CiroButton>
          </div>
        </div>
        {parentEnrichmentStepInputs.map((parentEnrichmentStepInput, i) => {
          return (
            <div
              key={i}
              className={classNames(
                "mt-4",
                "p-4",
                "whitespace-pre-wrap",
                "border",
                "rounded-lg",
                "bg-gray-100",
              )}
            >
              <div
                className={classNames(
                  "pt-4",
                  "grid",
                  "gap-4",
                  "grid-cols-2",
                )}
              >
                <CiroCheckbox
                  label="Required"
                  checked={parentEnrichmentStepInput.required || false}
                  {...register(`parentEnrichmentStepInputs.${i}.required`)}
                  onClick={() => {
                    setValue(
                      `parentEnrichmentStepInputs.${i}.required`,
                      !parentEnrichmentStepInputs[i]?.required,
                    );
                  }}
                />
                <div
                  className={classNames("flex", "justify-end")}
                >
                  <CiroButton
                    analyticsField="Remove Enrichment Step Input"
                    onClick={() => {
                      setValue(
                        "parentEnrichmentStepInputs",
                        parentEnrichmentStepInputs.filter((_, j) => j !== i),
                      );
                    }}
                    style={CiroButtonStyleEnum.UNSTYLED}
                  >
                    <TrashIcon
                      className={classNames(
                        "w-5",
                        "text-gray-400",
                      )}
                    />
                  </CiroButton>
                </div>
                <EnrichmentStepsDropdown
                  selectedStepId={
                    parentEnrichmentStepInput.sourceEnrichmentStepId || null
                  }
                  setSelectedStepId={(v) => {
                    setValue(
                      `parentEnrichmentStepInputs.${i}.sourceEnrichmentStepId`,
                      v || undefined,
                    );
                  }}
                />
                <CiroTextInput
                  label="Key"
                  register={register(`parentEnrichmentStepInputs.${i}.key`)}
                  error={errors.parentEnrichmentStepInputs?.[i]?.key?.message}
                />
              </div>
              <div className={classNames("pt-2")}>
                <CiroTextArea
                  label="Input"
                  register={register(`parentEnrichmentStepInputs.${i}.input`)}
                  error={errors.parentEnrichmentStepInputs?.[i]?.input?.message}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={classNames("mt-4")}>
        <FormProvider {...formMethods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueDefaultCard;
