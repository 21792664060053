import { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import papaparse from "papaparse";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Enrichments_enrichmentFlows } from "../../../routes/enrichments/Enrichments";
import {
  EnrichmentFlowAddMoreModal_AddRowsToFlowMutation,
  EnrichmentFlowAddMoreModal_AddRowsToFlowMutationVariables,
  EnrichmentFlowAddMoreModal_EnrichmentFlowFragment,
  EnrichmentFlowAddMoreModal_PermissionsQuery,
  EnrichmentFlowAddMoreModal_PermissionsQueryVariables,
} from "../../../__generated__/graphql";
import CiroModal from "../../shared/CiroModal";
import XIcon from "../../../assets/img/icons/XIcon";
import Loading from "../../shared/Loading";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../../shared/CiroTable/CiroTableHeader";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import CiroErrorMsg from "../../shared/forms/CiroErrorMsg";
import CiroDropDown from "../../shared/CiroDropdown";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";
import useCsvFileSizeError from "../../../reactHooks/enrichmentFlow/useFileError";
import { filterCsvHeadersAndData } from "../EnrichmentFlowUploadCSVPreviewModal";

export const EnrichmentFlowAddMoreModal_EnrichmentFlow = gql`
  fragment EnrichmentFlowAddMoreModal_EnrichmentFlow on EnrichmentFlow {
    id
    initializedSteps: enrichmentSteps(
      enrichmentTechniques: [initialize, initialize_company]
    ) {
      id
      name
      enrichment_technique
    }
  }
`;

const EnrichmentFlowAddMoreModal_Permissions = gql`
  query EnrichmentFlowAddMoreModal_Permissions {
    permissions {
      enrichmentAllowedRowLimit
    }
  }
`;

const EnrichmentFlowAddMoreModal_AddRowsToFlow = gql`
  mutation EnrichmentFlowAddMoreModal_AddRowsToFlow(
    $enrichmentFlowId: Int!
    $initializationColumnData: [AddRowsToInitializationStepInput!]!
  ) {
    addRowsToEnrichmentFlow(
      enrichmentFlowId: $enrichmentFlowId
      initializationColumnData: $initializationColumnData
    ) {
      success
      error
    }
  }
`;

const EnrichmentFlowAddRowsToFormSchema = yup.object({
  columnMap: yup
    .array()
    .of(
      yup.object({
        enrichmentStepId: yup.number().required(),
        mappedHeader: yup.string().nullable(),
      }),
    )
    .test(
      "unique-mappedHeader",
      "A Ciro Column can only be mapped once",
      (array) => {
        if (!array) return false;
        const mappedHeaders = array
          .map((item) => item.mappedHeader)
          .filter(Boolean);
        return mappedHeaders.length === new Set(mappedHeaders).size;
      },
    )
    .test(
      "At least one must be chosen",
      "Please map at least one column to a Ciro input column",
      (array) => {
        if (!array) return false;
        const mappedHeaders = array
          .map((item) => item.mappedHeader)
          .filter(Boolean);
        return mappedHeaders.length > 0;
      },
    ),
});

interface IUploadCSVCardProps {
  csvFile: File | null;
  onClose: () => void;
  enrichmentFlow: EnrichmentFlowAddMoreModal_EnrichmentFlowFragment;
}

const EnrichmentFlowAddMoreModal = ({
  csvFile,
  onClose,
  enrichmentFlow,
}: IUploadCSVCardProps) => {
  const {
    enrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    EnrichmentFlow_refetchEnrichmentFlow,
  } = useContext(EnrichmentFlowContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [csvLoading, setCsvLoading] = useState(true);
  const [headers, setHeaders] = useState<string[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [csvData, setCsvData] = useState<{ [v: string]: string }[] | null>(
    null,
  );

  const { data: permissionsData } = useQuery<
    EnrichmentFlowAddMoreModal_PermissionsQuery,
    EnrichmentFlowAddMoreModal_PermissionsQueryVariables
  >(EnrichmentFlowAddMoreModal_Permissions);

  const [
    addRowsToFlow,
    {
      data: addRowsToEnrichmentFlowData,
      error: addRowsToEnrichmentFlowError,
      loading: addRowsToEnrichmentFlowLoading,
    },
  ] = useMutation<
    EnrichmentFlowAddMoreModal_AddRowsToFlowMutation,
    EnrichmentFlowAddMoreModal_AddRowsToFlowMutationVariables
  >(EnrichmentFlowAddMoreModal_AddRowsToFlow, {
    refetchQueries: [Enrichments_enrichmentFlows],
  });

  const {
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EnrichmentFlowAddRowsToFormSchema),
    defaultValues: {
      columnMap: enrichmentFlow.initializedSteps.map((step) => ({
        enrichmentStepId: step.id,
        mappedHeader: null as null | string,
      })),
    },
  });

  const columnMap = watch("columnMap");

  const addRowsToEnrichmentFlow = ({
    columnMap,
  }: {
    columnMap: { mappedHeader: string | null; enrichmentStepId: number }[];
  }) => {
    addRowsToFlow({
      variables: {
        enrichmentFlowId: enrichmentFlow.id,
        initializationColumnData: columnMap
          .filter((col) => Boolean(col.mappedHeader))
          .map((column: any) => {
            return {
              enrichmentStepId: column.enrichmentStepId,
              initializationData: csvData!.map((csvRow) => {
                return csvRow[column.mappedHeader];
              }),
            };
          }),
      },
      onCompleted: () => {
        enrichmentFlowTableContainer_refetchEnrichmentFlowRows();
        EnrichmentFlow_refetchEnrichmentFlow();
        onClose();
      },
    });
  };

  useEffect(() => {
    if (!csvFile) {
      return;
    }

    setErrorMsg("");

    papaparse.parse(csvFile, {
      header: true, // Set to true if your CSV has a header row
      skipEmptyLines: "greedy",
      complete: (results: any) => {
        const { nonEmptyHeaders, dataWithoutEmptyHeaderColumns } =
          filterCsvHeadersAndData(results);
        setHeaders(nonEmptyHeaders);
        setTotalRows(results.data?.length);
        setCsvLoading(false);
        setCsvData(dataWithoutEmptyHeaderColumns);
      },
      error: (error: any) => {
        setErrorMsg(
          "Something went wrong. Please consult the Ciro team if you continue to experience issues",
        );
        console.error("Error parsing CSV:", error.message);
      },
    });
  }, [csvFile, headers.length, totalRows, setCsvData]);

  const fileSizeError = useCsvFileSizeError(
    headers,
    totalRows,
    csvFile,
    permissionsData?.permissions?.enrichmentAllowedRowLimit || 100,
  );

  return (
    <CiroModal isOpen={Boolean(csvFile)} onClose={onClose} size="LARGE">
      <div
        className={classNames(
          "overflow-y-auto",
          "max-h-screen-minus-16",
        )}
      >
        <div
          className={classNames(
            "flex",
            "font-medium",
            "items-center",
            "justify-between",
            "mb-4",
          )}
        >
          Add Rows
          <span
            onClick={() => {
              onClose();
            }}
            className={classNames("cursor-pointer")}
          >
            <XIcon />
          </span>
        </div>
        {!csvLoading && (
          <>
            <div
              className={classNames(
                "mb-10",
                "text-gray-500",
                "text-sm",
              )}
            >
              Please select which columns in your CSV you would like to import.
            </div>
            <CiroTable>
              <thead>
                <CiroTableRow clickable={false}>
                  <CiroTableHeader isFirst={true} isLast={false}>
                    Ciro Initialization Columns
                  </CiroTableHeader>
                  <CiroTableHeader
                    align={CiroTableHeaderAlignEnum.RIGHT}
                    isFirst={false}
                    isLast={true}
                  >
                    Select CSV Column
                  </CiroTableHeader>
                </CiroTableRow>
              </thead>
              <tbody>
                {enrichmentFlow.initializedSteps.map((step, index) => {
                  return (
                    <CiroTableRow key={index} clickable={false}>
                      <CiroTableCell lastLeft={index === headers.length - 1}>
                        <span className={classNames("mr-2")}>
                          {index + 1}
                        </span>
                        <span>{step.name}</span>
                      </CiroTableCell>
                      <CiroTableCell
                        align={CiroTableCellAlignEnum.RIGHT}
                        lastRight={index === headers.length - 1}
                        useFlex
                      >
                        <CiroDropDown
                          isMulti={false}
                          options={[
                            ...headers.map((header) => ({
                              label: header,
                              value: header,
                            })),
                            {
                              label: "Leave blank",
                              value: null,
                            },
                          ]}
                          value={columnMap[index]?.mappedHeader}
                          onChange={(newValue) => {
                            setValue(
                              `columnMap.${index}.mappedHeader`,
                              newValue,
                            );
                            clearErrors(`columnMap.${index}.mappedHeader`);
                          }}
                          error={
                            errors.columnMap?.[index]?.mappedHeader?.message
                          }
                        />
                      </CiroTableCell>
                    </CiroTableRow>
                  );
                })}
              </tbody>
            </CiroTable>
            {errors.columnMap?.message && (
              <CiroErrorMsg error={errors.columnMap.message} />
            )}
            <div className={classNames("mt-10", "mb-2")}>
              <CiroButton
                analyticsField={"clickedEnrichmentsAddRowsButton"}
                customClassName="w-full"
                disabled={Boolean(errorMsg)}
                onClick={handleSubmit(addRowsToEnrichmentFlow)}
                style={CiroButtonStyleEnum.LOUD}
              >
                Submit
              </CiroButton>
            </div>
          </>
        )}
        {(csvLoading || addRowsToEnrichmentFlowLoading) && (
          <Loading size="SMALL" />
        )}
        {!csvLoading && fileSizeError && <CiroErrorMsg error={fileSizeError} />}
        {Boolean(addRowsToEnrichmentFlowData) && (
          <div
            className={classNames(
              "mt-10",
              "text-gray-500",
              "text-sm",
            )}
          >
            {addRowsToEnrichmentFlowError && (
              <CiroErrorMsg
                error={`An error occurred: ${addRowsToEnrichmentFlowError.message}`}
              />
            )}
          </div>
        )}
      </div>
    </CiroModal>
  );
};

export default EnrichmentFlowAddMoreModal;
