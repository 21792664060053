import { FolderPlusIcon } from "@heroicons/react/24/outline";
import CiroButton from "../../shared/CiroButton";
import classNames from "classnames";
import { useRef, useState } from "react";
import EnrichmentFlowAddMoreModal, {
  EnrichmentFlowAddMoreModal_EnrichmentFlow,
} from "./EnrichmentFlowAddMoreModal";
import { gql } from "@apollo/client";
import {
  EnrichmentFlowAddMoreButton_EnrichmentFlowFragment,
  EnrichmentFlowAddMoreModal_EnrichmentFlowFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../__generated__/graphql";
import { useFragment } from "../../../__generated__";

export const EnrichmentFlowAddMoreButton_EnrichmentFlow = gql`
  fragment EnrichmentFlowAddMoreButton_EnrichmentFlow on EnrichmentFlow {
    id
    initializedSteps: enrichmentSteps(
      enrichmentTechniques: [initialize, initialize_company]
    ) {
      id
      enrichment_technique
    }
    ...EnrichmentFlowAddMoreModal_EnrichmentFlow
  }
  ${EnrichmentFlowAddMoreModal_EnrichmentFlow}
`;

interface IEnrichmentFlowAddMoreButtonProps {
  enrichmentFlow:
    | EnrichmentFlowAddMoreButton_EnrichmentFlowFragment
    | null
    | undefined;
}

const EnrichmentFlowAddMoreButton = ({
  enrichmentFlow,
}: IEnrichmentFlowAddMoreButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [csvFile, setCsvFile] = useState<File | null>(null);

  const addMoreModalFlow = useFragment(
    EnrichmentFlowAddMoreModal_EnrichmentFlowFragmentDoc,
    enrichmentFlow,
  );

  const isCsvUpload = enrichmentFlow?.initializedSteps?.some((step) => {
    return [
      EnrichmentTechniqueEnum.Initialize,
      EnrichmentTechniqueEnum.InitializeCompany,
    ].includes(step.enrichment_technique);
  });

  if (isCsvUpload) {
    return (
      <>
        <CiroButton
          analyticsField="upload new enrichment pressed"
          onClick={() => hiddenFileInput.current!.click()}
        >
          <FolderPlusIcon className={classNames("h-5")} />
        </CiroButton>
        <input
          type="file"
          onChange={(event) => {
            const fileUploaded = event.target.files![0];
            setCsvFile(fileUploaded);
            event.target.value = "";
          }}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />
        <EnrichmentFlowAddMoreModal
          enrichmentFlow={addMoreModalFlow!}
          csvFile={csvFile}
          onClose={() => {
            setCsvFile(null);
          }}
        />
      </>
    );
  }

  return null;
};

export default EnrichmentFlowAddMoreButton;
