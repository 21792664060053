import React from "react";
import classNames from "classnames";
import StarRatings from "react-star-ratings";
import {
  BusinessDetailsExtraDetails_CompanyFragment,
  Maybe,
  ProtectedFloat,
  ProtectedInt,
  ProtectedString,
} from "../../__generated__/graphql";
import { gql } from "@apollo/client";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { UPGRADE_PLAN_TOOLTIP } from "../../utils/consts";
import CiroCard from "../shared/CiroCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import BusinessDetailsSocialLink from "./BusinessDetailsSocialLink";
import YelpIcon from "../../assets/img/icons/YelpIcon";
import FacebookIcon from "../../assets/img/icons/FacebookIcon";
import InstagramIcon from "../../assets/img/icons/InstagramIcon";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import GoogleIcon from "../../assets/img/icons/GoogleIcon";

export const BusinessDetailsExtraDetails_Company = gql`
  fragment BusinessDetailsExtraDetails_Company on Company {
    yelp_star_rating {
      value
      populated
    }
    yelp_num_reviews {
      value
      populated
    }
    google_star_rating {
      value
      populated
    }
    google_num_reviews {
      value
      populated
    }
    instagram_follower_count {
      value
      populated
    }
    number_of_employees {
      value
      populated
    }
    number_of_locations {
      value
      populated
    }
    number_of_providers {
      value
      populated
    }
    instagram_url {
      value
      populated
    }
    google_url {
      value
      populated
    }
    linkedin_url {
      value
      populated
    }
    yelp_url {
      value
      populated
    }
    facebook_url {
      value
      populated
    }
  }
`;

interface IBusinessDetailsExtraDetailProps {
  label: string;
  field?: Maybe<ProtectedString | ProtectedFloat | ProtectedInt>;
  noBorder?: boolean;
  tooltipText?: string;
  renderAsString?: boolean;
}

interface IBusinessDetailsRatingDetailProps {
  label: string;
  rating?: Maybe<ProtectedFloat | ProtectedInt>;
  numReviews?: Maybe<ProtectedFloat | ProtectedInt>;
}

const BusinessDetailsExtraDetail = ({
  label,
  field,
  noBorder,
  tooltipText,
  renderAsString = false
}: IBusinessDetailsExtraDetailProps) => {
  if (!field?.populated) {
    return null;
  }

  return (
    <div
      className={classNames([
        "text-left",
        "pr-2",
        "mb-6",
        {
          "border-r-1": !noBorder,
          "border-gray-200": !noBorder,
        },
        "min-w-[90px]",
      ])}
    >
      <div
        className={classNames([
          "font-medium",
          "gray-900",
          "pb-2",
        ])}
      >
        <span className={classNames("pr-2")}>{label}</span>
        {tooltipText && (
          <CiroTooltipContainer tooltip={<div>{tooltipText}</div>}>
            <FontAwesomeIcon icon={faQuestionCircle} color="rgba(0,0,0,.55)" />
          </CiroTooltipContainer>
        )}
      </div>
      <div>
        {field.value ? (
          <div className={classNames("text-gray-700")}>
            {renderAsString ? field.value :
              <NumberFormat
              value={field.value}
              thousandSeparator={true}
              displayType={"text"}
            />}
          </div>
        ) : (
          <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
            <div className="blur-sm">
              {Math.round(Math.random() * 10)}
            </div>
          </CiroTooltipContainer>
        )}
      </div>
    </div>
  );
};

const BusinessDetailsRatingDetail = ({
  label,
  rating,
  numReviews,
}: IBusinessDetailsRatingDetailProps) => {
  if (!rating?.populated || !numReviews?.populated) {
    return null;
  }
  return (
    <div
      className={classNames([
        "text-left",
        "pr-2",
        "mb-6",
        "border-r-1",
        "border-gray-200",
      ])}
    >
      <div
        className={classNames([
          "font-medium",
          "gray-900",
          "pb-1",
        ])}
      >
        <span className={classNames("pr-2")}>{label}</span>
      </div>
      <div className={classNames("flex")}>
        {rating?.value ? (
          <div className={classNames("flex")}>
            <div
              className={classNames(
                "text-gray-500",
                "pt-1.5",
                "text-xs",
              )}
            >
              {rating.value!}
            </div>
            <div className={classNames("pl-1", "min-w-24")}>
              <StarRatings
                rating={rating.value!}
                starDimension="16px"
                starSpacing="0px"
                starRatedColor="#F78E11"
              />
            </div>
          </div>
        ) : (
          <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
            <div className={classNames("flex", "blur-sm")}>
              <div
                className={classNames(
                  "text-gray-500",
                  "pt-1.5",
                  "text-xs",
                )}
              >
                {Math.round(Math.random() * 5)}
              </div>
              <div className={classNames("pl-1", "min-w-24")}>
                <StarRatings
                  rating={3.5}
                  starDimension="16px"
                  starSpacing="0px"
                  starRatedColor="#F78E11"
                />
              </div>
            </div>
          </CiroTooltipContainer>
        )}
        {numReviews?.value ? (
          <div
            className={classNames(
              "text-gray-500",
              "pt-1.5",
              "text-xs",
            )}
          >
            {"(" + numReviews.value + ")"}
          </div>
        ) : (
          <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
            <div
              className={classNames(
                "text-gray-500",
                "pt-1.5",
                "text-xs",
                "blur-sm",
              )}
            >
              {"(" + Math.round(Math.random() * 1000) + ")"}
            </div>
          </CiroTooltipContainer>
        )}
      </div>
    </div>
  );
};

interface IBusinessDetailsExtraDetails {
  company: BusinessDetailsExtraDetails_CompanyFragment;
}

const BusinessDetailsExtraDetails = ({
  company,
}: IBusinessDetailsExtraDetails) => {

  const numberOfExtraDetails = [
    company.google_star_rating?.populated,
    company.google_num_reviews?.populated,
    company.number_of_providers?.populated,
    company.number_of_locations?.populated,
    company.number_of_employees?.populated,
    company.yelp_star_rating?.populated,
    company.yelp_num_reviews?.populated,
    company.instagram_follower_count?.populated,
    company.facebook_url?.populated,
    company.instagram_url?.populated,
    company.linkedin_url?.populated,
    company.google_url?.populated,
    company.yelp_url?.populated,
  ].filter(Boolean).length;

  const numberOfNumericDetails = [
    company.number_of_providers?.populated,
    company.number_of_locations?.populated,
    company.number_of_employees?.populated,
    company.instagram_follower_count?.populated,
  ].filter(Boolean).length;

  const renderExtraDetailsWithoutBorder = numberOfNumericDetails < 2;
  return numberOfExtraDetails === 0 ? null : (
    <div className={classNames("mt-4", "lg:mt-0")}>
      <CiroCard size="small">
        <div
          className={classNames([
            "lg:max-2xl:grid-cols-1",
            "grid-cols-2",
            "grid",
            "gap-x-4",
            "gap-y-2",
          ])}
        >
          <BusinessDetailsRatingDetail
            label="Google"
            rating={company.google_star_rating}
            numReviews={company.google_num_reviews}
          />
          <BusinessDetailsRatingDetail
            label="Yelp"
            rating={company.yelp_star_rating}
            numReviews={company.yelp_num_reviews}
          />
          <BusinessDetailsExtraDetail
            label="Instagram Follower Count"
            field={company.instagram_follower_count}
            noBorder={renderExtraDetailsWithoutBorder}
          />
          <BusinessDetailsExtraDetail
            label="Est. # of Providers"
            field={company.number_of_providers}
            noBorder={renderExtraDetailsWithoutBorder}
          />
          <BusinessDetailsExtraDetail
            label="Est. # of Locations"
            tooltipText="Total locations is based on the company website"
            field={company.number_of_locations}
            noBorder={renderExtraDetailsWithoutBorder}
          />
          <BusinessDetailsExtraDetail
            label="Est. # of Employees"
            field={company.number_of_employees}
            noBorder={renderExtraDetailsWithoutBorder}
            renderAsString
          />
        </div>
        <div className={classNames("flex", "justify-center")}>
          <BusinessDetailsSocialLink
            field={company?.yelp_url}
            icon={<YelpIcon />}
          />
          <BusinessDetailsSocialLink
            field={company?.facebook_url}
            icon={<FacebookIcon />}
          />
          <BusinessDetailsSocialLink
            field={company?.instagram_url}
            icon={<InstagramIcon />}
          />
          <BusinessDetailsSocialLink
            field={company?.linkedin_url}
            icon={<LinkedInIcon />}
          />
          <BusinessDetailsSocialLink
            field={company?.google_url}
            icon={<GoogleIcon />}
          />
        </div>
      </CiroCard>
    </div>
  );
};

export default BusinessDetailsExtraDetails;
