import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

const CompanyNameFilter = ({
  companyNameFilter,
  setCompanyNameFilter,
}: {
  companyNameFilter: string[];
  setCompanyNameFilter: (v: string[]) => void;
}) => {
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={companyNameFilter.length ? 1 : 0}
      sectionTitle={"Company name"}
      filterOptions={[
        {
          label: "Company name includes any of:",
          filter: (
            <CiroDropDown
              creatable={true}
              defaultOptions={true}
              options={companyNameFilter.map((name) => {
                return { value: name, label: name };
              })}
              isMulti={true}
              value={companyNameFilter}
              onChange={(newValue) => {
                setCompanyNameFilter(newValue);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default CompanyNameFilter;
