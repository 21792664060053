import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useState } from "react";
import cloudDownload from "../../../assets/img/icons/cloud-download.svg";
import {
  CompaniesFromFiltersInput,
  DownloadLink_ScheduleExportMutation,
  DownloadLink_ScheduleExportMutationVariables,
  ExportFormatEnum,
  PremiumDataFieldEnum,
} from "../../../__generated__/graphql";
import classNames from "classnames";
import CloudDownloadIcon from "../../../assets/img/icons/CloudDownloadIcon";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../CiroDropdownButton";
import DownloadLinkModal from "./DownloadLinkModal";
import AppContext from "../../../contexts/AppContext";
import fileDownload from "js-file-download";
import NavigationContext from "../../../contexts/NavigationContext";

const downloadLinkStyles = {
  ICON: "icon",
};

const DownloadLink_ScheduleExport = gql`
  mutation DownloadLink_ScheduleExport(
    $collectionId: Int
    $companyPks: [ID!]
    $companyQuery: CompaniesFromFiltersAndCountInput
    $exportFormat: ExportFormatEnum!
    $premiumFields: [PremiumDataFieldEnum!]!
  ) {
    scheduleExport(
      collectionId: $collectionId
      companyPks: $companyPks
      companyQuery: $companyQuery
      exportFormat: $exportFormat
      premiumFields: $premiumFields
    ) {
      success
      message
      scheduleToken
    }
  }
`;

interface IDownloadLinkProps {
  collectionId?: number;
  companiesCount?: number;
  filename?: string | null;
  filteredCompanyQueryVariables?: CompaniesFromFiltersInput;
  selectedCompanyPks?: string[];
  style?: typeof downloadLinkStyles.ICON;
}

function DownloadLink({
  collectionId,
  companiesCount,
  filename,
  filteredCompanyQueryVariables,
  selectedCompanyPks,
  style,
}: IDownloadLinkProps) {
  const [downloadType, setDownloadType] = useState<ExportFormatEnum>(
    ExportFormatEnum.Combined,
  );
  const { accessToken } = useContext(AppContext);
  const { refreshNavData } = useContext(NavigationContext);
  const [isExporting, setIsExporting] = useState(false);
  const [queryLimit, setQueryLimit] = useState(0);

  const [
    scheduleExport,
    {
      reset: resetScheduleExport,
      data: scheduleExportData,
      loading: scheduleExportLoading,
    },
  ] = useMutation<
    DownloadLink_ScheduleExportMutation,
    DownloadLink_ScheduleExportMutationVariables
  >(DownloadLink_ScheduleExport);

  const downloadCSV = useCallback(
    async (scheduleToken: string) => {
      const exportResponse = await fetch(
        `/export-accounts?scheduleToken=${scheduleToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      const blob = await exportResponse.blob();
      fileDownload(blob, `${filename || "ciro-data"}.csv`);
      setConfirmModalIsOpen(false);
      setIsExporting(false);
      // Refresh credit balance in navigation
      refreshNavData();
    },
    [accessToken, filename, refreshNavData],
  );

  const usingQueryFilters =
    filteredCompanyQueryVariables && !selectedCompanyPks?.length;

  const startDownloadingByPk = useCallback(
    (premiumFields: PremiumDataFieldEnum[]) => {
      setIsExporting(true);
      scheduleExport({
        variables: {
          companyPks: usingQueryFilters ? undefined : selectedCompanyPks,
          collectionId: collectionId,
          exportFormat: downloadType,
          companyQuery: usingQueryFilters
            ? {
                filters: filteredCompanyQueryVariables,
                limit: queryLimit,
                offset: 0,
              }
            : undefined,
          premiumFields,
        },
        onCompleted: async (data) => {
          if (data.scheduleExport.scheduleToken) {
            downloadCSV(data.scheduleExport.scheduleToken);
          } else if (!data.scheduleExport.success) {
            setIsExporting(false);
          }
        },
      });
    },
    [
      scheduleExport,
      usingQueryFilters,
      selectedCompanyPks,
      collectionId,
      downloadType,
      filteredCompanyQueryVariables,
      queryLimit,
      downloadCSV,
    ],
  );

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  useEffect(() => {
    if (!confirmModalIsOpen) {
      resetScheduleExport();
    }
  }, [confirmModalIsOpen, resetScheduleExport]);

  const csvLoading = scheduleExportLoading;
  const selectedCount = selectedCompanyPks?.length;

  return (
    <div className={classNames("flex", "justify-end")}>
      <DownloadLinkModal
        companiesCount={companiesCount}
        closeModal={() => setConfirmModalIsOpen(false)}
        collectionId={collectionId}
        companyPks={selectedCompanyPks}
        filteredCompanyQueryVariables={filteredCompanyQueryVariables}
        handleDownload={startDownloadingByPk}
        isExporting={isExporting}
        isOpen={confirmModalIsOpen}
        queryLimit={queryLimit}
        setQueryLimit={setQueryLimit}
        scheduleToken={scheduleExportData?.scheduleExport.scheduleToken}
      />
      {style === downloadLinkStyles.ICON && (
        <div>
          <div>{csvLoading && <span>Loading...</span>}</div>
          {!csvLoading && (
            <div>
              <div
                onClick={() => {
                  setConfirmModalIsOpen(true);
                  setDownloadType(ExportFormatEnum.Combined);
                }}
              >
                <img src={cloudDownload} alt="Download" width={20} />
              </div>
            </div>
          )}
        </div>
      )}
      {style !== downloadLinkStyles.ICON && selectedCompanyPks && (
        <div>
          <CiroDropdownButton
            customClassName="test-download-link"
            disabled={csvLoading}
            label={
              <span className="flex">
                <span className="mr-4">
                  <CloudDownloadIcon />
                </span>
                {selectedCount && selectedCount > 0 ? selectedCount : "Export"}
              </span>
            }
          >
            <CiroDropdownButtonItem
              onClick={() => {
                setDownloadType(ExportFormatEnum.Companies);
                setConfirmModalIsOpen(true);
              }}
              analyticsField="Download as companies"
            >
              Companies
            </CiroDropdownButtonItem>
            <CiroDropdownButtonItem
              onClick={() => {
                setDownloadType(ExportFormatEnum.Contacts);
                setConfirmModalIsOpen(true);
              }}
              analyticsField="Download as contacts"
            >
              Contacts
            </CiroDropdownButtonItem>
            <CiroDropdownButtonItem
              onClick={() => {
                setDownloadType(ExportFormatEnum.Combined);
                setConfirmModalIsOpen(true);
              }}
              analyticsField="Download combined"
            >
              Combined CSV
            </CiroDropdownButtonItem>
          </CiroDropdownButton>
        </div>
      )}
    </div>
  );
}

export { DownloadLink, downloadLinkStyles };
