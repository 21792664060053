import React, { useCallback, useMemo, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import AccountsTable, {
  AccountsTable_CompaniesFromFiltersResponse,
  AccountsTable_Permissions,
  AccountsTable_UserAccount,
} from "../../components/accounts/AccountsTable";
import useAccountFilters from "../../reactHooks/filters/accounts/useAccountFilters";
import {
  AccountsTable_CompaniesFromFiltersResponseFragmentDoc,
  CollectionSearchShareCollection_CollectionFragmentDoc,
  CollectionSearch_CollectionQuery,
  CollectionSearch_CollectionQueryVariables,
  CollectionSearch_CompaniesFromFiltersQuery,
  CollectionSearch_CompaniesFromFiltersQueryVariables,
} from "../../__generated__/graphql";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import approx from "approximate-number";
import { DownloadLink } from "../../components/shared/DownloadLink/DownloadLink";
import pluralize from "pluralize";
import CiroCTACard from "../../components/shared/CiroCTACard";
import getStoredChecked from "../../utils/getStoreChecked";
import { useFragment } from "../../__generated__";
import CiroFilterAccountsWrapper from "../../components/shared/CiroFilter/CiroFilterAccountsWrapper";
import { useParams, useSearchParams } from "react-router-dom";
import createAccountFiltersInput from "../../services/filters/createAccountFiltersInput";
import CiroUnlockFromFiltersCard from "../../components/shared/CiroUnlockFromFiltersCard";
import FilterContext from "../../contexts/FilterContext";
import { ACCOUNTS_MAX_NUM_ROWS } from "../accounts/Accounts";
import CollectionSearchRemoveFromCollection, {
  CollectionSearchRemoveFromCollection_Collection,
} from "../../components/collectionSearch/CollectionSearchRemoveFromCollection";
import CollectionSearchShareCollection, {
  CollectionSearchShareCollection_Collection,
  CollectionSearchShareCollection_Permissions,
  UserCollectionRoleEnum,
} from "../../components/collectionSearch/CollectionSearchShareCollection";
import CiroAlert from "../../components/shared/CiroAlert";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import EditCollectionModal, {
  EditCollectionModal_Permissions,
} from "../../components/collections/EditCollectionModal";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import CiroSpinner from "../../components/shared/CiroSpinner";
import AccountsEnrichButton, {
  AccountsEnrichButton_Permissions,
} from "../../components/accounts/AccountsEnrichButton";

export const CollectionSearch_companyCountFromFilters = gql`
  query CollectionSearch_companyCountFromFilters(
    $filters: CompaniesFromFiltersInput
  ) {
    companyCountFromFilters(filters: $filters)
  }
`;

export const CollectionSearch_Collection = gql`
  query CollectionSearch_Collection($collectionId: Int!) {
    collection(id: $collectionId) {
      id
      name
      currentUserCollection {
        role
      }
      ...CollectionSearchRemoveFromCollection_Collection
      ...CollectionSearchShareCollection_Collection
    }
    permissions {
      ...EditCollectionModal_Permissions
      ...CollectionSearchShareCollection_Permissions
    }
  }
  ${CollectionSearchRemoveFromCollection_Collection}
  ${CollectionSearchShareCollection_Collection}
  ${EditCollectionModal_Permissions}
  ${CollectionSearchShareCollection_Permissions}
`;

export const CollectionSearch_companiesFromFilters = gql`
  query CollectionSearch_companiesFromFilters(
    $filters: CompaniesFromFiltersInput
    $limit: Int
    $offset: Int
  ) {
    permissions {
      canExportAccountsData
      canShareCollections
      accountPaginationLimit
      ...AccountsTable_Permissions
      ...AccountsEnrichButton_Permissions
    }
    companiesFromFilters(filters: $filters, limit: $limit, offset: $offset) {
      companies {
        company {
          pk
        }
      }
      lockedCount
      ...AccountsTable_CompaniesFromFiltersResponse
    }
    userAccount {
      id
      ...AccountsTable_UserAccount
    }
  }
  ${AccountsTable_CompaniesFromFiltersResponse}
  ${AccountsTable_Permissions}
  ${AccountsTable_UserAccount}
  ${AccountsEnrichButton_Permissions}
`;

function CollectionSearch() {
  const { collectionId } = useParams();
  const { data: collectionData } = useQuery<
    CollectionSearch_CollectionQuery,
    CollectionSearch_CollectionQueryVariables
  >(CollectionSearch_Collection, {
    variables: {
      collectionId: Number(collectionId),
    },
  });

  const collectionAccountsStoredChecked = useMemo(
    () => `collection_search_accounts_stored_checked_${collectionId}`,
    [collectionId],
  );

  const [checked, setCheckedState] = useState<Set<string>>(
    getStoredChecked(collectionAccountsStoredChecked),
  );
  const setChecked = useCallback(
    (newChecked: Set<string>) => {
      setCheckedState(newChecked);
      sessionStorage.setItem(
        collectionAccountsStoredChecked,
        JSON.stringify([...newChecked]),
      );
    },
    [collectionAccountsStoredChecked],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [showSmbFilters, setShowSmbFilters] = useState(false);
  const [semanticSearchQuery, setSemanticSearchQuery] = useState<string | null>(
    null,
  );

  const [editCollectionModalOpen, setEditCollectionModalOpen] = useState(false);

  const filters = useAccountFilters({
    searchParams,
    setSearchParams,
  });

  const { offset, setOffset, textTypeFilter, textFilter } = filters;

  const filteredCompanyQueryVariables = createAccountFiltersInput(filters);
  filteredCompanyQueryVariables.userCollectionsFilter = [Number(collectionId)];

  let start = offset + 1;

  const companiesQueryResult = useQuery<
    CollectionSearch_CompaniesFromFiltersQuery,
    CollectionSearch_CompaniesFromFiltersQueryVariables
  >(CollectionSearch_companiesFromFilters, {
    // Needed for refetch after running ColelctionSearchRemoveFromCollection mutation
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: filteredCompanyQueryVariables,
      limit: ACCOUNTS_MAX_NUM_ROWS,
      offset: offset,
    },
  });

  const { data: collectionCountData, loading: companyCountResultsLoading } =
    useQuery(CollectionSearch_companyCountFromFilters, {
      variables: {
        filters: filteredCompanyQueryVariables,
      },
    });
  const companiesCount = collectionCountData?.companyCountFromFilters || 0;
  const {
    data: filteredCompaniesData,
    error: filteredCompaniesError,
    loading: filteredCompaniesLoading,
    refetch: refetchFilteredCompanies,
  } = companiesQueryResult;
  const permissions = filteredCompaniesData?.permissions || {};
  const { canExportAccountsData } = permissions;
  const filteredCompanies =
    filteredCompaniesData?.companiesFromFilters?.companies;
  const lockedCount =
    filteredCompaniesData?.companiesFromFilters?.lockedCount || 0;

  const accountsTableCompanies = useFragment(
    AccountsTable_CompaniesFromFiltersResponseFragmentDoc,
    filteredCompaniesData?.companiesFromFilters,
  );

  const shareCollectionFragment = useFragment(
    CollectionSearchShareCollection_CollectionFragmentDoc,
    collectionData?.collection,
  );

  if (filteredCompaniesError) {
    return (
      <div className={classNames("p-4")}>
        <CiroAlert
          message={`Company query error: ${filteredCompaniesError?.message}`}
        />
      </div>
    );
  }

  const end =
    Math.min(offset + ACCOUNTS_MAX_NUM_ROWS, companiesCount) - lockedCount;

  const numberVisibleChecked = (filteredCompanies || []).filter((key) =>
    checked.has(key.company.pk),
  ).length;

  const numberChecked = checked.size;

  const currentUserRole =
    collectionData?.collection?.currentUserCollection?.role;
  const canEdit = currentUserRole === UserCollectionRoleEnum.CAN_EDIT;

  return (
    <FilterContext.Provider
      value={{
        page: "COLLECTION_SEARCH",
        semanticSearchQuery,
        setSemanticSearchQuery,
        stringifiedParams: filters.stringifiedParams,
        companiesCount,
        showSmbFilters,
        setShowSmbFilters,
      }}
    >
      <CiroContainer className={classNames("bg-zinc-100")}>
        <div
          className={classNames(
            "flex",
            "justify-between",
            "mb-8",
            "text-left",
            "w-full",
          )}
        >
          <CiroTitle
            title={
              <div>
                Collection
                <div
                  className={classNames(
                    "text-xl",
                    "font-normal",
                  )}
                >
                  <div
                    className={classNames(
                      "flex",
                      "items-center",
                    )}
                  >
                    <div className={classNames("pr-4")}>
                      {collectionData?.collection?.name}
                    </div>
                    {canEdit && (
                      <CiroButton
                        analyticsField="Open Ciro Collection Styles"
                        style={CiroButtonStyleEnum.UNSTYLED}
                        onClick={() => setEditCollectionModalOpen(true)}
                      >
                        <PencilSquareIcon
                          className={classNames("h-5 w-5")}
                        />
                      </CiroButton>
                    )}
                    <EditCollectionModal
                      collectionId={
                        editCollectionModalOpen && collectionId
                          ? collectionId
                          : null
                      }
                      closeModal={() => setEditCollectionModalOpen(false)}
                      permissions={collectionData?.permissions}
                    />
                  </div>
                </div>
              </div>
            }
            subtitleClassName="test-display-company-count"
            subtitle={
              Boolean(companiesCount) ? (
                <>
                  Displaying{" "}
                  <NumberFormat
                    value={start}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                  -
                  <NumberFormat
                    value={end}
                    thousandSeparator={true}
                    displayType={"text"}
                  />{" "}
                  of{" "}
                  {`${approx(companiesCount)} ${pluralize("account", companiesCount)}`}
                  {Boolean(lockedCount) &&
                    ` (${lockedCount} hidden on current page)`}
                </>
              ) : (
                <CiroSpinner loading={companyCountResultsLoading} />
              ) // Maintains correct spacing on loading
            }
          />
          <div className={classNames("flex")}>
            <AccountsEnrichButton
              numberChecked={numberChecked}
              selectedCompanyPks={Array.from(checked)}
              semanticSearchQuery={
                textTypeFilter === "semantic" ? textFilter : null
              }
              setCheckedCompanies={setChecked}
              permissions={permissions}
            />
            <div className={classNames("pr-2")}>
              <CollectionSearchShareCollection
                collection={shareCollectionFragment}
                permissions={permissions}
              />
            </div>
            <div>
              {canExportAccountsData && Boolean(numberChecked) && (
                <DownloadLink
                  selectedCompanyPks={[...checked]}
                  filteredCompanyQueryVariables={filteredCompanyQueryVariables}
                />
              )}
            </div>
          </div>
        </div>
        <CiroFilterAccountsWrapper
          resetOffset={() => setOffset(0)}
          additionalButtonOptions={
            canEdit && (
              <CollectionSearchRemoveFromCollection
                collection={collectionData?.collection}
                checked={checked}
                refetchFilteredCompanies={refetchFilteredCompanies}
                setChecked={setChecked}
              />
            )
          }
          accountFilters={filters}
        />
        {!filteredCompaniesLoading &&
          (permissions.accountPaginationLimit || 0) <=
            Math.ceil(offset / ACCOUNTS_MAX_NUM_ROWS) + 1 && (
            <div className={classNames("py-5")}>
              <CiroCTACard
                title="Upgrade to view more companies"
                subtitle="Access additional features such as CSV downloads, CRM integrations and additional filters"
              />
            </div>
          )}
        {Boolean(lockedCount) && (
          <CiroUnlockFromFiltersCard
            filteredCompanyQueryVariables={filteredCompanyQueryVariables}
            offset={offset}
            refetchQueries={[
              {
                query: CollectionSearch_companiesFromFilters,
                variables: {
                  filters: filteredCompanyQueryVariables,
                  limit: ACCOUNTS_MAX_NUM_ROWS,
                  offset,
                },
              },
            ]}
            totalNumberRecords={companiesCount}
          />
        )}
        <div
          className={classNames(
            "overflow-auto",
            "w-full",
            "my-4",
          )}
        >
          <AccountsTable
            canSelectRecords={Boolean(canEdit || canExportAccountsData)}
            checked={checked}
            filteredCompanyQueryVariables={filteredCompanyQueryVariables}
            companiesFromFilter={accountsTableCompanies}
            isPageLoading={
              filteredCompaniesLoading || companyCountResultsLoading
            }
            numberVisibleChecked={numberVisibleChecked}
            setChecked={setChecked}
            totalNumberChecked={numberChecked}
            permissions={permissions}
            setOffset={setOffset}
            offset={offset}
            userAccount={filteredCompaniesData?.userAccount}
          />
        </div>
      </CiroContainer>
    </FilterContext.Provider>
  );
}

export default withAuthenticationRequired(CollectionSearch, {
  onRedirecting: () => <Loading />,
});
