import classNames from "classnames";

const CiroHR = () => {
  return (
    <hr
      className={classNames([
        "bg-gray-200",
        "border-1",
        "h-px",
        "my-8",
        "w-full",
      ])}
    />
  );
};

export default CiroHR;
