import classNames from "classnames";

interface IFilterSectionProps {
  title: string;
  filters: JSX.Element[];
}

interface ICiroFilterGroupProps {
  filterSections: IFilterSectionProps[];
}

const CiroFilterGroup = ({ filterSections }: ICiroFilterGroupProps) => {
  return (
    <>
      {filterSections.map((filterSection) => {
        return (
          <div
            key={filterSection.title}
            className={classNames("border-b-1")}
          >
            <div
              className={classNames(
                "text-zinc-500",
                "text-xs",
                "pt-4",
                "px-4",
              )}
            >
              {filterSection.title}
            </div>
            {filterSection.filters.map((filter, i) => {
              return <div key={i}>{filter}</div>;
            })}
          </div>
        );
      })}
    </>
  );
};

export default CiroFilterGroup;
