import { gql, useMutation } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import {
  MobileNumbersWorkflowDeleteModal_DeleteWorkflowMutation,
  MobileNumbersWorkflowDeleteModal_DeleteWorkflowMutationVariables,
} from "../../__generated__/graphql";
import { useState } from "react";
import CiroTextInput from "../shared/CiroTextInput";
import { useNavigate } from "react-router-dom";
import { MobileNumbersWorkflowsTable_Workflows } from "../mobileNumbers/MobileNumbersTable/MobileNumbersWorkflowsTable";

interface IMobileNumbersWorkflowDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  workflowId: number;
}

const MobileNumbersWorkflowDeleteModal_DeleteWorkflow = gql`
  mutation MobileNumbersWorkflowDeleteModal_DeleteWorkflow($id: Int!) {
    deletePhoneNumberRequestWorkflow(id: $id) {
      success
      message
    }
  }
`;

const MobileNumbersWorkflowDeleteModal = ({
  isOpen,
  onClose,
  workflowId,
}: IMobileNumbersWorkflowDeleteModalProps) => {
  const navigate = useNavigate();
  const [deleteWorkflow, { loading: deleteWorkflowLoading }] = useMutation<
    MobileNumbersWorkflowDeleteModal_DeleteWorkflowMutation,
    MobileNumbersWorkflowDeleteModal_DeleteWorkflowMutationVariables
  >(MobileNumbersWorkflowDeleteModal_DeleteWorkflow);

  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const handleDelete = async () => {
    if (deleteConfirmation !== "DELETE") {
      setDeleteError("Please type 'DELETE' to confirm");
      return;
    }

    await deleteWorkflow({
      variables: { id: workflowId },
      onCompleted: () => {
        navigate(-1);
        onClose();
      },
      refetchQueries: [MobileNumbersWorkflowsTable_Workflows],
    });
  };

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">
        Delete Workflow?
      </h2>
      <div className="mt-4">
        <CiroTextInput
          label="Type 'DELETE' to confirm"
          value={deleteConfirmation}
          onChange={(e) => {
            setDeleteConfirmation(e.target.value);
            setDeleteError(null);
          }}
          placeholder="DELETE"
          error={deleteError}
        />
      </div>
      <div className="mt-4 flex justify-end space-x-2">
        <CiroButton
          style={CiroButtonStyleEnum.SECONDARY}
          analyticsField="cancel-workflow-delete"
          onClick={onClose}
          disabled={deleteWorkflowLoading}
        >
          Cancel
        </CiroButton>
        <CiroButton
          analyticsField="confirm-workflow-delete"
          style={CiroButtonStyleEnum.LOUD}
          onClick={handleDelete}
          disabled={deleteWorkflowLoading}
        >
          Delete
        </CiroButton>
      </div>
    </CiroModal>
  );
};

export default MobileNumbersWorkflowDeleteModal;
