import { useAuth0 } from "@auth0/auth0-react";
import CiroTitle from "../shared/CiroTitle";
import classNames from "classnames";
import UserPhotoIcon from "../shared/UserPhotoIcon";

const HomeProfileCard = () => {
  const { user } = useAuth0();

  const getGreeting = (): string => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning";
    } else if (currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  const givenName = user?.given_name || user?.name?.split(" ")[0] || null;

  return (
    <div
      className={classNames(
        "flex",
        "flex-row",
        "p-5",
        "items-center",
      )}
    >
      <UserPhotoIcon />
      <div className={classNames("pl-4")}>
        <CiroTitle
          title={
            givenName ? `${getGreeting()}, ${givenName}` : `${getGreeting()}`
          }
          subtitle={`Create the perfect prospect list, then take over the world!`}
          size="medium"
        />
      </div>
    </div>
  );
};

export default HomeProfileCard;
