import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import IntegrationTypeContext from "../../../contexts/IntegrationTypeContext";
import classNames from "classnames";
import {
  MergeIntegrationEnum,
  ReviewDuplicatesContactPill_CrmContactDetailsFragment,
  ReviewDuplicatesCrmContactDetails_CrmContactDetailsFragmentDoc,
} from "../../../__generated__/graphql";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { gql } from "@apollo/client";
import ReviewDuplicatesCrmContactDetails, {
  ReviewDuplicatesCrmContactDetails_CrmContactDetails,
} from "./ReviewDuplicatesCrmContactDetails";
import { useFragment as getFragmentData } from "../../../__generated__";
import HubspotIcon from "../../../assets/img/icons/HubspotIcon";
import SalesforceIcon from "../../../assets/img/icons/SalesforceIcon";

export const ReviewDuplicatesContactPill_CrmContactDetails = gql`
  fragment ReviewDuplicatesContactPill_CrmContactDetails on CrmContactDetails {
    ... on MergeCrmContact {
      id
      firstName
      lastName
    }
    ... on MergeCrmLead {
      id
      firstName
      lastName
    }
    ...ReviewDuplicatesCrmContactDetails_CrmContactDetails
  }
  ${ReviewDuplicatesCrmContactDetails_CrmContactDetails}
`;

const ReviewDuplicatesContactPill = ({
  crmContactDetails,
}: {
  crmContactDetails: ReviewDuplicatesContactPill_CrmContactDetailsFragment;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRefContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { integrationType } = useContext(IntegrationTypeContext);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        tooltipRefContainerRef.current &&
        !tooltipRefContainerRef.current.contains(event.target as Node)
      ) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={tooltipRefContainerRef}
      className={classNames(
        "flex",
        "flex-col",
        "w-fit",
      )}
    >
      <CiroTooltipContainer
        customTheme="light"
        visible={showTooltip}
        tooltip={
          <div ref={tooltipRef}>
            <ReviewDuplicatesCrmContactDetails
              crmContact={getFragmentData(
                ReviewDuplicatesCrmContactDetails_CrmContactDetailsFragmentDoc,
                crmContactDetails,
              )}
            />
          </div>
        }
      >
        <CiroButton
          analyticsField="Review duplicates CRM contact details"
          analyticsProps={{
            crmContactId: crmContactDetails.id,
          }}
          style={CiroButtonStyleEnum.UNSTYLED}
          onClick={() => {
            setShowTooltip(!showTooltip);
          }}
        >
          <div
            className={classNames(
              "bg-gray-100",
              "rounded-md",
              "px-3",
              "py-1",
              "mb-1",
              "w-fit",
              "cursor-pointer",
              "flex",
              "items-center",
              "gap-2",
            )}
          >
            <div>
              {integrationType === MergeIntegrationEnum.HubSpot ? (
                <HubspotIcon />
              ) : integrationType === MergeIntegrationEnum.Salesforce ? (
                <SalesforceIcon />
              ) : null}
            </div>
            <div>
              {crmContactDetails.firstName} {crmContactDetails.lastName}
            </div>
          </div>
        </CiroButton>
      </CiroTooltipContainer>
    </div>
  );
};

export default ReviewDuplicatesContactPill;
