import { useQuery } from "@apollo/client";
import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import ContactListTable, {
  ContactListTable_ContactList,
} from "../../components/automation/ContactListTable";
import { gql } from "@apollo/client";
import {
  Automation_AutopilotSessionsCountQuery,
  Automation_ContactListsQuery,
  Automation_PersonasQuery,
  Automation_UserAccountQuery,
  AutomationGetStarted_UserAccountFragmentDoc,
  ContactListTable_ContactListFragment,
  PersonaTable_PersonaFragment,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import AutomationSplashPage from "./AutomationSplashPage";
import UpsertContactListModal from "../../components/automation/UpsertContactListModal";
import { useState } from "react";
import AutomationHomeTableSection from "../../components/automation/AutomationHomeTableSection";
import PersonaTable, {
  PersonaTable_Persona,
} from "../../components/automation/PersonaTable";
import UpsertPersonaModal from "../../components/automation/UpsertPersonaModal";
import AutomationGetStarted, {
  AutomationGetStarted_UserAccount,
} from "../../components/automation/AutomationGetStarted";
import CiroLink from "../../components/shared/CiroLink";
import { useFragment } from "../../__generated__";

const Automation_ContactLists = gql`
  query Automation_ContactLists {
    contactLists {
      ...ContactListTable_ContactList
    }
  }
  ${ContactListTable_ContactList}
`;

const Automation_Personas = gql`
  query Automation_Personas {
    personas {
      ...PersonaTable_Persona
    }
  }
  ${PersonaTable_Persona}
`;

const Automation_UserAccount = gql`
  query Automation_UserAccount {
    userAccount {
      id
      autopilot_initial_search_url
      ...AutomationGetStarted_UserAccount
    }
  }
  ${AutomationGetStarted_UserAccount}
`;

const Automation_AutopilotSessionsCount = gql`
  query Automation_AutopilotSessionsCount {
    autopilotSessionsCount
  }
`;

const Automation = () => {
  const {
    data: contactListsData,
    loading: loadingContactLists,
    refetch: refetchContactLists,
  } = useQuery<Automation_ContactListsQuery>(Automation_ContactLists);
  const {
    data: personasData,
    loading: loadingPersonas,
    refetch: refetchPersonas,
  } = useQuery<Automation_PersonasQuery>(Automation_Personas);
  const { data: userAccountData, loading: loadingUserAccount } =
    useQuery<Automation_UserAccountQuery>(Automation_UserAccount);
  const {
    data: autopilotSessionsCountData,
    loading: loadingAutopilotSessionsCount,
  } = useQuery<Automation_AutopilotSessionsCountQuery>(
    Automation_AutopilotSessionsCount,
  );
  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);
  const [isCreatePersonaModalOpen, setIsCreatePersonaModalOpen] =
    useState(false);

  const contactLists =
    contactListsData?.contactLists as ContactListTable_ContactListFragment[];
  const personas = personasData?.personas as PersonaTable_PersonaFragment[];

  const initialSearchUrl =
    userAccountData?.userAccount?.autopilot_initial_search_url;

  const getStartedUserAccount = useFragment(
    AutomationGetStarted_UserAccountFragmentDoc,
    userAccountData?.userAccount,
  );

  return (
    <>
      {loadingContactLists ||
      loadingUserAccount ||
      loadingAutopilotSessionsCount ? (
        <SkeletonLoading
          numSkeletons={5}
          skeletonHeight={"3rem"}
          containerCustomerClassName={classNames(
            "mx-20",
            "my-10",
            "flex",
            "flex-col",
            "gap-6",
          )}
        />
      ) : (!contactLists || contactLists.length === 0) &&
        (!personas || personas.length === 0) ? (
        <AutomationSplashPage
          onCreateNewList={() => {
            setIsCreateListModalOpen(true);
          }}
        />
      ) : (
        <CiroContainer className={classNames("bg-zinc-100")}>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "w-full",
              "pb-6",
              "mb-4",
              "border-b-1",
              "border-gray-100",
            )}
          >
            <CiroTitle
              title="Autopilot"
              subtitle={
                <div>
                  Create lists and have Ciro automatically populate them with
                  leads from{" "}
                  {initialSearchUrl ? (
                    <CiroLink href={initialSearchUrl}>
                      LinkedIn Sales Navigator
                    </CiroLink>
                  ) : (
                    "LinkedIn Sales Navigator"
                  )}
                  .
                </div>
              }
            />
          </div>
          {autopilotSessionsCountData?.autopilotSessionsCount === 0 && (
            <AutomationGetStarted
              userAccount={getStartedUserAccount}
            />
          )}
          <AutomationHomeTableSection
            objectType="list"
            table={
              <ContactListTable
                contactLists={contactLists}
                loading={loadingContactLists}
                refetch={refetchContactLists}
              />
            }
            buttonHandler={() => setIsCreateListModalOpen(true)}
          />
          <AutomationHomeTableSection
            objectType="persona"
            table={
              personas && personas.length > 0 ? (
                <PersonaTable
                  personas={personas}
                  loading={loadingPersonas}
                  refetch={refetchPersonas}
                />
              ) : undefined
            }
            buttonHandler={() => setIsCreatePersonaModalOpen(true)}
          />
        </CiroContainer>
      )}
      <UpsertContactListModal
        isOpen={isCreateListModalOpen}
        onClose={() => setIsCreateListModalOpen(false)}
        onSuccess={async () => {
          await refetchContactLists();
          setIsCreateListModalOpen(false);
        }}
      />
      <UpsertPersonaModal
        isOpen={isCreatePersonaModalOpen}
        onClose={() => setIsCreatePersonaModalOpen(false)}
        onSuccess={async () => {
          await refetchPersonas();
          setIsCreatePersonaModalOpen(false);
        }}
      />
    </>
  );
};

export default Automation;
