import React, { useState } from "react";
import { gql } from "@apollo/client";
import { PushToCrmTransactionDetailsModal_PushToCrmTransactionFragment } from "../../../__generated__/graphql";
import { formatDistanceToNow } from "date-fns";
import classNames from "classnames";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import CiroModal from "../../shared/CiroModal";
import { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroButton from "../../shared/CiroButton";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableHeader from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../../shared/CiroTable/CiroTableCell";

export const PushToCrmTransactionDetailsModal_PushToCrmTransaction = gql`
  fragment PushToCrmTransactionDetailsModal_PushToCrmTransaction on PushToCrmTransaction {
    id
    created_at
    finished_at
    metadata {
      successLogs {
        id
        firstName
        lastName
        companyName
      }
      failureLogs {
        firstName
        lastName
        companyName
        error
      }
    }
  }
`;

const PushToCrmTransactionDetailsModal = ({
  pushToCrmTransaction,
}: {
  pushToCrmTransaction: PushToCrmTransactionDetailsModal_PushToCrmTransactionFragment | null;
}) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  if (!pushToCrmTransaction) {
    return null;
  }

  if (!pushToCrmTransaction.finished_at) {
    return (
      <div className={classNames("text-sm", "text-gray-500")}>
        Currently running push to CRM (began{" "}
        {formatDistanceToNow(
          new Date(pushToCrmTransaction.created_at ?? new Date()),
        )}{" "}
        ago)
      </div>
    );
  }

  const failureLogs = pushToCrmTransaction.metadata?.failureLogs ?? [];
  const successLogs = pushToCrmTransaction.metadata?.successLogs ?? [];

  return (
    <>
      <div className={classNames("flex", "items-center")}>
        <div className={classNames("text-sm", "text-gray-500")}>
          Push to CRM finished{" "}
          {formatDistanceToNow(
            new Date(pushToCrmTransaction?.finished_at ?? new Date()),
          )}{" "}
          ago
        </div>
        {failureLogs.length > 0 && (
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Check logs related to last run"
            analyticsProps={{
              pushToCrmTransactionId: pushToCrmTransaction.id,
            }}
            onClick={() => setIsErrorModalOpen(true)}
          >
            <div className={classNames("pl-2")}>
              <CiroTooltipContainer tooltip="Check logs related to last run">
                {failureLogs.length > 0 ? (
                  <ExclamationTriangleIcon
                    className={classNames(
                      "w-4",
                      "text-red-500",
                    )}
                  />
                ) : (
                  <CheckCircleIcon
                    className={classNames(
                      "w-4",
                      "text-green-500",
                    )}
                  />
                )}
              </CiroTooltipContainer>
            </div>
          </CiroButton>
        )}
      </div>
      <CiroModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        size={"FITTED"}
      >
        {failureLogs.length > 0 && (
          <div>
            <div className={classNames("text-lg")}>Errors</div>
            <div className={classNames("pt-2")}>
              <CiroTable>
                <thead className={classNames("table-header-group")}>
                  <CiroTableRow clickable={false}>
                    <CiroTableHeader>First name</CiroTableHeader>
                    <CiroTableHeader>Last name</CiroTableHeader>
                    <CiroTableHeader>Company name</CiroTableHeader>
                    <CiroTableHeader isLast={true}>Error</CiroTableHeader>
                  </CiroTableRow>
                </thead>
                <tbody className={classNames("table-row-group")}>
                  {failureLogs.map((log, index) => (
                    <CiroTableRow key={index} clickable={false}>
                      <CiroTableCell>{log.firstName}</CiroTableCell>
                      <CiroTableCell>{log.lastName}</CiroTableCell>
                      <CiroTableCell>{log.companyName}</CiroTableCell>
                      <CiroTableCell lastLeft={true}>{log.error}</CiroTableCell>
                    </CiroTableRow>
                  ))}
                </tbody>
              </CiroTable>
            </div>
          </div>
        )}
        {(successLogs?.length ?? 0) > 0 && (
          <div>
            <div className={classNames("text-lg")}>Successes</div>
            <div className={classNames("pt-2")}>
              <CiroTable>
                <thead className={classNames("table-header-group")}>
                  <CiroTableRow clickable={false}>
                    <CiroTableHeader>First name</CiroTableHeader>
                    <CiroTableHeader>Last name</CiroTableHeader>
                    <CiroTableHeader>Company name</CiroTableHeader>
                  </CiroTableRow>
                </thead>
                <tbody className={classNames("table-row-group")}>
                  {successLogs.map((log, index) => (
                    <CiroTableRow key={index} clickable={false}>
                      <CiroTableCell>{log.firstName}</CiroTableCell>
                      <CiroTableCell>{log.lastName}</CiroTableCell>
                      <CiroTableCell>{log.companyName}</CiroTableCell>
                    </CiroTableRow>
                  ))}
                </tbody>
              </CiroTable>
            </div>
          </div>
        )}
      </CiroModal>
    </>
  );
};

export default PushToCrmTransactionDetailsModal;
