import React from "react";
import classNames from "classnames";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICiroErrorMsgProps {
  error?: Maybe<string>;
}

const CiroErrorMsg = ({ error }: ICiroErrorMsgProps) => {
  if (!error) {
    return null;
  }

  return (
    <div
      className={classNames([
        "text-xs",
        "pt-2",
        "text-rose-500",
      ])}
    >
      {error}
    </div>
  );
};

export default CiroErrorMsg;
