import classNames from "classnames";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import CiroCheckbox from "../shared/CiroCheckbox";
import { gql } from "@apollo/client";
import { MobileNumbersRunPreviewSelection_PhoneNumberRequestsFragment } from "../../__generated__/graphql";
import { useState } from "react";
import CiroCoinPill from "../shared/CiroCoinPill";
import CiroTextInput from "../shared/CiroTextInput";
import SearchIcon from "../../assets/img/icons/SearchIcon";
import BoxLinkIcon from "../../assets/img/icons/BoxLinkIcon";
import CiroLink from "../shared/CiroLink";

interface IMobileNumbersRunPreviewSelectionProps {
  phoneNumberRequests: MobileNumbersRunPreviewSelection_PhoneNumberRequestsFragment;
  selectedContactIds: Set<string>;
  setSelectedContactIds: (contactIds: Set<string>) => void;
  creditsNeeded: number;
  includePreviouslyEnriched: boolean;
}

export const MobileNumbersRunPreviewSelection_PhoneNumberRequests = gql`
  fragment MobileNumbersRunPreviewSelection_PhoneNumberRequests on NewPhoneNumberRequestsStatusResponse {
    phoneNumberRequests {
      org_phone_call_id
      orgContact {
        first_name
        last_name
        linkedin_id
        ciroNumbersExternalId
        company_name
        externalUrl
        lastEnrichedByCiroAt
      }
    }
  }
`;

const MobileNumbersRunPreviewSelection = ({
  phoneNumberRequests,
  selectedContactIds,
  setSelectedContactIds,
  creditsNeeded,
  includePreviouslyEnriched,
}: IMobileNumbersRunPreviewSelectionProps) => {
  const [searchBarText, setSearchBarText] = useState("");

  if (!phoneNumberRequests.phoneNumberRequests) {
    return null;
  }

  const contacts = phoneNumberRequests.phoneNumberRequests.filter((contact) => {
    if (includePreviouslyEnriched) {
      return true;
    }
    return !contact.orgContact.lastEnrichedByCiroAt;
  });

  const headers = [
    <CiroTableHeader isFirst={true} key={"checkbox"} width="w-14">
      <CiroCheckbox
        checked={selectedContactIds.size > 0}
        indeterminate={
          selectedContactIds.size > 0 &&
          selectedContactIds.size !== contacts.length
        }
        onClick={() => {
          if (selectedContactIds.size === contacts.length) {
            setSelectedContactIds(new Set());
          } else {
            setSelectedContactIds(
              new Set(
                contacts.map((contact) => contact.orgContact.ciroNumbersExternalId as string),
              ),
            );
          }
        }}
      />
    </CiroTableHeader>,
    <CiroTableHeader key={"name"}>Contact Name</CiroTableHeader>,
    <CiroTableHeader isLast={true} key={"end"}>
      {""}
    </CiroTableHeader>,
  ];

  const selectedContactCount = selectedContactIds.size;

  let filteredContacts = contacts;
  if (searchBarText) {
    filteredContacts = contacts.filter(
      (contact) =>
        (contact.orgContact.first_name ?? "")
          .toLowerCase()
          .startsWith(searchBarText.toLowerCase()) ||
        (contact.orgContact.last_name ?? "")
          .toLowerCase()
          .startsWith(searchBarText.toLowerCase()),
    );
  }

  return (
    <div>
      {contacts.length === 0 && (
        <div
          className={classNames(
            "flex",
            "bg-neutral-50",
            "rounded-lg",
            "p-4",
          )}
        >
          <div
            className={classNames(
              "text-xs",
              "text-gray-500",
              "mr-1",
            )}
          >
            Could not find any contacts requiring new mobile numbers
          </div>
        </div>
      )}
      {contacts.length > 0 && (
        <div>
          <div
            className={classNames("text-sm", "font-semibold")}
          >
            Number of contacts ({selectedContactCount})
          </div>
          <div className={classNames("my-2")}>
            <CiroTextInput
              icon={<SearchIcon />}
              value={searchBarText}
              onChange={(e) => setSearchBarText(e.target.value)}
              placeholder="Search contacts"
            />
          </div>
          <div
            className={classNames(
              "p-4",
              "bg-gray-100",
              "rounded-lg",
            )}
          >
            <div className={classNames()}>
              <CiroTable
                containerClassName={classNames(
                  "h-72",
                  "overflow-y-auto",
                )}
              >
                <thead className={classNames("table-header-group")}>
                  <CiroTableRow clickable={false} sticky={true}>
                    {headers}
                  </CiroTableRow>
                </thead>
                <tbody className={classNames("table-row-group")}>
                  {filteredContacts.map((contact) => (
                    <CiroTableRow
                      key={contact.orgContact.ciroNumbersExternalId as string}
                      clickable={false}
                    >
                      <CiroTableCell padding={2}>
                        <div className={classNames("pl-2")}>
                          <CiroCheckbox
                            checked={selectedContactIds.has(
                              contact.orgContact.ciroNumbersExternalId as string,
                            )}
                            onClick={() => {
                              if (
                                selectedContactIds.has(
                                  contact.orgContact.ciroNumbersExternalId as string,
                                )
                              ) {
                                selectedContactIds.delete(
                                  contact.orgContact.ciroNumbersExternalId as string,
                                );
                              } else {
                                selectedContactIds.add(
                                  contact.orgContact.ciroNumbersExternalId as string,
                                );
                              }

                              setSelectedContactIds(
                                new Set([...selectedContactIds]),
                              );
                            }}
                          />
                        </div>
                      </CiroTableCell>
                      <CiroTableCell padding={2}>
                        <div
                          className={classNames(
                            "text-xs",
                            "pl-2",
                          )}
                        >
                          <span
                            className={classNames(
                              "flex",
                              "items-center",
                            )}
                          >
                            <span>
                              {contact.orgContact.first_name}{" "}
                              {contact.orgContact.last_name}
                            </span>
                            {contact.orgContact.externalUrl && (
                              <span className="pl-2">
                                <CiroLink href={contact.orgContact.externalUrl}>
                                  <BoxLinkIcon />
                                </CiroLink>
                              </span>
                            )}
                          </span>
                        </div>
                      </CiroTableCell>
                      <CiroTableCell padding={2}>{""}</CiroTableCell>
                    </CiroTableRow>
                  ))}
                </tbody>
              </CiroTable>
            </div>
          </div>
          <div
            className={classNames(
              "my-2",
              "w-full",
              "flex",
              "justify-between",
              "items-center",
            )}
          >
            <div
              className={classNames("text-sm", "font-semibold")}
            >
              Total
            </div>
            <CiroCoinPill additionalText="Maximum" amount={creditsNeeded} />
          </div>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "items-start",
              "bg-neutral-50",
              "rounded-lg",
              "p-4",
            )}
          >
            <div
              className={classNames(
                "text-xs",
                "text-gray-500",
                "mr-1",
              )}
            >
              The maximum value will be deducted progressively. You will only be
              charged for phone numbers that are found.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNumbersRunPreviewSelection;
