import classNames from "classnames";
import { IFilterEnrichmentStep } from "./EnrichmentStepCardFiltersList";
import { useFormContext } from "react-hook-form";
import CiroDropDown from "../../../shared/CiroDropdown";

interface IEnrichmentStepFilterIncludesProps {
  filterEnrichmentStep: IFilterEnrichmentStep;
  stepIndex: number;
}

const EnrichmentStepFilterIncludes = ({
  filterEnrichmentStep,
  stepIndex,
}: IEnrichmentStepFilterIncludesProps) => {
  const { setValue } = useFormContext<{
    filterEnrichmentSteps: IFilterEnrichmentStep[];
  }>();

  const arrayIdx = filterEnrichmentStep.parentEnrichmentStepInputs.findIndex(
    (value) => {
      return value.key === "array";
    },
  );

  const arrayInput =
    filterEnrichmentStep.parentEnrichmentStepInputs[arrayIdx].input;
  const picklistOptions = arrayInput ? JSON.parse(arrayInput) : [];

  return (
    <div
      className={classNames(
        "flex",
        "flex-col",
        "items-start",
      )}
    >
      <CiroDropDown
        formatCreateLabel={(inputValue) => `Create "${inputValue}" option`}
        labelClassName={classNames("text-gray-500")}
        placeholder="Start typing to create a picklist option..."
        creatable
        isMulti
        options={(picklistOptions as any[]).map((option) => {
          return { label: option, value: option };
        })}
        value={picklistOptions}
        isClearable
        onChange={(v) => {
          setValue(
            `filterEnrichmentSteps.${stepIndex}.parentEnrichmentStepInputs.${arrayIdx}.input`,
            JSON.stringify(v),
          );
        }}
      />
    </div>
  );
};

export default EnrichmentStepFilterIncludes;
