import React from "react";
import classNames from "classnames";

interface IContainerProps {
  children: any;
  className?: any;
}

// Large screens have no navigation header
export const FULL_SCREEN_CONTAINER_CLASSES = [
  "lg:h-full",
  "lg:min-h-screen",
];

const CiroContainer = ({ children, className = "" }: IContainerProps) => {
  return (
    <div
      className={classNames(
        className,
        FULL_SCREEN_CONTAINER_CLASSES,
        "flex-col",
        "flex",
        "items-start",
        "pt-8",
        "pb-8",
        "text-left",
        "text-start",
        "w-full",
        "px-4",
        "sm:px-8",
        "lg:px-16",
      )}
    >
      {children}
    </div>
  );
};

export default CiroContainer;
