import classNames from "classnames";
import CiroPagination from "../../../shared/CiroPagination";

interface IExecutionCell_PaginationRowProps {
  offset: number;
  perPage: number;
  totalCount: number;
  paginationLimit: number;
  setOffset: (v: number) => void;
}

const ExecutionCell_PaginationRow = ({
  offset,
  perPage,
  totalCount,
  paginationLimit,
  setOffset,
}: IExecutionCell_PaginationRowProps) => {
  return (
    <div className={classNames("w-full")}>
      <CiroPagination
        offset={offset}
        perPage={perPage}
        totalCount={totalCount}
        paginationLimit={paginationLimit}
        setOffset={setOffset}
      />
    </div>
  );
};

export default ExecutionCell_PaginationRow;
