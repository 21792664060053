import { ReactNode } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import classNames from "classnames";
import { Maybe } from "../../__generated__/graphql";
import CiroTooltipContainer from "./CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import CiroErrorMsg from "./forms/CiroErrorMsg";
import { DEFAULT_FILTER_TOOLTIP_TEXT } from "../../utils/consts";
import CiroSpinner from "./CiroSpinner";

export const formatOptionsForCiroDropdown = (options: any[]) => {
  return options.map((option) => {
    return { label: option, value: option };
  });
};

export const dropdownStylesControl = (
  styles: any,
  { menuIsOpen, isFocused }: any,
) => ({
  ...styles,
  borderColor: menuIsOpen ? "orange" : isFocused ? "gray" : styles.borderColor,
  boxShadow: "none",
  ":hover": {
    borderColor: menuIsOpen ? "orange" : "gray",
  },
});

export const dropdownStylesOption = (
  styles: any,
  { isFocused, isSelected, isDisabled }: any,
) => ({
  ...styles,
  backgroundColor: isFocused
    ? "lightgray"
    : isSelected
      ? "whitesmoke"
      : styles.backgroundColor,
  color: isDisabled ? "lightgray" : "black",
  cursor: isDisabled ? "not-allowed" : "pointer",
});

export interface ICiroDropdownSharedProps {
  async?: boolean;
  blurred?: boolean;
  onBlur?: () => void;
  blurredTooltip?: any;
  className?: string;
  creatable?: boolean;
  error?: string;
  formatCreateLabel?: (inputValue: string) => string;
  infoTooltip?: any;
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  labelClassName?: string;
  onCreateOption?: (inputValue: string) => void;
  getOptionLabel?: (option: any) => any;
  placeholder?: ReactNode;
  isOptionDisabled?: (option: any) => boolean;
  maxMenuHeight?: number;
  isLoading?: boolean;
  showSpinner?: boolean;
  menuPlacement?: "auto" | "bottom" | "top";
  menuPortalTarget?: HTMLElement;
}

interface ICiroDropdownOption<OptionValue> {
  value: OptionValue;
  label: string;
  showSpinner?: boolean;
}

interface ICiroDropDownSingularProps<OptionValue>
  extends ICiroDropdownSharedProps {
  isMulti?: false;
  loadOptions?: (
    inputValue: string,
    callback: (options: ICiroDropdownOption<OptionValue>[]) => void,
  ) => void;
  defaultOptions?: boolean;
  options?: ICiroDropdownOption<OptionValue>[];
  onChange?: (v: OptionValue) => void;
  onChangeFullValues?: (v: ICiroDropdownOption<OptionValue>) => void;
  value?: Maybe<OptionValue>;
}

interface ICiroDropDownMultiProps<OptionValue>
  extends ICiroDropdownSharedProps {
  isMulti: true;
  loadOptions?: (
    inputValue: string,
    callback: (options: ICiroDropdownOption<OptionValue>[]) => void,
  ) => void;
  defaultOptions?: boolean;
  options: ICiroDropdownOption<OptionValue>[];
  onChange?: (v: OptionValue[]) => void;
  onChangeFullValues?: (v: ICiroDropdownOption<OptionValue>[]) => void;
  value: OptionValue[];
}

function CiroDropDown<T>({
  async = false,
  blurred = false,
  blurredTooltip = DEFAULT_FILTER_TOOLTIP_TEXT,
  className,
  creatable = false,
  error,
  formatCreateLabel = (inputValue: string) => `Search for ${inputValue}`,
  infoTooltip,
  isClearable = true,
  isDisabled = false,
  defaultOptions = true,
  isMulti,
  label,
  labelClassName,
  getOptionLabel = (option: any) => option.label,
  loadOptions,
  onBlur = () => null,
  onChange,
  onChangeFullValues,
  onCreateOption,
  options,
  placeholder,
  value,
  isOptionDisabled,
  maxMenuHeight,
  isLoading,
  showSpinner = false,
  menuPlacement = "bottom",
  menuPortalTarget = undefined,
}:
  | ICiroDropDownSingularProps<T>
  | (ICiroDropDownMultiProps<T> & { maxMenuHeight?: number })) {
  const selectedValue = isMulti
    ? options.filter((option) => value.includes(option.value))
    : options?.find((option) => option.value === value) || null;

  const handleChange = (changeVal: any) => {
    if (onChangeFullValues) {
      return onChangeFullValues(changeVal);
    }

    if (!onChange) {
      throw new Error("onChange or onChangeFullValues must be provided");
    }

    if (Array.isArray(changeVal)) {
      const newValues = changeVal.map((item) => item?.value) as any;
      onChange(newValues);
    } else {
      onChange(changeVal?.value);
    }
  };

  const sharedProps = {
    isDisabled,
    className,
    isMulti,
    options,
    onBlur,
    defaultOptions,
    getOptionLabel,
    components: {
      DropdownIndicator: (props: any) =>
        value ? null : <components.DropdownIndicator {...props} />,
      IndicatorSeparator: () => null,
    },
    isClearable,
    value: selectedValue,
    onChange: handleChange,
    isOptionDisabled,
    placeholder: (
      <span className={classNames("text-sm")}>
        {placeholder || (isMulti ? "Select one or more" : "Select one")}
      </span>
    ),
    styles: {
      control: dropdownStylesControl,
      option: dropdownStylesOption,
    },
    maxMenuHeight,
    isLoading,
    menuPlacement,
    menuPortalTarget,
  };

  return (
    <div
      className={classNames(
        "flex",
        "flex-col",
        "items-start",
      )}
    >
      {(label || infoTooltip) && (
        <div
          className={classNames(
            "flex",
            "flex-row",
            "items-center",
          )}
        >
          <div className={classNames("pb-2", labelClassName)}>
            {label}
            {infoTooltip && (
              <span className={classNames("pl-4")}>
                <CiroTooltipContainer tooltip={infoTooltip}>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color="rgba(0,0,0,.55)"
                  />
                </CiroTooltipContainer>
              </span>
            )}
          </div>
          {showSpinner && (
            <div className={classNames("ml-2", "mt-0.5")}>
              <CiroSpinner loading={true} />
            </div>
          )}
        </div>
      )}
      <CiroTooltipContainer
        className={classNames("w-full")}
        tooltip={blurredTooltip}
        disabled={!blurred}
      >
        <div
          className={classNames("w-full", {
            "blur-sm": blurred,
            "pointer-events-none": blurred,
          })}
        >
          {!async && !creatable && <Select {...sharedProps} />}
          {async && !creatable && (
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              noOptionsMessage={({ inputValue }) =>
                Boolean(inputValue)
                  ? `No results for "${inputValue}"`
                  : "Type to search"
              }
              {...sharedProps}
            />
          )}
          {!async && creatable && (
            <CreatableSelect
              formatCreateLabel={formatCreateLabel}
              onCreateOption={onCreateOption}
              {...sharedProps}
            />
          )}
          {async && creatable && (
            <AsyncCreatableSelect
              // TODO: Add as an actual option
              {...sharedProps}
            />
          )}
        </div>
      </CiroTooltipContainer>
      <CiroErrorMsg error={error} />
    </div>
  );
}

export default CiroDropDown;
