import { useContext, useState } from "react";
import classNames from "classnames";
import {
  IAccountFilters,
  TextTypeFilterEnum,
} from "../../../reactHooks/filters/accounts/useAccountFilters";
import CiroSearchBar from "./SearchBar/CiroSearchBar";
import CiroButton from "../CiroButton";
import CopyIcon from "../../../assets/img/icons/CopyIcon";
import CheckIcon from "../../../assets/img/icons/CheckIcon";
import { gql, useQuery } from "@apollo/client";
import {
  AccountFilterEnum,
  CiroFilterSectionAccountFilters_AccountFiltersFragmentDoc,
  CiroFilterAccountsWrapper_FiltersAndPermissionsQuery,
  CiroFilterAccountsWrapper_FiltersAndPermissionsQueryVariables,
} from "../../../__generated__/graphql";
import FilterIcon from "../../../assets/img/icons/FilterIcon";
import CiroFilterModalContainer from "./CiroFilterModalContainer";
import CiroFilterSectionAccountFilters, {
  CiroFilterSectionAccountFilters_AccountFilters,
} from "./CiroFilterSectionAccountFilters";
import { useFragment } from "../../../__generated__";
import FilterContext from "../../../contexts/FilterContext";
import ConfirmFiltersFromSemanticSearchModal from "./ConfirmSemanticSearchModal/CiroConfirmFiltersFromSemanticSearchModal";

const CiroFilterAccountsWrapper_FiltersAndPermissions = gql`
  query CiroFilterAccountsWrapper_FiltersAndPermissions {
    permissions {
      accountFilters
      canSearchWebPageContent
    }
    ...CiroFilterSectionAccountFilters_AccountFilters
  }
  ${CiroFilterSectionAccountFilters_AccountFilters}
`;

interface IFilters {
  accountFilters: IAccountFilters;
  additionalButtonOptions?: any;
  resetOffset: () => void;
}

function CiroFilterAccountsWrapper({
  accountFilters,
  additionalButtonOptions,
  resetOffset,
}: IFilters) {
  const { page } = useContext(FilterContext);
  const {
    resetAll: resetAllAccountFilters,
    textFilter,
    setTextFilter,
    textTypeFilter,
    setTextTypeFilter,
    numFiltersApplied: numAccountFiltersApplied,
  } = accountFilters;

  const totalFiltersApplied = numAccountFiltersApplied;

  const [copied, setCopied] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [
    showFiltersFromSemanticSearchModal,
    setShowFiltersFromSemanticSearchModal,
  ] = useState(false);

  const {
    data: filtersAndPermissionsData,
    loading: filtersAndPermissionsLoading,
  } = useQuery<
    CiroFilterAccountsWrapper_FiltersAndPermissionsQuery,
    CiroFilterAccountsWrapper_FiltersAndPermissionsQueryVariables
  >(CiroFilterAccountsWrapper_FiltersAndPermissions);

  const accountFilterData = useFragment(
    CiroFilterSectionAccountFilters_AccountFiltersFragmentDoc,
    filtersAndPermissionsData,
  );

  const displayCTA =
    !filtersAndPermissionsData?.permissions?.accountFilters?.includes(
      AccountFilterEnum.All,
    );
  const canSearchWebPageContent = Boolean(
    filtersAndPermissionsData?.permissions?.canSearchWebPageContent,
  );

  return (
    <>
      {showFiltersFromSemanticSearchModal && (
        <ConfirmFiltersFromSemanticSearchModal
          accountFilters={accountFilters}
          show={showFiltersFromSemanticSearchModal}
          onClose={() => setShowFiltersFromSemanticSearchModal(false)}
        />
      )}
      <div
        className={classNames([
          "flex-col",
          "flex",
          "justify-between",
          "w-full",
          "sm:flex-row",
          "pb-2",
        ])}
      >
        <div className={classNames("w-full", "mb-2")}>
          <CiroSearchBar
            canSearchWebPageContent={canSearchWebPageContent}
            resetOffset={resetOffset}
            setShowFiltersFromSemanticSearchModal={
              setShowFiltersFromSemanticSearchModal
            }
            setTextFilter={setTextFilter}
            setTextTypeFilter={setTextTypeFilter}
            textFilter={textFilter || ""}
            // Default to semantic search
            textTypeFilterWithDefault={
              textTypeFilter ? textTypeFilter : TextTypeFilterEnum.SEMANTIC
            }
          />
        </div>
        <div
          className={classNames([
            "flex",
            "justify-between",
            "h-max",
            "sm:justify-start",
          ])}
        >
          <div className={classNames("pr-2", "sm:pl-2")}>
            {Boolean(totalFiltersApplied) && (
              <CiroButton
                disabled={copied}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 2500);
                }}
                analyticsField={`Copy filters for ${page}`}
              >
                <span className={classNames("mr-4")}>
                  {copied ? <CheckIcon /> : <CopyIcon />}
                </span>
                <span className={classNames("whitespace-nowrap")}>
                  Copy filter view
                </span>
              </CiroButton>
            )}
          </div>
          {additionalButtonOptions}
          <CiroButton
            customClassName={classNames(
              "test-filters-button",
              "h-[45px]",
            )}
            onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
            analyticsField={`Open Ciro filters for ${page}`}
          >
            <span
              className={classNames(
                "whitespace-nowrap",
                "flex",
                "items-center",
                "font-medium",
              )}
            >
              <span className="mr-4">
                <FilterIcon />
              </span>
              Filters
              {Boolean(totalFiltersApplied) && ` (${totalFiltersApplied})`}
            </span>
          </CiroButton>
          <CiroFilterModalContainer
            displayCTA={displayCTA}
            resetAll={() => {
              resetAllAccountFilters();
            }}
            numFiltersApplied={totalFiltersApplied}
            filtersSection={[
              <CiroFilterSectionAccountFilters
                key={1}
                accountFilters={accountFilters}
                resetOffset={resetOffset}
                accountFilterData={accountFilterData}
              />,
            ]}
            isLoading={filtersAndPermissionsLoading}
            isOpen={isFilterModalOpen}
            onClose={() => setIsFilterModalOpen(false)}
            subtitle={"Apply filters to your search"}
          />
        </div>
      </div>
    </>
  );
}

export default CiroFilterAccountsWrapper;
