import EnrichmentExecutionDetailsKeyValuePair from "./EnrichmentExecutionDetailsKeyValuePair";
import classNames from "classnames";

interface IEnrichmentExecutionDetailsJsonBlockProps {
  data: any;
  currentPath: string[];
}

const EnrichmentExecutionDetailsJsonBlock = ({
  data,
  currentPath,
}: IEnrichmentExecutionDetailsJsonBlockProps) => {
  if (typeof data !== "object" || data === null) {
    return <span>{data}</span>;
  }

  return (
    <div className={classNames("text-neutral-600")}>
      {Object.keys(data).map((jsonObjectkey, i) => (
        <EnrichmentExecutionDetailsKeyValuePair
          data={data}
          jsonObjectkey={jsonObjectkey}
          currentPath={currentPath}
          isLastKeyInObject={i === Object.keys(data).length - 1}
        />
      ))}
    </div>
  );
};

export default EnrichmentExecutionDetailsJsonBlock;
