import LogoIcon from "../../assets/img/icons/LogoIcon";
import classNames from "classnames";
import LoadingBar from "./LoadingBar";

interface ILoadingProps {
  size?: "FULL" | "SMALL";
  customFillPercentage?: number;
  longLoad?: boolean;
  logo?: boolean;
}

interface ILoadingComponentProps {
  customFillPercentage?: number;
  longLoad?: boolean;
  logo?: boolean;
}

const LoadingComponent = ({
  customFillPercentage,
  logo,
  longLoad
}: ILoadingComponentProps) => {
  return (
    <div
      className={classNames(
        "flex",
        "flex-wrap",
        "justify-center",
        "flex-col",
        "items-center",
      )}
    >
      <div className="pb-3">
        <LogoIcon />
      </div>
      <div className={classNames("w-20")}>
        <LoadingBar
          customFillPercentage={customFillPercentage}
          longLoad={longLoad}
        />
      </div>
    </div>
  );
};

const Loading = ({
  size = "FULL",
  customFillPercentage,
  logo = true,
  longLoad = false,
}: ILoadingProps) => {
  if (size === "SMALL") {
    return (
      <LoadingComponent
        customFillPercentage={customFillPercentage}
        logo={logo}
        longLoad={longLoad}
      />
    );
  }

  return (
    <div
      className={classNames(
        "flex",
        "flex-row",
        "content-center",
        "justify-center",
        "w-screen",
        "h-screen",
      )}
    >
      <div
        className={classNames(
          "flex-wrap",
          "flex",
          "items-center",
        )}
      >
        <LoadingComponent
          customFillPercentage={customFillPercentage}
          logo={logo}
          longLoad={longLoad}
        />
      </div>
    </div>
  );
};

export default Loading;
