import React from "react";
import classNames from "classnames";
import {
  InternalRefetchQueriesInclude,
  gql,
  useMutation,
} from "@apollo/client";
import { AdminDestroyUserConfirmation_UserAccountFragment } from "../../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { Maybe } from "graphql/jsutils/Maybe";

export const AdminDestroyUserConfirmation_UserAccount = gql`
  fragment AdminDestroyUserConfirmation_UserAccount on UserAccount {
    id
    email
    id
  }
`;

const AdminDestroyUserConfirmation_RemoveUserAccount = gql`
  mutation AdminDestroyUserConfirmation_RemoveUserAccount(
    $userAccountId: Int!
  ) {
    removeUserAccount(userAccountId: $userAccountId) {
      success
    }
  }
`;

interface IAdminDestroyUserConfirmationProps {
  onClose: () => void;
  handleReturnToLastView: () => void;
  refetchQueries: InternalRefetchQueriesInclude;
  userAccount?: Maybe<AdminDestroyUserConfirmation_UserAccountFragment>;
}

const AdminDestroyUserConfirmation = ({
  onClose,
  handleReturnToLastView,
  refetchQueries,
  userAccount,
}: IAdminDestroyUserConfirmationProps) => {
  const [removeUserAccount, { loading: removeUserAccountLoading }] =
    useMutation(AdminDestroyUserConfirmation_RemoveUserAccount);

  if (!userAccount) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <div>
        Are you sure you want to delete {userAccount.email}? This will move the
        user to the deleted users list.
      </div>
      <div
        className={classNames(
          "flex",
          "justify-end",
          "pt-4",
        )}
      >
        <span className={classNames("pr-4")}>
          <CiroButton
            style={CiroButtonStyleEnum.INVERTED}
            analyticsField="Confirm Destroy User"
            disabled={removeUserAccountLoading}
            onClick={() => {
              removeUserAccount({
                variables: {
                  userAccountId: userAccount.id,
                },
                onCompleted: () => {
                  onClose();
                },
                refetchQueries,
              });
            }}
          >
            Delete User
          </CiroButton>
        </span>
        <CiroButton
          analyticsField="Confirm Destroy User"
          onClick={handleReturnToLastView}
          disabled={removeUserAccountLoading}
        >
          Cancel
        </CiroButton>
      </div>
    </div>
  );
};

export default AdminDestroyUserConfirmation;
