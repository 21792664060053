import { gql } from "@apollo/client";
import CiroTitle from "../shared/CiroTitle";
import BusinessDetailsMultilocationLinks, {
  BusinessDetailsMultilocationLinks_Company,
} from "./BusinessDetailsMultilocationLinks";
import BusinessDetailsOpenDays, {
  BusinessDetailsOpenDays_Company,
} from "./BusinessDetailsOpenDays";
import {
  BusinessDetailsMultilocationLinks_CompanyFragmentDoc,
  BusinessDetailsOpenDays_CompanyFragmentDoc,
  BusinessDetailsTitle_CompanyFragment,
} from "../../__generated__/graphql";
import { useFragment } from "../../__generated__";
import classNames from "classnames";
import CiroCard from "../shared/CiroCard";

export const BusinessDetailsTitle_Company = gql`
  fragment BusinessDetailsTitle_Company on Company {
    company_name {
      value
      populated
    }
    dba {
      value
      populated
    }
    formatted_address {
      value
      populated
    }
    tagline {
      value
      populated
    }
    ...BusinessDetailsMultilocationLinks_Company
    ...BusinessDetailsOpenDays_Company
  }
  ${BusinessDetailsMultilocationLinks_Company}
  ${BusinessDetailsOpenDays_Company}
`;
interface IBusinessDetailsTitleProps {
  company: BusinessDetailsTitle_CompanyFragment;
}

const BusinessDetailsTitle = ({ company }: IBusinessDetailsTitleProps) => {
  const multilocationLinksFragment = useFragment(
    BusinessDetailsMultilocationLinks_CompanyFragmentDoc,
    company,
  );

  const companyOpeningFragment = useFragment(
    BusinessDetailsOpenDays_CompanyFragmentDoc,
    company,
  );

  return (
    <div className={classNames("w-full")}>
      <CiroCard size="small">
        <CiroTitle
          title={company.company_name?.value}
          subtitle={
            <>
              <div>{company.dba?.value}</div>
              <div>{company.formatted_address?.value}</div>
              <div>{company.tagline?.value}</div>
              <BusinessDetailsMultilocationLinks
                company={multilocationLinksFragment}
              />
              <BusinessDetailsOpenDays
                company={companyOpeningFragment}
              ></BusinessDetailsOpenDays>
            </>
          }
        />
      </CiroCard>
    </div>
  );
};
export default BusinessDetailsTitle;
