import CiroTable from "../shared/CiroTable/CiroTable";
import classNames from "classnames";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../shared/CiroTable/CiroTableCell";
import {
  DownloadLink,
  downloadLinkStyles,
} from "../shared/DownloadLink/DownloadLink";
import { gql } from "@apollo/client";
import {
  CollectionsTable_CollectionsFragment,
  Maybe,
} from "../../__generated__/graphql";
import { EditCollectionModal_Permissions } from "./EditCollectionModal";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";

export const CollectionsTable_Collections = gql`
  fragment CollectionsTable_Collections on Query {
    permissions {
      canExportAccountsData
      ...EditCollectionModal_Permissions
    }
    collections {
      id
      name
      creatorName
      gcs_url
      company_count
    }
  }
  ${EditCollectionModal_Permissions}
`;

const ClickableCollectionTableData = ({
  id,
  children,
  align = CiroTableCellAlignEnum.LEFT,
  className,
  includeLink = true,
  onRowClick,
}: {
  id: string;
  children: any;
  align?: CiroTableCellAlignEnum;
  className?: string;
  includeLink?: boolean;
  onRowClick?: () => void;
}) => {

  return (
    <CiroTableCell
      align={align}
      className={className}
      href={includeLink ? `/collections/${id}` : undefined}
      onRowClick={onRowClick}
    >
      {children}
    </CiroTableCell>
  );
};

interface ICollectionsTableProps {
  collectionsData?: Maybe<CollectionsTable_CollectionsFragment>;
  showExport?: boolean;
  onRowClick?: (collection: number) => void;
}

const CollectionsTable = ({
  collectionsData,
  showExport = true,
  onRowClick,
}: ICollectionsTableProps) => {
  const canExportAccountsData = Boolean(collectionsData?.permissions?.canExportAccountsData);
  const exportColumnVisible = showExport && canExportAccountsData;
  
  return (
    <div className={classNames("pb-8", "test-collections-table-main")}>
      <CiroTable>
        <thead className={classNames("table-header-group")}>
          <CiroTableRow clickable={false} sticky={true}>
            <CiroTableHeader width="sm:w-1/2">
              Collection
            </CiroTableHeader>
            <CiroTableHeader
              className={classNames("hidden", "sm:table-cell")}
            >
              Creator
            </CiroTableHeader>
            <CiroTableHeader
              align={CiroTableHeaderAlignEnum.CENTER}
              className={classNames("hidden", "sm:table-cell")}
            >
              Number of accounts
            </CiroTableHeader>
            {exportColumnVisible && (
              <CiroTableHeader
                align={CiroTableHeaderAlignEnum.RIGHT}
                width="w-24"
              >
                Export
              </CiroTableHeader>
            )}
          </CiroTableRow>
        </thead>
        <tbody className={classNames(["table-row-group"])}>
          {collectionsData?.collections?.map((collection) => {
            if (!collection?.id) {
              return null;
            }
            return (
              <CiroTableRow key={collection.id}>
                <ClickableCollectionTableData
                  id={collection.id}
                  className="test-collection-name"
                  includeLink={!Boolean(onRowClick)}
                  onRowClick={() => {
                    onRowClick?.(parseInt(collection.id));
                  }}
                >
                  {collection.name}
                </ClickableCollectionTableData>
                <ClickableCollectionTableData
                  id={collection.id}
                  className={classNames(
                    "hidden",
                    "sm:table-cell",
                    "text-ellipsis",
                    "truncate",
                  )}
                  includeLink={!Boolean(onRowClick)}
                  onRowClick={() => {
                    onRowClick?.(parseInt(collection.id));
                  }}
                >
                  <CiroTooltipContainer
                    placement={"left-start"}
                    tooltip={collection.creatorName}
                  >
                    {collection.creatorName}
                  </CiroTooltipContainer>
                </ClickableCollectionTableData>
                <ClickableCollectionTableData
                  id={collection.id}
                  align={CiroTableCellAlignEnum.CENTER}
                  className={classNames(
                    "hidden",
                    "sm:table-cell",
                    "test-collection-count",
                  )}
                  includeLink={!Boolean(onRowClick)}
                  onRowClick={() => {
                    onRowClick?.(parseInt(collection.id));
                  }}
                >
                  {collection.company_count || 0}
                </ClickableCollectionTableData>
                {exportColumnVisible && (
                  <ClickableCollectionTableData
                    id={collection.id}
                    includeLink={false}
                    align={CiroTableCellAlignEnum.RIGHT}
                  >
                    <div onClick={(event) => event.stopPropagation()}>
                      <DownloadLink
                        filename={collection?.name}
                        style={downloadLinkStyles.ICON}
                        collectionId={Number(collection?.id)}
                      />
                    </div>
                  </ClickableCollectionTableData>
                )}
              </CiroTableRow>
            );
          })}
        </tbody>
      </CiroTable>
    </div>
  );
};

export default CollectionsTable;
