import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { ArrowUturnLeftIcon, UserMinusIcon } from "@heroicons/react/20/solid";
import {
  AdminOrganizationDetails_SelectedOrganization,
  OrganizationDetailsModalStateEnum,
} from "./AdminOrganizationDetails";
import {
  AdminDestroyUserConfirmation_UserAccountFragment,
  AdminViewOrganizationUsers_OrganizationFragment,
  Maybe,
} from "../../../__generated__/graphql";
import { gql } from "@apollo/client";
import AdminDestroyUserConfirmation, {
  AdminDestroyUserConfirmation_UserAccount,
} from "../AdminUserSection/AdminDestroyUserConfirmation";
import { useState } from "react";

export const AdminViewOrganizationUsers_Organization = gql`
  fragment AdminViewOrganizationUsers_Organization on Organization {
    id
    name
    userAccounts {
      id
      email
      ...AdminDestroyUserConfirmation_UserAccount
    }
  }
  ${AdminDestroyUserConfirmation_UserAccount}
`;

interface IAdminViewOrganizationUsersProps {
  organization?: Maybe<AdminViewOrganizationUsers_OrganizationFragment>;
  setModalState: (v: OrganizationDetailsModalStateEnum) => void;
}

const AdminViewOrganizationUsers = ({
  organization,
  setModalState,
}: IAdminViewOrganizationUsersProps) => {
  const [deletingUserAccount, setDeletingUserAccount] =
    useState<AdminDestroyUserConfirmation_UserAccountFragment | null>(null);

  if (!organization) {
    return null;
  }

  if (deletingUserAccount) {
    return (
      <AdminDestroyUserConfirmation
        handleReturnToLastView={() => setDeletingUserAccount(null)}
        refetchQueries={[
          {
            query: AdminOrganizationDetails_SelectedOrganization,
            variables: {
              organizationId: organization.id,
            },
          },
        ]}
        onClose={() => setDeletingUserAccount(null)}
        userAccount={deletingUserAccount}
      />
    );
  }

  return (
    <div>
      <div className={classNames("flex", "justify-between")}>
        <div className={classNames("font-medium", "text-lg")}>
          {organization.name} users ({organization.userAccounts.length})
        </div>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={"Return to org view from users view"}
          onClick={() => {
            setModalState(OrganizationDetailsModalStateEnum.VIEW);
          }}
        >
          <ArrowUturnLeftIcon
            className={classNames(["h-5"])}
            aria-hidden="true"
          />
        </CiroButton>
      </div>
      <div className={classNames("text-sm")}>
        All users that are associated with the organization
      </div>
      <div
        className={classNames(
          "pt-2",
          "max-h-96",
          "overflow-y-auto",
        )}
      >
        {organization.userAccounts.map((userAccount) => {
          return (
            <div
              className={classNames(
                "pt-4",
                "flex",
                "items-center",
              )}
            >
              <CiroButton
                analyticsField="Select user to destroy from org view"
                onClick={() => {
                  setDeletingUserAccount(
                    userAccount as AdminDestroyUserConfirmation_UserAccountFragment,
                  );
                }}
              >
                <UserMinusIcon
                  className={classNames("h-5")}
                  aria-hidden="true"
                />
              </CiroButton>
              <span className={classNames("ml-2")}>
                {userAccount.email}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminViewOrganizationUsers;
