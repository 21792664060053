import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import { gql, useQuery } from "@apollo/client";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Admin_PermissionsQuery,
  Admin_PermissionsQueryVariables,
  AdminPersonalSettings_UserAccountFragmentDoc,
} from "../../__generated__/graphql";
import AdminFindUserDropdown from "../../components/admin/AdminUserSection/AdminFindUserDropdown";
import classNames from "classnames";
import AddUserAccountForm, {
  AddUserAccountForm_Organizations,
} from "../../components/admin/AddUserAccountForm";
import CiroCard from "../../components/shared/CiroCard";
import AdminOrganizationDetails from "../../components/admin/AdminEditOrganizationModal/AdminOrganizationDetails";
import AdminPersonalSettings, {
  AdminPersonalSettings_UserAccount,
} from "../../components/admin/AdminPersonalSettings";
import { useFragment } from "../../__generated__";

const Admin_Permissions = gql`
  query Admin_Permissions {
    permissions {
      hasAdminPermissions
    }
    userAccounts {
      id
      email
    }
    userAccount {
      id
      ...AdminPersonalSettings_UserAccount
    }
    ...AddUserAccountForm_Organizations
  }
  ${AddUserAccountForm_Organizations}
  ${AdminPersonalSettings_UserAccount}
`;

const Admin = () => {
  const navigate = useNavigate();
  const { data: adminPermissionsData, loading: adminPermissionsLoading } =
    useQuery<Admin_PermissionsQuery, Admin_PermissionsQueryVariables>(
      Admin_Permissions,
    );
  const hasAdminPermissions =
    adminPermissionsData?.permissions?.hasAdminPermissions;

  const userAccount = useFragment(
    AdminPersonalSettings_UserAccountFragmentDoc,
    adminPermissionsData?.userAccount,
  );

  useEffect(() => {
    if (!adminPermissionsLoading && !hasAdminPermissions) {
      navigate("/");
    }
  }, [hasAdminPermissions, navigate, adminPermissionsLoading]);

  if (adminPermissionsLoading) {
    return <Loading />;
  }

  return (
    <CiroContainer>
      <CiroTitle title="Admin" />
      {userAccount && (
        <div className={classNames("pt-4", "w-full")}>
          <CiroCard>
            <AdminPersonalSettings userAccount={userAccount} />
          </CiroCard>
        </div>
      )}
      <div className={classNames("pt-4", "w-full")}>
        <CiroCard>
          <AdminFindUserDropdown />
        </CiroCard>
      </div>
      <div className={classNames("pt-4", "w-full")}>
        <CiroCard>
          <AddUserAccountForm organizationData={adminPermissionsData} />
        </CiroCard>
      </div>
      <div className={classNames("pt-4", "w-full")}>
        <CiroCard>
          <AdminOrganizationDetails />
        </CiroCard>
      </div>
    </CiroContainer>
  );
};

export default withAuthenticationRequired(Admin, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loading />,
});
