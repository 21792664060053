import React from "react";
import classNames from "classnames";
import {
  Maybe,
  ProtectedBoolean,
  ProtectedIndustryAssociations,
  ProtectedInt,
  ProtectedString,
  ProtectedStrings,
  ProtectedVendors,
} from "../../../__generated__/graphql";
import BusinessDetailsKeyDetailsValue from "./BusinessDetailsKeyDetailsValue";
import CiroPillTag from "../../shared/CiroPillTag";
import LinkArrowIcon from "../../../assets/img/icons/LinkArrowIcon";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import { UPGRADE_PLAN_TOOLTIP } from "../../../utils/consts";
import randomBytes from "../../../utils/randomBytes";

interface IBusinessDetailsKeyDetailsGroupProps {
  children: any;
  field?: Maybe<
    | ProtectedString
    | ProtectedStrings
    | ProtectedIndustryAssociations
    | ProtectedBoolean
    | ProtectedVendors
    | ProtectedInt
  >;
  primary?: boolean;
  title: any;
}

export const BusinessDetailsCardLabel = ({
  children,
  primary,
}: {
  children: any;
  primary?: boolean;
}) => {
  return (
    <div
      className={classNames(
        ["pb-2", "text-font-medium", "text-sm"],
        {
          "text-zinc-500": !primary,
          "text-slate-600": primary,
        },
      )}
    >
      {children}
    </div>
  );
};

const createRandomStringWithRandomLength = () => {
  return Math.ceil(Math.random() * 10) + 5;
};

const ObscuredBusinessDetail = ({
  field,
}: {
  field?: Maybe<
    | ProtectedString
    | ProtectedStrings
    | ProtectedIndustryAssociations
    | ProtectedBoolean
    | ProtectedVendors
    | ProtectedInt
  >;
}) => {
  switch (field?.__typename) {
    case "ProtectedIndustryAssociations":
      const industryCount = field.count;
      const obscuredIndustryAssociationArray = Array.from(
        { length: industryCount || 0 },
        (_, i) => {
          return (
            <span key={i} className={classNames(["mr-1"])}>
              <CiroPillTag
                text={randomBytes(
                  createRandomStringWithRandomLength(),
                ).toString()}
                className="test-industry-association-badge"
              />
            </span>
          );
        },
      );

      return (
        <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
          <div className="blur-sm">
            <BusinessDetailsKeyDetailsValue>
              <div
                className={classNames(["flex", "flex-wrap"])}
              >
                {obscuredIndustryAssociationArray}
              </div>
            </BusinessDetailsKeyDetailsValue>
          </div>
        </CiroTooltipContainer>
      );
    case "ProtectedVendors":
      const vendorCount = field.count;
      const obscuredVendorArray = Array.from(
        { length: vendorCount || 0 },
        (_, i) => {
          return (
            <span key={i} className={classNames(["mr-1"])}>
              <CiroPillTag
                text={randomBytes(
                  createRandomStringWithRandomLength(),
                ).toString()}
                className="test-vendor-badge"
              />
            </span>
          );
        },
      );

      return (
        <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
          <div className="blur-sm">
            <BusinessDetailsKeyDetailsValue>
              <div
                className={classNames(["flex", "flex-wrap"])}
              >
                {obscuredVendorArray}
              </div>
            </BusinessDetailsKeyDetailsValue>
          </div>
        </CiroTooltipContainer>
      );
    case "ProtectedStrings":
      const stringCount = field.count;
      const obscuredStringArray = Array.from(
        { length: stringCount || 0 },
        (_, i) => {
          return (
            <span key={i} className={classNames(["mr-1"])}>
              <CiroPillTag
                text={randomBytes(
                  createRandomStringWithRandomLength(),
                ).toString()}
                className="test-industry-association-badge"
              />
            </span>
          );
        },
      );

      return (
        <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
          <div className="blur-sm">
            <BusinessDetailsKeyDetailsValue>
              <div
                className={classNames(["flex", "flex-wrap"])}
              >
                {obscuredStringArray}
              </div>
            </BusinessDetailsKeyDetailsValue>
          </div>
        </CiroTooltipContainer>
      );
    default:
      return (
        <CiroTooltipContainer tooltip={UPGRADE_PLAN_TOOLTIP}>
          <div className={classNames(["blur-sm"])}>
            <BusinessDetailsKeyDetailsValue>
              {randomBytes(createRandomStringWithRandomLength()).toString()}
              <span className="pl-2 text-orange-400">
                <LinkArrowIcon />
              </span>
            </BusinessDetailsKeyDetailsValue>
          </div>
        </CiroTooltipContainer>
      );
  }
};

const BusinessDetailsKeyDetailsGroup = ({
  children,
  field,
  primary = false,
  title,
}: IBusinessDetailsKeyDetailsGroupProps) => {
  if (!field?.populated) {
    return null;
  }
  return (
    <div className={classNames(["pb-3"])}>
      <BusinessDetailsCardLabel primary={primary}>
        {title}
      </BusinessDetailsCardLabel>
      {field.value ? children : <ObscuredBusinessDetail field={field} />}
    </div>
  );
};

export default BusinessDetailsKeyDetailsGroup;
