import classNames from "classnames";
import EnrichmentExecutionDetailsContext from "../../../contexts/EnrichmentExecutionDetailsContext";
import EnrichmentExecutionDetailsJsonBlock from "./EnrichmentExecutionDetailsJsonBlock";
import JsonIcon from "../../../assets/img/icons/JsonIcon";
import { EnrichmentTechniqueEnum } from "../../../__generated__/graphql";
import XIcon from "../../../assets/img/icons/XIcon";
import CiroMarkdown from "../../shared/CiroMarkdown";

export interface IExecutionCellData {
  output: any;
  enrichmentStep: {
    id: number;
    enrichment_technique: EnrichmentTechniqueEnum;
    name: string;
  };
}

interface IEnrichmentExecutionDetailsViewerProps {
  hidden: boolean;
  executionCellData?: IExecutionCellData | null;
  onClose: () => void;
}

const EnrichmentExecutionDetailsViewer = ({
  hidden,
  executionCellData,
  onClose,
}: IEnrichmentExecutionDetailsViewerProps) => {
  if (hidden) return null;

  return (
    <div
      className={classNames(
        "w-[40%]",
        "ml-4",
        "h-full",
        "max-h-screen-minus-16",
        "rounded-lg",
        "border-1",
        "flex",
        "flex-col",
        "overflow-auto",
        "bg-neutral-50",
      )}
    >
      <EnrichmentExecutionDetailsContext.Provider
        value={{
          enrichmentStepId: executionCellData?.enrichmentStep?.id || 0,
          onClose,
        }}
      >
        <div
          className={classNames(
            "w-full",
            "h-[48px]",
            "min-h-[48px]",
            "p-1",
            "border-b",
            "border-neutral-200",
            "flex-col",
            "justify-center",
            "items-start",
            "gap-1",
            "inline-flex",
          )}
        >
          <div
            className={classNames(
              "flex",
              "flex-row",
              "justify-between",
              "w-full",
              "items-center",
            )}
          >
            <div
              className={classNames(
                "flex-row",
                "items-center",
                "flex",
                "gap-1",
                "ml-2",
              )}
            >
              <div className={classNames("text-neutral-400")}>
                <JsonIcon />
              </div>
              <div
                className={classNames(
                  "text-neutral-900",
                  "text-sm",
                )}
              >
                {executionCellData?.enrichmentStep?.name}
              </div>
            </div>
            <div
              className={classNames(
                "text-neutral-400",
                "text-sm",
                "font-medium",
                "mr-2",
                "cursor-pointer",
              )}
              onClick={onClose}
            >
              <XIcon />
            </div>
          </div>
        </div>
        <div className={classNames("my-4")}>
          {["object", "array"].includes(typeof executionCellData?.output) && (
            <EnrichmentExecutionDetailsJsonBlock
              data={executionCellData?.output}
              currentPath={[]}
            />
          )}
          {!["object", "array"].includes(typeof executionCellData?.output) && (
            <div
              className={classNames("ml-5", "text-neutral-600")}
            >
              <CiroMarkdown markdown={executionCellData?.output} />
            </div>
          )}
        </div>
      </EnrichmentExecutionDetailsContext.Provider>
    </div>
  );
};

export default EnrichmentExecutionDetailsViewer;
