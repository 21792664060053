import { gql } from "@apollo/client";
import {
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentTechniqueEnum,
  Maybe,
} from "../../../../__generated__/graphql";
import NodeBranchIcon from "../../../../assets/img/icons/NodeBranchIcon";
import classNames from "classnames";
import {
  EnrichmentFilterTechnique,
  enrichmentStepFilterDropdownOptions,
} from "./EnrichmentStepFilterDropdown";
import * as yup from "yup";
import { useFormContext } from "react-hook-form";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import EnrichmentStepFilter from "./EnrichmentStepFilter";
import CiroSwitch from "../../../shared/CiroSwitch";

export const EnrichmentStepCardFiltersList_EnrichmentStep = gql`
  fragment EnrichmentStepCardFiltersList_EnrichmentStep on EnrichmentStep {
    filterEnrichmentStepInputs: parentEnrichmentStepInputs(sourceType: filter) {
      id
      sourceEnrichmentStep {
        id
        name
        enrichment_technique
        parentEnrichmentStepInputs(sourceType: nonfilter) {
          id
          key
          input
          required
          source_enrichment_step_id
        }
      }
    }
  }
`;

export const EnrichmentStepCardFiltersListSchema = yup
  .array()
  .of(
    yup.object({
      enrichment_technique: yup
        .string()
        .matches(
          new RegExp(
            enrichmentStepFilterDropdownOptions
              .map((opt) => opt.value)
              .join("|"),
          ),
        )
        .required(),
      parentEnrichmentStepInputs: yup
        .array()
        .of(
          yup
            .object({
              input: yup.string().nullable(),
              key: yup.string().required(),
              sourceEnrichmentStepId: yup.number().nullable(),
            })
            .required("Input is required"),
        )
        .required("Input is required"),
    }),
)

export const parseEnrichmentStepsForList = (
  enrichmentStep?: Maybe<EnrichmentStepCardFiltersList_EnrichmentStepFragment>,
) => {
  if (!enrichmentStep) {
    return [];
  }

  return enrichmentStep.filterEnrichmentStepInputs?.map((input) => {
    return {
      name: input.sourceEnrichmentStep?.name || "",
      enrichment_technique: input.sourceEnrichmentStep
        ?.enrichment_technique as EnrichmentFilterTechnique,
      parentEnrichmentStepInputs:
        input.sourceEnrichmentStep?.parentEnrichmentStepInputs.map((input) => {
          let parsedInput = input.input && JSON.parse(input.input);
          const returnInput =
            typeof parsedInput === "string" ? parsedInput : input.input;

          return {
            input: returnInput || null,
            key: input.key || "",
            sourceEnrichmentStepId: input.source_enrichment_step_id || null,
          };
        }) || [],
    };
  });
};

export const parseEnrichmentStepsForGraphQL = (
  filterEnrichmentSteps: IFilterEnrichmentStep[],
) => {
  return filterEnrichmentSteps.map((filterEnrichmentStep) => {
    return {
      enrichment_technique:
        filterEnrichmentStep.enrichment_technique as EnrichmentTechniqueEnum,
      name: `${filterEnrichmentStep.enrichment_technique} based on ${filterEnrichmentStep.parentEnrichmentStepInputs
        .map((input) => `${input.input}`)
        .join(", ")}`,
      parentEnrichmentStepInputs:
        filterEnrichmentStep.parentEnrichmentStepInputs.map((input) => {
          return {
            key: input.key,
            input:
              typeof input.input === "string"
                ? JSON.stringify(input.input)
                : input.input,
            sourceEnrichmentStepId: input.sourceEnrichmentStepId || null,
            required: true,
          };
        }),
    };
  });
};

export interface IFilterEnrichmentStep {
  name: string;
  enrichment_technique: EnrichmentFilterTechnique;
  parentEnrichmentStepInputs: {
    input: string | null;
    key: string;
    sourceEnrichmentStepId: number | null;
  }[];
}

interface IEnrichmentStepCardFiltersListProps {
  filterEnrichmentSteps: IFilterEnrichmentStep[];
}

const EnrichmentStepCardFiltersList = ({
  filterEnrichmentSteps,
}: IEnrichmentStepCardFiltersListProps) => {
  const { setValue } = useFormContext();

  return (
    <div className={classNames("h-full")}>
      <div
        className={classNames(
          "flex",
          "flex-row",
          "justify-between",
        )}
      >
        <div className={classNames("flex")}>
          <span
            className={classNames("pr-2", "text-neutral-500")}
          >
            <NodeBranchIcon />
          </span>
          <div className={classNames("text-neutral-500")}>
            <span>Run conditions </span>
            <span className={classNames("text-xs")}>(Advanced)</span>
          </div>
        </div>
        <CiroSwitch
          checked={filterEnrichmentSteps?.length > 0}
          onChange={() => {
            if (filterEnrichmentSteps?.length > 0) {
              setValue("filterEnrichmentSteps", []);
            } else {
              setValue("filterEnrichmentSteps", [
                {
                  enrichment_technique:
                    EnrichmentTechniqueEnum.FiltersIsNotNull as EnrichmentFilterTechnique,
                  parentEnrichmentStepInputs: [
                    {
                      input: "",
                      key: "value",
                      sourceEnrichmentStepId: null,
                    },
                  ],
                },
              ]);
            }
          }}
        />
      </div>
      {filterEnrichmentSteps.map((filterEnrichmentStep, i) => {
        return (
          <EnrichmentStepFilter
            key={i}
            deleteStep={(stepIndex: number) => {
              setValue(
                "filterEnrichmentSteps",
                filterEnrichmentSteps.filter((_, index) => index !== stepIndex),
              );
            }}
            setFilterEnrichmentStep={(newStep: IFilterEnrichmentStep) => {
              setValue(`filterEnrichmentSteps.${i}`, newStep);
            }}
            filterEnrichmentStep={filterEnrichmentStep}
            stepIndex={i}
          />
        );
      })}
      {(filterEnrichmentSteps?.length > 0) && (
        <div className={classNames("ml-2")}>
          <CiroButton
            analyticsField="Add Enrichment Step"
            customClassName={classNames("px-0")}
            style={CiroButtonStyleEnum.BORDERLESS}
            onClick={() => {
              setValue("filterEnrichmentSteps", [
                {
                  enrichment_technique:
                    EnrichmentTechniqueEnum.FiltersIsNotNull as EnrichmentFilterTechnique,
                  parentEnrichmentStepInputs: [
                    {
                      input: "",
                      key: "value",
                      sourceEnrichmentStepId: null,
                    },
                  ],
                },
                ...filterEnrichmentSteps,
              ]);
            }}
          >
            <PlusCircleIcon
              className={classNames("w-5", "text-gray-400")}
            />
          </CiroButton>
        </div>
      )}
    </div>
  );
};

export default EnrichmentStepCardFiltersList;
