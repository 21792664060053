import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import classNames from "classnames";
import CiroCard from "./CiroCard";
import CiroHR from "./CiroHR";
import { PRICING_PAGE_LINK } from "../../utils/consts";

interface ICiroCTACardProps {
  subtitle: string;
  title: string;
}

const CiroCTACard = ({ subtitle, title }: ICiroCTACardProps) => {
  return (
    <CiroCard>
      <div
        className={classNames([
          "flex",
          "flex-col",
          "align-start",
        ])}
      >
        <div
          className={classNames(["font-semibold", "text-2xl"])}
        >
          {title}
        </div>
        <div className={classNames(["pt-2", "text-slate-600"])}>
          {subtitle}
        </div>
      </div>
      <div className={classNames(["-mx-8"])}>
        <CiroHR />
      </div>
      <div>
        <CiroButton
          href={PRICING_PAGE_LINK}
          style={CiroButtonStyleEnum.INVERTED}
          customClassName={classNames(["w-full"])}
          analyticsField="Upgrade CTA"
        >
          Upgrade Now
        </CiroButton>
      </div>
    </CiroCard>
  );
};

export default CiroCTACard;
