import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import React from "react";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";

interface IEnrichmentFlowTableDropdownOptionProps {
  option: string | React.ReactNode;
  onClick: () => void;
  Icon: any;
  tooltip?: string | null;
  disabled?: boolean;
}

const EnrichmentFlowTableDropdownOption = ({
  option,
  onClick,
  Icon,
  tooltip,
  disabled,
}: IEnrichmentFlowTableDropdownOptionProps) => {
  return (
    <div
      className={classNames(
        "flex",
        "m-1",
        "rounded-md",
        "w-full",
        "hover:bg-gray-200",
      )}
    >
      <CiroTooltipContainer
        className={"w-full"}
        disabled={!tooltip}
        tooltip={tooltip}
      >
        <CiroButton
          analyticsField={`enrichmentFlowTableDropdownOption ${option}`}
          style={CiroButtonStyleEnum.UNSTYLED}
          onClick={onClick}
          customClassName={classNames("w-full", "p-2")}
          customFlexClass={classNames("items-center")}
          disabled={disabled}
        >
          <span
            className={classNames(
              "w-full",
              "flex",
              "items-start",
            )}
          >
            <Icon
              className={classNames(
                "mr-2",
                "h-5",
                "text-neutral-400",
              )}
              aria-hidden="true"
            />
            <div className={classNames("text-neutral-600")}>
              {option}
            </div>
          </span>
        </CiroButton>
      </CiroTooltipContainer>
    </div>
  );
};

export default EnrichmentFlowTableDropdownOption;
