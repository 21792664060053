import classNames from "classnames";

const BusinessDetailsKeyDetailsValue = ({ children }: { children: any }) => {
  return (
    <span className={classNames("overflow-hidden")}>
      <span
        className={classNames(
          "flex",
          "font-semibold",
          "items-center",
          "truncate",
        )}
      >
        {children}
      </span>
    </span>
  );
};

export default BusinessDetailsKeyDetailsValue;
