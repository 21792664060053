import classNames from "classnames";
import {
  AutomationListReviewDuplicates_OrgContactCrmDuplicatesQuery,
  AutomationListReviewDuplicates_OrgContactCrmDuplicatesQueryVariables,
  AutomationListReviewDuplicatesTable_ContactListFragmentDoc,
  CrmObjectType,
} from "../../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import SkeletonLoading from "../../shared/SkeletonLoading";
import AutomationListReviewDuplicatesTable, {
  AutomationListReviewDuplicatesTable_ContactList,
} from "./AutomationListReviewDuplicatesTable";
import { useFragment } from "../../../__generated__";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { useContext, useEffect } from "react";
import { AutomationListStateEnum } from "../../../routes/automationList/AutomationList";
import ListContext from "../../../contexts/ListContext";

const AutomationListReviewDuplicates_OrgContactCrmDuplicates = gql`
  query AutomationListReviewDuplicates_OrgContactCrmDuplicates(
    $inputs: [OrgContactCrmDuplicateInput!]!
  ) {
    orgContactCrmDuplicates(inputs: $inputs) {
      ...AutomationListReviewDuplicatesTable_ContactList
      orgContactCrmDuplicates {
        orgContact {
          id
        }
      }
    }
  }

  ${AutomationListReviewDuplicatesTable_ContactList}
`;

export const AutomationListReviewDuplicates_ContactList = gql`
  fragment AutomationListReviewDuplicates_ContactList on ContactList {
    id
    contacts {
      id
      org_contact {
        id
        first_name
        last_name
        company_name
      }
    }
  }
`;

const AutomationListReviewDuplicatesSchema = yup.object({
  crmObjectType: yup
    .string()
    .oneOf([CrmObjectType.Contact, CrmObjectType.Lead])
    .required(),
  formDuplicateActions: yup.array().of(
    yup.object({
      orgContactId: yup.number().required(),
      action: yup.string().oneOf(["skip", "create"]).required(),
      account: yup.string().when(["$crmObjectType", "action"], {
        is: (crmObjectType: string, action: string) =>
          crmObjectType === "contact" && action === "create",
        then: (schema) => schema.required("Account is required for contacts"),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
  ),
});

export type FormDuplicateActionsType = {
  crmObjectType: CrmObjectType;
  formDuplicateActions: {
    account?: string | null | undefined;
    orgContactId: number;
    action: "skip" | "create";
  }[];
};

const AutomationListReviewDuplicates = ({
  crmObject,
  setViewState,
  filteredContactIdsToOrgContactIds,
  setDuplicateActions,
}: {
  crmObject: CrmObjectType;
  setViewState: (viewState: AutomationListStateEnum) => void;
  filteredContactIdsToOrgContactIds: Map<number, number>;
  setDuplicateActions: (
    value: FormDuplicateActionsType["formDuplicateActions"],
  ) => void;
}) => {
  const { selectedContactIdsToOrgContactIds } = useContext(ListContext);
  const { listId } = useParams();
  const { data, loading, error } = useQuery<
    AutomationListReviewDuplicates_OrgContactCrmDuplicatesQuery,
    AutomationListReviewDuplicates_OrgContactCrmDuplicatesQueryVariables
  >(AutomationListReviewDuplicates_OrgContactCrmDuplicates, {
    variables: {
      inputs: Array.from(
        selectedContactIdsToOrgContactIds.size > 0
          ? selectedContactIdsToOrgContactIds.values()
          : filteredContactIdsToOrgContactIds.values(),
      ).map((orgContactId) => ({
        orgContactId,
      })),
    },
    fetchPolicy: "network-only",
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(AutomationListReviewDuplicatesSchema),
    defaultValues: {
      crmObjectType: crmObject,
      formDuplicateActions:
        [] as FormDuplicateActionsType["formDuplicateActions"],
    },
  });

  const formDuplicateActions = useWatch({
    control,
    name: "formDuplicateActions",
  });

  useEffect(() => {
    if (data?.orgContactCrmDuplicates?.orgContactCrmDuplicates) {
      reset({
        crmObjectType: crmObject,
        formDuplicateActions:
          data.orgContactCrmDuplicates.orgContactCrmDuplicates.map(
            (duplicate) => ({
              orgContactId: duplicate.orgContact.id!,
              action: "skip",
              account: null,
            }),
          ),
      });
    }
  }, [
    data?.orgContactCrmDuplicates?.orgContactCrmDuplicates,
    reset,
    crmObject,
  ]);

  const duplicatesTableContactList = useFragment(
    AutomationListReviewDuplicatesTable_ContactListFragmentDoc,
    data?.orgContactCrmDuplicates ?? null,
  );

  if (error) {
    console.error(error);
    return <div>Error fetching duplicates. Please contact help@ciro.io</div>;
  }

  return (
    <>
      <div
        className={classNames(
          "flex",
          "w-full",
          "justify-between",
          "items-center",
        )}
      >
        <h1 className={classNames("text-2xl", "pb-6")}>
          Review duplicates
        </h1>
        <div className={classNames("flex", "gap-4")}>
          <CiroButton
            analyticsField="cancel-push-to-crm"
            disabled={loading}
            analyticsProps={{
              listId,
            }}
            style={CiroButtonStyleEnum.INVERTED_LOUD}
            onClick={() => {
              setViewState(AutomationListStateEnum.Home);
            }}
          >
            Cancel
          </CiroButton>
          <CiroButton
            analyticsField="push-to-crm-continue"
            analyticsProps={{
              listId,
            }}
            disabled={loading}
            style={CiroButtonStyleEnum.LOUD}
            onClick={handleSubmit((data) => {
              setViewState(AutomationListStateEnum.FinalizePushToCrm);
              setDuplicateActions(data.formDuplicateActions);
            })}
          >
            Continue
          </CiroButton>
        </div>
      </div>
      <div className={classNames("w-full", "mt-6")}>
        {loading && (
          <SkeletonLoading numSkeletons={3} skeletonHeight={"6rem"} />
        )}
        {!loading && formDuplicateActions.length === 0 && (
          <div className={classNames("text-center")}>
            No duplicates found! Please continue to review records to be
            exported.
          </div>
        )}
        {!loading && formDuplicateActions.length > 0 && (
          <AutomationListReviewDuplicatesTable
            setValue={setValue}
            formDuplicateActions={formDuplicateActions}
            contactList={duplicatesTableContactList ?? null}
            crmObjectType={crmObject}
          />
        )}
      </div>
    </>
  );
};

export default AutomationListReviewDuplicates;
