import { useAuth0 } from "@auth0/auth0-react";
import ProfilePhotoIcon from "../../assets/img/icons/ProfilePhotoIcon";
import classNames from "classnames";

const UserPhotoIcon = () => {
  const { user } = useAuth0();
  const profilePhotoUrl = user?.picture;

  return (
    <div>
      {profilePhotoUrl ? (
        <div
          className={classNames(
            "w-[75px]",
            "h-[75px]",
            "rounded-full",
            "border-4",
            "border-white",
            "!overflow-hidden",
            "bg-[#212121]",
          )}
        >
          <img
            src={profilePhotoUrl}
            alt="Profile"
            className={classNames(
              "w-full",
              "h-full",
              "object-cover",
            )}
          />
        </div>
      ) : (
        <ProfilePhotoIcon width={75} height={75} />
      )}
    </div>
  );
};

export default UserPhotoIcon;
