import classNames from "classnames";
import CiroDropDown from "../shared/CiroDropdown";
import { useMemo } from "react";
import {
  CallDispositionWorkflowFormSection_OrganizationQuery,
  CallDispositionWorkflowFormSection_OrganizationQueryVariables,
  CrmDisposition,
} from "../../__generated__/graphql";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import { gql, useQuery } from "@apollo/client";
import SkeletonLoading from "../shared/SkeletonLoading";

const CallDispositionWorkflowFormSection_Organization = gql`
  query CallDispositionWorkflowFormSection_Organization {
    organization {
      apolloIntegration {
        crmDispositions {
          id
          label
        }
      }
      organizationMergeIntegration {
        crmDispositions {
          id
          label
        }
      }
    }
  }
`;

const CallDispositionWorkflowFormSection = ({
  sharedWorkflowFormProperties: { setValue, trigger, errors, watch },
}: {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
}) => {
  const callDispositions = watch("segmentation.call_dispositions");
  const { data: callDispositionData, loading: callDispositionLoading } =
    useQuery<
      CallDispositionWorkflowFormSection_OrganizationQuery,
      CallDispositionWorkflowFormSection_OrganizationQueryVariables
    >(CallDispositionWorkflowFormSection_Organization);
  const callDispositionOptions = useMemo(() => {
    if (!callDispositionData) {
      return [] as { label: string; value: string }[];
    }

    const crmDispositions =
      callDispositionData.organization?.apolloIntegration?.crmDispositions ||
      callDispositionData.organization?.organizationMergeIntegration
        ?.crmDispositions ||
      [];

    return crmDispositions
      .map((disposition: CrmDisposition | null) => {
        if (!disposition) {
          return null;
        }
        return {
          label: disposition.label,
          value: disposition.id,
        };
      })
      .filter(Boolean) as { label: string; value: string }[];
  }, [callDispositionData]);

  if (callDispositionLoading) {
    return <SkeletonLoading numSkeletons={2} skeletonHeight={"3rem"} />;
  }

  return (
    <div className={classNames("mb-4")}>
      <CiroDropDown
        isMulti={true}
        label="Call dispositions indicating a bad number"
        options={callDispositionOptions}
        value={callDispositions || []}
        onChange={(value) => {
          setValue("segmentation.call_dispositions", value);
          trigger("segmentation.call_dispositions");
        }}
        infoTooltip={
          "Choose the call outcomes that suggest a phone number needs updating."
        }
        error={errors.segmentation?.call_dispositions?.message}
      />
    </div>
  );
};

export default CallDispositionWorkflowFormSection;
