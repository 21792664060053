import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../shared/CiroDropdownButton";
import classNames from "classnames";
import { useCallback, useContext } from "react";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";
import { ForwardIcon, PlayIcon } from "@heroicons/react/24/outline";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";

export const REMOVE_FILTERS_TOOLTIP = "Clear filters to run";
export const SELECTED_ENRICHMENT_TOOLTIP =
  "Close enrichment editing window to run";
export const RUNNING_JOB_TOOLTIP = "There is a job currently running.";
export const ONLY_INITIALIZE_TOOLTIP = "Add an enrichment step to run";
export const STEP_IS_DIRTY_TOOLTIP = "Save the step to run";

const ANALYTICS_FIELD = "Run Enrichment Flow";

interface IRunSelectedRowsOptionProps {
  onClick: (v: number[]) => void;
  cascade: boolean;
}
interface IRunSpecificRowsOptionProps extends IRunSelectedRowsOptionProps {
  numRowsToRun: number;
  offset: number;
  totalCount: number;
}

const RunSelectedRowsOption = ({
  onClick,
  cascade,
}: IRunSelectedRowsOptionProps) => {
  const { selectedRows, enrichmentFlowRowIds } = useContext(
    EnrichmentFlowContext,
  );
  const flowRowIds = [] as number[];
  enrichmentFlowRowIds.forEach((flowRowId, tableIndex) => {
    if (
      tableIndex >= selectedRows.tableIndexStart! &&
      tableIndex <= selectedRows.tableIndexEnd!
    ) {
      flowRowIds.push(flowRowId);
    }
  });

  return (
    <CiroDropdownButtonItem
      onClick={() => {
        onClick(flowRowIds);
      }}
      analyticsField={`${ANALYTICS_FIELD} for displayed rows with cascade ${cascade}`}
    >
      <div className={classNames("flex")}>
        <PlayIcon
          className={classNames("w-5", "text-neutral-400")}
        />
        <div className={classNames("pl-2")}>
          Run {selectedRows.tableIndexStart! + 1}-
          {selectedRows.tableIndexEnd! + 1}
        </div>
      </div>
    </CiroDropdownButtonItem>
  );
};

const RunSpecificRowsOption = ({
  numRowsToRun,
  onClick,
  cascade,
  offset,
  totalCount,
}: IRunSpecificRowsOptionProps) => {
  const { enrichmentFlowRowIds: rowIds } = useContext(EnrichmentFlowContext);

  if (totalCount - offset <= numRowsToRun) {
    return null;
  }

  return (
    <CiroDropdownButtonItem
      onClick={() => {
        onClick(rowIds.slice(0 + offset, numRowsToRun + offset));
      }}
      analyticsField={`${ANALYTICS_FIELD} for displayed rows with cascade ${cascade}`}
    >
      <div className={classNames("flex")}>
        <PlayIcon
          className={classNames("w-5", "text-neutral-400")}
        />
        <div className={classNames("pl-2")}>
          Run rows {offset + 1} to {offset + numRowsToRun}
        </div>
      </div>
    </CiroDropdownButtonItem>
  );
};

interface IRunEnrichmentFlowButtonProps {
  cascade: boolean;
  disabled: boolean;
  enrichmentStepId?: number | null;
  offset: number;
  totalCount: number;
  tooltip?: string | null;
}

const RunEnrichmentFlowButton = ({
  cascade,
  disabled,
  enrichmentStepId,
  offset,
  totalCount,
  tooltip,
}: IRunEnrichmentFlowButtonProps) => {
  const { selectedRows, prepareRunStep } = useContext(EnrichmentFlowContext);

  const onClick = useCallback(
    (enrichmentFlowRowIds: number[] | null) => {
      prepareRunStep({
        enrichmentStepId: enrichmentStepId || null,
        enrichmentFlowRowIds,
        numRowsToRun: null,
      });
    },
    [enrichmentStepId, prepareRunStep],
  );

  const rowsSelected =
    selectedRows.tableIndexStart !== null &&
    selectedRows.tableIndexEnd !== null;

  return (
    <CiroTooltipContainer disabled={!tooltip} tooltip={tooltip}>
      <CiroDropdownButton
        borderless
        customBgColor="bg-orange-500"
        customClassName={classNames("text-white")}
        dropdownButtonClassName={classNames("text-white")}
        disabled={disabled}
        label={
          <span className={classNames("flex")}>
            <ForwardIcon className={classNames("w-5")} />
            <span
              className={classNames(
                "pl-2",
                "whitespace-nowrap",
              )}
            >
              Run{enrichmentStepId && " Step"}
            </span>
          </span>
        }
      >
        <CiroDropdownButtonItem
          onClick={() => {
            onClick(null);
          }}
          analyticsField={`${ANALYTICS_FIELD} for all rows with cascade ${cascade}`}
        >
          <div className={classNames("flex")}>
            <ForwardIcon
              className={classNames("w-5", "text-neutral-400")}
            />
            <div className={classNames("pl-2")}>Run all</div>
          </div>
        </CiroDropdownButtonItem>
        {rowsSelected && (
          <RunSelectedRowsOption onClick={onClick} cascade={cascade} />
        )}
        {!rowsSelected && (
          <>
            <RunSpecificRowsOption
              numRowsToRun={10}
              onClick={onClick}
              cascade={cascade}
              offset={offset}
              totalCount={totalCount}
            />
            <RunSpecificRowsOption
              numRowsToRun={50}
              onClick={onClick}
              cascade={cascade}
              offset={offset}
              totalCount={totalCount}
            />
            <RunSpecificRowsOption
              numRowsToRun={100}
              onClick={onClick}
              cascade={cascade}
              offset={offset}
              totalCount={totalCount}
            />
          </>
        )}
      </CiroDropdownButton>
    </CiroTooltipContainer>
  );
};

export default RunEnrichmentFlowButton;
