import { gql } from "@apollo/client";
import {
  AutomationListReviewDuplicatesRow_ContactFragmentDoc,
  AutomationListReviewDuplicatesTable_ContactListFragment,
  CrmObjectType,
} from "../../../__generated__/graphql";
import CiroTable from "../../shared/CiroTable/CiroTable";
import classNames from "classnames";
import CiroTableHeader from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import AutomationListReviewDuplicatesRow, {
  AutomationListReviewDuplicatesRow_Contact,
} from "./AutomationListReviewDuplicatesRow";
import { useFragment as getFragmentData } from "../../../__generated__";
import { UseFormSetValue } from "react-hook-form";
import { FormDuplicateActionsType } from "./AutomationListReviewDuplicates";

export const AutomationListReviewDuplicatesTable_ContactList = gql`
  fragment AutomationListReviewDuplicatesTable_ContactList on OrgContactCrmDuplicatesResponse {
    orgContactCrmDuplicates {
      ...AutomationListReviewDuplicatesRow_Contact
      orgContact {
        id
      }
    }
  }

  ${AutomationListReviewDuplicatesRow_Contact}
`;

const AutomationListReviewDuplicatesTable = ({
  contactList,
  crmObjectType,
  formDuplicateActions,
  setValue,
}: {
  contactList: AutomationListReviewDuplicatesTable_ContactListFragment | null;
  crmObjectType: CrmObjectType;
  formDuplicateActions: FormDuplicateActionsType["formDuplicateActions"];
  setValue: UseFormSetValue<FormDuplicateActionsType>;
}) => {
  if (!contactList) {
    return null;
  }

  const contactMap = new Map(
    contactList.orgContactCrmDuplicates.map((contact) => [
      contact.orgContact.id,
      getFragmentData(
        AutomationListReviewDuplicatesRow_ContactFragmentDoc,
        contact,
      ),
    ]),
  );

  if (contactMap.size === 0) {
    return (
      <div className={classNames("flex", "justify-center")}>
        <div className={classNames("text-lg", "font-bold")}>
          No duplicates found
        </div>
      </div>
    );
  }

  return (
    <CiroTable>
      <thead className={classNames("table-header-group")}>
        <CiroTableRow clickable={false}>
          <CiroTableHeader isFirst={true}>Name</CiroTableHeader>
          <CiroTableHeader>Company</CiroTableHeader>
          <CiroTableHeader>Existing CRM Record</CiroTableHeader>
          <CiroTableHeader isLast={true}>Action</CiroTableHeader>
        </CiroTableRow>
      </thead>
      <tbody className={classNames("table-row-group")}>
        {Array.from(contactMap.values()).map((contact, idx) => {
          return (
            <AutomationListReviewDuplicatesRow
              setValue={setValue}
              idx={idx}
              key={contact.orgContact.id}
              contact={contact}
              isLastRow={idx === contactMap.size - 1}
              crmObjectType={crmObjectType}
              formDuplicateActions={formDuplicateActions}
            />
          );
        })}
      </tbody>
    </CiroTable>
  );
};

export default AutomationListReviewDuplicatesTable;
