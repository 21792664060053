import React from "react";
import { gql } from "@apollo/client";
import DateRangeFilter from "./DateRangeFilter";
import classNames from "classnames";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { LastActivityDateFilter_UserAccountFragment } from "../../__generated__/graphql";
import {
  CONNECT_TO_CRM_TOOLTIP_TEXT,
  DEFAULT_FILTER_TOOLTIP_TEXT,
} from "../../utils/consts";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface ILastActivityDateFilterProps {
  blurred?: boolean;
  lastActivityDateAfterFilter: Date | null;
  lastActivityDateBeforeFilter: Date | null;
  resetOffset: () => void;
  setLastActivityDateAfterFilter: (v: Date | null) => void;
  setLastActivityDateBeforeFilter: (v: Date | null) => void;
  userAccountData: LastActivityDateFilter_UserAccountFragment;
}

export const LastActivityDateFilter_userAccount = gql`
  fragment LastActivityDateFilter_userAccount on Query {
    userAccount {
      id
      hasCRMConnection
    }
  }
`;

function LastActivityDateFilter({
  blurred = false,
  lastActivityDateAfterFilter,
  lastActivityDateBeforeFilter,
  resetOffset,
  setLastActivityDateAfterFilter,
  setLastActivityDateBeforeFilter,
  userAccountData,
}: ILastActivityDateFilterProps) {
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={
        lastActivityDateAfterFilter || lastActivityDateBeforeFilter ? 1 : 0
      }
      sectionTitle={
        <div>
          Last Activity Date
          <span className={classNames("pl-2")}>
            <CiroTooltipContainer tooltip="CRM account or lead last activity date">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color="rgba(0,0,0,.55)"
              />
            </CiroTooltipContainer>
          </span>
        </div>
      }
      filterOptions={[
        {
          filter: (
            <DateRangeFilter
              blurred={
                blurred || !userAccountData.userAccount?.hasCRMConnection
              }
              blurredTooltip={
                blurred
                  ? DEFAULT_FILTER_TOOLTIP_TEXT
                  : CONNECT_TO_CRM_TOOLTIP_TEXT
              }
              displayDirection="vertical"
              endDate={lastActivityDateBeforeFilter}
              endDateLabel="Before:"
              endDatePlaceholderText="Enter Date"
              resetOffset={resetOffset}
              setEndDate={setLastActivityDateBeforeFilter}
              setStartDate={setLastActivityDateAfterFilter}
              startDate={lastActivityDateAfterFilter}
              startDateLabel="After:"
              startDatePlaceholderText="Enter Date"
            />
          ),
        },
      ]}
    />
  );
}

export default LastActivityDateFilter;
