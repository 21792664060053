import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { CHROME_EXTENSION_URL } from "../home/ChromeExtensionCard";
import { gql } from "@apollo/client";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import { AutomationGetStarted_UserAccountFragment } from "../../__generated__/graphql";
import ChromeOneColorIcon from "../../assets/img/icons/ChromeOneColorIcon";

export const AutomationGetStarted_UserAccount = gql`
  fragment AutomationGetStarted_UserAccount on UserAccount {
    id
    autopilot_initial_search_url
  }
`;

const AutomationGetStarted = ({
  userAccount,
}: {
  userAccount: AutomationGetStarted_UserAccountFragment | null | undefined;
}) => {
  return (
    <div className={classNames("w-full", "pb-6")}>
      <CiroTitle title="Get started" size="small" />
      <div
        className={classNames(
          "flex",
          "flex-col",
          "gap-4",
          "mt-4",
        )}
      >
        {(
          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "p-4",
              "flex",
              "items-center",
              "justify-between",
              "shadow-sm",
            )}
          >
            <div>
              <div
                className={classNames("font-medium", "text-sm")}
              >
                1. Install Chrome extension
              </div>
              <div
                className={classNames(
                  "text-gray-600",
                  "text-xs",
                )}
              >
                Add the Ciro extension to Chrome
              </div>
            </div>
            <CiroButton
              analyticsField="Install Chrome Extension"
              href={CHROME_EXTENSION_URL}
              style={CiroButtonStyleEnum.LOUD}
            >
              <div
                className={classNames(
                  "flex",
                  "items-center",
                  "gap-2",
                  "h-[20px]",
                )}
              >
                <ChromeOneColorIcon color="white" transform={1.25} />
                <span>Install Chrome extension</span>
              </div>
            </CiroButton>
          </div>
        )}
        {userAccount?.autopilot_initial_search_url && (
          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "p-4",
              "flex",
              "items-center",
              "justify-between",
              "shadow-sm",
            )}
          >
            <div>
              <div
                className={classNames("font-medium", "text-sm")}
              >
                2. Run Autopilot
              </div>
              <div
                className={classNames(
                  "text-gray-600",
                  "text-xs",
                )}
              >
                Use the extension to analyze some contacts in LinkedIn Sales
                Navigator. We have created a search specifically for you to get
                started!
              </div>
            </div>
            <CiroButton
              analyticsField="Run your first Autopilot"
              href={userAccount?.autopilot_initial_search_url}
              style={CiroButtonStyleEnum.LOUD}
            >
              <div
                className={classNames(
                  "flex",
                  "items-center",
                  "gap-2",
                  "h-[20px]",
                )}
              >
                <LinkedInIcon
                  mini={true}
                  customScale={0.75}
                  customInsideColor="#EA580C"
                  customRectColor="white"
                />
                <span>Open Sales Navigator</span>
              </div>
            </CiroButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationGetStarted;
