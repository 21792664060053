import React, { useState } from "react";
import classNames from "classnames";
import { gql, useQuery } from "@apollo/client";
import CiroModal from "../../shared/CiroModal";
import Loading from "../../shared/Loading";
import {
  AdminOrganizationDetails_SelectedOrganizationQuery,
  AdminOrganizationDetails_SelectedOrganizationQueryVariables,
  AdminViewOrganizationDetails_OrganizationFragmentDoc,
  AdminViewCreditPlanDetails_OrganizationFragmentDoc,
  AdminViewCustomPermissions_OrganizationFragmentDoc,
  AdminViewOrganizationUsers_OrganizationFragmentDoc,
  OrganizationSubscriptionTierEnum,
} from "../../../__generated__/graphql";
import AdminViewOrganizationDetails, {
  AdminViewOrganizationDetails_Organization,
} from "./AdminViewOrganizationDetails";
import { useFragment } from "../../../__generated__";
import AdminViewCreditPlanDetails, {
  AdminViewCreditPlanDetails_Organization,
} from "./AdminViewCreditsPlanDetails";
import AdminOrganizationDropdown from "../AdminOrganizationDropdown";
import AdminViewCustomPermissions, {
  AdminViewCustomPermissions_Organization,
} from "./AdminViewCustomPermissions";
import AdminViewOrganizationUsers, {
  AdminViewOrganizationUsers_Organization,
} from "./AdminViewOrganizationUsers";
import CiroCheckbox from "../../shared/CiroCheckbox";

// Used in:
// AdminViewCreditPlanDetails
// AdminViewCustomPermissions
// AdminViewOrganizationUsers
export const AdminOrganizationDetails_SelectedOrganization = gql`
  query AdminOrganizationDetails_SelectedOrganization(
    $organizationId: Int
  ) {
    organization(id: $organizationId) {
      name
      subscription_tier
      ...AdminViewCreditPlanDetails_Organization
      ...AdminViewCustomPermissions_Organization
      ...AdminViewOrganizationDetails_Organization
      ...AdminViewOrganizationUsers_Organization
    }
  }
  ${AdminViewCreditPlanDetails_Organization}
  ${AdminViewCustomPermissions_Organization}
  ${AdminViewOrganizationDetails_Organization}
  ${AdminViewOrganizationUsers_Organization}
`;

export enum OrganizationDetailsModalStateEnum {
  CREDIT_PLAN_DETAILS,
  CUSTOM_PERMISSIONS,
  USERS,
  VIEW,
}

const AdminOrganizationDetails = () => {
  const [selectedOrgId, setSelectedOrgId] = useState(0);
  const [modalState, setModalState] =
    useState<OrganizationDetailsModalStateEnum>(
      OrganizationDetailsModalStateEnum.VIEW,
    );
  const [subscriptionTiers, setSubscriptionTiers] = useState<{
    [v in OrganizationSubscriptionTierEnum]: boolean;
  }>({
    [OrganizationSubscriptionTierEnum.Professional]: true,
    [OrganizationSubscriptionTierEnum.Admin]: true,
    [OrganizationSubscriptionTierEnum.Free]: false,
  });

  const setSubscriptionTier = (tier: OrganizationSubscriptionTierEnum) => {
    const newSubscriptionTiers = { ...subscriptionTiers };
    newSubscriptionTiers[tier] = !newSubscriptionTiers[tier];
    setSubscriptionTiers(newSubscriptionTiers);
  };

  const {
    data: selectedOrganizationData,
    loading: selectedOrganizationLoading,
  } = useQuery<
    AdminOrganizationDetails_SelectedOrganizationQuery,
    AdminOrganizationDetails_SelectedOrganizationQueryVariables
  >(AdminOrganizationDetails_SelectedOrganization, {
    skip: !selectedOrgId,
    variables: {
      organizationId: selectedOrgId,
    },
  });

  const modalOrganizationDetails = useFragment(
    AdminViewOrganizationDetails_OrganizationFragmentDoc,
    selectedOrganizationData?.organization,
  );
  const modalPaymentPlanDetails = useFragment(
    AdminViewCreditPlanDetails_OrganizationFragmentDoc,
    selectedOrganizationData?.organization,
  );
  const modalOrganizationPermissions = useFragment(
    AdminViewCustomPermissions_OrganizationFragmentDoc,
    selectedOrganizationData?.organization,
  );
  const modalOrganizationUsers = useFragment(
    AdminViewOrganizationUsers_OrganizationFragmentDoc,
    selectedOrganizationData?.organization,
  );

  return (
    <div>
      <div className={classNames("pb-2", "font-medium")}>
        Organization Details
      </div>
      <AdminOrganizationDropdown
        placeholder="Select Organization"
        selectedOrgId={selectedOrgId}
        setSelectedOrgId={setSelectedOrgId}
        subscriptionTiers={
          Object.entries(subscriptionTiers)
            .filter(([_, v]) => v)
            .map(([k, _]) => k) as OrganizationSubscriptionTierEnum[]
        }
      />
      <CiroModal
        size="XLARGE"
        onClose={() => {
          setSelectedOrgId(0);
          setModalState(OrganizationDetailsModalStateEnum.VIEW);
        }}
        isOpen={Boolean(selectedOrgId)}
      >
        {selectedOrganizationLoading && <Loading size="SMALL" />}
        {modalState === OrganizationDetailsModalStateEnum.VIEW && (
          <AdminViewOrganizationDetails
            organization={modalOrganizationDetails}
            setModalState={setModalState}
          />
        )}
        {modalState ===
          OrganizationDetailsModalStateEnum.CREDIT_PLAN_DETAILS && (
          <AdminViewCreditPlanDetails
            organization={modalPaymentPlanDetails}
            setModalState={setModalState}
            modalState={modalState}
          />
        )}
        {modalState ===
          OrganizationDetailsModalStateEnum.CUSTOM_PERMISSIONS && (
          <AdminViewCustomPermissions
            organization={modalOrganizationPermissions}
            setModalState={setModalState}
          />
        )}
        {modalState === OrganizationDetailsModalStateEnum.USERS && (
          <AdminViewOrganizationUsers
            organization={modalOrganizationUsers}
            setModalState={setModalState}
          />
        )}
      </CiroModal>
      <div className={classNames("pt-4", "flex")}>
        <span className={classNames("pr-4")}>
          <CiroCheckbox
            label="Free"
            checked={subscriptionTiers[OrganizationSubscriptionTierEnum.Free]}
            onClick={() => {
              setSubscriptionTier(OrganizationSubscriptionTierEnum.Free);
            }}
          />
        </span>
        <span className={classNames("pr-4")}>
          <CiroCheckbox
            label="Professional"
            checked={
              subscriptionTiers[OrganizationSubscriptionTierEnum.Professional]
            }
            onClick={() => {
              setSubscriptionTier(
                OrganizationSubscriptionTierEnum.Professional,
              );
            }}
          />
        </span>
        <span className={classNames("pr-4")}>
          <CiroCheckbox
            label="Admin"
            checked={subscriptionTiers[OrganizationSubscriptionTierEnum.Admin]}
            onClick={() => {
              setSubscriptionTier(OrganizationSubscriptionTierEnum.Admin);
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default AdminOrganizationDetails;
