import React, { useState } from "react";
import classNames from "classnames";
import CiroButton from "../CiroButton";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

interface ICiroTableProps {
  borderless?: boolean;
  children: React.ReactNode;
  containerClassName?: string;
  pagination?: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  };
  scrollable?: boolean;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const [inputPage, setInputPage] = useState<string>(currentPage.toString());

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputPage(value);
    }
  };

  const handleInputBlur = () => {
    let pageNumber = parseInt(inputPage, 10);
    if (isNaN(pageNumber) || pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    setInputPage(pageNumber.toString());
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      setInputPage((currentPage - 1).toString());
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      setInputPage((currentPage + 1).toString());
    }
  };

  return (
    <div
      className={classNames(
        "flex",
        "justify-between",
        "items-center",
        "py-4",
        "space-x-4",
        "px-4",
        "text-sm",
        "text-neutral-500",
        "min-w-fit",
        "w-full",
        "overflow-x-auto",
      )}
    >
      <div className={classNames("w-40")}>
        {currentPage !== 1 && (
          <CiroButton
            onClick={handlePrevious}
            disabled={currentPage === 1}
            analyticsField="previous-page"
          >
            <div className={classNames("flex", "items-center")}>
              <ArrowLeftIcon
                className={classNames(
                  "w-4",
                  "h-4",
                  "mr-1",
                )}
              />
              Previous
            </div>
          </CiroButton>
        )}
      </div>
      <div className={classNames("flex", "items-center")}>
        <span className={classNames("mr-2")}>Page</span>
        <input
          type="text"
          value={inputPage}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyPress}
          className={classNames(
            "w-12",
            "px-2",
            "border",
            "border-gray-300",
            "rounded",
            "text-center",
          )}
        />
        <span className={classNames("ml-2")}>of {totalPages}</span>
      </div>
      <div
        className={classNames(
          "w-40",
          "justify-end",
          "flex",
        )}
      >
        {currentPage !== totalPages && (
          <CiroButton onClick={handleNext} analyticsField="next-page">
            <div className={classNames("flex", "items-center")}>
              Next
              <ArrowRightIcon
                className={classNames(
                  "w-4",
                  "h-4",
                  "ml-1",
                )}
              />
            </div>
          </CiroButton>
        )}
      </div>
    </div>
  );
};

const CiroTable = ({
  borderless = false,
  children,
  containerClassName = "",
  pagination,
  scrollable = false,
}: ICiroTableProps) => {
  return (
    <div
      className={classNames([
        "bg-white",
        "rounded-lg",
        "text-left",
        "border-b-0",
        "relative",
        containerClassName,
        {
          "overflow-x-auto": scrollable,
        },
      ])}
    >
      <div
        className={classNames("rounded-lg", "min-w-full", {
          "border": !borderless,
          "border-gray-200": !borderless,
          "overflow-hidden": !borderless,
        })}
      >
        <div className={classNames("overflow-x-auto")}>
          <table
            className={classNames([
              "w-full",
              "table",
              { "table-fixed": !scrollable },
              {
                "border-spacing-0": !borderless,
                "overflow-hidden": !borderless,
              },
            ])}
          >
            {children}
          </table>
          {pagination && (
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              onPageChange={pagination.onPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CiroTable;
