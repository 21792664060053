import classNames from "classnames";
import NumberFormat from "react-number-format";
import CoinIcon from "../../assets/img/icons/CoinIcon";

const CiroCoinPill = ({
  amount,
  additionalText,
  customColor,
  customBackgroundColor,
}: {
  amount: number;
  additionalText?: string;
  customColor?: string;
  customBackgroundColor?: string;
}) => {
  return (
    <span
      className={classNames(
        "flex",
        "items-center",
        "rounded",
        "px-1",
        "text-sm",
        {
          "text-amber-500": !customColor,
          "bg-amber-100": !customBackgroundColor,
        },
        customColor,
        customBackgroundColor,
      )}
    >
      {additionalText && (
        <span className={classNames("pr-1")}>{additionalText}</span>
      )}
      <NumberFormat displayType="text" thousandSeparator="," value={amount} />
      <span className={classNames("pl-1")}>
        <CoinIcon />
      </span>
    </span>
  );
};

export default CiroCoinPill;
