import classNames from "classnames";
import CiroButton from "../../shared/CiroButton";
import { useState } from "react";
import FilterIcon from "../../../assets/img/icons/FilterIcon";
import CiroFilterModalContainer from "../../shared/CiroFilter/CiroFilterModalContainer";
import CiroFilterSectionContactListFilters from "./CiroFilterSectionContactListFilters";
import { IContactListFilters } from "../../../reactHooks/filters/contactList/useContactListFiltersAndSort";

const AutomationListHomeFilterButton = ({
  resetAll,
  numFiltersApplied,
  filters,
}: {
  resetAll: () => void;
  numFiltersApplied: number;
  filters: IContactListFilters;
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  return (
    <>
      <CiroButton
        onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
        analyticsField={`Open Ciro filters for Automation List`}
      >
        <span
          className={classNames(
            "whitespace-nowrap",
            "flex",
            "items-center",
            "font-medium",
          )}
        >
          <span className="mr-4">
            <FilterIcon />
          </span>
          Filters
          {Boolean(numFiltersApplied) && ` (${numFiltersApplied})`}
        </span>
      </CiroButton>
      <CiroFilterModalContainer
        displayCTA={false}
        resetAll={resetAll}
        numFiltersApplied={numFiltersApplied}
        filtersSection={[
          <CiroFilterSectionContactListFilters key={1} filters={filters} />,
        ]}
        isLoading={false}
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        subtitle={"Apply filters to your search"}
      />
    </>
  );
};

export default AutomationListHomeFilterButton;
