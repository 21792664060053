import {
  PencilSquareIcon,
  TrashIcon,
  PlayIcon,
  ForwardIcon,
} from "@heroicons/react/24/outline";
import EnrichmentFlowTableDropdownOption from "./EnrichmentFlowTableDropdownOption";
import { useContext, useState } from "react";
import CiroConfirmationModal from "../../../shared/CiroConfirmationModal";
import { EnrichmentTechniqueEnum } from "../../../../__generated__/graphql";
import EnrichmentFlowContext from "../../../../contexts/EnrichmentFlowContext";
import classNames from "classnames";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFiltersAppliedCount } from "../../../../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";
import { REMOVE_FILTERS_TOOLTIP } from "../../RunEnrichmentFlowButton";

const ThumbtackIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faThumbtack}
      className={classNames(
        "text-neutral-400",
        "h-5",
        "mr-3",
        "ml-1",
      )}
    />
  );
};

const EnrichmentFlowTableHeaderDropdown = (props: {
  handleOpenEnrichmentStep: () => void;
  handleDeleteEnrichmentStep: (enrichmentStepId: number) => void;
  enrichmentStepId: number;
  rowIds: number[];
  enrichmentTechnique: EnrichmentTechniqueEnum;
  togglePinned: () => void;
  isPinned: boolean;
}) => {
  const {
    handleOpenEnrichmentStep,
    handleDeleteEnrichmentStep,
    enrichmentStepId,
    rowIds,
    enrichmentTechnique,
    togglePinned,
    isPinned,
  } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    prepareRunStep,
    selectedRows,
    enrichmentFlowRowIds,
    runningJobId,
    enrichmentFlowFilters,
  } = useContext(EnrichmentFlowContext);
  const rowsSelected =
    selectedRows.tableIndexStart !== null &&
    selectedRows.tableIndexEnd !== null;

  const runTooltip =
    getFiltersAppliedCount(enrichmentFlowFilters) > 0
      ? REMOVE_FILTERS_TOOLTIP
      : null;
  const runEnrichmentFlowDisabled =
    getFiltersAppliedCount(enrichmentFlowFilters) > 0;

  return (
    <div className={classNames("overflow-hidden")}>
      {enrichmentTechnique !== EnrichmentTechniqueEnum.Initialize &&
        enrichmentTechnique !== EnrichmentTechniqueEnum.InitializeCompany &&
        runningJobId === null && (
          <>
            {!rowsSelected && rowIds.length > 10 && (
              <EnrichmentFlowTableDropdownOption
                option="Run 10"
                onClick={() =>
                  prepareRunStep({
                    enrichmentStepId,
                    enrichmentFlowRowIds: rowIds,
                    numRowsToRun: 10,
                  })
                }
                Icon={PlayIcon}
                tooltip={runTooltip}
                disabled={runEnrichmentFlowDisabled}
              />
            )}
            {rowsSelected && (
              <EnrichmentFlowTableDropdownOption
                option={`Run ${selectedRows.tableIndexStart! + 1}-${selectedRows.tableIndexEnd! + 1}`}
                onClick={() => {
                  const flowRowIds = [] as number[];
                  enrichmentFlowRowIds.forEach((flowRowId, tableIndex) => {
                    if (
                      tableIndex >= selectedRows.tableIndexStart! &&
                      tableIndex <= selectedRows.tableIndexEnd!
                    ) {
                      flowRowIds.push(flowRowId);
                    }
                  });
                  prepareRunStep({
                    enrichmentStepId,
                    enrichmentFlowRowIds: flowRowIds,
                    numRowsToRun: null,
                  });
                }}
                Icon={PlayIcon}
                tooltip={runTooltip}
                disabled={runEnrichmentFlowDisabled}
              />
            )}
            <EnrichmentFlowTableDropdownOption
              option="Run All"
              onClick={() =>
                prepareRunStep({
                  enrichmentStepId,
                  enrichmentFlowRowIds: null,
                  numRowsToRun: null,
                })
              }
              Icon={ForwardIcon}
              tooltip={runTooltip}
              disabled={runEnrichmentFlowDisabled}
            />
            <EnrichmentFlowTableDropdownOption
              option="Edit"
              onClick={handleOpenEnrichmentStep}
              Icon={PencilSquareIcon}
            />
          </>
        )}
      <EnrichmentFlowTableDropdownOption
        option="Delete"
        onClick={() => setDeleteModalOpen(true)}
        Icon={TrashIcon}
      />
      <EnrichmentFlowTableDropdownOption
        option={isPinned ? "Unpin column" : "Pin column"}
        onClick={() => togglePinned()}
        Icon={ThumbtackIcon}
      />
      <CiroConfirmationModal
        text="Are you sure you want to delete this column? All data will be lost"
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => {
          handleDeleteEnrichmentStep(enrichmentStepId);
        }}
        analyticsField="Delete Enrichment Step"
      />
    </div>
  );
};

export default EnrichmentFlowTableHeaderDropdown;
