import CiroCheckbox from "../CiroCheckbox";
import { BULK_SELECTION_LIMIT } from "../../../routes/accounts/Accounts";

import { CiroDropdownButtonItem } from "../CiroDropdownButton";

import { CiroDropdownButton } from "../CiroDropdownButton";
import classNames from "classnames";

interface ICiroDropdownCheckboxButton {
  canSelectRecordsInBulk: boolean | null | undefined;
  canSelectMoreRecords: boolean;
  numDisplayedRecords: number;
  handleTopCheckboxSelection: () => void;
  noDropdownActions: boolean;
  noSelectionLimit?: boolean;
  numberVisibleChecked: number;
  selectFirstMaxNumberRecords: () => void;
  startIdx: number;
  totalCount: number;
  totalNumberChecked: number;
  unselectAll: () => void;
  unselectVisible: () => void;
}

const CiroDropdownCheckboxButton = ({
  canSelectRecordsInBulk,
  canSelectMoreRecords,
  numDisplayedRecords,
  handleTopCheckboxSelection,
  noDropdownActions,
  noSelectionLimit,
  numberVisibleChecked,
  selectFirstMaxNumberRecords,
  startIdx,
  totalCount,
  totalNumberChecked,
  unselectAll,
  unselectVisible,
}: ICiroDropdownCheckboxButton) => {
  return (
    <CiroDropdownButton
      disabled={totalNumberChecked === 0 && !canSelectMoreRecords}
      customPadding={
        noDropdownActions
          ? classNames("pl-4", "pr-4", "py-2")
          : undefined
      }
      hideChevron={noDropdownActions}
      prettyDisable={true}
      onLabelClick={handleTopCheckboxSelection}
      menuPosition="right"
      label={
        <CiroCheckbox
          className="test-table-top-checkbox"
          checked={totalNumberChecked > 0}
          indeterminate={
            totalNumberChecked !== 0 &&
            numberVisibleChecked !== numDisplayedRecords
          }
        />
      }
    >
      {canSelectMoreRecords && canSelectRecordsInBulk && (
        <CiroDropdownButtonItem
          onClick={selectFirstMaxNumberRecords}
          analyticsField={"Bulk select records"}
        >
          Select {startIdx + 1}-
          {noSelectionLimit ? totalCount : Math.min(totalCount, startIdx + BULK_SELECTION_LIMIT)}
        </CiroDropdownButtonItem>
      )}
      {numberVisibleChecked > 0 && (
        <CiroDropdownButtonItem
          onClick={unselectVisible}
          analyticsField="Unselect all"
        >
          Unselect visible
        </CiroDropdownButtonItem>
      )}
      {totalNumberChecked > 0 && (
        <CiroDropdownButtonItem
          onClick={unselectAll}
          analyticsField="Unselect all"
        >
          Unselect all
        </CiroDropdownButtonItem>
      )}
    </CiroDropdownButton>
  );
};

export default CiroDropdownCheckboxButton;
