import classNames from "classnames";
import XIcon from "../../assets/img/icons/XIcon";
import {
  OnboardingHintEnum,
} from "../../__generated__/graphql";
import CiroModal from "../shared/CiroModal";
import CiroLink from "../shared/CiroLink";
import LinkArrowIcon from "../../assets/img/icons/LinkArrowIcon";
import useOnboardingHints from "../../reactHooks/onboarding/useOnboardingHints";

const AccountsIntroModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (v: boolean) => void;
}) => {

  const { onDismiss, hintViewed, hintLoading } = useOnboardingHints({
    hintType: OnboardingHintEnum.Accounts,
    setShowHint: setShowModal,
  });

  const emojis = ["🔎", "✅", "✨"];
  const bullets = [
    "Search & filter for companies",
    "Check off the accounts you're interested in",
    'Click "enrich" to enhance accounts with LinkedIn contacts and custom fields scraped from the web',
  ];

  // show modal if hint is done loading AND hasn't been viewed yet OR if the modal is forced open by user
  const isOpen = (!hintLoading && !hintViewed) || (showModal)

  return (
    <div className={classNames("w-full", "my-2")}>
      <CiroModal isOpen={isOpen} onClose={onDismiss} size={"LARGE"}>
        <div
          className={classNames(
            "flex",
            "font-medium",
            "items-center",
            "justify-between",
          )}
        >
          <span>Ciro: Searchable database of all US companies</span>
          <span
            onClick={onDismiss}
            className={classNames("cursor-pointer")}
          >
            <XIcon />
          </span>
        </div>
        <div
          className={classNames(
            "text-neutral-500",
            "flex",
            "flex-col",
            "my-4",
          )}
        >
          <ul className={classNames("pl-0")}>
            {bullets.map((bullet, idx) => {
              return (
                <li
                  key={idx}
                  className={classNames(
                    "flex",
                    "items-center",
                    "mb-2",
                  )}
                >
                  <span role="img" className={classNames("mr-3")}>
                    {emojis[idx]}
                  </span>
                  <div className={classNames("leading-4")}>
                    {bullet}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <CiroLink
            href={"https://www.youtube.com/watch?v=3vi4ZsiV6A0"}
            shouldOpenInNewTab={true}
          >
            <div
              className={classNames(
                "flex",
                "flex-row",
                "gap-1",
                "items-center",
              )}
            >
              Watch demo
              <span className="pl-2">
                <LinkArrowIcon />
              </span>
            </div>
          </CiroLink>
        </div>
      </CiroModal>
    </div>
  );
};

export default AccountsIntroModal;
