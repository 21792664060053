import classNames from "classnames";
import LinkArrowIcon from "../../assets/img/icons/LinkArrowIcon";
import { analytics } from "../../utils/vendors";
import { useNavigate } from "react-router-dom";

interface ICiroLinkProps {
  href?: string;
  shouldOpenInNewTab?: boolean;
  children?: any;
}

const CiroLink = ({
  href,
  children,
  shouldOpenInNewTab = true,
}: ICiroLinkProps) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.track("linkClicked", {
      href,
    });

    if (shouldOpenInNewTab && href) {
      window.open(href, "_blank", "noopener,noreferrer");
    } else if (href) {
      navigate(href);
    }
  };

  return (
    <a
      className={classNames(
        "no-underline",
        "text-orange-400",
        "hover:text-orange-500",
      )}
      onClick={handleClick}
      href={href}
    >
      {children || <LinkArrowIcon />}
    </a>
  );
};

export default CiroLink;
