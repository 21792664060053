interface ILinkedInIconProps {
  mini?: Boolean;
  customScale?: number;
  customRectColor?: string;
  customInsideColor?: string;
}

const LinkedInIcon = ({
  mini,
  customScale,
  customRectColor,
  customInsideColor = "white",
}: ILinkedInIconProps) => {
  let transform;
  let fill;

  if (mini) {
    transform = `scale(${customScale ?? 0.75})`;
    fill = customRectColor ?? "gray";
  } else {
    transform = `scale(${customScale ?? 1})`;
    fill = customRectColor ?? "#1275B1";
  }

  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={transform}
    >
      <rect x="0.257812" width="28" height="28" rx="14" fill={fill} />
      <path
        d="M10.8764 7.69215C10.8764 8.6267 10.0663 9.3843 9.06709 9.3843C8.06785 9.3843 7.25781 8.6267 7.25781 7.69215C7.25781 6.7576 8.06785 6 9.06709 6C10.0663 6 10.8764 6.7576 10.8764 7.69215Z"
        fill={customInsideColor}
      />
      <path
        d="M7.50524 10.6281H10.598V20H7.50524V10.6281Z"
        fill={customInsideColor}
      />
      <path
        d="M15.5774 10.6281H12.4846V20H15.5774C15.5774 20 15.5774 17.0496 15.5774 15.2049C15.5774 14.0976 15.9555 12.9855 17.464 12.9855C19.1688 12.9855 19.1586 14.4345 19.1506 15.5571C19.1402 17.0244 19.165 18.5219 19.165 20H22.2578V15.0537C22.2316 11.8954 21.4086 10.4401 18.7011 10.4401C17.0932 10.4401 16.0965 11.1701 15.5774 11.8305V10.6281Z"
        fill={customInsideColor}
      />
    </svg>
  );
};

export default LinkedInIcon;
