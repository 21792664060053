import { useCallback, useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";
import useFilterBaseStringArray from "../base/useFilterBaseStringArray";
import useFilterBaseNumberArray from "../base/useFilterBaseNumberArray";
import useFilterBaseString from "../base/useFilterBaseString";
import useFilterBaseBoolean from "../base/useFilterBaseBoolean";

export type ContactListSortByEnum =
  | "default"
  | "companyName"
  | "name"
  | "persona"
  | "confidenceLevel";

export interface IContactListFilters {
  resetAll: () => void;

  offset: number;
  setOffset: (v: number) => void;

  companyNameFilter: string[];
  confidenceLevelFilter: string[];
  firstNameFilter: string[];
  hideDisqualifiedPersonas: boolean;
  lastNameFilter: string[];
  personaFilter: number[];

  setCompanyNameFilter: (v: string[]) => void;
  setConfidenceLevelFilter: (v: string[]) => void;
  setFirstNameFilter: (v: string[]) => void;
  setHideDisqualifiedPersonas: (v: boolean) => void;
  setLastNameFilter: (v: string[]) => void;
  setPersonaFilter: (v: number[]) => void;
  sortBy: ContactListSortByEnum | null;
  setSortBy: (v: string | null) => void;
  sortDirection: "asc" | "desc" | null;
  setSortDirection: (v: "asc" | "desc" | null) => void;

  numFiltersApplied: number;
}

export interface IFilteredContactListQueryVariables {
  [v: string]: string | string[];
}

const useContactListFiltersAndSort = ({
  searchParams,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (params: IFilteredContactListQueryVariables) => void;
}) => {
  const [stringifiedParams, setStringifiedParams] = useState("{}");
  const [numFiltersApplied, setNumFiltersApplied] = useState(0);

  const [offset, setOffset] = useState(0);

  const [companyNameFilter, setCompanyNameFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "companyName",
  });

  const [confidenceLevelFilter, setConfidenceLevelFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "confidenceLevel",
    });

  const [firstNameFilter, setFirstNameFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "firstName",
  });

  const [hideDisqualifiedPersonas, setHideDisqualifiedPersonas] =
    useFilterBaseBoolean({
      searchParams,
      filterName: "hideDisqualifiedPersonas",
    });

  const [lastNameFilter, setLastNameFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "lastName",
  });

  const [personaFilter, setPersonaFilter] = useFilterBaseNumberArray({
    searchParams,
    filterName: "persona",
  });

  const [sortBy, setSortBy] = useFilterBaseString({
    searchParams,
    filterName: "sortBy",
  });

  const [sortDirection, setSortDirection] = useFilterBaseString({
    searchParams,
    filterName: "sortDirection",
  });

  useEffect(() => {
    analytics.track("contactListFiltersChanged", {
      value: JSON.parse(stringifiedParams),
    });
  }, [stringifiedParams]);

  useEffect(() => {
    let newNumFiltersApplied = 0;
    let updatedParams = {} as IFilteredContactListQueryVariables;

    if (companyNameFilter.length > 0) {
      updatedParams.companyName = companyNameFilter;
      newNumFiltersApplied++;
    }
    if (confidenceLevelFilter.length > 0) {
      updatedParams.confidenceLevel = confidenceLevelFilter;
      newNumFiltersApplied++;
    }
    if (firstNameFilter.length > 0) {
      updatedParams.firstName = firstNameFilter;
      newNumFiltersApplied++;
    }
    if (hideDisqualifiedPersonas === false) {
      updatedParams.hideDisqualifiedPersonas = "false";
    }
    if (lastNameFilter.length > 0) {
      updatedParams.lastName = lastNameFilter;
      newNumFiltersApplied++;
    }
    if (personaFilter.length > 0) {
      updatedParams.persona = personaFilter.map(String);
      newNumFiltersApplied++;
    }
    if (sortBy) {
      updatedParams.sortBy = sortBy as ContactListSortByEnum;
    }
    if (sortDirection) {
      updatedParams.sortDirection = sortDirection as "asc" | "desc";
    }

    setSearchParams(updatedParams);
    setStringifiedParams(JSON.stringify(updatedParams));
    setNumFiltersApplied(newNumFiltersApplied);
  }, [
    offset,
    companyNameFilter,
    confidenceLevelFilter,
    firstNameFilter,
    hideDisqualifiedPersonas,
    lastNameFilter,
    personaFilter,
    sortBy,
    sortDirection,
    setSearchParams,
  ]);

  const resetAll = useCallback(() => {
    setOffset(0);
    setCompanyNameFilter([]);
    setConfidenceLevelFilter([]);
    setFirstNameFilter([]);
    setHideDisqualifiedPersonas(true);
    setLastNameFilter([]);
    setPersonaFilter([]);
    setSortBy(null);
    setSortDirection(null);
  }, [
    setOffset,
    setCompanyNameFilter,
    setConfidenceLevelFilter,
    setFirstNameFilter,
    setHideDisqualifiedPersonas,
    setLastNameFilter,
    setPersonaFilter,
    setSortBy,
    setSortDirection,
  ]);

  return {
    resetAll,

    companyNameFilter,
    confidenceLevelFilter,
    firstNameFilter,
    hideDisqualifiedPersonas: hideDisqualifiedPersonas ?? true,
    lastNameFilter,
    personaFilter,
    offset,
    sortBy: sortBy as ContactListSortByEnum | null,
    sortDirection: sortDirection as "asc" | "desc" | null,

    setCompanyNameFilter,
    setConfidenceLevelFilter,
    setFirstNameFilter,
    setHideDisqualifiedPersonas,
    setLastNameFilter,
    setOffset,
    setPersonaFilter,
    setSortBy,
    setSortDirection,
    numFiltersApplied,
  };
};

export default useContactListFiltersAndSort;
