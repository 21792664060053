import classNames from "classnames";
import CiroNumbersFormSection from "../shared/CiroNumbersFormSection";
import CiroDropDown from "../shared/CiroDropdown";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import CiroCheckbox from "../shared/CiroCheckbox";

const WriteSettingsWorkflowFormSection = ({
  sharedWorkflowFormProperties: {
    integrationName,
    isHubspot,
    isSalesforce,
    setValue,
    errors,
    orgContactObjectFields,
    trigger,
    watch,
  },
  isLoadingWorkflow,
}: {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
  isLoadingWorkflow: boolean;
}) => {
  const { timestampFields, filterFields } = orgContactObjectFields;

  const overwrite = watch("overwrite");
  const defaultPhoneWriteField = watch("default_phone_write_field");
  const timestamp = watch("timestamp");
  const timestampField = watch("timestamp_field");
  const isHubSpotOrSalesforce = isHubspot || isSalesforce;

  return (
    <CiroNumbersFormSection
      title="Write options"
      description={`Choose how to write back to ${integrationName}.`}
      isLoading={isLoadingWorkflow}
    >
      <div className={classNames("mb-4")}>
        <CiroDropDown
          label="Write new numbers back to my CRM"
          options={[
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" },
          ]}
          error={errors.overwrite?.message}
          value={overwrite}
          onChange={(v) => {
            setValue("overwrite", v as string);
            if (v !== "YES") {
              setValue("default_phone_write_field", "");
              if (!isHubSpotOrSalesforce) {
                setValue("default_phone_write_field", "mobilephone");
              }
            }
            trigger("overwrite");
          }}
        />
        {(overwrite === "YES" || overwrite === "SAME_FIELD_ONLY") &&
          isHubSpotOrSalesforce && (
            <>
              <div className="pt-4">
                <CiroDropDown
                  label="Phone number field to write to"
                  options={filterFields}
                  value={defaultPhoneWriteField}
                  onChange={(v) => {
                    setValue("default_phone_write_field", v as string);
                    trigger("default_phone_write_field");
                  }}
                  error={errors.default_phone_write_field?.message as string}
                />
              </div>
              <div className="pt-4">
                <CiroCheckbox
                  label="Use a timestamp field to indicate when the contact was updated by Ciro with a new number"
                  checked={timestamp}
                  onClick={() => {
                    setValue("timestamp", !timestamp);
                    if (!timestamp) {
                      setValue("timestamp_field", "");
                    }
                  }}
                />
              </div>
              {timestamp && (
                <div className="pt-4">
                  <CiroDropDown
                    label="Select the timestamp field"
                    options={timestampFields}
                    value={timestampField}
                    onChange={(v) => {
                      setValue("timestamp_field", v as string);
                    }}
                    error={errors.timestamp_field?.message as string}
                    menuPlacement="top"
                  />
                </div>
              )}
            </>
          )}
      </div>
    </CiroNumbersFormSection>
  );
};

export default WriteSettingsWorkflowFormSection;
