import classNames from "classnames";
import HomeProfileCard from "../../components/home/HomeProfileCard";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import { useRef, useState } from "react";
import EnrichmentFlowUploadCSVPreviewModal from "../../components/enrichmentFlow/EnrichmentFlowUploadCSVPreviewModal";
import { CreateIntegrationButton_UserAccount } from "../../components/manageIntegration/manageIntegrationButton/ManageIntegrationsButton";
import { gql, useQuery } from "@apollo/client";
import ChromeExtensionCard, {
  ChromeExtensionCard_ChromeExtensionTransactions,
} from "../../components/home/ChromeExtensionCard";
import HomeWorkflowsTable from "../../components/home/HomeWorkflowsTable";
import HomeListsTable from "../../components/home/HomeListsTable";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import EnrichedContactsTable, {
  EnrichedContactsTable_Organization,
} from "../../components/home/EnrichedContactsTable";
import {
  ChromeExtensionCard_ChromeExtensionTransactionsFragmentDoc,
  EnrichedContactsTable_OrganizationFragmentDoc,
  Home_PhoneNumbersOverviewQuery,
} from "../../__generated__/graphql";
import { useFragment as getFragmentData } from "../../__generated__";
import HomeGetStarted from "../../components/home/HomeGetStarted";

export const Home_PhoneNumbersOverview = gql`
  query Home_PhoneNumbersOverview {
    ...ChromeExtensionCard_ChromeExtensionTransactions
    userAccount {
      ...CreateIntegrationButton_UserAccount
    }
    organization {
      ...EnrichedContactsTable_Organization
      enrichedContactRequests {
        id
      }
    }
    phoneNumbersRecoveredCount(requestType: batch)
    manuallyCreatedContactListsCount
    autopilotSessionsCount
  }
  ${ChromeExtensionCard_ChromeExtensionTransactions}
  ${CreateIntegrationButton_UserAccount}
  ${EnrichedContactsTable_Organization}
`;

const Home = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { data, loading } = useQuery<Home_PhoneNumbersOverviewQuery>(
    Home_PhoneNumbersOverview,
  );

  const hasRecoveredPhoneNumbers = Boolean(
    data?.phoneNumbersRecoveredCount && data.phoneNumbersRecoveredCount > 0,
  );

  const hasAutopilotSessions = Boolean(
    data?.autopilotSessionsCount && data.autopilotSessionsCount > 0,
  );

  const showGetStarted = Boolean(
    !hasAutopilotSessions || !hasRecoveredPhoneNumbers,
  );

  return (
    <CiroContainer className="bg-neutral-100">
      {!loading ? (
        <>
          <HomeProfileCard />
          {showGetStarted && (
            <HomeGetStarted
              userAccountData={data?.userAccount}
              hasAutopilotSessions={hasAutopilotSessions}
              hasRecoveredPhoneNumbers={hasRecoveredPhoneNumbers}
            />
          )}
          <ChromeExtensionCard
            chromeExtensionTransactions={
              getFragmentData(
                ChromeExtensionCard_ChromeExtensionTransactionsFragmentDoc,
                data,
              ) ?? null
            }
          />
          {(data?.organization?.enrichedContactRequests?.length ?? 0) > 0 && (
            <div
              className={classNames(
                "w-full",
                "mt-5",
                "px-5",
              )}
            >
              <CiroTitle title="My enriched contacts" size="small" />
              <EnrichedContactsTable
                enrichedContactsOrg={
                  getFragmentData(
                    EnrichedContactsTable_OrganizationFragmentDoc,
                    data?.organization,
                  ) ?? null
                }
                itemsPerPage={5}
              />
            </div>
          )}
        </>
      ) : (
        <div className={classNames("w-full")}>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"7rem"} />
          <div
            className={classNames(
              "flex",
              "flex-row",
              "justify-between",
              "gap-4",
              "mt-4",
              "w-full",
            )}
          >
            <div className={classNames("w-full")}>
              <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
            </div>
            <div className={classNames("w-full")}>
              <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
            </div>
            <div className={classNames("w-full")}>
              <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
            </div>
          </div>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"7rem"} />
          <div className={classNames("w-full", "mt-5")}>
            <SkeletonLoading numSkeletons={5} skeletonHeight={"2rem"} />
          </div>
        </div>
      )}
      <HomeWorkflowsTable />
      <HomeListsTable newList={() => hiddenFileInput.current!.click()} />
      <EnrichmentFlowUploadCSVPreviewModal
        csvFile={csvFile}
        onClose={() => {
          setCsvFile(null);
        }}
      />
      <input
        type="file"
        onChange={(event) => {
          const fileUploaded = event.target.files![0];
          setCsvFile(fileUploaded);
          event.target.value = "";
        }}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
    </CiroContainer>
  );
};

export default Home;
