import React from "react";
import CiroCard from "./CiroCard";
import classNames from "classnames";
import NoRecordsIcon from "../../assets/img/icons/NoRecordsIcon";

interface ICiroNoRecordsCardProps {
  title: string;
  subtitle: string;
}

const CiroNoRecordsCard = ({ title, subtitle }: ICiroNoRecordsCardProps) => {
  return (
    <div
      className={classNames(
        "flex",
        "justify-center",
        "mt-32",
      )}
    >
      <div className={classNames("max-w-sm")}>
        <CiroCard>
          <div
            className={classNames(
              "flex",
              "flex-col",
              "items-center",
            )}
          >
            <div className={classNames("pb-5")}>
              <NoRecordsIcon />
            </div>
            <div
              className={classNames("font-semibold", "text-lg")}
            >
              {title}
            </div>
            <div className={classNames("text-sm", "mt-2")}>
              {subtitle}
            </div>
          </div>
        </CiroCard>
      </div>
    </div>
  );
};

export default CiroNoRecordsCard;
